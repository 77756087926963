import type {VoidFunctionComponent} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {gt as semverGreaterThan} from 'semver';
import UpdateGraphic from '../../../../frontend/graphics/UpdateGraphic.svg';
import {useAsyncMemo} from '../../../../frontend/hooks';
import {getAppStoreAppVersion, getAppVersion, openAppStore} from '../../../../frontend/native-hooks/appVersion';
import {getCookie} from '../../../../frontend/utilities/storage';
import {__} from '../../../../translations';
import {Tekst} from '../../Tekst';
import {Popup} from '../Popup';

export const PopupStoreUpdate: VoidFunctionComponent = () => {
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const appVersion = useAsyncMemo(() => getAppVersion(), []);
    const latestVersion = useAsyncMemo(() => getAppStoreAppVersion(), []);

    const popupBody = useMemo(() => {
        const os = getCookie('os');

        return os === 'android'
            ? __('Checkbuster needs to update from the Google Play App Store.')
            : os === 'ios'
                ? __('Checkbuster needs to update from the App Store.')
                : __('Checkbuster needs to update from the store.');
    }, []);

    useEffect(() => {
        if (typeof latestVersion !== 'string' || typeof appVersion !== 'string') {
            return;
        }

        setUpdateAvailable(semverGreaterThan(latestVersion, appVersion));
    }, [latestVersion, appVersion]);

    return updateAvailable
        ? (
            <Popup
                compact={false}
                showCancelButton
                cancelButtonText={__('Skip')}
                titleText={__('Checkbuster needs an update')}
                confirmButtonText={__('Update now')}
                onConfirm={() => openAppStore()}
                onClose={() => setUpdateAvailable(false)}
            >
                <Tekst>{popupBody}</Tekst>

                <UpdateGraphic/>
            </Popup>
        )

        : null;
};

