import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {ResourceTranslationDocument} from './ResourceTranslationDocument';

export const resourceTranslationTypeDef: TypeDef<ResourceTranslationDocument> = {
    additionalProperties: false,
    optionalProperties: {
        expiresAt: {
            type: 'timestamp'
        },
        offline_id: {
            type: 'string'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        entity_id: {
            type: 'string'
        },
        languageCode: {
            enum: ['en', 'nl', 'de', 'fr', 'it', 'es', 'uk-UA', 'zh-CN', 'ja', 'vi-VN', 'pt', 'he-IL', 'hi-IN']
        },
        removed: {
            type: 'float64'
        },
        resource_id: {
            type: 'string'
        },
        resourceType: {
            enum: ['inspection', 'inspectionTemplate', 'inspectionPlanning', 'incident', 'incidentTemplate', 'location', 'reportTemplate', 'task', 'user']
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        translations: {
            values: {
                type: 'string'
            }
        }
    }
};
