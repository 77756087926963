import {dexie} from '../../../../../dexie';
import {fetchApi} from '../../../../helpers/frontend';
import {HttpStatusCode} from '../../../../HttpStatusCode';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path} from './shared';

export const getSession = async (urlParams: ParamType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        urlParams
    });

    const {response, json} = result;
    if (response.ok && response.status !== HttpStatusCode.NOT_MODIFIED && json) {
        await dexie.transaction('readwrite', dexie.sessions, async () => {
            if (json.token) {
                await dexie.sessions.where('token').above('0').modify((session) => {
                    delete session.token;
                });
            }

            await dexie.sessions.put(json);
        });
    } else if (response.status === HttpStatusCode.NOT_FOUND) {
        await dexie.sessions.delete(urlParams.sessionId);
    }

    return result;
};
