import type {FormEventHandler, ReactElement, ReactNode, VoidFunctionComponent} from 'react';
import React from 'react';
import type {SlideoutUrlState} from '../../hooks';
import {useUrlState} from '../../hooks';
import {Backdrop} from '../Backdrop';
import './Slideout.scss';

export type SlideoutProps = {
    children: ReactNode;
    onReset?: FormEventHandler<HTMLFormElement>;
};

export type SlideoutComponent = ReactElement<SlideoutProps>;

export const Slideout: VoidFunctionComponent<SlideoutProps> = ({
    children,
    onReset
}): SlideoutComponent => {
    const [, setSlideout] = useUrlState<SlideoutUrlState>('slideout', '');

    return (
        <div className="slideout">
            <Backdrop onClick={() => setSlideout('')}/>
            <form className="slideout__form" onReset={onReset} onSubmit={(event) => event.preventDefault()}>
                {children}
            </form>
        </div>
    );
};
