// https://tools.ietf.org/html/rfc6902

import type {JTDSchemaType} from 'ajv/dist/jtd';
import {applyPatch, deepClone} from 'fast-json-patch';
import type {JsonOperationCopy, JsonOperationTest, JsonPatch} from '../interfaces/models/json-patch';
import {hasProperty} from './helpers';
import type {JsonPointer} from './JsonPointer';
import {createJsonPointer, destructJsonPointer} from './JsonPointer';
import type {SerializableScalar} from './Serializable';

export * from '../interfaces/models/json-patch';

const pathTypeDef: JTDSchemaType<JsonOperationTest['path']> = {
    type: 'string'
};

const valueTypeDef: JTDSchemaType<JsonOperationTest['value']> = {} as never;

const fromTypeDef: JTDSchemaType<JsonOperationCopy['from']> = {
    type: 'string'
};

export const jsonPatchTypeDef: JTDSchemaType<JsonPatch> = {
    elements: {
        discriminator: 'op',
        mapping: {
            test: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef,
                    value: valueTypeDef
                }
            },
            add: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef,
                    // @ts-expect-error
                    value: valueTypeDef
                }
            },
            replace: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef,
                    // @ts-expect-error
                    value: valueTypeDef
                }
            },
            remove: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef
                }
            },
            move: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef,
                    from: fromTypeDef
                }
            },
            copy: {
                additionalProperties: false,
                properties: {
                    path: pathTypeDef,
                    from: fromTypeDef
                }
            }
        }
    }
};

/**
 * @deprecated we need something better than this, but for now it'll do
 */
export const createJsonTest = <K extends string>({source, properties, prefix}: {
    source: Partial<Record<K, SerializableScalar>>;
    properties: K[];
    prefix: JsonPointer;
}): JsonOperationTest | null => {
    for (const property of properties) {
        if (hasProperty(source, property)) {
            const value = source[property];
            if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean' || value === null) {
                return {
                    op: 'test',
                    path: createJsonPointer(...destructJsonPointer(prefix), property),
                    value
                };
            }
        }
    }

    return null;
};

export const applyJsonPatch = <T>(source: T, _patch: JsonPatch, mutateSource = false, throwError = false): T => {
    // TODO: Remove filter two weeks after all test operations have been made valid (check Sentry!)
    const patch = _patch.filter((operation) => {
        if (operation.op === 'test' && typeof operation.value !== 'string' && typeof operation.value !== 'number' && typeof operation.value !== 'boolean' && operation.value !== null) {
            console.error('Bad test operation', operation);
            return false;
        }

        return true;
    });

    if (!patch.length) {
        return mutateSource ? source : deepClone(source);
    }

    try {
        const result = applyPatch(source, deepClone(patch), undefined, mutateSource);
        return mutateSource ? source : result[result.length - 1].newDocument;
    } catch (error: any) {
        if (throwError) {
            // TODO: Fix this ASAP
            error.name = 'TEST_OPERATION_FAILED';

            throw error;
        } else {
            console.error(error);
        }
        return source;
    }
};
