import type {PublicSessionDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/sessies';

export const method = HttpMethod.DELETE;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results that match the specified environment.
     */
    environment?: PublicSessionDocument['environment'];

    /**
     * Pick results expiring after the specified timestamp.
     */
    expiresAfter?: number;

    /**
     * Pick results expiring before the specified timestamp.
     */
    expiresBefore?: number;

    /**
     * Pick results that fit any of given user ids.
     */
    userIdAny?: string[];
};

export type OutputType = never;
