import type {PublicSessionDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/sessies';

export const method = HttpMethod.POST;

export type BodyType = {
    email: string;
    password: string;
};

export type ParamType = never;

export type QueryType = never;

export type OutputType = PublicSessionDocument;
