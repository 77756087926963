import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {classNames} from '../../helpers/styling';
import type {TreeGroupProps} from '../TreeGroup';
import type {TreeItemComponent} from '../TreeItem';
import './TreeView.scss';

export type TreeViewProps = {
    children: TreeItemComponent | TreeItemComponent[];
    className?: string;
};

export type TreeViewComponent = ReactElement<TreeGroupProps>;

export const TreeView: VoidFunctionComponent<TreeViewProps> = ({
    children,
    className
}): TreeViewComponent => (
    <ul role="tree" className={classNames('treeview', {}, className)}>
        {children}
    </ul>
);
