import {captureException} from '@sentry/react';
import type {FunctionComponent} from 'react';
import React, {useMemo} from 'react';
import {HttpStatusCode} from '../../../../api/HttpStatusCode';
import {Icoontje} from '../../../../react/components/Icoontje';
import {Knopje} from '../../../../react/components/Knopje';
import {useCurrentSession} from '../../../../react/context';
import {classNames} from '../../../../react/helpers/styling';
import {useQueueCount, useSyncState} from '../../../../react/hooks';
import {UpdateStatus} from '../../../../service-worker/updates';
import {__} from '../../../../translations';
import {useMenu} from '../../../context/MenuContext';
import {useUpdateStatus} from '../../../hooks';
import ArrowLeftIcon from '../../../icons/ArrowLeft.svg';
import Check from '../../../icons/check-thin.svg';
import Download from '../../../icons/download.svg';
import MenuIcon from '../../../icons/hamburger-menu.svg';
import Update from '../../../icons/rocket-vertical.svg';
import Sync from '../../../icons/sync.svg';
import {Button} from '../../buttons/Button';
import './Appbar.scss';

const onDownloadClick = () => alert(__('Some data is being downloaded. You can use the app while this happens, but you may not be able to see all of your data just yet.'));

type AppBarProps = {
    title: string;
    showDownloadButton?: boolean;
    showMenuButton?: boolean;
    onBack?: () => void;
    override?: boolean;
};

export const Appbar: FunctionComponent<AppBarProps> = ({
    title,
    showDownloadButton = false,
    showMenuButton,
    onBack,
    override,
    children
}) => {
    document.title = title.length ? `Checkbuster | ${title}` : 'Checkbuster';
    const queueCount = useQueueCount();
    const [{isSidebarOpen}, updateMenu] = useMenu();
    const [syncActive, setSyncActive] = useSyncState();
    const updateStatus = useUpdateStatus();
    const showUpdateButton = useMemo(() => updateStatus === UpdateStatus.WAITING && process.env.NODE_ENV === 'production', [updateStatus]);
    const MainIcon = showMenuButton || location.hash === '#(%E2%95%AF%C2%B0%E2%96%A1%C2%B0%EF%BC%89%E2%95%AF' && !onBack
        ? MenuIcon
        : ArrowLeftIcon;
    const {currentSession} = useCurrentSession();

    const onSyncClick = async () => {
        try {
            const status = setSyncActive ? await setSyncActive() : HttpStatusCode.UNAUTHORIZED;

            setTimeout(async () => {
                if (status === 0) {
                    alert(__('All is well, any changes you\'ve made have already been synchronized with the server!'));
                } else if (status === HttpStatusCode.NO_NETWORK) {
                    alert(__('You are offline. Any changes you make are stored on your device and will be synchronized with the server once your internet connection is restored.'));
                } else if (status === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                    alert(__('The server is having some issues processing the changes you\'ve made. Don\'t worry: your data is safe on your device, but please try again later!'));
                } else if (status === HttpStatusCode.UNAUTHORIZED) {
                    alert(__('You\'ve been logged out; please log back in to complete synchronisation.'));
                } else if (status >= 200 && status < 400) {
                    alert(__('All changes you\'ve made have been synchronized with the server!'));
                } else if (status === -2) {
                    alert(__('Your data doesn\'t pass validation, please contact us at info@checkbuster.com to resolve this issue'));
                } else {
                    alert(`${status}: ${__('Something went wrong while synchronizing with the server.')}\n${__('Don\'t worry: your changes have been saved on your device.')}`);
                }
            }, 1000);
        } catch (error) {
            console.warn(error);
            captureException(error);
            setTimeout(() => {
                alert(`${status}: ${__('Something went wrong while synchronizing with the server.')}\n${__('Don\'t worry: your changes have been saved on your device.')}`);
            }, 1000);
        }
    };

    const onMenuButtonClick = (): void => {
        if (MainIcon === MenuIcon) {
            updateMenu({
                isSidebarOpen: !isSidebarOpen
            });
        } else if (location.hash === '#(%E2%95%AF%C2%B0%E2%96%A1%C2%B0%EF%BC%89%E2%95%AF' && onBack) {
            onBack();
        } else if (override && onBack) {
            onBack();
        } else {
            history.back();
        }
    };

    return (
        <div className={classNames('appbar', {
            danger: currentSession?.impersonator_id
        })}>
            <Button className="appbar__button" id="menu-button" Icon={MainIcon} onClick={onMenuButtonClick}/>
            <span className="appbar__title">
                {title}
            </span>
            {showDownloadButton
                ? (
                    <Knopje size="huge" shape="square" corners="sharp" onClick={onDownloadClick} color="white">
                        <Icoontje size="standard" Svg={Download} animation="delayed" color="black"/>
                    </Knopje>
                )
                : null}
            {location.hash.includes('updated=true') && !showUpdateButton
                ? <Icoontje size="large" className="update-checkmark" color="black" Svg={Check} animation="fade-out"/>
                : null}
            {showUpdateButton
                ? (
                    <Knopje
                        direction="vertical"
                        tooltip={__('Click here to install update')}
                        size="huge"
                        corners="sharp"
                        onClick={() => {
                            location.href = `${window.location.origin}${window.location.pathname}#updated=true`;
                            location.reload();
                        }} color="white">
                        <Icoontje size="standard" Svg={Update} animation={location.hash.includes('updated=true') ? 'fade-out' : undefined} badge={location.hash.includes('updated=true') ? undefined : __('update')} badgeX="center" badgeY="bottom" color="black"/>
                    </Knopje>
                )
                : null}

            <Knopje size="huge" shape="square" corners="sharp" onClick={onSyncClick} color={syncActive ? 'orange' : 'white'}>
                <Icoontje size="standard" Svg={Sync} badge={queueCount} badgeX="left" badgeY="bottom" animation={syncActive ? 'spin' : 'none'} color="black"/>
            </Knopje>
            {children}
        </div>
    );
};
