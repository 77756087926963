import type {FunctionComponent} from 'react';
import React from 'react';
import {Icoontje} from '../../../react/components/Icoontje';
import {Image} from '../../../react/components/Image';
import {Photo} from '../../../react/components/Photo';
import {useUser} from '../../../react/hooks';
import {__} from '../../../translations';
import NotAvailableIcon from '../../icons/not-available.svg';
import './User.scss';

export const User: FunctionComponent<{
    id?: string;
    email?: string;
    name?: string;
    className?: string;
}> = ({id, email, name, className}) => {
    const {user: profile} = useUser(id);

    const classNames = [
        className,
        'user'
    ].join(' ').trim();

    return (
        <div className={classNames} id={`user-${id ?? email}`}>
            {profile?.photo_id
                ? <Photo photo_id={profile.photo_id} className="user__img"/>
                : profile
                    ? <Image loading={!profile} className="user__img" src={typeof profile.photo === 'object' ? profile.photo?.photoSource : `/workbox-checkbuster/photos/${profile._id}-${profile.photo}`}/>
                    : <Icoontje Svg={NotAvailableIcon} className="user__img" color="red"/>}
            <div className="user__username">
                {profile?.name || profile?.email || name || email || __('Unknown user')}
            </div>
        </div>
    );
};
