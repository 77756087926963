import type {History} from 'history';
import {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useHashChange} from './useHashChange';

const parseUrl = (name: string): string[] => new URLSearchParams(location.hash.slice(1)).getAll(name);

const updateHash = (history: History<unknown>, name: string, newValues: string[]): void => {
    const params = new URLSearchParams(history.location.hash.slice(1));
    params.delete(name);
    newValues.forEach((nv) => params.append(name, nv));
    params.sort();

    history.replace({
        ...history.location,
        hash: `#${params.toString()}`
    });
};

export const useUrlStateArray = (name: string, initialValue: string[]): [string[], (newValue: string[]) => void] => {
    const history = useHistory();
    const pageLoadValue = useMemo(() => parseUrl(name), []);
    const [value, setValue] = useState(pageLoadValue.length ? pageLoadValue : initialValue);

    useHashChange(() => {
        const newValue = parseUrl(name);

        setValue(newValue.length ? newValue : initialValue);
    }, []);

    useEffect(() => {
        if (pageLoadValue.length || !initialValue.length) {
            return;
        }

        updateHash(history, name, initialValue);
    }, []);

    return [value, (newValues: string[]) => {
        if (value === newValues || value.length === 0 && newValues.length === 0) {
            return;
        }

        updateHash(history, name, newValues);
    }];
};

