import {captureException} from '@sentry/react';
import {dexie} from '../../../../dexie';
import {ajv} from '../../../helpers/ajv/frontend';
import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path, bodyTypeDef} from './shared';

const bodyTypeDefClean: typeof bodyTypeDef = {
    ...bodyTypeDef,
    additionalProperties: false
};
const bodyValidator = ajv.compile(bodyTypeDefClean);

export const createTask = (input) => {
    const inputCopy = JSON.parse(JSON.stringify(input));
    const validBody = bodyValidator(inputCopy);

    if (!validBody) {
        console.error(bodyValidator.errors);
        const error = new Error(`${method} ${path} failed output integrity check`);
        error.name = 'ValidationError';
        captureException(error, {
            extra: {
                errors: bodyValidator.errors
            }
        });
        throw error;
    }

    return fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body: inputCopy,
        method,
        path
    }).then(async (result) => {
        result.response.ok && result.json && await dexie.tasks.put(result.json);
        return result;
    });
};
