import {captureException} from '@sentry/react';
import type {ChartSegmentDocument} from '../../../../../../database/collections/chartSegments';
import {dexie} from '../../../../../../dexie';
import type {JsonOf} from '../../../../../../interfaces/helpers';
import {ajv} from '../../../../../helpers/ajv/frontend';
import {fetchApi} from '../../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path, bodyTypeDef} from './shared';

const bodyValidator = ajv.compile(bodyTypeDef);

export const createChartSegment = (urlParams: ParamType, body: JsonOf<BodyType>) => {
    const inputCopy: JsonOf<BodyType> = JSON.parse(JSON.stringify(body));
    const validBody = bodyValidator(inputCopy);

    if (!validBody) {
        console.error(bodyValidator.errors);
        const error = new Error(`${method} ${path} failed output integrity check`);
        error.name = 'ValidationError';
        captureException(error, {
            extra: {
                errors: bodyValidator.errors
            }
        });
        throw error;
    }

    return fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body: inputCopy,
        method,
        path,
        urlParams
    }).then(async (result) => {
        result.response.ok && result.json && await dexie.chartSegments.put(result.json as unknown as JsonOf<ChartSegmentDocument>);
        return result;
    });
};
