import type {Table} from 'dexie';
import {useLiveQuery} from 'dexie-react-hooks';
import {useMemo} from 'react';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie';
import {dexie, maxKey, minKey} from '../../dexie';
import type {RequireAtMostOne} from '../../interfaces/helpers';
import {createJsonPointer} from '../../interfaces/models/json-pointer';
import {emptyArray} from '../../util/arrays';

const valueInRange = <V>({value, minValue, maxValue}: {
    value: V | V[];
    maxValue: V | typeof maxKey;
    minValue: V | typeof minKey;
}) => {
    if (minValue === minKey && maxValue === maxKey) {
        return true;
    }

    if (value === minValue || value === maxValue) {
        return true;
    }

    if (typeof value === 'number') {
        return typeof minValue === 'number' && typeof maxValue === 'number'
            && value >= minValue && value <= maxValue;
    }

    return Array.isArray(value) && value.some((v) => valueInRange({value: v, minValue, maxValue}));
};

export const usePrimaryKeys = <
    T,
    K extends keyof T & string,
    V extends (T[K] extends any[] ? T[K][0] : T[K])
>({
        include,
        exclude = emptyArray,
        user_id,
        entity_id,
        table,
        sortProperty,
        sortValueEquals,
        sortValueBetween,
        sortDirection = 'ascending'
    }: {
    include?: string[];
    exclude?: string[];
    user_id: string | undefined;
    entity_id: string | undefined;
    table: Table<T, string>;
    sortProperty: T[K] extends any[] ? `*${K}` : K;
    sortDirection?: 'ascending' | 'descending';
} & RequireAtMostOne<{
    sortValueEquals: V;
    sortValueBetween: [V, V];
}>): string[] => {
    const multiEntry = sortProperty.startsWith('*');
    const minValue = sortValueEquals ?? sortValueBetween?.[0] ?? minKey;
    const maxValue = sortValueEquals ?? sortValueBetween?.[1] ?? maxKey;

    const includeCreates = useLiveQuery<string[], string[]>(async () => {
        const includeCreates = new Set<string>();

        if (!user_id || include?.length === 0) {
            return emptyArray;
        }

        await dexie.queue
            .where(['userId', 'resourceTableName', 'action', 'timestamp'])
            .between([user_id, table.name, 'create', minKey], [user_id, table.name, 'create', maxKey], true, true)
            .each((queueEntry) => {
                const body = (queueEntry as QueueCreateEntry).body as T;
                const value = body[sortProperty.replace('*', '')] as V;

                if (value === undefined) {
                    return;
                }

                valueInRange({
                    minValue,
                    maxValue,
                    value
                }) && includeCreates.add(queueEntry.resourceId);
            });

        return Array.from(includeCreates);
    }, [include?.length, user_id, table, minValue, maxValue], emptyArray);

    const {includeUpdates, excludeRemoves} = useLiveQuery<{
        includeUpdates: string[];
        excludeRemoves: string[];
    }, {
        includeUpdates: string[];
        excludeRemoves: string[];
    }>(async () => {
        const includeUpdates = new Set<string>();
        const excludeRemoves = new Set<string>();

        if (!user_id || include?.length === 0) {
            return {includeUpdates: emptyArray, excludeRemoves: emptyArray};
        }

        await dexie.queue
            .where(['userId', 'resourceTableName', 'action', 'timestamp'])
            .between([user_id, table.name, 'update', minKey], [user_id, table.name, 'update', maxKey], true, true)
            .each((queueEntry) => {
                let lastOperationTestedValue: string | null = null;

                for (const operation of (queueEntry as QueueUpdateEntry).patch) {
                    if (operation.op === 'replace' && operation.path === createJsonPointer('_deleted') && operation.value === true) {
                        excludeRemoves.add(queueEntry.resourceId);
                    }

                    if (operation.op === 'replace' && operation.path === createJsonPointer(sortProperty)) {
                        const value = operation.value as V;

                        valueInRange({
                            minValue,
                            maxValue,
                            value
                        })
                            ? includeUpdates.add(queueEntry.resourceId)
                            : includeUpdates.delete(queueEntry.resourceId);
                    }

                    if (multiEntry && operation.op === 'add' && operation.path === createJsonPointer(sortProperty.replace('*', ''), '-')) {
                        const value = operation.value as V;

                        if (valueInRange({
                            minValue,
                            maxValue,
                            value
                        })) {
                            excludeRemoves.delete(queueEntry.resourceId);
                            includeUpdates.add(queueEntry.resourceId);
                        }
                    }

                    if (multiEntry && operation.op === 'test' && operation.path.startsWith(createJsonPointer(sortProperty.replace('*', '')))) {
                        const value = operation.value as V;

                        lastOperationTestedValue = valueInRange({
                            minValue,
                            maxValue,
                            value
                        })
                            ? operation.path
                            : null;
                        continue;
                    }

                    if (multiEntry && operation.op === 'remove' && operation.path === lastOperationTestedValue) {
                        excludeRemoves.add(queueEntry.resourceId);
                        includeUpdates.delete(queueEntry.resourceId);
                    }

                    lastOperationTestedValue = null;
                }
            });

        return {
            includeUpdates: Array.from(includeUpdates),
            excludeRemoves: Array.from(excludeRemoves)
        };
    }, [include?.length, user_id, table, sortProperty, minValue, maxValue], {
        includeUpdates: emptyArray,
        excludeRemoves: emptyArray
    });

    const storedKeys = useLiveQuery<string[], string[]>(async () => {
        if (!entity_id || include?.length === 0) {
            return emptyArray;
        }

        const storedKeys = multiEntry
            ? await table
                .where(sortProperty.replace('*', ''))
                .between(minValue, maxValue, true, true)
                .primaryKeys()
            : await table
                .where(`[context.entityId+${sortProperty}]`)
                .between([entity_id, minValue], [entity_id, maxValue], true, true)
                .primaryKeys();

        return sortDirection === 'ascending' ? storedKeys : storedKeys.reverse();
    }, [entity_id, include?.length, sortProperty, sortDirection, minValue, maxValue], emptyArray);

    return useMemo(() => {
        if (storedKeys === emptyArray || include?.length === 0) {
            return emptyArray;
        }

        const sortedKeys: string[] = [
            ...(includeCreates ?? emptyArray),
            ...(includeUpdates ?? emptyArray),
            ...storedKeys
        ];

        const excludedOrRemoved = [...exclude, ...(excludeRemoves ?? emptyArray)];
        const filter: ((k: string) => boolean) | null = include && excludedOrRemoved.length
            ? (k) => include.includes(k) && !excludedOrRemoved.includes(k)
            : include
                ? (k) => include.includes(k)
                : excludedOrRemoved.length
                    ? (k) => !excludedOrRemoved.includes(k)
                    : null;

        return filter
            ? sortedKeys.filter(filter)
            : sortedKeys;
    }, [include?.length, exclude.length, includeCreates, includeUpdates, excludeRemoves, storedKeys]);
};

