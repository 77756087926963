import type {LibraryDocument} from '../../../../../../../database';
import {HttpMethod} from '../../../../../../HttpMethod';

export const path = '/apiv2/public-libraries/public/library-items/:libraryItemId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    libraryItemId: string;
};

export type QueryType = never;

export type OutputType = LibraryDocument;
