import type {IParagraphOptions, IRunOptions, ParagraphChild} from 'docx';
import {BorderStyle, ExternalHyperlink, Paragraph, TextRun} from 'docx';
import {decode} from 'he';
import showdown from 'showdown';
import {hexColor} from '../conversions';
import type {GetImageRunBoundViewport} from '../dependencies/GetImageRun';

showdown.setFlavor('github');
showdown.setOption('ghMentions', false);
showdown.setOption('noHeaderId', true);
showdown.setOption('simpleLineBreaks', true);

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

type HtmlChunk = string;

type HtmlString = string;

export type Markdown = string;

export const splitHtmlIntoChunks = (html: HtmlString): HtmlChunk[] => {
    const result = html
        .split(/(<br \/>|<\w+>|<\/\w+>|<a href=".+?">|<img src=".+?" alt=".+?" \/>)/g)
        .filter((htmlInlinePart) => !!htmlInlinePart);

    if (result.length === 1) {
        return result;
    }

    return result.map(splitHtmlIntoChunks).flat();
};

export const markdownToHtml = (markdownString: Markdown): HtmlString => new showdown.Converter()
    .makeHtml(markdownString)
    .replace(/\n/g, '');

export const htmlComponentsToDocxParagraphs = async ({getImageRun, htmlChunks, overrides}: {
    getImageRun: GetImageRunBoundViewport;
    htmlChunks: HtmlChunk[];
    overrides: {
        paragraph?: Partial<IParagraphOptions>;
        run?: Partial<IRunOptions>;
    };
}, noFallback = false): Promise<Paragraph[]> => {
    const parents: Paragraph[] = [];
    let paragraphChildren: ParagraphChild[] = [];
    let hyperlinkChildren: ParagraphChild[] = [];

    let hyperlinkActive = false;
    let boldEnabled = false;
    let italicEnabled = false;
    let bulletDepthCounter = -1;

    for (let i = htmlChunks.length - 1; i >= 0; i--) {
        const chunk = htmlChunks[i];

        if (chunk === '</h1>' || chunk === '</li>' || chunk === '</ol>') {
            // Omit
        } else if (chunk === '</p>') {
            if (parents.length) {
                paragraphChildren.unshift(new TextRun({
                    break: 1,
                    text: ''
                }));
            }
        } else if (chunk === '<strong>') {
            boldEnabled = false;
        } else if (chunk === '</strong>') {
            boldEnabled = true;
        } else if (chunk === '<em>') {
            italicEnabled = false;
        } else if (chunk === '</em>') {
            italicEnabled = true;
        } else if (chunk === '<ul>') {
            bulletDepthCounter--;
        } else if (chunk === '</ul>') {
            bulletDepthCounter++;
        } else if (chunk === '<p>') {
            parents.unshift(new Paragraph({
                ...overrides.paragraph,
                children: paragraphChildren
            }));
            paragraphChildren = [];
        } else if (chunk === '<li>') {
            const options = bulletDepthCounter >= 0
                ? {
                    bullet: {
                        level: bulletDepthCounter
                    }
                }
                : {};
            parents.unshift(new Paragraph({
                ...overrides.paragraph,
                ...options,
                children: paragraphChildren
            }));
            paragraphChildren = [];
        } else if (chunk === '<h1>') {
            parents.unshift(new Paragraph({
                ...overrides.paragraph,
                children: paragraphChildren
            }));
            paragraphChildren = [];
        } else if (chunk === '<hr />') {
            parents.unshift(new Paragraph({
                ...overrides.paragraph,
                border: {
                    bottom: {
                        color: hexColor('#b6b6b6'),
                        size: 3,
                        style: BorderStyle.SINGLE
                    }
                }
            }));
        } else if (chunk === '<br />') {
            paragraphChildren.unshift(new TextRun({
                break: 1,
                text: ''
            }));
        } else if (chunk.startsWith('<a') && chunk.endsWith('>')) {
            const {href} = (/<a href="(?<href>.+?)">/).exec(chunk)!.groups!;

            paragraphChildren.unshift(new ExternalHyperlink({
                children: hyperlinkChildren,
                link: href
            }));
            hyperlinkChildren = [];
            hyperlinkActive = false;
        } else if (chunk === '</a>') {
            hyperlinkActive = true;
        } else if (chunk.startsWith('<img') && chunk.endsWith('/>')) {
            const {alt, src} = (/<img src="(?<src>.+?)" alt="(?<alt>.+?)" \/>/).exec(chunk)!.groups!;

            (hyperlinkActive ? hyperlinkChildren : paragraphChildren).unshift(await getImageRun({alt, src}));
        } else {
            const options: DeepWriteable<IRunOptions> = {
                text: decode(chunk)
            };

            if (boldEnabled) {
                options.bold = boldEnabled;
            }

            if (italicEnabled) {
                options.italics = italicEnabled;
            }

            (hyperlinkActive ? hyperlinkChildren : paragraphChildren).unshift(new TextRun({
                ...options as IRunOptions,
                ...overrides.run
            }));
        }
    }

    return parents.length || noFallback
        ? parents
        : [
            new Paragraph({
                ...overrides.paragraph,
                children: []
            })
        ];
};

export const renderMarkdown = async ({getImageRun, markdown}: {
    getImageRun: GetImageRunBoundViewport;
    markdown: Markdown;
}, overrides: {
    paragraph?: Partial<IParagraphOptions>;
    run?: Partial<IRunOptions>;
} = {}, noFallback = false): ReturnType<typeof htmlComponentsToDocxParagraphs> => {
    const html = markdownToHtml(markdown.trim());
    const htmlChunks = splitHtmlIntoChunks(html);
    return htmlComponentsToDocxParagraphs({getImageRun, htmlChunks, overrides}, noFallback);
};
