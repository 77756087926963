import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {DateInput} from '../../../frontend/components/DateInput';
import {RadioButton} from '../../../frontend/components/RadioButton';
import {__} from '../../../translations';
import {useUrlState} from '../../hooks';
import {Knopje} from '../Knopje';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';

export type SlideoutProps = {
    urlName?: string;
    createdBefore?: string;
    setCreatedBefore?: (timestampInMs: string) => void;
    createdAfter?: string;
    setCreatedAfter?: (timestampInMs: string) => void;
};

export type SlideoutComponent = ReactElement<SlideoutProps>;

export const DateSlideout: VoidFunctionComponent<SlideoutProps> = ({
    urlName = 'created',
    createdBefore,
    setCreatedBefore,
    createdAfter,
    setCreatedAfter
}): SlideoutComponent => {
    if (createdBefore === undefined || !setCreatedBefore) {
        [createdBefore, setCreatedBefore] = useUrlState<string>(`${urlName}Before`, '');
    }
    if (!createdAfter === undefined || !setCreatedAfter) {
        [createdAfter, setCreatedAfter] = useUrlState<string>(`${urlName}After`, '');
    }
    const day = 1000 * 60 * 60 * 24;
    const today = new Date().setHours(0, 0, 0, 0);
    const todayStart = `${today}`;
    const todayEnd = `${today + day}`;
    const weekAgo = `${today - 6 * day}`;
    const monthAgo = `${today - 30 * day}`;
    const threeMonthsAgo = `${today - 92 * day}`;
    const yearAgo = `${today - 365 * day}`;
    const yearToDateStart = `${new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)}`;
    const setDateFilter = (value, valueEnd = todayEnd) => {
        setCreatedAfter!(value.split('-')[0]);
        setCreatedBefore!(valueEnd);
    };
    const customActive = !!createdBefore && !!createdAfter && (createdBefore !== todayEnd
        || (createdAfter !== todayStart && createdAfter !== weekAgo && createdAfter !== monthAgo && createdAfter !== threeMonthsAgo && createdAfter !== yearAgo && createdAfter !== yearToDateStart)
    );

    return (
        <Slideout onReset={() => {
            setCreatedAfter!('');
            setCreatedBefore!('');
        }}>
            <Tekst weight="bold" className="slideout__title">
                {__('Filter date')}
            </Tekst>
            <Knopje color="white" paint="fill" type="reset" className="slideout__clear">
                <Tekst color="blue">
                    {__('Clear all')}
                </Tekst>
            </Knopje>
            <div className="slideout__list">
                <RadioButton value={`${todayStart}-${todayEnd}`} label={__('Today')} onChange={(value) => setDateFilter(value)} checked={todayStart === createdAfter && todayEnd === createdBefore}/>
                <RadioButton value={`${weekAgo}-${todayEnd}`} label={__('Last Week')} onChange={(value) => setDateFilter(value)} checked={weekAgo === createdAfter && todayEnd === createdBefore}/>
                <RadioButton value={`${monthAgo}-${todayEnd}`} label={__('Last month')} onChange={(value) => setDateFilter(value)} checked={monthAgo === createdAfter && todayEnd === createdBefore}/>
                <RadioButton value={`${threeMonthsAgo}-${todayEnd}`} label={__('Last 3 months')} onChange={(value) => setDateFilter(value)} checked={threeMonthsAgo === createdAfter && todayEnd === createdBefore}/>
                <RadioButton value={`${yearAgo}-${todayEnd}`} label={__('Last year')} onChange={(value) => setDateFilter(value)} checked={yearAgo === createdAfter && todayEnd === createdBefore}/>
                <RadioButton value={`${yearToDateStart}-${todayEnd}`} label={__('Year to date')} onChange={(value) => setDateFilter(value)} checked={yearToDateStart === createdAfter && todayEnd === createdBefore}/>
                <RadioButton
                    value="custom" label={(
                        <div className="custom-date">
                            <Tekst>{__('From')}:</Tekst>
                            <DateInput value={createdAfter ? new Date(Number(createdAfter)) : undefined} name="from date" onChange={(value) => setCreatedAfter!(`${new Date(value).getTime()}`)}/>
                            <Tekst>{__('To')}:</Tekst>
                            <DateInput value={createdBefore ? new Date(Number(createdBefore)) : undefined} name="to date" onChange={(value) => setCreatedBefore!(`${new Date(value).getTime()}`)}/>
                        </div>
                    )} onChange={() => undefined}
                    checked={customActive}/>
            </div>

        </Slideout>
    );
};
