import type {ExclusiveLibraryTemplateDocument} from '../../../../../../database';
import {HttpMethod} from '../../../../../HttpMethod';

export const path = '/apiv2/exclusive-libraries/:exclusiveLibraryId/library-items';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {

    /**
     * Pick results that match the specified library _id;
     */
    exclusiveLibraryId: string;
};

export type QueryType = {

    /**
     * Pick results that (partially) match the specified category;
     * case-insensitive.
     */
    category?: string;

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results with fewer downloads than the specified number
     */
    downloadsFewerThan?: number;

    /**
     * Pick results with more downloads than the specified number
     */
    downloadsMoreThan?: number;

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Pick results that match the specified original _id;
     */
    originalId?: string;

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'created' | 'downloads'}`;

};

export type OutputType = ExclusiveLibraryTemplateDocument[];
