import type {ReactElement, VoidFunctionComponent} from 'react';
import React, {useState} from 'react';
import {List} from '../../../frontend/components/List';
import {ListItem} from '../../../frontend/components/ListItem';
import {createEntityRelationshipMap, createEntityTree, toEntityArray} from '../../../frontend/utilities/entities';
import {__} from '../../../translations';
import {emptyArray, removeValues, unique} from '../../../util/arrays';
import {escapeRegex} from '../../../util/strings';
import {useCurrentUser} from '../../context';
import {useEntities, useUrlStateArray} from '../../hooks';
import {Knopje} from '../Knopje';
import {LocationTree} from '../LocationTree';
import {SearchBar} from '../SearchBar';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';
import {Checkbox} from '../toggles/Checkbox';
import {RadioButton} from '../toggles/RadioButton';

export type LocationSlideoutProps = {
  show: 'all' | 'assigned';
  radio?: boolean;
    headerText?: string;
    locationIds?: string[];
    setLocationIds?: (locationIds) => void;
    onCheckboxChange?: (_id: string, value: boolean, name?: string) => void;
    onContextChange?: (resultValue: string[]) => void;
};

export type SlideoutComponent = ReactElement<LocationSlideoutProps>;

export const LocationSlideout: VoidFunctionComponent<LocationSlideoutProps> = (
    {show, radio, headerText,
        locationIds,
        setLocationIds,
        onCheckboxChange = (_id, value) => {
            if (value) {
                setLocationIds!([...locationIds!, _id]);
            } else {
                const filtered = locationIds!.filter((locationId) => locationId !== _id);
                setLocationIds!(filtered);
            }
        },
        onContextChange = (resultValue) => {
            setLocationIds!([...resultValue]);
        }}): SlideoutComponent => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchChange = (event) => {
        setSearchValue(event.currentTarget.value);
    };
    if (!locationIds || !setLocationIds) {
        [locationIds, setLocationIds] = useUrlStateArray('entityIds', []);
    }
    const {entities} = useEntities();

    return (
        <Slideout onReset={() => setLocationIds!([])}>
            <SearchBar
                value={searchValue}
                onChange={onSearchChange}
                placeholder={__('Search locations')}
                description={__('Search for locations based on their name')}
                className="slideout__search"
            />
            <Tekst weight="bold" className="slideout__title">
                {headerText ? headerText : __('Filter locations')}
            </Tekst>
            {!headerText
                ? (
                    <Knopje color="white" paint="fill" type="reset" className="slideout__clear">
                        <Tekst color="blue">
                            {__('Clear all')}
                        </Tekst>
                    </Knopje>
                )
                : <div/>}
            <div className="slideout__list">
                {searchValue
                    ? (
                        <List>
                            {entities.filter(({name, _id}) => {
                                if (show === 'assigned' && !authenticatedUser?.locations.includes(_id)) {
                                    return false;
                                }
                                return name && new RegExp(`${escapeRegex(searchValue)}`, 'i')?.test(name);
                            }
                            ).map(({_id, name}) => {
                                const selected = !!locationIds!.find((locationId) => locationId === _id);
                                return (
                                    <ListItem key={_id}>
                                        {radio
                                            ? (
                                                <RadioButton
                                                    checked={selected ? 'checked' : 'unchecked'}
                                                    onChange={(value) => onCheckboxChange(_id, value === 'checked', name ?? undefined)}
                                                >
                                                    {name}
                                                </RadioButton>
                                            )
                                            : (
                                                <Checkbox
                                                    checked={selected ? 'checked' : 'unchecked'}
                                                    onChange={(value) => onCheckboxChange(_id, value === 'checked', name ?? undefined)}
                                                >
                                                    {name}
                                                </Checkbox>
                                            )}
                                    </ListItem>
                                );
                            })}
                        </List>
                    )
                    : (
                        <LocationTree
                            checkedIds={locationIds}
                            disabledMessage={__('You do not have access to this location.')}
                            enabledIds={show === 'all' ? undefined : authenticatedUser?.locations ?? emptyArray}
                            onChange={(checked, event) => onCheckboxChange(event.target.value!, checked === 'checked', entities.find((entity) => entity._id === event.target.value!)?.name ?? undefined)}
                            onContextMenu={(checked, input) => {
                                const locationId = input.value;

                                if (!locationId) {
                                    return;
                                }

                                const rootLocation = entities.find((entity) => entity._id === locationId);
                                const tree = rootLocation ? createEntityTree(rootLocation, createEntityRelationshipMap(entities)) : null;
                                const array = tree ? toEntityArray(tree).map(({_id}) => _id) : [];

                                const locations = checked === 'unchecked'
                                    ? unique([...(locationIds ?? []), ...array])
                                    : removeValues(locationIds ?? [], array);

                                return onContextChange(locations);
                            }}
                            variant={radio ? 'radiobutton' : 'checkbox'}
                        />
                    )}
            </div>
        </Slideout>
    );
};
