import type {FunctionComponent} from 'react';
import {useEffect} from 'react';
import type {MetricKey, QueueBaseEntry} from '../../dexie';
import {prepareTracking} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import {useCurrentUser} from '../context';
import {useMetrics} from '../hooks';

const matchers = {
    '/isDone': 'Done',
    '/_deleted': 'Delete',
    '/locked': 'Locked',
    '/assignedToUser': 'Assigned',
    '/meta/comments/-': 'Commented'
};

export const UsageMetrics: FunctionComponent = () => {
    const {trackMetric} = useMetrics();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const userId = authenticatedUser?._id;

    useEffect(() => {
        if (!userId) {
            return;
        }

        prepareTracking(userId);

        return addEventListener('create', (event) => {
            if (document.hidden || event.detail.table !== 'queue') {
                return;
            }

            const record = event.detail.obj as QueueBaseEntry;
            const {action, resourceTableName, patch} = record;

            if (action === 'create') {
                return trackMetric(`${resourceTableName}Created`);
            }

            if (!patch?.length) {
                return;
            }

            let actionName = 'Updated';

            for (const p of patch) {
                if (p.op !== 'replace' && p.op !== 'add' || !p.value || !matchers[p.path]) {
                    continue;
                }

                actionName = matchers[p.path];
                break;
            }

            trackMetric(`${resourceTableName}${actionName}` as MetricKey);
        });
    }, [userId]);

    return null;
};
