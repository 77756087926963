import type {ChartSegmentDocument,
    InspectionChartSegmentDocument, TaskChartSegmentDocument} from '../../../../../../database/collections/chartSegments';
import {chartSegmentTypeDef, inspectionChartSegmentTypeDef,
    taskChartSegmentTypeDef} from '../../../../../../database/collections/chartSegments';
import type {TypeDef} from '../../../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../../../HttpMethod';

export const path = '/apiv2/charts/:chartId/chart-segments';

export const method = HttpMethod.POST;

export type BodyType = Omit<InspectionChartSegmentDocument, '_id'> | Omit<TaskChartSegmentDocument, '_id'>;

export const bodyTypeDef: TypeDef<BodyType> = (() => {
    const {_id: inspection_id, ...inspectionProperties} = inspectionChartSegmentTypeDef.properties;
    const {_id: task_id, ...taskProperties} = taskChartSegmentTypeDef.properties;

    return {
        discriminator: 'collection',
        mapping: {
            inspections: {
                additionalProperties: false,
                metadata: inspectionChartSegmentTypeDef.metadata,
                optionalProperties: inspectionChartSegmentTypeDef.optionalProperties,
                properties: inspectionProperties
            },
            tasks: {
                additionalProperties: false,
                metadata: taskChartSegmentTypeDef.metadata,
                optionalProperties: taskChartSegmentTypeDef.optionalProperties,
                properties: taskProperties
            }
        },
        metadata: chartSegmentTypeDef.metadata
    };
})();

export type ParamType = {

    /**
     * Pick results that match the specified chart _id;
     */
    chartId: string;
};

export type QueryType = never;

export type OutputType = ChartSegmentDocument;
