import type {ChartDocument} from '../../../../../database/collections/charts';
import {dexie} from '../../../../../dexie';
import type {JsonOf} from '../../../../../interfaces/helpers';
import {fetchApi} from '../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const updateChart = async (urlParams: ParamType, body: BodyType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body,
        method,
        path,
        urlParams
    });
    result.response.ok && result.json && await dexie.charts.put(result.json as unknown as JsonOf<ChartDocument>);
    return result;
};
