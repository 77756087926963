import type {JsonSchema04} from '../JsonSchema04';

export const tasksSchema: JsonSchema04 = {
    $jsonSchema: {
        additionalProperties: false,
        bsonType: 'object',
        properties: {
            _deleted: {
                bsonType: 'bool'
            },
            _id: {
                bsonType: 'objectId'
            },
            assignedToExternalUser: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            },
            assignedToUser: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            },
            closed: {
                bsonType: 'number',
                enum: [0, 1]
            },
            closedAt: {
                bsonType: 'number'
            },
            closedBy: {
                bsonType: 'objectId'
            },
            context: {
                additionalProperties: false,
                bsonType: 'object',
                properties: {
                    entityId: {
                        bsonType: 'string'
                    }
                },
                required: [
                    'entityId'
                ]
            },
            created: {
                bsonType: 'number'
            },
            createdAt: {
                bsonType: 'number'
            },
            createdBy: {
                bsonType: ['string', 'objectId']
            },
            creator: {
                bsonType: 'string'
            },
            creatorName: {
                bsonType: 'string'
            },
            description: {
                bsonType: 'string'
            },
            dueDate: {
                bsonType: [
                    'null',
                    'number'
                ]
            },
            expiresAt: {
                bsonType: 'date'
            },
            meta: {
                additionalProperties: false,
                bsonType: 'object',
                properties: {
                    comments: {
                        bsonType: 'array',
                        items: {
                            additionalProperties: false,
                            bsonType: 'object',
                            properties: {
                                date: {
                                    bsonType: 'number'
                                },
                                email: {
                                    bsonType: 'string'
                                },
                                fileIds: {
                                    bsonType: 'array'
                                },
                                files: {
                                    bsonType: 'array',
                                    items: {
                                        anyOf: [
                                            {
                                                bsonType: 'string'
                                            },
                                            {
                                                additionalProperties: false,
                                                bsonType: 'object',
                                                properties: {
                                                    key: {
                                                        bsonType: 'string'
                                                    },
                                                    name: {
                                                        bsonType: 'string'
                                                    }
                                                },
                                                required: [
                                                    'name',
                                                    'key'
                                                ]
                                            }
                                        ]
                                    }
                                },
                                id: {
                                    bsonType: 'number'
                                },
                                state: {
                                    bsonType: 'string'
                                },
                                text: {
                                    bsonType: 'string'
                                },
                                user: {
                                    bsonType: 'string'
                                },
                                userName: {
                                    bsonType: 'string'
                                }
                            },
                            required: [
                                'user',
                                'text',
                                'date'
                            ]
                        }
                    },
                    files: {
                        bsonType: 'array',
                        items: {
                            anyOf: [
                                {
                                    bsonType: 'string'
                                },
                                {
                                    additionalProperties: false,
                                    bsonType: 'object',
                                    properties: {
                                        key: {
                                            bsonType: 'string'
                                        },
                                        name: {
                                            bsonType: 'string'
                                        }
                                    },
                                    required: [
                                        'name',
                                        'key'
                                    ]
                                }
                            ]
                        }
                    },
                    incidentId: {
                        bsonType: 'string'
                    },
                    incidentItemId: {
                        bsonType: 'string'
                    },
                    incidentItemIndex: {
                        bsonType: 'number'
                    },
                    incidentItemName: {
                        bsonType: 'string'
                    },
                    incidentName: {
                        bsonType: 'string'
                    },
                    inspectionId: {
                        bsonType: 'string'
                    },
                    inspectionItemId: {
                        bsonType: [
                            'string',
                            'object'
                        ]
                    },
                    inspectionItemIndex: {
                        bsonType: 'number'
                    },
                    inspectionItemName: {
                        bsonType: 'string'
                    },
                    inspectionName: {
                        bsonType: 'string'
                    },
                    locationId: {
                        bsonType: 'string'
                    },
                    locationName: {
                        bsonType: [
                            'null',
                            'string'
                        ]
                    },
                    sectionIndex: {
                        bsonType: [
                            'number',
                            'string'
                        ]
                    },
                    sectionName: {
                        bsonType: 'string'
                    },
                    templateId: {
                        bsonType: 'string'
                    },
                    templateName: {
                        bsonType: 'string'
                    }
                },
                required: [
                    'files',
                    'comments'
                ]
            },
            modified: {
                bsonType: 'number'
            },
            offline_id: {
                bsonType: 'string'
            },
            open: {
                bsonType: 'bool'
            },
            overdue: {
                bsonType: 'bool'
            },
            planned: {
                bsonType: 'bool'
            },
            rejected: {
                bsonType: 'bool'
            },
            removed: {
                bsonType: 'number'
            },
            removedAt: {
                bsonType: 'number'
            },
            removedBy: {
                bsonType: 'objectId'
            },
            state: {
                bsonType: 'string'
            },
            tags: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }

            },
            task: {
                bsonType: 'number'
            },
            title: {
                bsonType: 'string'
            },
            touchedAt: {
                bsonType: 'number'
            },
            touchedBy: {
                bsonType: 'objectId'
            },
            updated: {
                bsonType: 'number'
            },
            updatedAt: {
                bsonType: 'number'
            },
            updatedBy: {
                bsonType: ['string', 'objectId']
            },
            version: {
                bsonType: 'string'
            }
        },
        required: [
            '_id'
        ]
    }

};
