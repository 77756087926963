import type {VoidFunctionComponent} from 'react';
import React, {useMemo, useState} from 'react';
import {createMagicLink} from '../../../../api/endpoints/magic-links/post/frontend';
import {createSession} from '../../../../api/endpoints/sessies/post/frontend';
import {createUser} from '../../../../api/endpoints/users/post/frontend';
import {Input} from '../../../../frontend/components/Input';
import Logo from '../../../../frontend/icons/logo_checkbuster-black.png';
import Mail from '../../../../frontend/icons/mail.svg';
import SocialLogoApple from '../../../../frontend/icons/social-logo-apple.svg';
import SocialLogoGithub from '../../../../frontend/icons/social-logo-github.svg';
import SocialLogoGoogle from '../../../../frontend/icons/social-logo-google.svg';
import SocialLogoMicrosoft from '../../../../frontend/icons/social-logo-microsoft.svg';
import {getCookie, setCookie} from '../../../../frontend/utilities/storage';
import {formatRelativeTime} from '../../../../frontend/utilities/timing';
import {currentLocale} from '../../../../helpers/locales';
import {__} from '../../../../translations';
import {Icoontje} from '../../Icoontje';
import {Image} from '../../Image';
import type {KnopjePropsButton} from '../../Knopje';
import {Knopje} from '../../Knopje';
import {Tekst} from '../../Tekst';
import {Checkbox} from '../../toggles/Checkbox';
import type {PopupProps} from '../Popup';
import {Popup} from '../Popup';
import './PopupAuthentication.scss';

export const PopupAuthentication: VoidFunctionComponent = () => {
    const [screen, setScreen] = useState('sso');

    if (screen === 'sso') {
        return (
            <PopupSingleSignOn
                onLoginClick={() => setScreen('login')}
                onRegisterClick={() => setScreen('register')}
            />
        );
    } else if (screen === 'register') {
        return (
            <PopupEmailRegister
                onCancel={() => setScreen('sso')}
            />
        );
    } else if (screen === 'login') {
        return (
            <PopupEmailLogin
                onCancel={() => setScreen('sso')}
                onForgotClick={() => setScreen('forgot')}
            />
        );
    } else if (screen === 'forgot') {
        return (
            <PopupEmailForgot
                onCancel={() => setScreen('login')}
            />
        );
    }

    return null;
};

const formatUrl = (sso: string) => {
    const url = new URL(`https://${location.host}/auth/${sso}`);
    url.searchParams.append('target_url', location.href);
    return url.toString();
};

export const PopupSingleSignOn: VoidFunctionComponent<{
    onRegisterClick: KnopjePropsButton['onClick'];
    onLoginClick: KnopjePropsButton['onClick'];
}> = ({onRegisterClick, onLoginClick}) => {
    const first = useMemo(() => {
        const os = getCookie('os');
        return os === 'ios' || os === 'mac' ? 'apple' : os === 'android' ? 'google' : 'microsoft';
    }, []);

    return (
        <Popup
            simple
            cancelButtonText={__('Back')}
            compact={false}
            confirmButtonText={__('Log In')}
            name="authentication"
            showCancelButton={false}
            showCloseButton={false}
            showConfirmButton={false}
            titleText=""
        >
            <Image alt={__('Checkbuster logo')} className="logo" src={Logo}/>

            <Tekst className="title" size="huge">
                {__('Let\'s check in')}
            </Tekst>

            <Knopje
                type="anchor"
                to={formatUrl(first)}
                color="blue"
                corners="round"
                paint="outline"
                size="huge"
                rel=""
                target="_self"
            >
                <Icoontje
                    size="standard"
                    Svg={first === 'apple' ? SocialLogoApple : first === 'google' ? SocialLogoGoogle : SocialLogoMicrosoft}
                />
                <Tekst color="black" size="standard" weight="bold">
                    {first === 'apple' ? __('Continue with Apple') : first === 'google' ? __('Continue with Google') : __('Continue with Microsoft')}
                </Tekst>
            </Knopje>

            <Knopje
                type="anchor"
                to={formatUrl(first !== 'microsoft' ? 'microsoft' : 'google')}
                color="blue"
                corners="round"
                paint="outline"
                size="huge"
                rel=""
                target="_self"
            >
                <Icoontje size="standard" Svg={first !== 'microsoft' ? SocialLogoMicrosoft : SocialLogoGoogle}/>
                <Tekst color="black" size="standard" weight="bold">
                    {first !== 'microsoft' ? __('Continue with Microsoft') : __('Continue with Google')}
                </Tekst>
            </Knopje>

            <Knopje
                type="anchor"
                to={formatUrl(first === 'apple' ? 'google' : 'apple')}
                color="blue"
                corners="round"
                paint="outline"
                size="huge"
                rel=""
                target="_self"
            >
                <Icoontje size="standard" Svg={first === 'apple' ? SocialLogoGoogle : SocialLogoApple}/>
                <Tekst color="black" size="standard" weight="bold">
                    {first === 'apple' ? __('Continue with Google') : __('Continue with Apple')}
                </Tekst>
            </Knopje>

            {process.env.NODE_ENV !== 'production'
                ? (
                    <Knopje
                        type="anchor"
                        to={formatUrl('github')}
                        color="blue"
                        corners="round"
                        paint="outline"
                        size="huge"
                        rel=""
                        target="_self"
                    >
                        <Icoontje size="standard" Svg={SocialLogoGithub}/>
                        <Tekst color="black" size="standard" weight="bold">
                            {__('Continue with GitHub')}
                        </Tekst>
                    </Knopje>
                )
                : null}

            <Tekst className="alternative">
                {__('or')}
            </Tekst>

            <Knopje color="white" corners="round" paint="fill" size="huge" type="button" onClick={onRegisterClick}>
                <Icoontje color="black" size="standard" Svg={Mail}/>
                <Tekst color="black" size="standard" weight="bold">
                    {__('Create account with e-mail')}
                </Tekst>
            </Knopje>

            <Knopje color="white" corners="round" paint="fill" size="huge" type="button" onClick={onLoginClick}>
                <Icoontje color="black" size="standard" Svg={Mail}/>
                <Tekst color="black" size="standard" weight="bold">
                    {__('Log in with e-mail')}
                </Tekst>
            </Knopje>
        </Popup>
    );
};

export const PopupEmailLogin: VoidFunctionComponent<{
    onCancel: PopupProps['onCancel'];
    onForgotClick: KnopjePropsButton['onClick'];
}> = ({onForgotClick, onCancel}) => {
    const [message, setMessage] = useState('');

    return (
        <Popup
            simple
            cancelButtonText={__('Back')}
            onCancel={onCancel}
            compact={false}
            confirmButtonText={__('Log In')}
            name="authentication"
            onConfirm={async (event, data) => {
                const email = data.get('email')?.toString();
                const password = data.get('password')?.toString();

                if (!email || !password) {
                    return;
                }

                try {
                    setMessage(__('Attempting to login...'));
                    const {response} = await createSession({
                        email,
                        password
                    });

                    if (response.ok) {
                        setMessage(__('Logged in successfully!'));
                    } else {
                        setMessage(__('Failed to login. Did you type your password correctly?'));
                    }
                } catch {
                    setMessage(__('Failed to login. Are you connected to the internet?'));
                }
            }}
            showCloseButton={false}
            titleText=""
        >
            <Image alt={__('Checkbuster logo')} className="logo" src={Logo}/>

            <Tekst className="title" size="huge">
                {__('Log in')}
            </Tekst>

            <Input
                required
                autoComplete="email"
                label={__('Email Address')}
                name="email"
                type="email"
            />

            <Input
                required
                autoComplete="current-password"
                label={__('Password')}
                name="password"
                type="password"
            />

            <Knopje color="white" corners="round" paint="fill" size="huge" type="button" onClick={onForgotClick}>
                <Tekst color="black" decoration="underline" size="standard">
                    {__('Forgot password?')}
                </Tekst>
            </Knopje>

            <Tekst>{message}</Tekst>
        </Popup>
    );
};

export const PopupEmailRegister: VoidFunctionComponent<{
    onCancel: PopupProps['onCancel'];
}> = ({onCancel}) => {
    const [message, setMessage] = useState('');

    return (
        <Popup
            simple
            cancelButtonText={__('Back')}
            onCancel={onCancel}
            onConfirm={async (event, data) => {
                const email = data.get('email')?.toString();
                const password = data.get('password')?.toString();

                if (!email || !password) {
                    return;
                }

                try {
                    setMessage(__('Attempting to register...'));
                    const {response, json: user} = await createUser({
                        email,
                        password,
                        method: 'register',
                        language: currentLocale?.iso.split('-')[0]
                    });

                    if (!user || !response.ok) {
                        setMessage(__('This account could not be registered. Have you already registered this account?'));
                    } else {
                        setCookie('authpage', 'email-login');
                        if (user.verified) {
                            setMessage(__('Skipping verification to log in immediately...'));
                            await createSession({email, password});
                        } else {
                            setMessage(__('We\'ve sent you an email to confirm your registration!'));
                        }
                    }
                } catch {
                    setMessage(__('This account could not be registered. Are you connected to the internet?'));
                }
            }}
            compact={false}
            confirmButtonText={__('Create Account')}
            name="authentication"
            showCloseButton={false}
            titleText=""
        >
            <Image alt={__('Checkbuster logo')} className="logo" src={Logo}/>

            <Tekst className="title" size="huge">
                {__('Create account')}
            </Tekst>

            <Input
                required
                autoComplete="email"
                label={__('Your work email')}
                name="email"
                type="email"
            />

            <Input
                required
                autoComplete="new-password"
                label={__('Password')}
                minLength={8}
                name="password"
                type="password"
            />

            <Checkbox required defaultChecked="unchecked">
                <a
                    href="https://checkbuster.com/terms-of-service/"
                    rel="noreferrer"
                    target="_blank"
                >
                    {__('I agree to the terms of service')}
                </a>
            </Checkbox>

            <Tekst>{message}</Tekst>
        </Popup>
    );
};

export const PopupEmailForgot: VoidFunctionComponent<{
    onCancel: PopupProps['onCancel'];
}> = ({onCancel}) => {
    const [message, setMessage] = useState('');

    return (
        <Popup
            simple
            cancelButtonText={__('Back')}
            compact={false}
            confirmButtonText={__('Reset')}
            name="authentication"
            onCancel={onCancel}
            onConfirm={async (event, data) => {
                const email = data.get('email')?.toString();

                if (!email) {
                    return;
                }

                try {
                    setMessage(__('We\'re sending you an email...'));
                    const {response, json: magicLink} = await createMagicLink({email});

                    if (!response.ok || !magicLink) {
                        setMessage(__('We could not send you an email. Did you type your email address correctly?'));
                        return;
                    }

                    if (magicLink.expiresAt) {
                        const expireMessage = formatRelativeTime(+new Date(magicLink.expiresAt) - Date.now());
                        setMessage(`${__('We\'ve sent you an email!')} ${__('The email will expire:')} ${expireMessage}`);
                    } else {
                        setMessage(__('We\'ve sent you an email!'));
                    }
                } catch {
                    setMessage(__('We could not send you an email. Are you connected to the internet?'));
                }
            }}
            showCloseButton={false}
            titleText=""
        >
            <Image alt={__('Checkbuster logo')} className="logo" src={Logo}/>

            <Tekst className="title" size="huge">
                {__('Reset Your Password')}
            </Tekst>

            <Input
                required
                autoComplete="email"
                label={__('Email Address')}
                name="email"
                type="email"
            />

            <Tekst>{message}</Tekst>
        </Popup>
    );
};
