import React, {forwardRef} from 'react';
import type {ToggleProps, ToggleCheckedState} from '../Toggle';
import {Toggle} from '../Toggle';
import './RadioButton.scss';

export type RadioButtonCheckedState = ToggleCheckedState;

export type RadioButtonProps = Omit<ToggleProps<RadioButtonCheckedState>, 'variant' | 'onContextMenu'>;

/**
 * @see {Checkbox}
 * @see {Switch}
 * @see {Toggle}
 * @see {ToggleDescription}
 * @see {RadioButtonProps}
 */
export const RadioButton = forwardRef<HTMLLabelElement, RadioButtonProps>((props, labelRef) => (
    <Toggle {...props} variant="radio" ref={labelRef}/>
));
