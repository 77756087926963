import type {History} from 'history';
import {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useHashChange} from './useHashChange';

const parseUrl = <T extends string>(name: string): T | null => new URLSearchParams(location.hash.slice(1)).get(name) as T | null;

const updateHash = <T extends string>(history: History<unknown>, name: string, newValue: T): void => {
    const params = new URLSearchParams(history.location.hash.slice(1));
    newValue ? params.set(name, newValue) : params.delete(name);
    params.sort();

    history.replace({
        ...history.location,
        hash: `#${params.toString()}`
    });
};

export type SlideoutUrlState = '' | 'teamMember' | 'createdBy' | 'updatedBy' | 'location' | 'edit-location' | 'template' | 'date' | 'createdAt' | 'updatedAt' | 'inspectionTemplate' | 'score' | 'assignees' | 'schedule' | 'reportTemplate' | 'planningSchedule' | 'inspectionState' | 'taskState' | 'customRepeat' | 'customDueAt' | 'customClosesAt';

export const useUrlState = <T extends string>(name: string, initialValue: T): [T, (newValue: T) => void] => {
    const history = useHistory();
    const pageLoadValue = useMemo<T | null>(() => parseUrl(name), []);
    const [value, setValue] = useState<T>(pageLoadValue || initialValue);

    useHashChange(() => {
        const newValue = parseUrl<T>(name);

        if (!newValue && initialValue) {
            updateHash(history, name, initialValue);
        }

        setValue(newValue || initialValue);
    }, []);

    useEffect(() => {
        if (pageLoadValue || !initialValue) {
            return;
        }

        updateHash(history, name, initialValue);
    }, []);

    return [value, (newValue: T) => {
        if (value === newValue) {
            return;
        }

        updateHash(history, name, newValue);
    }];
};
