import type {PublicSessionDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/sessies/:sessionId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    sessionId: string;
};

export type QueryType = never;

export type OutputType = PublicSessionDocument;
