import type {FunctionComponent} from 'react';
import {useEffect} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {useCurrentUser} from '../../../../react/context';
import {getCookie} from '../../../utilities/storage';

export const LogoutPage: FunctionComponent<RouteComponentProps> = ({history}) => {
    const {currentUser: authenticatedUser, ready} = useCurrentUser();
    const loading = !ready;

    useEffect(() => {
        if (loading) {
            return;
        }

        const sso = getCookie('authpage') === 'sso';
        !authenticatedUser && history.replace(sso ? '/single-sign-on' : '/login');
    }, [loading, !!authenticatedUser]);

    return null;
};

export default LogoutPage;
