import type {ICreateChange, IDeleteChange} from 'dexie-observable/api';
import {useEffect, useState} from 'react';
import {dexie} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import {useCurrentUser} from '../context';

export const useQueueCount = () => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const [count, setCount] = useState(0);

    useEffect(() => {
        if (!authenticatedUser?._id) {
            return;
        }

        const updateCount = () => dexie.queue
            .where('userId').equals(authenticatedUser._id)
            .count()
            .then(setCount);

        const callback = (event: CustomEvent<ICreateChange | IDeleteChange>) => {
            if (event.detail.table === 'queue') {
                updateCount();
            }
        };

        updateCount();

        const removeCreateListener = addEventListener('create', callback);
        const removeDeleteListener = addEventListener('delete', callback);

        return () => {
            removeCreateListener();
            removeDeleteListener();
        };
    }, [authenticatedUser?._id]);

    return count;
};
