import type {FunctionComponent, ReactNode} from 'react';
import React, {useEffect, useMemo} from 'react';
import {getInspectionTemplates} from '../../../api/endpoints/inspection-templates/get/frontend';
import {setSentryUser} from '../../../frontend/components/system/Sentry';
import {noop} from '../../../util/functions';
import {useUser, useVisibilityState} from '../../hooks';
import {useCurrentSession} from '../CurrentSession';
import type {CurrentUserContext} from './CurrentUserContext';
import {currentUserContext} from './CurrentUserContext';

export const CurrentUserProvider: FunctionComponent<{
    children: ReactNode;
}> = ({children}) => {
    const {currentSession, ready: readySession} = useCurrentSession();
    const {user: currentUser, ready: readyUser} = useUser(currentSession?.user_id);

    const visibilityState = useVisibilityState();

    const value = useMemo<CurrentUserContext>(() => ({
        isCheckbuster: !!currentUser?.email.endsWith('@checkbuster.com'),
        currentUser,
        ready: !(!readySession || !!currentSession && !readyUser)
    }), [currentUser, readySession, readyUser]);

    useEffect(() => {
        readyUser && setSentryUser({
            id: currentSession?.user_id,
            email: currentUser?.email,
            username: currentUser?.name,
            ip_address: '{{auto}}'
        });
    }, [readyUser, currentSession?.user_id, currentUser?.email, currentUser?.name]);

    useEffect(() => {
        if (!currentUser?.templates) {
            return;
        }

        getInspectionTemplates({isAssigned: true}).catch(noop);
    }, [currentUser?.templates, visibilityState]);

    return (
        <currentUserContext.Provider value={value}>
            {children}
        </currentUserContext.Provider>
    );
};
