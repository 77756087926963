import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {classNames} from '../../helpers/styling';
import type {SvgComponent} from '../../helpers/types';
import './Icoontje.scss';

export type IcoontjeProps = {
    Svg: SvgComponent;
    color?: 'inherit' | 'black' | 'gray' | 'white' | 'red' | 'green' | 'blue' | 'orange' | 'yellow';
    size?: 'inherit' | 'mini' | 'small' | 'standard' | 'large' | 'huge';
    badge?: number | string;
    badgeX?: 'left' | 'center' | 'right';
    badgeY?: 'top' | 'center' | 'bottom' | 'custom';
    animation?: 'none' | 'spin' | 'delayed' | 'fade-out';
    className?: string;
};

export type IcoontjeComponent = ReactElement<IcoontjeProps>;

export const Icoontje: VoidFunctionComponent<IcoontjeProps> = ({
    Svg,
    color = 'inherit',
    size = 'inherit',
    badge,
    badgeX = 'right',
    badgeY = 'top',
    animation = 'none',
    className
}): IcoontjeComponent => {
    const pictureClassName = classNames('icoontje', {
        [color]: color !== 'inherit',
        [size]: size !== 'inherit',
        [badgeX]: badge !== undefined && badgeX,
        [badgeY]: badge !== undefined && badgeY
    }, className);
    const svgClassName = classNames('icoontje__svg', {
        [animation]: animation !== 'none'
    });

    return (
        <picture className={pictureClassName} data-badge={typeof badge === 'string' || badge !== undefined && badge >= 0 ? badge : undefined}>
            <Svg aria-hidden="true" role="img" className={svgClassName}/>
        </picture>
    );
};
