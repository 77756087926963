import {captureException} from '@sentry/react';
import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useMemo, useState} from 'react';
import {getInspection} from '../../api/endpoints/inspections/[inspectionId]/get/frontend';
import type {InspectionDocument} from '../../database';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {__} from '../../translations';
import type {JsonOperation, JsonPatch} from '../../types/JsonPatch';
import {applyJsonPatch} from '../../types/JsonPatch';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useReportAutomations} from './useReportAutomations';
import {useVisibilityChange} from './useVisibilityChange';

export const useInspection = (inspectionId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspection: JsonOf<InspectionDocument> | null;
    updateInspection: (patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};
    const {reportAutomations, handleAutomation} = useReportAutomations();
    const {favouriteOnPicture, favouriteOnComment, favouriteOnTask} = reportAutomations;

    const [fetchActive, setFetchActive] = useState(true);
    const [dexieActive, setDexieActive] = useState(true);
    const {ready} = useReadyState({dexieActive, fetchActive});
    const [visibilityState, setVisibilityState] = useState(0);

    const offline = useLiveQuery<JsonOf<InspectionDocument> | undefined>(async () => {
        if (!inspectionId) {
            return;
        }

        setDexieActive(true);
        const result = await dexie.inspections
            .where('_id').equals(inspectionId)
            .or('offline_id').equals(inspectionId)
            .first();
        setDexieActive(false);

        return result;
    }, [inspectionId]) ?? null;

    const creation = useLiveQuery<QueueCreateEntry | undefined>(() => {
        if (!inspectionId) {
            return;
        }

        return dexie.queue
            .where(['resourceId', 'action']).equals([inspectionId, 'create'])
            .first() as Promise<QueueCreateEntry | undefined>;
    }, [inspectionId])?.body as JsonOf<InspectionDocument> | null ?? null;

    const patches = useLiveQuery<JsonOperation[]>(async () => {
        if (!inspectionId) {
            return [];
        }

        const bloated = await dexie.queue
            .where(['resourceId', 'action']).equals([inspectionId, 'update'])
            .toArray() as QueueUpdateEntry[];

        return bloated
            .map(({patch}) => patch)
            .flat(2)
            .filter(({op}) => op !== 'test');
    }, [inspectionId]) ?? null;

    const inspection = useMemo<JsonOf<InspectionDocument> | null>(() => {
        const base = offline ?? creation;

        if (!base || !patches?.length) {
            return base;
        }

        return applyJsonPatch<JsonOf<InspectionDocument>>(base, patches);
    }, [offline, creation, patches]);

    useEffect(() => {
        if (!inspectionId) {
            return;
        }

        setFetchActive(true);
        getInspection({inspectionId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [inspectionId, visibilityState]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspection,
        updateInspection: async (patch: JsonPatch) => {
            if (!userId || !entityId || !inspectionId) {
                return false;
            }

            if (inspection?.locked) {
                alert(__('This inspection has been locked and cannot be changed anymore.'));
                return false;
            }

            const automationPatch: JsonPatch = [];
            if (favouriteOnPicture || favouriteOnComment || favouriteOnTask) {
                for (const entry of patch) {
                    const automationEntry = handleAutomation(entry);
                    automationEntry && automationPatch.push(automationEntry);
                }
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspections',
                    resourceId: inspectionId,
                    userId,
                    entityId,
                    patch: [...patch, ...automationPatch]
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
