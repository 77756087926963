import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useState} from 'react';
import {getSessions} from '../../api/endpoints/sessies/get/frontend';
import type {PublicSessionDocument} from '../../database';
import {dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {emptyArray} from '../../util/arrays';
import {getBrowserName, getOperatingSystemName} from '../../util/userAgent';
import {useReadyState} from './useReadyState';
import {useVisibilityState} from './useVisibilityState';

export const useSessions = (userId: string | undefined, predicate?: (session: JsonOf<PublicSessionDocument>) => boolean): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    sessions: JsonOf<PublicSessionDocument>[];
} => {
    const visibilityState = useVisibilityState();
    const [dexieActive, setDexieActive] = useState(true);
    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive, fetchActive});

    const sessions = useLiveQuery(async () => {
        if (!userId) {
            return;
        }

        setDexieActive(true);
        let query = dexie.sessions
            .where('user_id').equals(userId)
            .or('impersonator_id').equals(userId)
            .distinct();

        if (predicate) {
            query = query.filter(predicate);
        }

        const result = await query
            .toArray();
        setDexieActive(false);

        return result.map((session) => ({
            ...session,
            browserName: session.browserName ?? getBrowserName({
                userAgent: session.userAgent ?? ''
            }),
            operatingSystemName: session.operatingSystemName ?? getOperatingSystemName({
                userAgent: session.userAgent ?? ''
            })
        }));
    }, [userId, predicate]) ?? emptyArray;

    useEffect(() => {
        if (!userId) {
            return;
        }

        setFetchActive(true);
        getSessions({userIdAny: [userId]})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        sessions
    };
};
