import {HttpMethod} from '../../../HttpMethod';
import type {QueryType as InspectionsQueryType} from '../../inspections/get/shared';

export const path = '/apiv2/inspection-details';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = Omit<InspectionsQueryType, 'sort'>;

export type OutputType = {
    closed: number;
    open: number;
    overdue: number;
    removed: number;
    total: number;
};
