import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getUser} from '../../api/endpoints/users/[userId]/get/frontend';
import type {UserWithContextDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import {useCurrentUser} from '../context';
import {useDexieResource} from './useDexieResource';
import {useReadyState} from './useReadyState';

export const useUser = (userId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    user: JsonOf<UserWithContextDocument> | null;
    updateUser: (patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState] = useState(0);

    const {resource: user, dexieActive} = useDexieResource<UserWithContextDocument>({
        table: dexie.users,
        primaryKey: userId
    });

    const {ready} = useReadyState({dexieActive, fetchActive});

    useEffect(() => {
        if (!userId) {
            return;
        }

        setFetchActive(true);
        getUser({userId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        user,
        updateUser: async (patch: JsonPatch) => {
            if (!authenticatedUser || !userId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'users',
                    resourceId: userId,
                    userId: authenticatedUser._id,
                    entityId: authenticatedUser.context.entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
