import type {ReactElement, VoidFunctionComponent} from 'react';
import React, {useMemo} from 'react';
import type {EntityDocument} from '../../../database';
import type {EntityRelationshipNode} from '../../../frontend/utilities/entities';
import {createEntityRelationshipMap, createEntityTree} from '../../../frontend/utilities/entities';
import type {JsonOf} from '../../../interfaces/helpers';
import {__} from '../../../translations';
import {classNames} from '../../helpers/styling';
import {useEntities} from '../../hooks';
import type {TreeGroupProps} from '../TreeGroup';
import {TreeGroup} from '../TreeGroup';
import {TreeItem} from '../TreeItem';
import {TreeView} from '../TreeView';
import type {CheckboxVariantProps, RadioButtonVariantProps} from './variants';
import {CheckboxVariant, RadioButtonVariant, TextVariant} from './variants';

type RecursiveRenderFunction = VoidFunctionComponent<{
    location: JsonOf<EntityDocument>;
}>;

const RecursiveNode: VoidFunctionComponent<{
    node: EntityRelationshipNode;
    Render: RecursiveRenderFunction;
}> = ({node, Render}) => (
    <TreeItem>
        <Render location={node.current}/>

        {node.children.length
            ? (
                <TreeGroup>
                    {node.children.map((node) => (
                        <RecursiveNode
                            key={node.current._id ?? node.current.offline_id}
                            node={node}
                            Render={Render}
                        />
                    ))}
                </TreeGroup>
            )
            : null}
    </TreeItem>
);

const variantMap = {
    text: TextVariant,
    checkbox: CheckboxVariant,
    radiobutton: RadioButtonVariant
} as const;

export type LocationTreeProps = {
    checkedIds: string[];
    disabledIds?: string[];
    disabledMessage?: string;
    enabledIds?: string[];
    onChange: CheckboxVariantProps['onChange'] | RadioButtonVariantProps['onChange'];
    onContextMenu: CheckboxVariantProps['onContextMenu'];
    variant: keyof typeof variantMap;
};

export type LocationTreeComponent = ReactElement<TreeGroupProps> | null;

export const LocationTree: VoidFunctionComponent<LocationTreeProps> = ({
    checkedIds,
    disabledMessage = '',
    enabledIds,
    onChange,
    onContextMenu,
    variant
}): LocationTreeComponent => {
    const {entities} = useEntities();

    const entityTree = useMemo<EntityRelationshipNode | null>(() => {
        const rootEntity = entities.find(({parentId}) => !parentId);
        return rootEntity ? createEntityTree(rootEntity, createEntityRelationshipMap(entities)) : null;
    }, [entities]);

    // eslint-disable-next-line react/no-unstable-nested-components
    const Render = useMemo<RecursiveRenderFunction>(() => {
        const Variant = variantMap[variant];

        return ({location}) => (
            <Variant
                checked={location._id && checkedIds.includes(location._id) || location.offline_id && checkedIds.includes(location.offline_id) ? 'checked' : 'unchecked'}
                onChange={onChange}
                onContextMenu={onContextMenu}
                locationId={location._id}
                locationName={location.name ?? __('Unknown')}
                disabled={enabledIds && !enabledIds.includes(location._id) ? disabledMessage : ''}
            />
        );
    }, [variant, checkedIds, onChange, onContextMenu]);

    return entityTree
        ? (
            <TreeView className={classNames('locationtree', {
                compact: variant === 'text'
            })}>
                <RecursiveNode node={entityTree} Render={Render}/>
            </TreeView>
        )
        : null;
};
