import {metricWeights} from '../helpers/variables';
import type {ResourceTableName, MetricsRecord} from './dexie';
import {dexie} from './dexie';

let lastUserId: string;

export type MetricKey = `${ResourceTableName}${'Created' | 'Updated' | 'Deleted'}`
    | 'inspectionsDone'
    | 'inspectionTemplatesActivated'
    | 'inspectionsLocked'
    | 'tasksAssigned' | 'tasksCommented'
    | 'errors' | 'crashes';

export const getTrackedUser = (): string | undefined => lastUserId;

export const prepareTracking = (userId: string): void => {
    lastUserId = userId;
    dexie.metrics.add({userId} as MetricsRecord).catch(() => undefined);
};

export const trackMetric = (userId: string, metric: MetricKey): void => {
    lastUserId = userId;
    dexie.metrics.where('userId').equals(userId).modify((record: MetricsRecord): void => {
        record[metric] = (record[metric] ?? 0) + 1;
    }).catch(() => undefined);
};

export const calculateUsageScore = (metrics: Partial<MetricsRecord>): number => Object.entries(metrics).reduce((acc, [key, val]) => {
    if (typeof val !== 'number') {
        return acc;
    }

    return acc + (metricWeights[key] ?? 0) * val!;
}, 0);
