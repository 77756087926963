import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {__} from '../../../translations';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';
import {RadioButton} from '../toggles/RadioButton';

type SlideoutComponent = ReactElement;

export type inspectionStateSlideoutProps = {
    inspectionState: string;
    setInspectionState: (inspectionState) => void;
};

export const InspectionStateSlideout: VoidFunctionComponent<inspectionStateSlideoutProps> = ({
    inspectionState,
    setInspectionState
}): SlideoutComponent => (
    <Slideout>
        <Tekst weight="bold" className="slideout__title">
            {__('Filter inspection state')}
        </Tekst>

        <div className="slideout__list">
            <RadioButton onChange={() => setInspectionState('all')} checked={inspectionState === 'all' ? 'checked' : 'unchecked'}>
                <Tekst>{__('All inspections')}</Tekst>
            </RadioButton>
            <RadioButton onChange={(value) => setInspectionState(value === 'checked' ? 'open' : 'all')} checked={inspectionState === 'open' ? 'checked' : 'unchecked'}>
                <Tekst>{__('Open inspections')}</Tekst>
            </RadioButton>
            <RadioButton onChange={(value) => setInspectionState(value === 'checked' ? 'done' : 'all')} checked={inspectionState === 'done' ? 'checked' : 'unchecked'}>
                <Tekst>{__('Finished inspections')}</Tekst>
            </RadioButton>
        </div>
    </Slideout>
);

