import type {ChartDocument} from '../../../../database/collections/charts';
import {chartTypeDef} from '../../../../database/collections/charts';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/charts';

export const method = HttpMethod.POST;

export type BodyType = Omit<ChartDocument, '_id'>

export const bodyTypeDef: TypeDef<BodyType> = (() => {
    const {_id, ...properties} = chartTypeDef.properties;

    return {
        additionalProperties: false,
        optionalProperties: chartTypeDef.optionalProperties,
        properties: {
            ...properties
        }
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = ChartDocument;
