import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {inspectionSectionTypeDef} from '../inspection-sections/inspectionSectionTypeDef';
import type {InspectionDocument} from './InspectionDocument';

export const inspectionTypeDef: TypeDef<InspectionDocument> = {
    additionalProperties: false,
    metadata: {
        name: 'InspectionDocument'
    },
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        automatedExport: {
            additionalProperties: false,
            properties: {
                pdf: {
                    additionalProperties: false,
                    optionalProperties: {
                        Bucket: {
                            nullable: true,
                            type: 'string'
                        },
                        Key: {
                            nullable: true,
                            type: 'string'
                        },
                        createdAt: {
                            nullable: true,
                            type: 'float64'
                        },
                        filePath: {
                            type: 'string'
                        },
                        fileSize: {
                            type: 'float64'
                        },
                        signedUrl: {
                            type: 'string'
                        }
                    },
                    properties: {
                        email: {
                            type: 'string'
                        },
                        errorCount: {
                            type: 'float64'
                        }
                    }
                }
            }
        },
        closedAt: {
            type: 'float64'
        },
        closedBy: {
            type: 'string'
        },
        closesAt: {
            type: 'float64'
        },
        codeID: {
            type: 'string'
        },
        created: {
            type: 'float64'
        },
        creator: {
            type: 'string'
        },
        dueAt: {
            type: 'float64'
        },
        dueDate: {} as never,
        dueTime: {} as never,
        endDate: {} as never,
        endTime: {} as never,
        expiresAt: {
            type: 'timestamp'
        },
        incident: {
            type: 'boolean'
        },
        inspectorEmail: {
            type: 'string'
        },
        inspectorName: {
            type: 'string'
        },
        locationName: {
            type: 'string'
        },
        lockedAt: {
            type: 'float64'
        },
        lockedBy: {
            type: 'string'
        },
        modified: {
            type: 'float64'
        },
        offline_id: {
            type: 'string'
        },
        opensAt: {
            type: 'float64'
        },
        planningsId: {
            type: 'string'
        },
        predefinedComments: {
            elements: {
                additionalProperties: false,
                properties: {
                    value: {
                        type: 'string'
                    }
                }
            }
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        startDate: {} as never,
        startTime: {} as never,
        templateName: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        closed: {
            type: 'float64'
        },
        conclusion: {
            type: 'string'
        },
        conductedAt: {
            type: 'float64'
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    type: 'string'
                }
            }
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        isDone: {
            type: 'boolean'
        },
        location: {
            type: 'string'
        },
        locked: {
            type: 'float64'
        },
        name: {
            type: 'string'
        },
        removed: {
            type: 'float64'
        },
        reportTemplateIds: {
            elements: {
                type: 'string'
            }
        },
        sections: {
            elements: inspectionSectionTypeDef
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        taskIds: {
            elements: {
                type: 'string'
            }
        },
        templateId: {
            type: 'string'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        users: {
            elements: {
                type: 'string'
            }
        }
    }
};
