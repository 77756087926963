import {useEffect, useState} from 'react';
import {dexie} from '../../dexie';
import {useCurrentSession} from '../context';

export const useUserCount = (): {
    userCount: number | null;
} => {
    const {currentSession} = useCurrentSession();
    const {entity_id: entityId} = currentSession ?? {};
    const [userCount, setUserCount] = useState<number | null>(null);

    useEffect(() => {
        if (!entityId) {
            return;
        }

        dexie.users
            .where('context.entityId').equals(entityId)
            .count()
            .then(setUserCount);
    }, [entityId]);

    return {userCount};
};
