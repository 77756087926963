import type {ResourceTranslationDocument} from '../../../../database';
import {resourceTranslationTypeDef} from '../../../../database/collections/resourceTranslations/resourceTranslationTypeDef';
import type {JsonOf} from '../../../../interfaces/helpers';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/resource-translations';

export const method = HttpMethod.POST;

export type BodyType = Omit<JsonOf<ResourceTranslationDocument>,
    '_id'
    | 'expiresAt'
    | 'entity_id'
    | 'createdAt'
    | 'createdBy'
    | 'removed'
    | 'removedAt'
    | 'removedBy'
    | 'touchedAt'
    | 'touchedBy'
    | 'updatedAt'
    | 'updatedBy'
    >;

export const bodyTypeDef: TypeDef<BodyType> = (() => {
    const {_id, entity_id, createdAt, createdBy, removed, touchedAt, touchedBy, ...properties} = resourceTranslationTypeDef.properties;
    const {expiresAt, updatedBy, updatedAt, removedAt, removedBy, ...optionalProperties} = resourceTranslationTypeDef.optionalProperties;

    return {
        additionalProperties: false,
        optionalProperties,
        properties
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = ResourceTranslationDocument;

