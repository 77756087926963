import type {InspectionItemDocument} from '../database/collections/inspection-items';
import type {ItemCondition} from '../database/collections/inspection-items/ItemCondition';
import type {MaybeJsonOf} from '../interfaces/helpers';

export const satisfiesCondition = (item: MaybeJsonOf<Exclude<InspectionItemDocument, null>>, condition: ItemCondition): boolean => {
    if ('itemState' in condition) {
        return condition.equals === '==='
            ? item.input.state === condition.itemState
            : item.input.state !== condition.itemState;
    } else if ('value' in item.input) {
        return condition.equals === '==='
            ? item.input.value === condition.itemValue
            : item.input.value !== condition.itemValue;
    } else if (item.type === 'dropdown') {
        const values = item.input.options
            .filter(({selected}) => selected)
            .map(({text}) => text);
        return condition.equals === '==='
            ? values.some((value) => value === condition.itemValue)
            : values.every((value) => value !== condition.itemValue);
    }

    return false;
};

export const parentConditionFilter = (item: MaybeJsonOf<InspectionItemDocument>, _: number, items: MaybeJsonOf<InspectionItemDocument>[]): boolean => {
    if (!item || item.parentId === undefined || item.parentConditionId === undefined) {
        return true;
    }

    for (const possibleParent of items) {
        if (!possibleParent || possibleParent.id !== item.parentId) {
            continue;
        }

        const condition = possibleParent.spec.conditions?.find(({id}) => id === item.parentConditionId);

        if (!condition) {
            return false;
        }

        if ('itemState' in condition) {
            return condition.equals === '==='
                ? possibleParent.input.state === condition.itemState
                : possibleParent.input.state !== condition.itemState;
        } else if ('value' in possibleParent.input) {
            return condition.equals === '==='
                ? possibleParent.input.value === condition.itemValue
                : possibleParent.input.value !== condition.itemValue;
        } else if (possibleParent.type === 'dropdown') {
            const values = possibleParent.input.options
                .filter(({selected}) => selected)
                .map(({text}) => text);
            return condition.equals === '==='
                ? values.some((value) => value === condition.itemValue)
                : values.every((value) => value !== condition.itemValue);
        }

        return false;
    }

    return false;
};

export const favouriteFilter = (item: MaybeJsonOf<InspectionItemDocument>): boolean => !!item?.isFavourite;

export const groupParentsAndChildren = <T extends MaybeJsonOf<Exclude<InspectionItemDocument, null>>>(items: T[]): T[] => {
    const getChildren = (parent: T, condition: T['spec']['conditions'][0]): T[] => items
        .filter((child) => child.parentId === parent.id && child.parentConditionId === condition.id)
        .map((child) => [child, ...child.spec.conditions?.map(getChildren.bind(null, child)).flat() ?? []])
        .flat();

    return items.map((item: T): T | T[] | null => {
        if (item.parentId || item.parentConditionId) {
            // Children are pulled back in by their parents
            return null;
        }

        if (!item.spec.conditions?.length) {
            // An item that is not a child and does not have conditions cannot have children of its own
            return item;
        }

        return [item, ...item.spec.conditions?.map(getChildren.bind(null, item)).flat() ?? []];
    }).flat().filter((item): item is T => !!item);
};
