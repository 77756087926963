import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {EntityDocument} from './EntityDocument';

export const entityTypeDef: TypeDef<EntityDocument> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        address: {
            additionalProperties: false,
            optionalProperties: {
                address: {
                    type: 'string'
                },
                city: {
                    type: 'string'
                },
                postcode: {
                    type: 'string'
                }
            }
        },
        apps: {
            elements: {
                type: 'float64'
            }
        },
        branch: {
            type: 'string'
        },
        classicLocationId: {
            type: 'string'
        },
        classicParentId: {
            type: 'string'
        },
        code: {
            type: 'string'
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    nullable: true,
                    type: 'string'
                }
            }
        },
        created: {
            type: 'float64'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {},
        creator: {
            type: 'string'
        },
        device: {
            type: 'string'
        },
        exclusiveLibrary: {
            type: 'boolean'
        },
        expiresAt: {
            type: 'timestamp'
        },
        logoSrc: {
            type: 'string'
        },
        modified: {
            type: 'float64'
        },
        offline_id: {
            type: 'string'
        },
        owner: {
            type: 'string'
        },
        parentId: {
            nullable: true,
            type: 'string'
        },
        photoSettings: {
            additionalProperties: false,
            optionalProperties: {
                size: {
                    additionalProperties: false,
                    properties: {
                        input: {
                            additionalProperties: false,
                            properties: {
                                state: {
                                    type: 'string'
                                },
                                value: {
                                    type: 'float64'
                                }
                            }
                        },
                        spec: {
                            additionalProperties: false,
                            properties: {
                                valueEnd: {
                                    type: 'float64'
                                },
                                valueMax: {
                                    type: 'float64'
                                },
                                valueMin: {
                                    type: 'float64'
                                },
                                valueStart: {
                                    type: 'float64'
                                }
                            }
                        }
                    }
                }
            },
            properties: {
                color: {
                    additionalProperties: false,
                    properties: {
                        input: {
                            additionalProperties: false,
                            properties: {
                                state: {
                                    type: 'string'
                                },
                                value: {} as never
                            }
                        },
                        spec: {
                            additionalProperties: false,
                            properties: {
                                valueEnd: {
                                    type: 'float64'
                                },
                                valueMax: {
                                    type: 'float64'
                                },
                                valueMin: {
                                    type: 'float64'
                                },
                                valueStart: {
                                    type: 'float64'
                                }
                            }
                        }
                    }
                }
            }
        },
        photoSource: {
            type: 'string'
        },
        removed: {
            type: 'float64'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        resellerId: {
            type: 'string'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        token: {
            type: 'string'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        type: {
            type: 'string'
        },
        uid: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        },
        version: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        name: {
            nullable: true,
            type: 'string'
        }
    }
};
