import type {
    InspectionItemDocument,
    AtpItemDocument,
    DropdownItemDocument,
    LocationItemDocument,
    NumberItemDocument,
    RangeItemDocument,
    SignatureItemDocument,
    SmileyItemDocument,
    StarItemDocument,
    TextItemDocument,
    YesNoItemDocument
} from '../database/collections/inspection-items';
import type {InspectionSectionDocument} from '../database/collections/inspection-sections';
import type {JsonOf} from '../interfaces/helpers';
import {getIncrementalId} from '../util/idCounter';
import {groupParentsAndChildren} from '../util/inspections';
import {isAtpItemV1} from './InspectionItemAtp';
import {isDropdownItemV1} from './InspectionItemDropdown';
import {isGpsItemV1} from './InspectionItemGps';
import {isNumberInputItemV1} from './InspectionItemNumberInput';
import {isNumberSliderItemV1} from './InspectionItemNumberSlider';
import {isSignatureItemV1} from './InspectionItemSignature';
import {isSmileyItemV1} from './InspectionItemSmiley';
import {isStarItemV1} from './InspectionItemStar';
import {isTextItemV1} from './InspectionItemText';
import {isYesNoItemV1} from './InspectionItemYesNo';

export type {InspectionSectionDocument} from '../database/collections/inspection-sections';

export const normalizeInspectionSection = (section: JsonOf<InspectionSectionDocument>): JsonOf<InspectionSectionDocument> => {
    if (!section?.data || !Array.isArray(section.data)) {
        return null;
    }

    const data: JsonOf<Exclude<InspectionItemDocument, null>>[] = section.data
        .map((item): JsonOf<InspectionItemDocument> => {
            if (!item?.spec) {
                return null;
            }

            const singleWeight: string | number = 'weight' in item.spec ? (Array.isArray(item.spec.weight) ? item.spec.weight[0] : item.spec.weight) : 1;
            const singleWeightNumber = typeof singleWeight === 'number'
                ? singleWeight
                : isNaN(parseFloat(singleWeight))
                    ? (singleWeight as any | 0 || 1)
                    : parseFloat(singleWeight);
            const multipleWeights: number[] = ('weight' in item.spec && Array.isArray(item.spec.weight) ? item.spec.weight : [])
                .filter((w): w is number => w !== null)
                .map((w) => +w)
                .filter((w) => w >= 0 && !isNaN(w));

            if (isAtpItemV1(item)) {
                const atpItem: JsonOf<AtpItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: typeof item.input.value === 'number' || typeof item.input.value === 'string'
                            ? item.input.value.toString()
                            : ''
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => 'itemValue' in condition
                            ? {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemValue: condition.itemValue
                            }
                            : {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemState: condition.itemState ?? 'ok'
                            }) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isAtp: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        unit: item.spec.unit ?? '',
                        values: item.spec.values?.length === 7 ? item.spec.values.map((v) => +v) as [number, number, number, number, number, number, number] : [0, 1500, 1501, 3000, 3001, 5000, 5001],
                        weight: multipleWeights.length === 4
                            ? multipleWeights as [number, number, number, number]
                            : [1, 1, 1, 1]
                    },
                    type: 'atp'
                };
                return atpItem;
            } else if (isDropdownItemV1(item)) {
                const dropdownItem: JsonOf<DropdownItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        options: item.spec.options?.map(({text, value}) => ({
                            selected: false,
                            text: text ?? '',
                            value: value ? +value : 0
                        })) ?? [],
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? []
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => 'itemValue' in condition
                            ? {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemValue: condition.itemValue
                            }
                            : {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemState: condition.itemState ?? 'ok'
                            }) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isDropdown: true,
                        label: (item.spec.label ?? '---').toString(),
                        multi: !!item.spec.multi,
                        options: item.spec.options?.map(({text, value}) => ({
                            text: text ?? '',
                            value: value ? +value : 0
                        })) ?? [],
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? []
                    },
                    type: 'dropdown'
                };
                return dropdownItem;
            } else if (isGpsItemV1(item)) {
                const locationItem: JsonOf<LocationItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: !item.input.value
                        || typeof item.input.value === 'string'
                        || typeof item.input.value === 'number'
                        || item.input.value.coordinates?.length !== 2
                        || !item.input.value.coordinates[0]
                        || !item.input.value.coordinates[1]
                            ? null
                            : {
                                coordinates: [+item.input.value.coordinates[0], +item.input.value.coordinates[1]],
                                type: 'Point'
                            }
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => ({
                            equals: condition.equals ?? '===',
                            id: condition.id,
                            itemState: condition.itemState ?? 'ok'
                        })) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isLocation: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'location'
                };
                return locationItem;
            } else if (isNumberInputItemV1(item)) {
                const numberItem: JsonOf<NumberItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: item.input.value?.toString() ?? ''
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => 'itemValue' in condition
                            ? {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemValue: condition.itemValue
                            }
                            : {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemState: condition.itemState ?? 'ok'
                            }) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isNumber: true,
                        label: (item.spec.label ?? '---').toString(),
                        norm: item.spec.norm ? +item.spec.norm : 0,
                        operator: item.spec.operator ?? '=',
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        unit: item.spec.unit ?? '',
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'number'
                };
                return numberItem;
            } else if (isNumberSliderItemV1(item)) {
                const rangeItem: JsonOf<RangeItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: item.input.value?.toString() ?? ''
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => 'itemValue' in condition
                            ? {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemValue: condition.itemValue
                            }
                            : {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemState: condition.itemState ?? 'ok'
                            }) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isRange: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        unit: item.spec.unit ?? '',
                        valueEnd: item.spec.valueEnd ?? 10,
                        valueMax: item.spec.valueMax ?? 6,
                        valueMin: item.spec.valueMin ?? 4,
                        valueStart: item.spec.valueStart ?? 0,
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'range'
                };
                return rangeItem;
            } else if (isSignatureItemV1(item)) {
                const signatureItem: JsonOf<SignatureItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        image: typeof item.input.image === 'string' ? item.input.image : '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? []
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => ({
                            equals: condition.equals ?? '===',
                            id: condition.id,
                            itemState: condition.itemState ?? 'ok'
                        })) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isSignature: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'signature'
                };
                return signatureItem;
            } else if (isSmileyItemV1(item)) {
                const smileyItem: JsonOf<SmileyItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: Math.max(0, Math.min(item.input.value && typeof item.input.value !== 'object' ? +item.input.value : 0, 5))
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => ({
                            equals: condition.equals ?? '===',
                            id: condition.id,
                            itemState: condition.itemState ?? 'ok'
                        })) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isSmiley: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: multipleWeights.length === 5
                            ? multipleWeights as [number, number, number, number, number]
                            : [0, 0.25, 0.5, 0.75, 1]
                    },
                    type: 'smiley'
                };
                return smileyItem;
            } else if (isStarItemV1(item)) {
                const starItem: JsonOf<StarItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: Math.max(0, Math.min(item.input.value && typeof item.input.value !== 'object' ? +item.input.value : 0, 5))
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => ({
                            equals: condition.equals ?? '===',
                            id: condition.id,
                            itemState: condition.itemState ?? 'ok'
                        })) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isStar: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: multipleWeights.length === 5
                            ? multipleWeights as [number, number, number, number, number]
                            : [0, 0.25, 0.5, 0.75, 1]
                    },
                    type: 'star'
                };
                return starItem;
            } else if (isTextItemV1(item)) {
                const textItem: JsonOf<TextItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? [],
                        value: item.input.value?.toString() ?? ''
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => 'itemValue' in condition
                            ? {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemValue: condition.itemValue
                            }
                            : {
                                equals: condition.equals ?? '===',
                                id: condition.id,
                                itemState: condition.itemState ?? 'ok'
                            }) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isText: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'text'
                };
                return textItem;
            } else if (isYesNoItemV1(item)) {
                const yesNoItem: JsonOf<YesNoItemDocument> = {
                    id: item.id ?? getIncrementalId(),
                    input: {
                        comment: item.input.comment ?? '',
                        photos: item.input.photos ?? [],
                        state: item.input.state ?? 'empty',
                        taskIds: item.input.taskIds ?? []
                    },
                    isFavourite: !!item.isFavourite,
                    locked: !!item.locked,
                    parentConditionId: item.parentConditionId,
                    parentId: item.parentId,
                    spec: {
                        autoTask: !!item.spec.autoTask,
                        conditions: item.spec.conditions?.map((condition) => ({
                            equals: condition.equals ?? '===',
                            id: condition.id,
                            itemState: condition.itemState ?? 'ok'
                        })) ?? [],
                        dynamic: !!item.spec.dynamic,
                        extraInfoPhotos: item.spec.extraInfoPhotos ?? [],
                        extraInfoText: item.spec.extraInfoText ?? '',
                        extraInfoToggle: !!item.spec.extraInfoToggle,
                        isYesNo: true,
                        label: (item.spec.label ?? '---').toString(),
                        predefinedComments: !!item.spec.predefinedComments,
                        predefinedCommentsArray: item.spec.predefinedCommentsArray ?? [],
                        weight: Math.max(0, singleWeightNumber)
                    },
                    type: 'yesNo'
                };
                return yesNoItem;
            }

            return null;
        })
        .filter((item): item is JsonOf<Exclude<InspectionItemDocument, null>> => !!item);

    return {
        copy: false,
        data: groupParentsAndChildren(data),
        id: section.id ?? getIncrementalId(),
        text: (section.text ?? '---').toString()
    };
};
