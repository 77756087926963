import type {MutableRefObject} from 'react';
import {useEffect, useRef, useState} from 'react';

export const useResizeObserver = (elementRef: MutableRefObject<HTMLElement | null>) => {
    const [width, setWidth] = useState(0);
    const observerRef = useRef<ResizeObserver>(new ResizeObserver(([{contentRect}]) => setWidth(contentRect.width)));
    const {current: currentElement} = elementRef;

    useEffect(() => {
        if (!currentElement) {
            return;
        }

        const {current: currentObserver} = observerRef;

        currentObserver.disconnect();
        currentObserver.observe(currentElement);

        return () => currentObserver.disconnect();
    }, [currentElement]);

    return width;
};
