import type {UserWithContextDocument} from '../../../../database';
import {userTypeDef} from '../../../../database/collections/users';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/users';

export const method = HttpMethod.POST;

export type BodyType = {
    email: string;
    language?: string;
    organisationName?: string;
    userName?: string;
} & ({
    entityId: string;
    method: 'invite';
} | {
    method: 'register';
    password: string;
});

export const bodyTypeDef: TypeDef<BodyType> = {
    discriminator: 'method',
    mapping: {
        invite: {
            additionalProperties: false,
            optionalProperties: {
                language: {
                    metadata: {
                        example: 'en',
                        length: 2
                    },
                    type: 'string'
                },
                organisationName: {
                    metadata: {
                        example: 'My Organisation'
                    },
                    type: 'string'
                },
                userName: {
                    metadata: {
                        example: 'Me'
                    },
                    type: 'string'
                }

            },
            properties: {
                email: userTypeDef.properties.email,
                entityId: {
                    type: 'string'
                }
            }
        },
        register: {
            additionalProperties: false,
            optionalProperties: {
                language: {
                    metadata: {
                        example: 'en',
                        length: 2
                    },
                    type: 'string'
                },
                organisationName: {
                    metadata: {
                        example: 'My Organisation'
                    },
                    type: 'string'
                },
                userName: {
                    metadata: {
                        example: 'Me'
                    },
                    type: 'string'
                }

            },
            properties: {
                email: userTypeDef.properties.email,
                password: {
                    type: 'string'
                }
            }
        }
    }
};

export type ParamType = never;

export type QueryType = never;

export type OutputType = Omit<UserWithContextDocument, '_ip' | 'activeCampaignId' | 'blocked' | 'classicUserId' | 'isReseller' | 'isSuperAdmin' | 'mail' | 'password' | 'profile' | 'salesGotInfo' | 'verificationKey' | 'version'>;
