import {useState} from 'react';
import {useVisibilityChange} from './useVisibilityChange';

export const useVisibilityState = (updateWhenVisible = true, updateWhenHidden = false) => {
    const [visibilityState, setVisibilityState] = useState(0);

    useVisibilityChange((visible) => {
        (visible && updateWhenVisible || !visible && updateWhenHidden) && setVisibilityState(Date.now());
    }, []);

    return visibilityState;
};
