import type {JsonSchemaSplit} from '../../../interfaces/models/json-schema-draft-04';
import {atpItemSchema} from './atpItemSchema';
import {dropdownItemSchema} from './dropdownItemSchema';
import {locationItemSchema} from './locationItemSchema';
import {numberItemSchema} from './numberItemSchema';
import {rangeItemSchema} from './rangeItemSchema';
import {signatureItemSchema} from './signatureItemSchema';
import {smileyItemSchema} from './smileyItemSchema';
import {starItemSchema} from './starItemSchema';
import {textItemSchema} from './textItemSchema';
import {yesNoItemSchema} from './yesNoItemSchema';

export const inspectionItemSchema: JsonSchemaSplit = {
    anyOf: [
        yesNoItemSchema,
        textItemSchema,
        numberItemSchema,
        rangeItemSchema,
        dropdownItemSchema,
        locationItemSchema,
        signatureItemSchema,
        smileyItemSchema,
        starItemSchema,
        atpItemSchema
    ]
} as const;
