import type {MouseEvent} from 'react';

export const ripple = (event: MouseEvent<HTMLElement>): void => {
    try {
        const {currentTarget, nativeEvent} = event;
        const {offsetX, offsetY} = nativeEvent;

        const duration = 400;
        let startTime: number;

        const repaint = (timestamp: number) => {
            if (!startTime) {
                startTime = timestamp;
            }

            const elapsed = timestamp - startTime;

            if (elapsed >= duration) {
                currentTarget.style.backgroundImage = '';
            } else {
                const easing = elapsed / duration * (2 - elapsed / duration);
                const circle = `circle at ${offsetX}px ${offsetY}px`;
                const color = `rgba(0, 0, 0, ${0.3 * (1 - easing)})`;
                const stop = `${90 * easing}%`;

                currentTarget.style.backgroundImage = `radial-gradient(${circle}, ${color} ${stop}, transparent ${stop})`;
                window.requestAnimationFrame(repaint);
            }
        };

        repaint(performance.now());
    } catch (error) {
        console.warn(error);
    }
};
