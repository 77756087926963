import type {FunctionComponent, PropsWithChildren} from 'react';
import React, {forwardRef} from 'react';
import {PopupAuthentication} from '../../../../react/components/popups/PopupAuthentication';
import {useCurrentSession} from '../../../../react/context';
import './Page.scss';

export const Page: FunctionComponent<{
    name: string;
    disableLogin?: boolean;
}> = ({name, children, disableLogin, ...props}) => {
    if (process.env.NODE_ENV === 'development' && (!(/^[a-z0-9-]+$/).test(name) || (/--/).test(name))) {
        throw new Error(`Invalid value "${name}" for property "name": name must be lowercase and must not contain consecutive dashes`);
    }

    const {currentSession, ready} = useCurrentSession();

    return (
        <div {...props} className={`newpage newpage--${name}`}>
            {ready && !currentSession && !disableLogin ? <PopupAuthentication/> : null}
            {children}
        </div>
    );
};

export const ObservablePage = forwardRef<HTMLDivElement, PropsWithChildren<{
    name: string;
    disableLogin?: boolean;
}>>(({name, children, disableLogin, ...props}, ref) => {
    if (process.env.NODE_ENV === 'development' && (!(/^[a-z0-9-]+$/).test(name) || (/--/).test(name))) {
        throw new Error(`Invalid value "${name}" for property "name": name must be lowercase and must not contain consecutive dashes`);
    }

    const {currentSession, ready} = useCurrentSession();

    return (
        <div {...props} className={`newpage newpage--${name}`} ref={ref}>
            {ready && !currentSession && !disableLogin ? <PopupAuthentication/> : null}
            {children}
        </div>
    );
});
