import type {SerializableScalar} from '../types/Serializable';

export const emptyArray: any[] = Object.freeze([]) as unknown as any[];

export const unique = <T extends SerializableScalar>(array: T[]): T[] => [...new Set(array)];

export const removeValue = <T extends SerializableScalar>(array: T[], value: T): T[] => array.filter((entry) => entry !== value);

export const removeValues = <T extends SerializableScalar>(array: T[], values: T[]): T[] => array.filter((entry) => !values.includes(entry));

export const sortAlphabetically = <Document extends {
    name?: string | null;
}>(a: Document, b: Document): number => {
    if (typeof a.name !== 'string') {
        return -1;
    }

    if (typeof b.name !== 'string') {
        return 1;
    }

    return a.name.localeCompare(b.name);
};

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/fromAsync
 */
export const fromAsync = async <T>(source: Iterable<T> | AsyncIterable<T>): Promise<T[]> => {
    const items: T[] = [];
    for await (const item of source) {
        items.push(item);
    }
    return items;
};

export const isIterable = <T>(object: T): object is T & (Iterable<any> | AsyncIterable<any>) => typeof object[Symbol.iterator] === 'function' || typeof object[Symbol.asyncIterator] === 'function';
