import type {InspectionPlanningDocument} from '../../../../database';
import {inspectionPlanTypeDef} from '../../../../database/collections/inspection-plans/inspectionPlanTypeDef';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/inspection-plannings';

export const method = HttpMethod.POST;

export type BodyType = Omit<InspectionPlanningDocument,
    '_id' |
    'expiresAt' |
    'updatedAt' |
    'updatedBy' |
    'removedAt' |
    'removedBy' |
    'createdAt' |
    'createdBy' |
    'touchedAt' |
    'touchedBy' |
    '1' |
    'executedOnServer' |
    'language' |
    'uid' |
    'removed' |
    'updated' |
    'device' |
    '_deleted'>
    & Required<Pick<InspectionPlanningDocument, 'template' | 'location'>>;

export const bodyTypeDef: TypeDef<BodyType> = (() => {
    const {_id, ...properties} = inspectionPlanTypeDef.properties;
    const {
        expiresAt,
        updatedAt,
        updatedBy,
        removedAt,
        removedBy,
        createdAt,
        createdBy,
        touchedBy,
        touchedAt,
        updated,
        1: unknownNumber,
        executedOnServer,
        language,
        uid,
        device,
        _deleted,
        removed,
        ...optionalProperties
    } = inspectionPlanTypeDef.optionalProperties;

    return {
        additionalProperties: true,
        optionalProperties,
        properties
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = InspectionPlanningDocument;
