import type {TaskDocument} from '../../../../../database';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/tasks/:taskId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    taskId: string;
};

export type QueryType = never;

export type OutputType = TaskDocument;
