import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {inspectionItemTypeDef} from '../inspection-items/inspectionItemTypeDef';
import type {InspectionSectionDocument} from './InspectionSectionDocument';

export const inspectionSectionTypeDef: TypeDef<InspectionSectionDocument> = {
    additionalProperties: false,
    metadata: {
        name: 'InspectionSectionDocument'
    },
    nullable: true,
    optionalProperties: {
        addedAt: {
            type: 'float64'
        },
        addedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        copy: {
            type: 'boolean'
        },
        data: {
            elements: inspectionItemTypeDef
        },
        id: {
            type: 'float64'
        },
        text: {
            type: 'string'
        }
    }
};
