import type {FunctionComponent, MouseEvent} from 'react';
import React, {useEffect, useState} from 'react';
import {useMenuData} from '../../../context/MenuContext';
import CloseIcon from '../../../icons/cross.svg';
import {Button} from '../../buttons/Button';
import './Footer.scss';

const Footer: FunctionComponent<{
    status?: 'unknown' | 'pending' | 'success' | 'failure';
    onClick: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
}> = ({children, onClick, status = 'unknown', ...props}) => {
    const {isSidebarOpen} = useMenuData();

    return (
        <div {...props} className={`old-footer old-footer--enabled-${isSidebarOpen ? 'no' : 'yes'} old-footer--status-${status}`}>
            <div className="old-footer__content">{children}</div>
            <Button id="close-footer" className="old-footer__close" Icon={CloseIcon} onClick={onClick}/>
        </div>
    );
};

/**
 * Uses the Footer to show a small message to the user, while allowing the user to close the message at any time.
 * Changing the message triggers the Footer to become visible once again, while setting the message to an empty string
 * will automatically close the Footer without the user having to click on the close button.
 */
export const MessageFooter: FunctionComponent<{
    message: string;
    status?: 'unknown' | 'pending' | 'success' | 'failure';
}> = ({message, status}) => {
    const [showMessage, setShowMessage] = useState(!!message.length);

    useEffect(() => {
        message && console.log('[Message]', message);
        setShowMessage(!!message.length);
    }, [message]);

    return showMessage
        ? (
            <Footer status={status} onClick={() => setShowMessage(false)}>
                {message}
            </Footer>
        )

        : null;
};
