import {captureException} from '@sentry/react';
import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useMemo, useState} from 'react';
import {getInspections} from '../../api/endpoints/inspections/get/frontend';
import type {InspectionDocument} from '../../database';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import {applyJsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {emptyArray} from '../../util/arrays';
import {noop} from '../../util/functions';
import {escapeRegex} from '../../util/strings';
import {useCurrentSession, useCurrentUser} from '../context';
import {useQueueCount} from './useQueueCount';
import {useReadyState} from './useReadyState';
import {useSyncState} from './useSyncState';
import {useVisibilityState} from './useVisibilityState';

export const useInspections = (page: number, pageSize: number, filter: {
    name?: string;
    conductedAfter?: number;
    conductedBefore?: number;
    userIds?: string[];
    entityIds?: string[];
    inspectionTemplateIds?: string[];
    tab: 'open' | 'done' | 'overdue' | 'all';
}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspections: JsonOf<InspectionDocument>[];
    createInspection: (inspection: Partial<JsonOf<InspectionDocument>>) => Promise<boolean>;
    updateInspection: (inspectionId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [dexieActive, setDexieActive] = useState(false);
    const {ready} = useReadyState({dexieActive, fetchActive});
    const [onlineInspections, setOnlineInspections] = useState<JsonOf<InspectionDocument>[]>(emptyArray);
    const visibilityState = useVisibilityState();
    const queueCount = useQueueCount();
    const [syncState] = useSyncState();
    const refresh = useMemo(() => {
        if (queueCount === 0 && !syncState) {
            return Date.now();
        }

        return visibilityState;
    }, [visibilityState, queueCount, syncState]);

    const {
        name,
        tab,
        conductedAfter,
        conductedBefore,
        userIds = emptyArray,
        entityIds = authenticatedUser?.locations ?? emptyArray,
        inspectionTemplateIds = authenticatedUser?.templates ?? emptyArray
    } = filter;

    const filterFunction = useMemo<(inspection: JsonOf<InspectionDocument>) => boolean>(() => {
        const escapedName = name && escapeRegex(name);

        return ({name, _deleted, conductedAt, isDone, dueAt, location, templateId, users}) => {
            if (_deleted) {
                return false;
            }

            if (conductedBefore !== undefined && conductedBefore < conductedAt) {
                return false;
            }

            if (conductedAfter !== undefined && conductedAfter > conductedAt) {
                return false;
            }

            if (tab === 'open') {
                if (isDone) {
                    return false;
                }

                if (dueAt && dueAt < Date.now()) {
                    return false;
                }
            } else if (tab === 'overdue') {
                if (isDone) {
                    return false;
                }

                if (!dueAt || dueAt > Date.now()) {
                    return false;
                }
            } else if (tab === 'done') {
                if (!isDone) {
                    return false;
                }
            }

            if (!entityIds.includes(location)) {
                return false;
            }

            if (!inspectionTemplateIds.includes(templateId)) {
                return false;
            }

            if (userIds.length) {
                if (!users?.length) {
                    return false;
                }

                if (!userIds.some((userId) => users.includes(userId))) {
                    return false;
                }
            }

            if (name && escapedName && !new RegExp(escapedName, 'i').test(name)) {
                return false;
            }

            return true;
        };
    }, [tab, conductedAfter, conductedBefore, name, userIds, entityIds, inspectionTemplateIds]);

    useEffect(() => {
        if (!userId) {
            return;
        }

        let aborted = false;

        setFetchActive(true);
        getInspections({
            sort: '-conductedAt',
            name,
            conductedAfter,
            conductedBefore,
            isClosed: tab === 'all' ? undefined : tab === 'done',
            isOverdue: tab === 'overdue' ? true : tab === 'open' ? false : undefined,
            isRemoved: false,
            locationIdAny: filter.entityIds?.length ? filter.entityIds : undefined,
            templateIdAny: filter.inspectionTemplateIds?.length ? filter.inspectionTemplateIds : undefined,
            assignedToAny: userIds.length ? userIds : undefined
        }, (page - 1) * pageSize, page * pageSize - 1)
            .then(({json, response}) => {
                !aborted && setOnlineInspections(response.ok && json ? json as JsonOf<InspectionDocument>[] : emptyArray);
            })
            .catch((error) => {
                captureException(error);
                !aborted && setOnlineInspections(emptyArray);
            })
            .then(() => setFetchActive(false), noop);

        return () => {
            aborted = true;
        };
    }, [userId, tab, page, pageSize, refresh, filterFunction]);

    const offlineInspections = useLiveQuery<JsonOf<InspectionDocument>[] | undefined>(async () => {
        if (inspectionTemplateIds === emptyArray || entityIds === emptyArray) {
            return;
        }

        setDexieActive(true);
        let skip = (page - 1) * pageSize;

        const records = await dexie.inspections
            .orderBy('conductedAt')
            .reverse()
            .filter((inspection) => {
                if (filterFunction(inspection)) {
                    skip--;
                    return skip < 0;
                }

                return false;
            })
            .limit(pageSize)
            .toArray();

        setDexieActive(false);
        return records;
    }, [tab, page, pageSize, filterFunction]) ?? emptyArray;

    const onlineOrOfflineInspections = (onlineInspections.length ? onlineInspections : offlineInspections);

    const createdInspections = useLiveQuery<JsonOf<InspectionDocument>[] | undefined>(async () => {
        if (!entityIds?.length || !inspectionTemplateIds?.length) {
            return;
        }

        const entries = await dexie.queue
            .where(['resourceTableName', 'action']).equals([dexie.inspections.name, 'create'])
            .and(({body}) => filterFunction(body as JsonOf<InspectionDocument>))
            .reverse()
            .sortBy('conductedAt') as QueueCreateEntry[];

        return entries.map(({body}) => body as JsonOf<InspectionDocument>);
    }, [filterFunction]) ?? emptyArray;

    const wantedPatches = useMemo<[string, 'update'][] | null>(() => {
        if (!onlineOrOfflineInspections.length && !createdInspections.length) {
            return null;
        }

        return [
            ...onlineOrOfflineInspections.map(({_id, offline_id}) => [_id, offline_id]) ?? [],
            ...createdInspections.map(({_id, offline_id}) => [_id, offline_id]) ?? []
        ]
            .flat(2)
            .filter((s): s is string => !!s)
            .map((id) => [id, 'update']);
    }, [onlineOrOfflineInspections, createdInspections, filterFunction]);

    const patches = useLiveQuery<QueueUpdateEntry[] | undefined>(async () => {
        if (!wantedPatches?.length) {
            return;
        }

        return dexie.queue
            .where(['resourceId', 'action']).anyOf(wantedPatches)
            .toArray() as Promise<QueueUpdateEntry[]>;
    }, [wantedPatches]) ?? emptyArray;

    const inspections = useMemo<JsonOf<InspectionDocument>[]>(() => {
        const result = [
            ...createdInspections,
            ...onlineOrOfflineInspections
        ];

        if (!result.length || !patches.length) {
            return result;
        }

        for (const update of patches) {
            const {resourceId, patch} = update;

            const index = result.findIndex(({_id, offline_id}) => resourceId === _id || (offline_id && resourceId === offline_id));
            if (index >= 0) {
                result[index] = applyJsonPatch<JsonOf<InspectionDocument>>(result[index], patch.filter(({op}) => op !== 'test'));
            }
        }

        return result.filter(filterFunction);
    }, [onlineInspections, offlineInspections, createdInspections, patches]);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspections,
        createInspection: async (body) => {
            if (!userId || !entityId) {
                return false;
            }
            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'inspections',
                    resourceId: body.offline_id!,
                    userId,
                    entityId,
                    body
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        updateInspection: async (inspectionId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !inspectionId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspections',
                    resourceId: inspectionId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
