import {dexie} from '../../../../dexie';
import {fetchApi} from '../../../helpers/frontend';
import {createRange} from '../../../helpers/headers';
import {HttpStatusCode} from '../../../HttpStatusCode';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const getInspectionTemplates = async (queryParams: QueryType, rangeStartIndex = 0, rangeEndIndex = 999) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        headers: {
            Range: createRange(rangeStartIndex, rangeEndIndex)
        },
        method,
        path,
        queryParams
    });

    const {response, json} = result;
    if (response.ok && response.status !== HttpStatusCode.NOT_MODIFIED && json) {
        await Promise.all(json.map<Promise<unknown>>((inspectionTemplate) => inspectionTemplate._deleted
            ? dexie.inspectionTemplates.delete(inspectionTemplate._id)
            : dexie.inspectionTemplates.put(inspectionTemplate)));
    }

    return result;
};
