declare const window: {
    webkit?: {
        messageHandlers?: {
            getAppVersion?: {
                postMessage: (message: '') => Promise<string>;
            };
            getAppStoreAppVersion?: {
                postMessage: (message: '') => Promise<string>;
            };
            openAppStore?: {
                postMessage: (message: '') => Promise<string>;
            };
        };
    };
    android?: {
        getAppVersion: () => Promise<string>;
        getAppStoreAppVersion: () => Promise<string>;
        openAppStore: () => Promise<string>;
    };
};

export const getAppVersion = async (): Promise<string | null> => {
    if (window.webkit?.messageHandlers?.getAppVersion?.postMessage) {
        const result = await window.webkit.messageHandlers.getAppVersion.postMessage('') ?? 0;
        return result || null;
    } else if (window?.android?.getAppVersion) {
        const result = await window.android.getAppVersion();
        return result || null;
    }

    return null;
};

export const getAppStoreAppVersion = async (): Promise<string | null> => {
    if (window.webkit?.messageHandlers?.getAppStoreAppVersion) {
        const result = await window.webkit.messageHandlers.getAppStoreAppVersion.postMessage('') ?? null;
        return result || null;
    } else if (window?.android?.getAppStoreAppVersion) {
        const result = await window.android.getAppStoreAppVersion();
        return result || null;
    }

    return null;
};

export const openAppStore = async (): Promise<string | null> => {
    if (window.webkit?.messageHandlers?.openAppStore?.postMessage) {
        const result = await window.webkit.messageHandlers.openAppStore.postMessage('') ?? 0;
        return result || null;
    } else if (window?.android?.openAppStore) {
        const result = await window.android.openAppStore();
        return result || null;
    }

    return null;
};
