import type {Dispatch, SetStateAction} from 'react';
import {useEffect, useState} from 'react';
import {singletonHook} from 'react-singleton-hook';
import {processQueue} from '../../dexie';
import {noop} from '../../util/functions';
import {useCurrentUser} from '../context';

let globalSetActive: Dispatch<SetStateAction<boolean>> | null = null;

const useSyncStateSingleton = singletonHook(false, () => {
    const [active, setActive] = useState(false);

    globalSetActive = setActive;

    return active;
});

export const useSyncState = (): [boolean, (() => ReturnType<typeof processQueue>) | null] => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const active = useSyncStateSingleton();

    const setActive = authenticatedUser
        ? async () => {
            try {
                globalSetActive?.(true);
                return await processQueue(authenticatedUser._id);
            } finally {
                globalSetActive?.(false);
            }
        }
        : null;

    useEffect(() => void setActive?.().catch(noop), [authenticatedUser?._id]);

    return [active, setActive];
};
