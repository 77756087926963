import type {FunctionComponent} from 'react';
import React, {useEffect, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {getChartSegments} from '../../../../api/endpoints/charts/[chartId]/chart-segments/get/frontend';
import {createChartSegment} from '../../../../api/endpoints/charts/[chartId]/chart-segments/post/frontend';
import {updateChart} from '../../../../api/endpoints/charts/[chartId]/patch/frontend';
import {createChart} from '../../../../api/endpoints/charts/post/frontend';
import type {ChartDocument} from '../../../../database/collections/charts';
import type {ChartSegmentDocument, TaskChartSegmentDocument, InspectionChartSegmentDocument} from '../../../../database/collections/chartSegments';
import type {JsonOf} from '../../../../interfaces/helpers';
import {createJsonPointer} from '../../../../interfaces/models/json-pointer';
import {DateSlideout} from '../../../../react/components/DateSlideout';
import {FilterButton} from '../../../../react/components/FilterButton';
import {Icoontje} from '../../../../react/components/Icoontje';
import {InspectionStateSlideout} from '../../../../react/components/InspectionStateSlideout';
import {InspectionTemplateSlideout} from '../../../../react/components/InspectionTemplateSlideout';
import {Knopje} from '../../../../react/components/Knopje';
import {LocationSlideout} from '../../../../react/components/LocationSlideout';
import {Switch} from '../../../../react/components/Switch';
import {TaskStateSlideout} from '../../../../react/components/TaskStateSlideout';
import {TeamMemberSlideout} from '../../../../react/components/TeamMemberSlideout';
import {Tekst} from '../../../../react/components/Tekst';
import {RadioButton} from '../../../../react/components/toggles/RadioButton';
import {useCurrentUser} from '../../../../react/context';
import type {SlideoutUrlState} from '../../../../react/hooks';
import {useUrlState} from '../../../../react/hooks';
import {useChart} from '../../../../react/hooks/useChart';
import {__} from '../../../../translations';
import {createOfflineObjectId} from '../../../../types/MongoDb';
import {Input} from '../../../components/Input';
import {Appbar} from '../../../components/layout/Appbar';
import {Content} from '../../../components/layout/Content';
import {Page} from '../../../components/layout/Page';
import {Sidebar} from '../../../components/layout/Sidebar';
import {Select, SelectOption} from '../../../components/Select';
import {Textarea} from '../../../components/Textarea';
import {useAsyncMemo} from '../../../hooks';
import AddIcon from '../../../icons/plus-2.svg';
import TrashIcon from '../../../icons/trash.svg';
import './StatisticsCreatePage.scss';

const inspectionFieldOptions: {
    label: string;
    value: string;
    field: JsonOf<ChartSegmentDocument>['field'];
    filter: JsonOf<ChartSegmentDocument>['filter'];
}[] = [{
    label: __('Score'),
    value: 'score',
    field: 'score',
    filter: {
        isClosed: true
    }
}, {
    label: __('Amount'),
    value: 'amount',
    field: '_id',
    filter: {}
}, {
    label: __('Amount of incorrect items'),
    value: 'incorrect-item-count',
    field: 'incorrect-item-count',
    filter: {
        isClosed: true
    }
}];
const taskFieldOptions: {
    label: string;
    value: string;
    field: JsonOf<ChartSegmentDocument>['field'];
    filter: JsonOf<ChartSegmentDocument>['filter'];
}[] = [{
    label: __('Amount'),
    value: 'amount',
    field: '_id',
    filter: {}
}];
let fieldOptions = inspectionFieldOptions;
let defaultSelectedFieldValue = [fieldOptions[0].value];

export const StatisticsCreatePage: FunctionComponent<RouteComponentProps<{
    chartId?: string;
}>> = (props) => {
    const {chartId} = props.match.params;
    const {currentUser} = useCurrentUser();
    const [slideout, setSlideout] = useUrlState<SlideoutUrlState>('slideout', '');
    const [chartName, setChartName] = useState<string>();
    const [groupBy, setGroupBy] = useState<JsonOf<ChartDocument>['groupBy']>('date');
    const [orientation, setOrientation] = useState<JsonOf<ChartDocument>['orientation']>('horizontal');
    const [showLegend, setShowLegend] = useState<boolean>(true);
    const [showGrid, setShowGrid] = useState<boolean>(true);
    const [showTooltip, setShowTooltip] = useState<boolean>(true);
    const [showXAxis, setShowXAxis] = useState<boolean>(true);
    const [showYAxis, setShowYAxis] = useState<boolean>(true);
    const [customRange, setCustomRange] = useState<boolean>(false);
    const [yRangeStart, setYRangeStart] = useState<string>();
    const [yRangeEnd, setYRangeEnd] = useState<string>();
    const [step, setStep] = useState<'chart' | 'segments'>('chart');
    const {chart} = chartId ? useChart(chartId) : {chart: undefined};
    const [segmentIndex, setSegmentIndex] = useState<number>(0);
    const [segments, setSegments] = useState<(
        JsonOf<Omit<InspectionChartSegmentDocument, '_id' | 'chart_id'>>
        | JsonOf<Omit<TaskChartSegmentDocument, '_id' | 'chart_id'>>
    )[]>([]);
    const [mainSegmentIndex, setMainSegmentIndex] = useState<number>(0);
    const [selectedFieldValue, setSelectedFieldValue] = useState(defaultSelectedFieldValue);

    useAsyncMemo(async () => {
        if (!chartId) {
            return;
        }

        const {response, json: chartSegments} = await getChartSegments({chartId});
        if (!response.ok) {
            return;
        }

        if (chart && chartSegments) {
            setChartName(`${chart.name} - ${__('duplicate')}`);
            setGroupBy(chart.groupBy);
            setOrientation(chart.orientation);
            setShowGrid(chart.showGrid);
            setShowLegend(chart.showLegend);
            setShowTooltip(chart.showTooltip);
            setShowXAxis(chart.showXAxis);
            setShowYAxis(chart.showYAxis);
            setSegments(chartSegments);
            setStep('chart');
        }
    }, [chartId, chart]);

    useEffect(() => {
        if (!segments.length) {
            return;
        }
        const {collection, field} = segments[segmentIndex];
        if (collection === 'inspections') {
            fieldOptions = inspectionFieldOptions;
        } else if (collection === 'tasks') {
            fieldOptions = taskFieldOptions;
        } else {
            return;
        }
        const selectedOption = fieldOptions.find((option) => option.field === field);
        setSelectedFieldValue([selectedOption?.value ?? fieldOptions[0].value]);
    }, [segments, segmentIndex]);

    const resetSegment = (chartSegment: JsonOf<Omit<InspectionChartSegmentDocument, '_id' | 'chart_id'>>
        | JsonOf<Omit<TaskChartSegmentDocument, '_id' | 'chart_id'>>): JsonOf<Omit<InspectionChartSegmentDocument, '_id' | 'chart_id'>>
        | JsonOf<Omit<TaskChartSegmentDocument, '_id' | 'chart_id'>> => {
        if (chartSegment.collection === 'inspections') {
            fieldOptions = inspectionFieldOptions;
            defaultSelectedFieldValue = [fieldOptions[0].value];

            return {
                ...chartSegment,
                filter: {isClosed: true},
                field: 'score',
                method: 'average'
            };
        } else if (chartSegment.collection === 'tasks') {
            fieldOptions = taskFieldOptions;
            defaultSelectedFieldValue = [fieldOptions[0].value];

            return {
                ...chartSegment,
                filter: {},
                field: '_id',
                method: 'add'
            };
        }
        console.log(chartSegment);
        return chartSegment;
    };
    const createChartAndSegments = async () => {
        if (!currentUser?.context?.entityId) {
            return;
        }
        const chart: JsonOf<Omit<ChartDocument, '_id'>> = {
            createdAt: Date.now(),
            createdBy: currentUser._id,
            entity_id: currentUser?.context?.entityId,
            groupBy,
            name: chartName ?? __('Untitled chart'),
            orientation,
            showGrid,
            showLegend,
            showTooltip,
            showXAxis,
            showYAxis,
            showZoom: false,
            touchedAt: Date.now(),
            touchedBy: currentUser._id,
            valueDataKey: '0'.repeat(24),
            yRangeStart: customRange && yRangeStart ? +yRangeStart : undefined,
            yRangeEnd: customRange && yRangeEnd ? +yRangeEnd : undefined,
            offline_id: createOfflineObjectId(),
            removed: 0,
            removedAt: undefined,
            removedBy: undefined
        };

        const {json: createdChart} = await createChart(chart);
        if (!createdChart) {
            return;
        }
        for (const [index, segment] of segments.entries()) {
            const completeSegment: JsonOf<Omit<InspectionChartSegmentDocument | TaskChartSegmentDocument, '_id'>> = {...segment, chart_id: createdChart._id, name: segment.name || __('Untitled segment')};
            const {json: createdSegment} = await createChartSegment({chartId: createdChart._id}, completeSegment as JsonOf<Omit<InspectionChartSegmentDocument, '_id'>> | JsonOf<Omit<TaskChartSegmentDocument, '_id'>>);
            if (createdSegment && index === mainSegmentIndex) {
                await updateChart({chartId: createdChart._id}, [{
                    op: 'replace',
                    path: createJsonPointer('valueDataKey'),
                    value: createdSegment._id
                }]);
            }
        }
        props.history.push('/statistics');
    };
    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setSegments([{
            collection: 'inspections',
            colors: ['#29aee0'],
            createdAt: Date.now(),
            createdBy: currentUser._id,
            touchedAt: Date.now(),
            touchedBy: currentUser._id,
            entity_id: currentUser.context?.entityId!,
            field: 'score',
            filter: {isClosed: true},
            method: 'average',
            name: '',
            type: 'line',
            removed: 0,
            removedAt: undefined,
            removedBy: undefined
        }]);
    }, [currentUser]);

    return (
        <Page name="statistics-create">
            <Appbar title={__('Statistics / create chart')}/>
            <Sidebar/>
            <Content>
                <div className="preview">&nbsp;</div>
                <div className="details">
                    <div className="details--element"><Tekst size="large" weight="bold">{step === 'chart' ? __('Create chart') : __('Create segments')}</Tekst></div>
                    <div className="details--body">
                        {step === 'chart'
                            ? (
                                <><div>
                                    <Tekst weight="bold">{__('Chart name')}</Tekst>
                                    <Textarea name="chart name" placeholder={__('Chart name')} value={chartName} onChange={(event) => setChartName(event.target.value)}/>
                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Group by')}</Tekst>
                                    <Select value={[groupBy]} name="group by" onChange={(event) => setGroupBy(event.target.value as unknown as JsonOf<ChartDocument>['groupBy'])}>
                                        <SelectOption value="date" text={__('Date')}/>
                                        <SelectOption value="template_id" text={__('Template')}/>
                                        <SelectOption value="loction_id" text={__('Location')}/>
                                        <SelectOption value="assignedTo" text={__('Assigned user')}/>
                                    </Select>
                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Orientation')}</Tekst>
                                    <Select value={[orientation]} name="orientation" onChange={(event) => setOrientation(event.target.value as unknown as JsonOf<ChartDocument>['orientation'])}>
                                        <SelectOption value="horizontal" text={__('Horizontal')}/>
                                        <SelectOption value="vertical" text={__('Vertical')}/>

                                    </Select>
                                </div>
                                <div className="details--show-items">
                                    <Tekst weight="bold">{__('Chart configuration')}</Tekst>
                                    <Switch label={__('Show legend')} value={showLegend ? 'on' : 'off'} onChange={(value) => setShowLegend(value === 'on')}/>
                                    <Switch label={__('Show grid')} value={showGrid ? 'on' : 'off'} onChange={(value) => setShowGrid(value === 'on')}/>
                                    <Switch label={__('Show tooltip')} value={showTooltip ? 'on' : 'off'} onChange={(value) => setShowTooltip(value === 'on')}/>
                                    <Switch label={__('Show x-axis')} value={showXAxis ? 'on' : 'off'} onChange={(value) => setShowXAxis(value === 'on')}/>
                                    <Switch label={__('Show y-axis')} value={showYAxis ? 'on' : 'off'} onChange={(value) => setShowYAxis(value === 'on')}/>
                                    <Switch label={__('Custom ranges')} value={customRange ? 'on' : 'off'} onChange={(value) => setCustomRange(value === 'on')}/>
                                </div>
                                {customRange
                                    ? (
                                        <div className="details--value-range">
                                            <Tekst className="details--value-range_name">{__('Value range')}</Tekst>
                                            <Tekst className="details--value-range_start">{__('Start')}</Tekst>
                                            <Input className="details--value-range_start-input" name="" label="" type="number" autoComplete="off" value={yRangeStart} onChange={(event) => setYRangeStart(event.currentTarget.value)}/>

                                            <Tekst className="details--value-range_end">{__('End')}</Tekst>
                                            <Input className="details--value-range_end-input" name="" label="" type="number" autoComplete="off" value={yRangeEnd} onChange={(event) => setYRangeEnd(event.currentTarget.value)}/>

                                        </div>
                                    )
                                    : null}</>
                            )
                            : (
                                <><div className="details--choose-segment">
                                    <div className="details--choose-segment_segments">{segments.map((segment, index) => (
                                        <Knopje key={index} shape="square" corners="sharp" size="large" paint="underline" color={index === segmentIndex ? 'blue' : 'white'} onClick={() => setSegmentIndex(index)}><Tekst color="black" weight="bold">{index}</Tekst></Knopje>
                                    ))}</div>
                                    <Knopje onClick={() => {
                                        if (!currentUser) {
                                            return;
                                        }

                                        setSegments([...segments, {
                                            collection: 'inspections',
                                            colors: ['#29aee0'],
                                            createdAt: Date.now(),
                                            createdBy: currentUser._id,
                                            touchedAt: Date.now(),
                                            touchedBy: currentUser._id,
                                            entity_id: currentUser.context?.entityId!,
                                            field: 'score',
                                            filter: {
                                                isClosed: true
                                            },
                                            method: 'average',
                                            name: '',
                                            type: 'line',
                                            removed: 0,
                                            removedAt: undefined,
                                            removedBy: undefined
                                        }]);
                                        setSegmentIndex(segments.length);
                                    }}><Icoontje color="black" Svg={AddIcon}/></Knopje>
                                </div>
                                <RadioButton checked={mainSegmentIndex === segmentIndex ? 'checked' : 'unchecked'} tooltip="Select this to auto-scale the value axis to this segment" onChange={() => setMainSegmentIndex(segmentIndex)}>
                                    {__('Main segment')}
                                </RadioButton>
                                <Knopje
                                    paint="fill" color="red" onClick={() => {
                                        setSegments(segments.filter((segment, index) => index !== segmentIndex));
                                        setSegmentIndex(Math.max(0, segmentIndex - 1));
                                    }}>
                                    <Icoontje color="white" Svg={TrashIcon}/>
                                </Knopje>

                                <div>
                                    <Tekst weight="bold">{__('Segment name')}</Tekst>
                                    <Textarea
                                        name="segment name"
                                        placeholder={__('Segment name')}
                                        value={segments[segmentIndex]?.name}
                                        onChange={(event) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].name = event.target.value;
                                            setSegments(newArr);
                                        }}/>
                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Collection')}</Tekst>
                                    <Select
                                        value={[segments[segmentIndex]?.collection]} name="Collection" onChange={(event) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].collection = event.target.value as JsonOf<ChartSegmentDocument>['collection'];
                                            newArr[segmentIndex] = resetSegment(newArr[segmentIndex]);
                                            setSegments(newArr);
                                        }}>
                                        <SelectOption value="inspections" text={__('Inspections')}/>
                                        <SelectOption value="tasks" text={__('Tasks')}/>

                                    </Select>
                                </div>
                                <div className="details--color">
                                    <Tekst weight="bold">{__('Color')}</Tekst>
                                    <Input
                                        className="details--color-input" name="" label="" type="color" autoComplete="off" value={segments[segmentIndex]?.colors[0]} onChange={(event) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].colors[0] = event.currentTarget.value as JsonOf<ChartSegmentDocument>['colors']['0'];
                                            setSegments(newArr);
                                        }}/>

                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Visualisation')}</Tekst>
                                    <Select
                                        value={[segments[segmentIndex]?.type]} name="Visualisation" onChange={(event) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].type = event.target.value as JsonOf<ChartSegmentDocument>['type'];
                                            setSegments(newArr);
                                        }}>
                                        <SelectOption value="line" text={__('Line')}/>
                                        <SelectOption value="bar" text={__('Bar')}/>
                                        <SelectOption value="area" text={__('Area')}/>

                                    </Select>
                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Field')}</Tekst>
                                    <Select
                                        name="Field"
                                        value={selectedFieldValue}
                                        onChange={(event) => {
                                            const {value} = event.target;
                                            const fieldOption = fieldOptions.find((option) => option.value === value);

                                            if (!fieldOption) {
                                                return;
                                            }

                                            setSelectedFieldValue([fieldOption.value]);
                                            const newArr = [...segments];
                                            newArr[segmentIndex].field = fieldOption.field;

                                            newArr[segmentIndex].filter = {
                                                assignedToAny: newArr[segmentIndex].filter.assignedToAny,
                                                locationIdAny: newArr[segmentIndex].filter.locationIdAny,
                                                templateIdAny: newArr[segmentIndex].filter.templateIdAny,
                                                ...fieldOption.filter
                                            };

                                            setSegments(newArr);
                                        }}>
                                        {fieldOptions.map(({label, value}) => (
                                            <SelectOption key={value} value={value} text={label}/>
                                        ))}
                                    </Select>
                                </div>
                                <div className="details--dropdown">
                                    <Tekst weight="bold">{__('Chart data')}</Tekst>
                                    <Select
                                        value={[segments[segmentIndex]?.method]} name="Chart data" onChange={(event) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].method = event.target.value as JsonOf<ChartSegmentDocument>['method'];
                                            setSegments(newArr);
                                        }}>
                                        <SelectOption value="average" text={__('Average')}/>
                                        <SelectOption value="add" text={__('Add')}/>
                                    </Select>
                                </div>
                                <Tekst weight="bold">{__('Filter')}</Tekst>
                                <div className="details--filter">
                                    <FilterButton active={!!(segments?.[segmentIndex]?.filter?.createdBefore || segments?.[segmentIndex]?.filter?.createdAfter)} onClick={() => setSlideout('date')}>
                                        {__('Creation date')}
                                    </FilterButton>
                                    <FilterButton active={!!segments?.[segmentIndex]?.filter?.assignedToAny?.length} onClick={() => setSlideout('teamMember')}>
                                        {__('Assigned To')}
                                    </FilterButton>
                                    <FilterButton active={!!segments?.[segmentIndex]?.filter?.locationIdAny?.length} onClick={() => setSlideout('location')}>
                                        {__('Location')}
                                    </FilterButton>
                                    <FilterButton active={!!segments?.[segmentIndex]?.filter?.templateIdAny?.length} onClick={() => setSlideout('inspectionTemplate')}>
                                        {__('Template')}
                                    </FilterButton>
                                    {
                                        segments?.[segmentIndex]?.collection === 'inspections'
                                            ? (
                                                <FilterButton active={segments?.[segmentIndex]?.filter?.isClosed !== undefined} onClick={() => setSlideout('inspectionState')}>
                                                    {__('Inspection state')}
                                                </FilterButton>
                                            )
                                            : segments?.[segmentIndex]?.collection === 'tasks'
                                                ? (
                                                    <FilterButton active={segments?.[segmentIndex]?.filter?.isClosed !== undefined} onClick={() => setSlideout('taskState')}>
                                                        {__('Task state')}
                                                    </FilterButton>
                                                )
                                                : null
                                    }
                                </div>
                                </>
                            )}
                    </div>
                    <div className="details--footer">
                        <Knopje
                            paint="fill" color="white" onClick={() => {
                                step === 'segments' ? setStep('chart') : props.history.goBack();
                            }}><Tekst color="black">{step === 'chart' ? __('Cancel') : __('Previous')}</Tekst></Knopje>
                        {step === 'chart'
                            ? <Knopje paint="fill" color="blue" onClick={() => setStep('segments')}><Tekst color="white">{__('Next')}</Tekst></Knopje>
                            : <Knopje paint="fill" color="blue" onClick={createChartAndSegments}><Tekst color="white">{__('Create Chart')}</Tekst></Knopje>}
                    </div>
                </div>
                {slideout === 'teamMember'
                    ? (
                        <TeamMemberSlideout
                            show="all" userIds={segments[segmentIndex].filter.assignedToAny ?? []} setUserIds={(userArray) => {
                                const newArr = [...segments];
                                newArr[segmentIndex].filter.assignedToAny = userArray as JsonOf<ChartSegmentDocument>['filter']['assignedToAny'];
                                setSegments(newArr);
                            }}
                        />
                    )
                    : slideout === 'location'
                        ? (
                            <LocationSlideout
                                show="assigned" locationIds={segments[segmentIndex].filter.locationIdAny ?? []} setLocationIds={(locationArray) => {
                                    const newArr = [...segments];
                                    newArr[segmentIndex].filter.locationIdAny = locationArray as JsonOf<ChartSegmentDocument>['filter']['locationIdAny'];
                                    setSegments(newArr);
                                }}/>
                        )
                        : slideout === 'date'
                            ? (
                                <DateSlideout
                                    createdBefore={segments[segmentIndex].filter.createdBefore?.toString() ?? ''}
                                    createdAfter={segments[segmentIndex].filter.createdAfter?.toString() ?? ''}
                                    setCreatedBefore={(timestampInMs) => {
                                        const newArr = [...segments];
                                        newArr[segmentIndex].filter.createdBefore = timestampInMs ? +timestampInMs as JsonOf<ChartSegmentDocument>['filter']['createdBefore'] : undefined;
                                        setSegments(newArr);
                                    }}
                                    setCreatedAfter={(timestampInMs) => {
                                        const newArr = [...segments];
                                        newArr[segmentIndex].filter.createdAfter = timestampInMs ? +timestampInMs as JsonOf<ChartSegmentDocument>['filter']['createdAfter'] : undefined;
                                        setSegments(newArr);
                                    }}
                                />
                            )

                            : slideout === 'inspectionTemplate'
                                ? (
                                    <InspectionTemplateSlideout
                                        show="assigned" inspectionTemplateIds={segments[segmentIndex].filter.templateIdAny ?? []} setInspectionTemplateIds={(templateArray) => {
                                            const newArr = [...segments];
                                            newArr[segmentIndex].filter.templateIdAny = templateArray as JsonOf<ChartSegmentDocument>['filter']['templateIdAny'];
                                            setSegments(newArr);
                                        }}/>
                                )
                                : slideout === 'inspectionState'
                                    ? (
                                        <InspectionStateSlideout
                                            inspectionState={segments[segmentIndex].filter.isClosed === undefined ? 'all' : segments[segmentIndex].filter.isClosed ? 'done' : 'open'}
                                            setInspectionState={(inspectionState) => {
                                                const isClosed = inspectionState === 'done' ? true : inspectionState === 'open' ? false : undefined;
                                                const newArr = [...segments];
                                                newArr[segmentIndex].filter.isClosed = isClosed as JsonOf<ChartSegmentDocument>['filter']['isClosed'];
                                                setSegments(newArr);
                                            }}
                                        />
                                    )
                                    : slideout === 'taskState'
                                        ? (
                                            <TaskStateSlideout
                                                taskState={segments[segmentIndex].filter.isClosed === undefined && (segments[segmentIndex] as JsonOf<TaskChartSegmentDocument>).filter.isReviewable === undefined ? 'all' : segments[segmentIndex].filter.isClosed ? 'done' : (segments[segmentIndex] as JsonOf<TaskChartSegmentDocument>).filter.isReviewable ? 'submitted' : 'open'}
                                                setTaskState={(taskState) => {
                                                    const isClosed = taskState === 'done' ? true : taskState === 'open' || taskState === 'submitted' ? false : undefined;
                                                    const isReviewable = taskState === 'submitted' ? true : taskState === 'open' || taskState === 'done' ? false : undefined;
                                                    const newArr = [...segments];
                                                    if (newArr[segmentIndex].collection !== 'tasks') {
                                                        return;
                                                    }
                                                    newArr[segmentIndex].filter.isClosed = isClosed as JsonOf<TaskChartSegmentDocument>['filter']['isClosed'];
                                                    (newArr[segmentIndex] as JsonOf<TaskChartSegmentDocument>).filter.isReviewable = isReviewable as JsonOf<TaskChartSegmentDocument>['filter']['isReviewable'];
                                                    setSegments(newArr);
                                                }}
                                            />
                                        )
                                        : null}
            </Content>
        </Page>
    );
};

export default StatisticsCreatePage;
