import {fetchApi} from '../../../../../helpers/frontend';
import {createRange} from '../../../../../helpers/headers';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const getExclusiveLibraryItems = async (urlParams: ParamType, queryParams: QueryType, rangeStartIndex = 0, rangeEndIndex = 99) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        headers: {
            Range: createRange(rangeStartIndex, rangeEndIndex)
        },
        method,
        path,
        queryParams,
        urlParams
    });

    return result;
};
