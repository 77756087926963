import {dexie} from '../../../../dexie';
import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path} from './shared';

export const deleteSessions = async (queryParams: QueryType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        queryParams
    });

    const {response} = result;
    if (response.ok) {
        await dexie.sessions.filter((session) => {
            if (queryParams.environment && session.environment !== queryParams.environment) {
                return false;
            }

            if (queryParams.expiresBefore && new Date(session.expiresAt) < new Date(queryParams.expiresBefore)) {
                return false;
            }

            if (queryParams.expiresAfter && new Date(session.expiresAt) > new Date(queryParams.expiresAfter)) {
                return false;
            }

            if (queryParams.userIdAny && queryParams.userIdAny.includes(session.user_id)) {
                return false;
            }

            return true;
        }).delete();
    }

    return result;
};
