import type {TaskDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/tasks/:taskId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    taskId: string;
};

export type QueryType = never;

export type OutputType = TaskDocument;
