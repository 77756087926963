import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getReportLayout} from '../../api/endpoints/report-layouts/[reportLayoutId]/get/frontend';
import type {ReportLayoutDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import {useCurrentSession} from '../context';
import type {SimplifiedReportLayout} from '../helpers/SimplifiedReportLayout';
import {simplifyReportLayout} from '../helpers/SimplifiedReportLayout';
import {useDexieResource} from './useDexieResource';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useReportLayout = (reportLayoutId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    reportLayout: JsonOf<ReportLayoutDocument> | null;
    simplifiedReportLayout: SimplifiedReportLayout | null;
    updateReportLayout: (patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resource: reportLayout, dexieActive} = useDexieResource<ReportLayoutDocument>({
        table: dexie.reportTemplates,
        primaryKey: reportLayoutId
    });

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId || !reportLayoutId) {
            return;
        }

        setFetchActive(true);
        getReportLayout({reportLayoutId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, reportLayoutId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        reportLayout,
        simplifiedReportLayout: reportLayout && simplifyReportLayout(reportLayout),
        updateReportLayout: async (patch: JsonPatch) => {
            if (!userId || !entityId || !reportLayoutId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'reportTemplates',
                    resourceId: reportLayoutId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
