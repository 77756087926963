import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import type {KnopjePropsButton} from '../Knopje';
import {Knopje} from '../Knopje';
import {Tekst} from '../Tekst';
import './FilterButton.scss';

export type FilterButtonProps = {
    active: boolean;
    children: string;
    onClick: KnopjePropsButton['onClick'];
    disabled?: KnopjePropsButton['disabled'];
};

export type FilterButtonComponent = ReactElement<FilterButtonProps>;

export const FilterButton: VoidFunctionComponent<FilterButtonProps> = ({
    active,
    children,
    onClick,
    disabled
}): FilterButtonComponent => (
    <Knopje
        disabled={disabled}
        onClick={onClick}
        color={active ? 'orange' : 'gray'}
        paint="fill"
        corners="round"
        className="filter-button"
    >
        <Tekst color="black" className="filter-button__text">
            {children}
        </Tekst>
    </Knopje>
);
