import type {FunctionComponent} from 'react';
import React, {useState, useEffect, useMemo} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {Icoontje} from '../../../../react/components/Icoontje';
import {Image} from '../../../../react/components/Image';
import {Knopje} from '../../../../react/components/Knopje';
import {Tekst} from '../../../../react/components/Tekst';
import {useCurrentUser} from '../../../../react/context';
import {__} from '../../../../translations';
import {Content} from '../../../components/layout/Content';
import {MessageFooter} from '../../../components/layout/Footer';
import {Page} from '../../../components/layout/Page';
import Logo from '../../../icons/logo_checkbuster-black.png';
import Mail from '../../../icons/mail.svg';
import SocialLogoApple from '../../../icons/social-logo-apple.svg';
import SocialLogoGithub from '../../../icons/social-logo-github.svg';
import SocialLogoGoogle from '../../../icons/social-logo-google.svg';
import SocialLogoMicrosoft from '../../../icons/social-logo-microsoft.svg';
import {getCookie, setCookie} from '../../../utilities/storage';
import './SingleSignOnPage.scss';

export const SingleSignOnPage: FunctionComponent<RouteComponentProps> = ({history}) => {
    document.title = `Checkbuster | ${__('Single Sign-On')}`;
    const [message, setMessage] = useState<string>('');

    const first = useMemo(() => {
        const os = getCookie('os');
        return os === 'ios' || os === 'mac' ? 'apple' : os === 'android' ? 'google' : 'microsoft';
    }, []);

    const {currentUser: authenticatedUser} = useCurrentUser();

    useEffect(() => {
        authenticatedUser && history.replace(authenticatedUser.needsPassword ? `/team-members/${authenticatedUser._id}` : '/inspections');
    }, [authenticatedUser]);

    useEffect(() => setCookie('authpage', 'sso'), []);

    useEffect(() => {
        location.hash.includes('link-microsoft') && setMessage(__('We\'ve sent you an email to confirm your registration!'));
    }, [location.hash]);

    return (
        <Page name="sso" disableLogin>
            <Content>
                <Image src={Logo} className="logo" alt={__('Checkbuster logo')}/>

                <Tekst size="huge" className="title">
                    {__('Let\'s check in')}
                </Tekst>

                <Knopje
                    type="anchor" to="#" color="blue" paint="outline" corners="round" size="huge" className="social"
                    trackingId={`sso-${first}`} onClick={() => {
                        location.pathname = `/auth/${first}`;
                    }}>
                    <Icoontje size="standard" Svg={first === 'apple' ? SocialLogoApple : first === 'google' ? SocialLogoGoogle : SocialLogoMicrosoft}/>
                    <Tekst size="standard" weight="bold" color="black">
                        {first === 'apple' ? __('Continue with Apple') : first === 'google' ? __('Continue with Google') : __('Continue with Microsoft')}
                    </Tekst>
                </Knopje>

                <Knopje
                    type="anchor" to="#" color="blue" paint="outline" corners="round" size="huge" className="social"
                    trackingId={`sso-${first !== 'microsoft' ? 'microsoft' : 'google'}`} onClick={() => {
                        location.pathname = `/auth/${first !== 'microsoft' ? 'microsoft' : 'google'}`;
                    }}>
                    <Icoontje size="standard" Svg={first !== 'microsoft' ? SocialLogoMicrosoft : SocialLogoGoogle}/>
                    <Tekst size="standard" weight="bold" color="black">
                        {first !== 'microsoft' ? __('Continue with Microsoft') : __('Continue with Google')}
                    </Tekst>
                </Knopje>

                <Knopje
                    type="anchor" to="#" color="blue" paint="outline" corners="round" size="huge" className="social"
                    trackingId={`sso-${first === 'apple' ? 'google' : 'apple'}`} onClick={() => {
                        location.pathname = `/auth/${first === 'apple' ? 'google' : 'apple'}`;
                    }}>
                    <Icoontje size="standard" Svg={first === 'apple' ? SocialLogoGoogle : SocialLogoApple}/>
                    <Tekst size="standard" weight="bold" color="black">
                        {first === 'apple' ? __('Continue with Google') : __('Continue with Apple')}
                    </Tekst>
                </Knopje>
                {process.env.NODE_ENV !== 'production'
                    ? (
                        <Knopje
                            color="blue" paint="outline" corners="round" size="huge" className="social"
                            onClick={() => {
                                location.pathname = '/auth/github';
                            }}>
                            <Icoontje size="standard" Svg={SocialLogoGithub}/>
                            <Tekst size="standard" weight="bold" color="black">
                                {__('Continue with GitHub')}
                            </Tekst>
                        </Knopje>
                    )
                    : null}

                <Tekst className="alternative">
                    {__('or')}
                </Tekst>

                <Knopje
                    type="anchor" to="/register" color="blue" paint="outline" corners="round" size="huge"
                    className="social" trackingId="sso-email-register">
                    <Icoontje size="standard" Svg={Mail}/>
                    <Tekst size="standard" weight="bold" color="black">
                        {__('Create account with e-mail')}
                    </Tekst>
                </Knopje>

                <hr className="ruler"/>

                <Tekst>
                    {__('You can also check in with e-mail')}
                </Tekst>

                <Knopje
                    type="anchor" to="/login" color="white" corners="round" paint="fill" className="login"
                    trackingId="sso-email-login">
                    <Tekst color="blue" decoration="underline">
                        {__('Log in with e-mail')}
                    </Tekst>
                </Knopje>
            </Content>
            <MessageFooter message={message}/>
        </Page>
    );
};
