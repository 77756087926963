import {useEffect, useMemo, useState} from 'react';
import {getMemberships} from '../../api/endpoints/memberships/get/frontend';
import type {SimplifiedMembershipDocument} from '../../database';
import {dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {useCurrentSession} from '../context';
import {sortStably, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useMembership = (): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    membership: (JsonOf<SimplifiedMembershipDocument> & {
        remainingDays: number;
    }) | null;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: uid, entity_id: entityId, token} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resources: memberships, dexieActive} = useDexieResources<SimplifiedMembershipDocument>({
        sort: sortStably,
        table: dexie.memberships,
        query: async () => {
            if (!entityId) {
                return;
            }

            const membership = await dexie.memberships
                .where('context.entityId').equals(entityId)
                .first();

            return membership ? [membership] : [];
        }
    }, [entityId]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    const membership = useMemo<(JsonOf<SimplifiedMembershipDocument> & {
        remainingDays: number;
    }) | null>(() => {
            if (!memberships[0]?.trialEnd) {
                return null;
            }

            const remainingMillis = memberships[0].trialEnd - Date.now();

            return {
                ...memberships[0],
                remainingDays: Math.max(0, Math.round(remainingMillis / (24 * 60 * 60 * 1000)))
            };
        }, [!!memberships[0], memberships[0]?.trialEnd, memberships[0]?.cancelled]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!uid) {
            return;
        }

        setFetchActive(true);
        getMemberships({})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [uid, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        membership
    };
};
