import {
    canAssignNames,
    canAssignPasswords,
    canAssignRoles,
    canCreateTemplates,
    canUpdateTemplates,
    canRemoveTemplates,
    canAssignTemplates,
    canCreateLocations,
    canUpdateLocations,
    canRemoveLocations,
    canAssignLocations,
    canCreateTasks,
    canUpdateTasks,
    canRemoveTasks,
    canAssignTasks,
    canCreateUsers,
    canRemoveUsers,
    canVerifyDomains, canCreateCharts, canRemoveCharts
} from '../../util/permissions';
import {useCurrentUser} from '../context';

export const usePermissions = (): {
    canAssignNames: boolean;
    canAssignPasswords: boolean;
    canAssignRoles: boolean;
    canCreateTemplates: boolean;
    canUpdateTemplates: boolean;
    canRemoveTemplates: boolean;
    canAssignTemplates: boolean;
    canCreateLocations: boolean;
    canUpdateLocations: boolean;
    canRemoveLocations: boolean;
    canAssignLocations: boolean;
    canCreateTasks: boolean;
    canUpdateTasks: boolean;
    canRemoveTasks: boolean;
    canAssignTasks: boolean;
    canCreateUsers: boolean;
    canRemoveUsers: boolean;
    canVerifyDomains: boolean;
    canCreateCharts: boolean;
    canRemoveCharts: boolean;
} => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const roles = authenticatedUser?.roles ?? [];

    return {
        canAssignNames: canAssignNames(roles),
        canAssignPasswords: canAssignPasswords(roles),
        canAssignRoles: canAssignRoles(roles),
        canCreateTemplates: canCreateTemplates(roles),
        canUpdateTemplates: canUpdateTemplates(roles),
        canRemoveTemplates: canRemoveTemplates(roles),
        canAssignTemplates: canAssignTemplates(roles),
        canCreateLocations: canCreateLocations(roles),
        canUpdateLocations: canUpdateLocations(roles),
        canRemoveLocations: canRemoveLocations(roles),
        canAssignLocations: canAssignLocations(roles),
        canCreateTasks: canCreateTasks(roles),
        canUpdateTasks: canUpdateTasks(roles),
        canRemoveTasks: canRemoveTasks(roles),
        canAssignTasks: canAssignTasks(roles),
        canCreateUsers: canCreateUsers(roles),
        canRemoveUsers: canRemoveUsers(roles),
        canVerifyDomains: canVerifyDomains(roles),
        canCreateCharts: canCreateCharts(roles),
        canRemoveCharts: canRemoveCharts(roles)
    };
};
