import type {ResourceTranslationDocument} from '../../../../../database';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/resource-ranslations/:resourceTranslationId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    resourceTranslationId: string;
};

export type QueryType = never;

export type OutputType = ResourceTranslationDocument;
