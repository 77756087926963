import type {Serializable} from '../../types/Serializable';

export const hashValue = async (signature: Serializable): Promise<string> => {
    const msgUint8 = new TextEncoder().encode(JSON.stringify(signature));
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
};

export type CbCookie = 'iso' | 'version_pref' | 'env' | 'os' | 'size' | 'authpage' | 'auth.popup';

export const getCookie = (name: CbCookie): string | undefined => new RegExp(`(?:^| )${name}=([^;]+)`).exec(document.cookie)?.[1];

export const setCookie = (name: CbCookie, value: string, days = 365, samesite: 'lax' | 'none' = 'lax'): void => {
    document.cookie = `${name}=${value}; expires=${new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString()}; path=/; samesite=${samesite}; secure`;
};
