import type {ComponentType} from 'react';
import React, {lazy, Suspense} from 'react';
import type {RouteDetails} from '../Router';

export const lazyRoute = <T extends ComponentType<any>>(
    path: string,
    lazyComponent: () => Promise<{default: T;}>
): RouteDetails => {
    const LazyComponent = lazy<ComponentType>(lazyComponent);

    return {
        path,
        // eslint-disable-next-line react/display-name
        Component: (props) => (
            <Suspense fallback={(
                <div style={{
                    height: 48
                }}
                />
            )}
            >
                <LazyComponent {...props}/>
            </Suspense>
        )

    };
};

export const asyncRoute = <T extends ComponentType<any>>(
    path: string,
    asyncComponent: Promise<{default: T;}>
): RouteDetails => lazyRoute(path, () => asyncComponent);

export const syncRoute = (
    path: string,
    Component: ComponentType<any>
): RouteDetails => ({
    path,
    Component
});
