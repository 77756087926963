import type {PropsWithChildren, ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';

export type TreeGroupProps = PropsWithChildren<{}>;

export type TreeGroupComponent = ReactElement<TreeGroupProps>;

export const TreeGroup: VoidFunctionComponent<TreeGroupProps> = ({children}): TreeGroupComponent => (
    <ul role="group" className="treeview__group">
        {children}
    </ul>
);
