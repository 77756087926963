import type {InspectionTemplateDocument} from '../../../../../database';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/inspection-templates/:inspectionTemplateId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    inspectionTemplateId: string;
};

export type QueryType = never;

export type OutputType = InspectionTemplateDocument;
