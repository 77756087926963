import {dexie} from '../../../../../dexie';
import {fetchApi} from '../../../../helpers/frontend';
import {HttpStatusCode} from '../../../../HttpStatusCode';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export const getInspectionPlanning = async (urlParams: ParamType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        urlParams
    });

    const {response, json} = result;
    if (response.ok && response.status !== HttpStatusCode.NOT_MODIFIED && json) {
        await dexie.inspectionPlannings.put(json);
    } else if (response.status === HttpStatusCode.NOT_FOUND) {
        await dexie.inspectionPlannings.delete(urlParams.inspectionPlanningId);
    }

    return result;
};
