import type {RequestEvent, ResponseEvent} from '../../frontend/utilities/networkEvents';
import {eventTarget} from '../../frontend/utilities/networkEvents';
import {getCookie} from '../../frontend/utilities/storage';
import type {JsonOf} from '../../interfaces/helpers';
import {hasProperty} from '../../types';
import type {HttpMethod} from '../HttpMethod';
import {HttpStatusCode} from '../HttpStatusCode';

type ErrorLike = {
    message: string;
    name: string;
};

export const getQueryString = (query: Record<string, string | string[] | number | number[] | boolean | boolean[] | undefined | null> | undefined): string => {
    if (!query) {
        return '';
    }

    const sanitized = JSON.parse(JSON.stringify(query)) as Record<string, string | string[] | number | number[] | boolean | boolean[]>;

    if (!Object.keys(sanitized).length) {
        return '';
    }

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(sanitized)) {
        if (Array.isArray(value)) {
            value.length && value.forEach((v) => params.append(key, v));
        } else {
            params.set(key, value.toString());
        }
    }

    params.sort();
    return `?${params}`;
};

export const fetchApi = async <BodyType, OutputType, ParamType, QueryType>({
    method,
    path,
    body,
    queryParams,
    urlParams,
    headers = {}
}: {
    body?: JsonOf<BodyType>;
    headers?: Record<string, string>;
    method: HttpMethod;
    path: string;
    queryParams?: Partial<Record<keyof QueryType, string | string[] | number | number[] | boolean | boolean[] | undefined | null>> | undefined;
    urlParams?: Record<keyof ParamType, string>;
}): Promise<{
    json: JsonOf<OutputType> | null;
    response: Response;
}> => {
    const url = path.replace(/:[a-z]+/gi, (match: string) => {
        const replacement = urlParams?.[match.substring(1)];

        if (!replacement) {
            throw new Error(`Missing value for identifier "${match}" in path "${path}`);
        }

        return replacement;
    });

    eventTarget.dispatchEvent(new CustomEvent<RequestEvent>('request', {
        detail: {
            method,
            url
        }
    }));

    const response = await fetch(`${url}${getQueryString(queryParams)}`, {
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
            ...headers,
            'Checkbuster-Environment': getCookie('env') ?? 'web',
            'Content-Type': 'application/json'
        },
        method
    });

    const isJsonResponse = response.headers.get('Content-Type')?.includes('application/json');

    if (isJsonResponse) {
        eventTarget.dispatchEvent(new CustomEvent<ResponseEvent>('response', {
            detail: {
                json: response.ok && response.status !== HttpStatusCode.NO_CONTENT ? await response.clone().json() : null,
                method,
                ok: response.ok,
                status: response.status,
                url
            }
        }));
    }

    const json: JsonOf<OutputType> | ErrorLike | null = isJsonResponse
        ? await response.clone().json() as JsonOf<OutputType> | ErrorLike
        : null;

    return {
        json: hasProperty(json, 'name') && hasProperty(json, 'message') ? null : json,
        response
    };
};
