import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {ChecklistDocument} from './ChecklistDocument';

export const checklistTypeDef: TypeDef<ChecklistDocument> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        activated: {
            type: 'boolean'
        },
        category: {
            nullable: true,
            type: 'string'
        },
        classicTemplateId: {
            type: 'string'
        },
        codeID: {
            type: 'string'
        },
        collection: {
            type: 'string'
        },
        comment: {
            type: 'string'
        },
        company: {
            type: 'string'
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    nullable: true,
                    type: 'string'
                }
            }
        },
        created: {
            type: 'float64'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        creator: {
            type: 'string'
        },
        data: {} as never,
        device: {
            type: 'string'
        },
        downloads: {
            type: 'float64'
        },
        exclusive: {
            type: 'boolean'
        },
        expiresAt: {
            type: 'timestamp'
        },
        fromExclusive: {
            type: 'boolean'
        },
        incident: {
            type: 'boolean'
        },
        libraryTemplateId: {
            type: 'string'
        },
        local: {
            type: 'boolean'
        },
        name: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        originalId: {
            type: 'string'
        },
        predefinedComments: {
            elements: {
                additionalProperties: false,
                properties: {
                    value: {
                        type: 'string'
                    }
                }
            },
            nullable: true
        },
        private: {
            type: 'boolean'
        },
        rating: {
            type: 'float64'
        },
        removed: {
            type: 'float64'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        reportTemplateIds: {
            elements: {
                type: 'string'
            },
            nullable: true
        },
        sections: {
            elements: {} as never
        },
        source_id: {
            type: 'string'
        },
        source_offline_id: {
            type: 'string'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        targetId: {
            type: 'string'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        },
        userId: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        }
    }
};
