import type {FunctionComponent} from 'react';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import type {SimplifiedMembershipDocument} from '../../database';
import type {MetricKey} from '../../dexie';
import {calculateUsageScore} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import {getAppVersion} from '../../frontend/native-hooks/appVersion';
import type {AppEnvironment} from '../../helpers/AppEnvironment';
import {currentLocale} from '../../helpers/locales';
import {metricWeights} from '../../helpers/variables';
import type {OperatingSystemName} from '../../util/userAgent';
import {useCurrentUser} from '../context';
import {useEntity, useUserCount, useMembership, useMetrics, useCookie} from '../hooks';

type UserAttributes = {
    app_version: string | undefined;
    app_type: AppEnvironment | string | undefined;
    app_os: 'android' | 'ios' | 'windows' | 'mac' | 'chromeos' | 'linux' | string | undefined;
    web_version: string;
    web_timestamp: Date;
    web_language: string;

    email: string;
    user_id: string;
    user_type: 'owner' | 'team member';
    user_created_at: Date | undefined;
    user_verified_at: Date | undefined;
    user_created_os: OperatingSystemName | undefined;
    user_created_type: AppEnvironment | undefined;
    first_name: 'unknown' | string;
    usage_score: number;
    review_attempts: number | undefined;
    review_timestamp: Date | undefined;

    used_os_windows: true | undefined;
    used_os_android: true | undefined;
    used_os_ios: true | undefined;
    used_os_chromeos: true | undefined;
    used_os_mac: true | undefined;
    used_os_linux: true | undefined;
    used_type_app: true | undefined;
    used_type_web: true | undefined;
    used_type_pwa: true | undefined;
    company: CompanyAttributes;
};

type CompanyAttributes = {
    name: 'unknown' | string;
    company_id: string;
    company_size: number;
    subscription_id: string;
    subscription_type: 'free' | 'trial' | 'premium' | 'enterprise';
    subscription_start: Date;
    subscription_end: Date | undefined;
    subscription_limit: number | undefined;
};

type EventAttributes = {
    count: number;
};

declare const window: {
    UE?: {
        pageHit?: (attributes: UserAttributes) => void;
    };
    userengage?: (event: `event.${MetricKey}`, attributes: EventAttributes) => Partial<Promise<unknown>> | void;
};

export const UserDotCom: FunctionComponent = () => {
    const {metrics, review} = useMetrics();
    const {userCount} = useUserCount();
    const {membership} = useMembership();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {entity} = useEntity(authenticatedUser?.context?.entityId);
    const {pathname} = useLocation();
    const app_type = useCookie('env');
    const app_os = useCookie('os');

    const android = app_os === 'android' || undefined;
    const ios = app_os === 'ios' || undefined;
    const windows = app_os === 'windows' || undefined;
    const mac = app_os === 'mac' || undefined;
    const chromeos = app_os === 'chromeos' || undefined;
    const linux = app_os === 'linux' || undefined;
    const web = app_type === 'web' || undefined;
    const pwa = app_type === 'pwa' || undefined;
    const app = app_type === 'app' || undefined;

    const [app_version, setAppVersion] = useState<string>();

    useEffect(() => {
        (async () => {
            const version = await getAppVersion();
            if (version) {
                setAppVersion(version);
            }
        })();
    }, [app_version]);

    useEffect(() => {
        if (!authenticatedUser || !entity || !membership || !metrics || !userCount) {
            return;
        }

        const {userId: ignore, ...metricsWithoutUserId} = metrics;
        const metricsSnakeCased = Object.fromEntries(
            Object.entries(metricsWithoutUserId)
                .map(([key, value]) => [key.replace(/([A-Z])/g, '_$1').toLowerCase(), value])
        );

        window.UE?.pageHit?.({
            ...metricsSnakeCased,

            app_version,
            app_os,
            app_type,
            web_version: __WEBPACK_RELEASE__,
            web_timestamp: new Date(__WEBPACK_RELEASE_TIMESTAMP__),
            web_language: currentLocale?.iso || 'unknown',

            email: authenticatedUser.email,
            user_id: authenticatedUser._id,
            user_type: entity.owner === authenticatedUser._id ? 'owner' : 'team member',
            user_created_at: authenticatedUser.created ? new Date(authenticatedUser.created) : undefined,
            user_created_os: authenticatedUser.createdOn ? authenticatedUser.createdOn.split(',')[0] as OperatingSystemName : undefined,
            user_created_type: authenticatedUser.createdOn ? authenticatedUser.createdOn.split(',')[1] as AppEnvironment : undefined,
            user_verified_at: authenticatedUser.verifiedAt ? new Date(authenticatedUser.verifiedAt) : undefined,
            first_name: authenticatedUser.name || 'unknown',
            usage_score: calculateUsageScore(metricsWithoutUserId),
            review_attempts: review?.attemptedCount,
            review_timestamp: review?.lastAttemptedAt ? new Date(review.lastAttemptedAt) : undefined,
            used_os_android: android,
            used_os_ios: ios,
            used_os_windows: windows,
            used_os_linux: linux,
            used_os_mac: mac,
            used_os_chromeos: chromeos,
            used_type_app: app,
            used_type_pwa: pwa,
            used_type_web: web,
            company: {
                name: entity.name || 'unknown',
                company_id: entity._id,
                company_size: userCount,
                subscription_id: membership.stripeCustomerId || 'unknown',
                subscription_type: membership.productName as SimplifiedMembershipDocument['productName'],
                subscription_start: new Date(membership.productName === 'trial' ? membership.trialStart : membership.startsAt),
                subscription_end: membership.productName === 'trial' ? new Date(membership.trialEnd) : membership.endsAt !== undefined ? new Date(membership.endsAt) : undefined,
                subscription_limit: membership.bundleLimit
            }
        });
    }, [
        !!metrics,
        review?.attemptedCount,
        review?.lastAttemptedAt,
        pathname,
        userCount,
        entity?._id,
        entity?.name,
        membership?.stripeCustomerId,
        membership?.productName,
        membership?.renewedAt,
        membership?.renewsAt,
        membership?.endsAt,
        membership?.bundleLimit,
        authenticatedUser?._id,
        authenticatedUser?.email,
        authenticatedUser?.createdOn,
        entity?.owner,
        authenticatedUser?.name
    ]);

    useEffect(() => addEventListener('update', (event) => {
        const {table: tableName, mods} = event.detail;

        if (tableName !== 'metrics' || !window.userengage || document.hidden) {
            return;
        }

        for (const [key, value] of Object.entries(mods)) {
            if (key === 'userId' || typeof value !== 'number' || !metricWeights[key]) {
                return;
            }

            window.userengage(`event.${key as MetricKey}`, {count: value})
                ?.catch?.(() => undefined);
        }
    }), []);

    return null;
};
