export const formatUtcDate = (timestamp: number | Date, separator: string = '/'): string => {
    const date = new Date(timestamp);
    const yyyy = date.getUTCFullYear().toString().padStart(4, '0');
    const mm = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const dd = date.getUTCDate().toString().padStart(2, '0');

    return `${yyyy}${separator}${mm}${separator}${dd}`;
};

export const formatLocalDate = (timestamp: number | Date, separator: string = '/'): string => {
    const date = new Date(timestamp);
    const yyyy = date.getFullYear().toString().padStart(4, '0');
    const mm = (date.getMonth() + 1).toString().padStart(2, '0');
    const dd = date.getDate().toString().padStart(2, '0');

    return `${yyyy}${separator}${mm}${separator}${dd}`;
};

export const formatUtcTime = (timestamp: number | Date, separator: string = ':'): string => {
    const date = new Date(timestamp);
    const hh = date.getUTCHours().toString().padStart(2, '0');
    const mm = date.getUTCMinutes().toString().padStart(2, '0');
    const ss = date.getUTCSeconds().toString().padStart(2, '0');

    return `${hh}${separator}${mm}${separator}${ss}`;
};

export const formatLocalTime = (timestamp: number | Date, separator: string = ':'): string => {
    const date = new Date(timestamp);
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    const ss = date.getSeconds().toString().padStart(2, '0');

    return `${hh}${separator}${mm}${separator}${ss}`;
};

export const formatUtcDateTime = (timestamp: number | Date, dateSeparator?: string, timeSeparator?: string): string => `${formatUtcDate(timestamp, dateSeparator)} ${formatUtcTime(timestamp, timeSeparator)}`;

export const formatLocalDateTime = (timestamp: number | Date, dateSeparator?: string, timeSeparator?: string): string => `${formatLocalDate(timestamp, dateSeparator)} ${formatLocalTime(timestamp, timeSeparator)}`;
