import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const getInspectionDetails = (queryParams: QueryType) => fetchApi<BodyType, OutputType, ParamType, QueryType>({
    method,
    path,
    queryParams
});
