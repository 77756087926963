import {init, reactRouterV5Instrumentation, ErrorBoundary, Profiler, setUser, setTag} from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import React, {PureComponent} from 'react';
import {getTrackedUser, trackMetric} from '../../../../dexie';
import {__} from '../../../../translations';
import {hasProperty} from '../../../../types';
import './Sentry.scss';

export type StartSentryArguments = {
    routing: Parameters<typeof reactRouterV5Instrumentation>;
};

export const startSentry = ({routing}: StartSentryArguments): void => {
    init({
        enabled: process.env.NODE_ENV === 'production' && !navigator.webdriver,
        dsn: 'https://369417f38ed84aff8eeb07b540fcb710@o403235.ingest.sentry.io/5426372',
        tracesSampleRate: 0.01,
        release: __WEBPACK_RELEASE__,
        environment: __WEBPACK_ENVIRONMENT__,
        ignoreErrors: [/^401:/],
        denyUrls: [
            /user\.com/,
            /googletagmanager\.com/
        ],
        beforeSend(event, hint) {
            const exception = hint?.originalException as Error | undefined;

            if (hasProperty(exception, 'isFetchError')) {
                return null;
            }

            const userId = getTrackedUser();
            userId && trackMetric(userId, 'errors');

            if (event.exception) {

                /*
                 *showReportDialog({
                 *  eventId: event.event_id,
                 *  title: __('It looks like we’re having issues.'),
                 *  subtitle: __('Our team has been notified.'),
                 *  subtitle2: __('If you’d like to help, tell us what happened below.'),
                 *  labelName: __('Name'),
                 *  labelEmail: __('Email'),
                 *  labelComments: __('What happened?'),
                 *  labelClose: __('Close'),
                 *  labelSubmit: __('Submit'),
                 *  errorGeneric: __('An unknown error occurred while submitting your report. Please try again.'),
                 *  errorFormEntry: __('Some fields were invalid. Please correct the errors and try again.'),
                 *  successMessage: __('Your feedback has been sent. Thank you!')
                 *});
                 */
            }

            return event;
        },
        integrations: process.env.NODE_ENV === 'production' && !navigator.webdriver
            ? [
                new Integrations.BrowserTracing({
                    routingInstrumentation: reactRouterV5Instrumentation(...routing)
                })
            ]
            : []
    });

    setTag('service_worker', false);
};

export const setSentryUser = setUser;

export const Fallback = ({error, componentStack, resetError}) => {
    const userId = getTrackedUser();
    (window as any)._error !== error && userId && trackMetric(userId, 'crashes');
    (window as any)._error = error;

    return (
        <div className="errorscreen">
            <h1 className="errorscreen__header">
                {__('Something went wrong!')}
            </h1>

            <div className="errorscreen__content">
                <p>{error.toString()}</p>
                <p>{location.pathname}</p>
                <details>
                    <summary className="errorscreen__summary">{__('Entire error message')}</summary>
                    <div className="errorscreen__detail">
                        {componentStack?.replace(/\)\sin/g, ')\nin')}
                    </div>
                </details>
            </div>

            <button type="button" className="button errorscreen__try-again" onClick={() => resetError()}>
                <div className="button__content">
                    {__('Try Again')}
                </div>
            </button>

            <button type="button" className="button errorscreen__reload-page" onClick={() => location.reload()}>
                <div className="button__content">
                    {__('Reload Page')}
                </div>
            </button>

            <button type="button" className="button errorscreen__reload-app" onClick={() => location.href = '/'}>
                <div className="button__content">
                    {__('Reload App')}
                </div>
            </button>

            <div className="errorscreen__contacttext">
                {__('If none of those options work, please')}
            </div>

            <a className="errorscreen__link" href="https://checkbuster.com/contact/" rel="noreferrer" target="_blank">
                {__('Contact us')}
            </a>
        </div>
    );
};
export class Sentry extends PureComponent {
    render() {
        return (
            <Profiler>
                <ErrorBoundary {...this.props} showDialog={false} fallback={Fallback}/>
            </Profiler>
        );
    }
}
