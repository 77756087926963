import {useEffect, useState} from 'react';

export const useReadyState = ({dexieActive, fetchActive}: {
    dexieActive: boolean;
    fetchActive: boolean;
}): {
    ready: boolean;
} => {
    const [ready, setReady] = useState(!dexieActive && !fetchActive);

    useEffect(() => {
        if (!dexieActive && !fetchActive) {
            setReady(true);
        }
    }, [dexieActive, fetchActive]);

    return {ready};
};
