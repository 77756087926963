import {createContext} from 'react';
import type {UserWithContextDocument} from '../../../database';
import type {JsonOf} from '../../../interfaces/helpers';

export type CurrentUserContext = {
    isCheckbuster: boolean;
    currentUser: JsonOf<UserWithContextDocument> | null;
    ready: boolean;
};

export const currentUserContext = createContext<CurrentUserContext>({
    isCheckbuster: false,
    currentUser: null,
    ready: false
});
