import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {userTypeDef} from './userTypeDef';
import type {UserWithContextDocument} from './UserWithContextDocument';

const {...properties} = userTypeDef.properties;
const {context, token, ...optionalProperties} = userTypeDef.optionalProperties;

export const userWithContextTypeDef: TypeDef<UserWithContextDocument> = {
    additionalProperties: false,
    optionalProperties,
    properties: {
        ...properties,
        context
    }
};
