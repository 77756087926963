import type {ObjectId} from 'mongodb';

let idCounter = 0;

export type MongoDbObjectId = string;

export type OfflineObjectId = string;

/**
 * Checks whether an id is a valid MongoDB Object ID.
 *
 * Note that this does _not_ mean this ID is present in our database(s),
 * it just means the syntax is valid and it _could_ be in our database(s)!
 */
export const isMongoDbObjectId = (id: ObjectId | string | null | undefined): id is MongoDbObjectId => {
    if (!id || !(/^[0-9a-f]{24}$/i).test(id.toString())) {
        return false;
    }

    // Note: The following check will break at Sun Feb 07 2106 07:28:15 GMT+0100 (Central European Standard Time)
    const timestampHex = id.toString().substring(0, 8);
    const timestampDec = parseInt(timestampHex, 16);
    const creationYear = new Date(timestampDec * 1000).getUTCFullYear();
    const currentYear = new Date().getUTCFullYear();

    return creationYear >= 2000 && creationYear <= currentYear;
};

/**
 * Checks whether an id is an offline id; not created by MongoDB.
 */
export const isOfflineObjectId = (id: string | undefined): id is OfflineObjectId => !!id && (id.startsWith('offline-') || id.endsWith('_new'));

/**
 * Creates a new offline id that is guaranteed to be unique for this user.
 * Collision can occur when two people generate an offline id at the same millisecond.
 */
export const createOfflineObjectId = (): OfflineObjectId => {
    idCounter++;
    return `offline-${idCounter}${Date.now()}`;
};
