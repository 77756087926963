/* eslint-disable jsx-a11y/control-has-associated-label */

import type {ChangeEventHandler, FocusEventHandler, KeyboardEventHandler} from 'react';
import React, {forwardRef} from 'react';
import {ripple} from '../../utilities/animations';
import './Textarea.scss';

type InputProps = {
    value?: string;
    name: string;
    required?: boolean;
    placeholder: string;
    className?: string;
    disabled?: boolean;
    onFocus?: FocusEventHandler<HTMLTextAreaElement>;
    onChange: ChangeEventHandler<HTMLTextAreaElement>;
    onKeyUp?: KeyboardEventHandler<HTMLTextAreaElement>;
    onBlur?: FocusEventHandler<HTMLTextAreaElement>;
    autoFocus?: boolean;
};
export const Textarea = forwardRef<HTMLTextAreaElement, InputProps>(({value, name, required, disabled, placeholder, onChange, onBlur, onKeyUp, onFocus, className, autoFocus = false}, externalRef) => {
    const classNames = [
        className
    ].join(' ').trim();
    return (
        <div className={`${classNames}, grow-wrap`} data-replicated-value={value}>
            <textarea
                ref={externalRef}
                autoFocus={autoFocus}
                rows={1}
                disabled={disabled}
                value={value}
                name={name}
                required={required}
                placeholder={placeholder}
                onClick={ripple}
                id={`textarea-${name}`}
                className={classNames}
                data-testid={`textarea-${name}`}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            /></div>
    );
});
