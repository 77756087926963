import type {FunctionComponent} from 'react';
import React from 'react';
import {useMenu} from '../../../context/MenuContext';
import './Content.scss';

export const Content: FunctionComponent = (props) => {
    const [{isSidebarOpen}, updateMenu] = useMenu();

    const onClick = isSidebarOpen
        ? () => {
            if (document.body.clientWidth >= 1280) {
                return;
            }
            updateMenu({
                isSidebarOpen: false
            });
        }
        : undefined;

    return (
        <div {...props} className={`content content--enabled-${isSidebarOpen ? 'no' : 'yes'}`} onClick={onClick} role="main"/>
    );
};
