import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {removeUser} from '../../api/endpoints/users/[userId]/delete/frontend';
import {getUser} from '../../api/endpoints/users/[userId]/get/frontend';
import {getUsers} from '../../api/endpoints/users/get/frontend';
import type {UserWithContextDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId} from '../../types/MongoDb';
import {escapeRegex} from '../../util/strings';
import {useCurrentSession} from '../context';
import {sortStably, useDexieResources} from './useDexieResources';
import {useMetrics} from './useMetrics';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useUsers = (filter: {
  search?: string;
  entityIds?: string[];
  inspectionTemplateIds?: string[];
  userIds?: string[];
  name?: string;
} = {}, condition: {
    fetch?: boolean;
    dexie?: boolean;
} = {
    fetch: true,
    dexie: true
}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    users: JsonOf<UserWithContextDocument>[];
    updateUser: (userId: MongoDbObjectId, patch: JsonPatch) => Promise<boolean>;
    deleteUser: (userId: MongoDbObjectId) => Promise<boolean>;
} => {
    const [refresh, setRefresh] = useState(Date.now());
    const {trackMetric} = useMetrics();
    const {currentSession: session} = useCurrentSession();
    const {user_id: uid, entity_id: entityId} = session ?? {};
    const searchValue = filter.search && escapeRegex(filter.search);
    const {entityIds, inspectionTemplateIds, userIds} = filter;

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const templateIds = inspectionTemplateIds;

    const {resources: users, dexieActive} = useDexieResources<UserWithContextDocument>({
        filter({_id, email, templates, locations, name}: JsonOf<UserWithContextDocument>) {
            if (entityIds && (!locations || !entityIds.some((entityId) => locations.includes(entityId)))) {
                return false;
            }

            if (templateIds && (!templates || !templateIds.some((templateId) => templates.includes(templateId)))) {
                return false;
            }

            if (userIds && !userIds.includes(_id.toString())) {
                return false;
            }

            const tester = searchValue && new RegExp(searchValue, 'i');
            if (tester && !(!!name && tester.test(name) || !!email && tester.test(email))) {
                return false;
            }

            return true;
        },
        sort: sortStably,
        table: dexie.users,
        query: async () => !!entityId && condition.dexie !== false && dexie.users
            .where('context.entityId').equals(entityId)
            .toArray()
    }, [condition.dexie, searchValue, entityId, entityIds, refresh, templateIds, userIds]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!uid) {
            return;
        }

        if (!condition.fetch) {
            return setFetchActive(false);
        }

        const fetchPromise = userIds
            ? Promise.all(userIds.map((userId) => getUser({userId})))
            : getUsers({locationIdAny: entityIds, templateIdAny: templateIds});

        fetchPromise
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));

        setFetchActive(true);
    }, [condition.fetch, uid, visibilityState, entityIds, templateIds, userIds]);

    return {
        ready,
        dexieActive,
        fetchActive,
        users,
        updateUser: async (userId, patch: JsonPatch) => {
            if (!uid || !entityId || !userId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'users',
                    resourceId: userId,
                    userId: uid,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        deleteUser: async (userId) => {
            if (!uid || !entityId || !userId) {
                return false;
            }

            try {
                const {response} = await removeUser({userId});
                if (!response.ok) {
                    return false;
                }
            } catch (error) {
                console.warn(error);
                return false;
            }

            try {
                await dexie.users.delete(userId);

                setRefresh(Date.now());
                trackMetric('usersDeleted');

                return true;
            } catch (error) {
                captureException(error);
                console.warn(error);
                return false;
            }
        }
    };
};
