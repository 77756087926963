import type {JsonOf} from '../interfaces/helpers';
import type {
    AtpItemDocument,
    DropdownItemDocument,
    LocationItemDocument,
    NumberItemDocument,
    RangeItemDocument,
    SignatureItemDocument,
    SmileyItemDocument,
    StarItemDocument,
    TextItemDocument,
    YesNoItemDocument
} from '../types';
import {getIncrementalId} from './idCounter';

type DeepPartial<T> = T extends unknown[] ? T : T extends Record<string, unknown> ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;

export const createAtpItem = (fill: DeepPartial<JsonOf<AtpItemDocument>> = {}): JsonOf<AtpItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: '',
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isAtp: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        unit: 'RLU',
        values: [0, 1500, 1501, 3000, 3001, 5000, 5001],
        weight: [1, 1, 1, 1],
        ...fill.spec
    },
    type: 'atp'
});

export const createDropdownItem = (fill: DeepPartial<JsonOf<DropdownItemDocument>> = {}): JsonOf<DropdownItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        options: [],
        photos: [],
        state: 'empty',
        taskIds: [],
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isDropdown: true,
        label: '',
        multi: false,
        options: [],
        predefinedComments: false,
        predefinedCommentsArray: [],
        ...fill.spec
    },
    type: 'dropdown'
});

export const createLocationItem = (fill: DeepPartial<JsonOf<LocationItemDocument>> = {}): JsonOf<LocationItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        ...fill.input,
        value: null
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isLocation: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: 1,
        ...fill.spec
    },
    type: 'location'
});

export const createNumberItem = (fill: DeepPartial<JsonOf<NumberItemDocument>> = {}): JsonOf<NumberItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: '',
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isNumber: true,
        label: '',
        norm: 0,
        operator: '=',
        predefinedComments: false,
        predefinedCommentsArray: [],
        unit: '',
        weight: 1,
        ...fill.spec
    },
    type: 'number'
});

export const createRangeItem = (fill: DeepPartial<JsonOf<RangeItemDocument>> = {}): JsonOf<RangeItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: '',
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isRange: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        unit: '',
        valueEnd: 10,
        valueMax: 6,
        valueMin: 4,
        valueStart: 0,
        weight: 1,
        ...fill.spec
    },
    type: 'range'
});

export const createSignatureItem = (fill: DeepPartial<JsonOf<SignatureItemDocument>> = {}): JsonOf<SignatureItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        image: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isSignature: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: 1,
        ...fill.spec
    },
    type: 'signature'
});

export const createSmileyItem = (fill: DeepPartial<JsonOf<SmileyItemDocument>> = {}): JsonOf<SmileyItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: 0,
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isSmiley: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: [1, 1, 1, 1, 1],
        ...fill.spec
    },
    type: 'smiley'
});

export const createStarItem = (fill: DeepPartial<JsonOf<StarItemDocument>> = {}): JsonOf<StarItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: 0,
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isStar: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: [1, 1, 1, 1, 1],
        ...fill.spec
    },
    type: 'star'
});

export const createTextItem = (fill: DeepPartial<JsonOf<TextItemDocument>> = {}): JsonOf<TextItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        value: '',
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isText: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: 1,
        ...fill.spec
    },
    type: 'text'
});

export const createYesNoItem = (fill: DeepPartial<JsonOf<YesNoItemDocument>> = {}): JsonOf<YesNoItemDocument> => ({
    id: getIncrementalId(),
    isFavourite: false,
    locked: false,
    ...fill,
    input: {
        comment: '',
        photos: [],
        state: 'empty',
        taskIds: [],
        ...fill.input
    },
    spec: {
        autoTask: false,
        conditions: [],
        dynamic: !!fill.addedAt,
        extraInfoPhotos: [],
        extraInfoText: '',
        extraInfoToggle: false,
        isYesNo: true,
        label: '',
        predefinedComments: false,
        predefinedCommentsArray: [],
        weight: 1,
        ...fill.spec
    },
    type: 'yesNo'
});
