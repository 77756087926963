import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {RadioButton} from '../../../toggles/RadioButton';
import type {RadioButtonProps} from '../../../toggles/RadioButton';

export type RadioButtonVariantProps = Required<Pick<RadioButtonProps, 'checked' | 'onChange' | 'disabled'>> & {
    locationId: string;
    locationName: string;
};

export type RadioButtonVariantComponent = ReactElement<RadioButtonVariantProps>;

export const RadioButtonVariant: VoidFunctionComponent<RadioButtonVariantProps> = ({
    locationId,
    locationName,
    ...rest
}): RadioButtonVariantComponent => (
    <RadioButton {...rest} value={locationId}>
        {locationName}
    </RadioButton>
);
