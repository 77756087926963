import type {FunctionComponent, ReactNode} from 'react';
import React from 'react';

export const ListItem = (({children}) => (
    <li className="list__item">
        {children}
    </li>
)) satisfies FunctionComponent<{
    children: ReactNode;
}>;

