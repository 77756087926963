import type {InspectionDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/inspections/:inspectionId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    inspectionId: string;
};

export type QueryType = never;

export type OutputType = InspectionDocument;
