import type {InspectionTemplateDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/inspection-templates/:inspectionTemplateId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    inspectionTemplateId: string;
};

export type QueryType = never;

export type OutputType = InspectionTemplateDocument;
