import type {InspectionPlanDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/inspection-plannings/:inspectionPlanningId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    inspectionPlanningId: string;
};

export type QueryType = never;

export type OutputType = InspectionPlanDocument;
