import {delay} from '../utilities/timing';

declare const window: {
    webkit?: {
        messageHandlers?: {

            /**
             * @deprecated
             */
            showReview?: {
                postMessage: (message: '') => (void | number | Promise<number>);
            };
            requestReview?: {
                postMessage: (message: '') => (number | Promise<number>);
            };
        };
    };
    android?: {
        requestReview?: () => Promise<void | number>;
    };
};

const requestReviewIos = async (): Promise<number> => {
    const requestReview = window.webkit?.messageHandlers?.requestReview
        ?? window.webkit?.messageHandlers?.showReview;

    if (!requestReview?.postMessage) {
        return -1;
    }

    return await requestReview.postMessage('') ?? 0;
};

const requestReviewAndroid = async (): Promise<number> => {
    if (!window?.android?.requestReview) {
        return -1;
    }

    return await window.android.requestReview() ?? 0;
};

let hasRequestedThisSession = false;
const fiveMinuteTimer = delay(5 * 60 * 1000);

export const canRequestReview = (isCheckbuster = false) => !hasRequestedThisSession
    && !!(isCheckbuster || window.webkit?.messageHandlers?.showReview?.postMessage || window?.android?.requestReview);

export const requestReview = async (isCheckbuster = false): Promise<number> => {
    if (hasRequestedThisSession) {
        throw new Error('Cannot request review: already requested!');
    }

    hasRequestedThisSession = true;

    return fiveMinuteTimer.then(() => {
        if (isCheckbuster) {
            alert('attempting to show review popup');
        }

        if (window.webkit?.messageHandlers?.requestReview?.postMessage
            || window.webkit?.messageHandlers?.showReview?.postMessage) {
            return requestReviewIos();
        } else if (window?.android?.requestReview) {
            return requestReviewAndroid();
        }

        isCheckbuster && alert('could not show review popup due to missing hooks');
        return -1;
    });
};
