import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/users/:userId';

export const method = HttpMethod.DELETE;

export type BodyType = never;

export type ParamType = {
    userId: string;
};

export type QueryType = never;

export type OutputType = never;
