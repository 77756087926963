import type {JsonSchemaObject} from '../../../interfaces/models/json-schema-draft-04';

export const locationItemSchema: JsonSchemaObject = {
    additionalProperties: false,
    bsonType: 'object',
    properties: {
        addedAt: {
            bsonType: 'number'
        },
        addedBy: {
            bsonType: 'string'
        },
        id: {
            bsonType: 'number'
        },
        input: {
            additionalProperties: false,
            bsonType: 'object',
            properties: {
                comment: {
                    bsonType: 'string'
                },
                photos: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                state: {
                    bsonType: 'string',
                    enum: ['ok', 'empty', 'bad', 'none']
                },
                taskIds: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                value: {
                    additionalProperties: false,
                    bsonType: ['object', 'null'],
                    properties: {
                        coordinates: {
                            bsonType: 'array',
                            items: {
                                bsonType: ['string', 'number']
                            }
                        },
                        type: {
                            bsonType: 'string',
                            enum: ['Point']
                        }
                    }
                }
            },
            required: [
                'comment',
                'photos',
                'state',
                'taskIds',
                'value'
            ]
        },
        isFavourite: {
            bsonType: 'bool'
        },
        locked: {
            bsonType: 'bool'
        },
        parentConditionId: {
            bsonType: 'number'
        },
        parentId: {
            bsonType: 'number'
        },
        spec: {
            additionalProperties: false,
            bsonType: 'object',
            properties: {
                autoTask: {
                    bsonType: 'bool'
                },
                conditions: {
                    bsonType: 'array',
                    items: {
                        additionalProperties: false,
                        bsonType: 'object',
                        properties: {
                            equals: {
                                bsonType: 'string',
                                enum: ['===', '!==']
                            },
                            id: {
                                bsonType: 'number'
                            },
                            itemState: {
                                bsonType: 'string',
                                enum: ['ok', 'bad', 'empty', 'none']
                            }
                        },
                        required: [
                            'equals',
                            'id',
                            'itemState'
                        ]
                    }
                },
                dynamic: {
                    bsonType: 'bool'
                },
                extraInfoPhotos: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                extraInfoText: {
                    bsonType: 'string'
                },
                extraInfoToggle: {
                    bsonType: 'bool'
                },
                isLocation: {
                    bsonType: 'bool'
                },
                label: {
                    bsonType: 'string'
                },
                predefinedComments: {
                    bsonType: 'bool'
                },
                predefinedCommentsArray: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                weight: {
                    bsonType: 'number'
                }
            },
            required: [
                'autoTask',
                'conditions',
                'dynamic',
                'extraInfoPhotos',
                'extraInfoText',
                'extraInfoToggle',
                'isLocation',
                'label',
                'predefinedComments',
                'predefinedCommentsArray',
                'weight'
            ]
        },
        type: {
            bsonType: 'string',
            enum: ['location']
        },
        updated: {
            bsonType: 'number'
        },
        updatedAt: {
            bsonType: 'number'
        },
        updatedBy: {
            bsonType: 'objectId'
        }
    },
    required: [
        'id',
        'input',
        'isFavourite',
        'locked',
        'spec',
        'type'
    ]
} as const;
