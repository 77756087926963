import type {ChangeEvent, FunctionComponent} from 'react';
import React, {useEffect} from 'react';
import type {CheckboxProps, CheckboxValue} from '../Checkbox';
import {Checkbox} from '../Checkbox';
import './Switch.scss';

export type SwitchValue = 'on' | 'off' | 'indeterminate';

type Awaitable<T> = T | Promise<T>;

const convertToCheckbox: Record<SwitchValue, CheckboxValue> = {
    on: 'checked',
    off: 'unchecked',
    indeterminate: 'indeterminate'
};

const convertToSwitch: Record<CheckboxValue, SwitchValue> = {
    checked: 'on',
    unchecked: 'off',
    indeterminate: 'indeterminate'
};

export type SwitchProps = Omit<CheckboxProps, 'value' | 'onChange'> & {
    value: 'on' | 'off' | 'indeterminate';
    onChange: (value: SwitchValue, event: ChangeEvent<HTMLInputElement>) => Awaitable<void>;
};

/**
 * @deprecated
 */
export const Switch: FunctionComponent<SwitchProps> = ({value, onChange, labelPosition = 'left', ...props}) => {
    if (process.env.NODE_ENV === 'development') {
        useEffect(() => console.warn('Switch has been deprecated; please use /src/react/components/toggles/Switch instead!'), []);
    }

    return (
        <Checkbox
            {...props}
            labelPosition={labelPosition}
            value={convertToCheckbox[value]}
            onChange={(value, event) => onChange(convertToSwitch[value], event)}
            role="switch"
        />
    );
};
