import {saveFile} from '../../frontend/native-hooks/saveFile';
import {dexie} from '../dexie';

const getQueueEntriesForDownload = async (userId: string): Promise<string> => {
    const data = await dexie.queue
        .where('userId').equals(userId)
        .toArray();
    const json = data.map((entry) => JSON.stringify(entry));
    return `[${json.join(',')}]`;
};

export const copyQueueEntries = async (userId: string): Promise<void> => {
    const json = await getQueueEntriesForDownload(userId);
    await navigator.clipboard.writeText(json);
};

export const downloadQueueEntries = async (userId: string): Promise<void> => {
    const json = await getQueueEntriesForDownload(userId);
    const encoder = new TextEncoder();
    const {buffer} = encoder.encode(json);
    await saveFile(`checkbuster-offline-backup-${Date.now()}.json`, buffer);
};
