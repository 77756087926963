import type {PhotoDocument} from '../../../../../database';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/photos/:photoId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    photoId: string;
};

export type QueryType = never;

export type OutputType = Omit<PhotoDocument, 's3_url' | 's3_bucket' | 's3_key' | 's3_region'>;

