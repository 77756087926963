import type {InspectionDocument} from '../../../../database';
import {inspectionTypeDef} from '../../../../database/collections/inspections/inspectionTypeDef';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/inspections';

export const method = HttpMethod.POST;

export type BodyType = Omit<InspectionDocument, '_id'>;

export const bodyTypeDef: TypeDef<BodyType> = (() => {
    const {_id, ...properties} = inspectionTypeDef.properties;
    return {
        ...inspectionTypeDef,
        properties
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = InspectionDocument;
