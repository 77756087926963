import {captureException} from '@sentry/react';
import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useMemo} from 'react';
import type {OutputType as InspectionDetails} from '../../api/endpoints/inspection-details/get/frontend';
import {getInspectionDetails} from '../../api/endpoints/inspection-details/get/frontend';
import {getQueryString} from '../../api/helpers/frontend';
import {dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {emptyArray} from '../../util/arrays';
import {useCurrentSession} from '../context';
import {useQueueCount} from './useQueueCount';
import {useSyncState} from './useSyncState';
import {useVisibilityState} from './useVisibilityState';

export const useInspectionDetails = ({
    name,
    conductedAfter,
    conductedBefore,
    userIds = emptyArray,
    entityIds = emptyArray,
    inspectionTemplateIds = emptyArray
}: {
    name?: string;
    conductedAfter?: number;
    conductedBefore?: number;
    userIds?: string[];
    entityIds?: string[];
    inspectionTemplateIds?: string[];
} = {}): JsonOf<InspectionDetails> | null => {
    const visibilityState = useVisibilityState();
    const queueCount = useQueueCount();
    const [syncState] = useSyncState();
    const refresh = useMemo(() => {
        if (queueCount === 0 && !syncState) {
            return Date.now();
        }

        return visibilityState;
    }, [visibilityState, queueCount, syncState]);
    const {currentSession} = useCurrentSession();
    const {user_id: userId} = currentSession ?? {};

    useEffect(() => {
        if (!userId) {
            return;
        }

        getInspectionDetails({
            name,
            conductedAfter,
            conductedBefore,
            locationIdAny: entityIds?.length ? entityIds : undefined,
            templateIdAny: inspectionTemplateIds?.length ? inspectionTemplateIds : undefined,
            assignedToAny: userIds.length ? userIds : undefined
        }).then(({json, response}) => {
            if (!response.ok || !json) {
                return;
            }

            const {searchParams} = new URL(response.url);
            const query = searchParams.toString();

            return dexie.contentDetails.put({
                userId,
                query,
                details: json,
                resourceTableName: 'inspections',
                timestamp: Date.now()
            });
        }).catch(captureException);
    }, [userId, refresh, name, conductedAfter, conductedBefore, entityIds, inspectionTemplateIds, userIds]);

    return useLiveQuery<JsonOf<InspectionDetails> | undefined>(async () => {
        if (!userId) {
            return;
        }

        const query = getQueryString({
            name,
            conductedAfter,
            conductedBefore,
            locationIdAny: entityIds?.length ? entityIds : undefined,
            templateIdAny: inspectionTemplateIds?.length ? inspectionTemplateIds : undefined,
            assignedToAny: userIds.length ? userIds : undefined
        }).replace('?', '');

        const record = await dexie.contentDetails.get([userId, 'inspections', query]);

        return record?.details as JsonOf<InspectionDetails> | undefined;
    }, [userId, name, conductedAfter, conductedBefore, entityIds, inspectionTemplateIds, userIds]) ?? null;
};
