import type {TaskDocument} from '../../../../database';
import type {JsonSchema} from '../../../../database/collections/JsonSchema04';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/tasks';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results assigned to all specified user ids.
     */
    assignedToAll?: string[];

    /**
     * Pick results assigned to any of the specified user ids.
     */
    assignedToAny?: string[];

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results created by any of the specified user ids.
     */
    createdByAny?: string[];

    /**
     * Pick results that (partially) match the specified description;
     * case-insensitive.
     */
    description?: string;

    /**
     * Pick results expiring after the specified timestamp.
     */
    expiresAfter?: number;

    /**
     * Pick results expiring before the specified timestamp.
     */
    expiresBefore?: number;

    /**
     * Pick results that have at least one comment.
     */
    hasComments?: boolean;

    /**
     * Pick results that have a due date set.
     */
    hasDueDate?: boolean;

    /**
     * Pick results that have an expiry date set.
     */
    hasExpirationDate?: boolean;

    /**
     * Pick results that have been updated at least once.
     */
    hasUpdatedDate?: boolean;

    /**
     * Pick results connected to any of the specified inspection ids.
     */
    inspectionIdAny?: string[];

    /**
     * Pick results connected to any of the specified inspection template ids.
     */
    inspectionTemplateIdAny?: string[];

    /**
     * Pick results that have been assigned to at least one user.
     */
    isAssigned?: boolean;

    /**
     * Pick results have been closed.
     */
    isClosed?: boolean;

    /**
     * Pick results that are overdue.
     */
    isOverdue?: boolean;

    /**
     * Pick results have been removed.
     */
    isRemoved?: boolean;

    /**
     * Pick results are reviewable.
     */
    isReviewable?: boolean;

    /**
     * Pick results connected to any of the specified location ids.
     */
    locationIdAny?: string[];

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Pick results that are or will be overdue after the specified timestamp.
     */
    overdueAfter?: number;

    /**
     * Pick results that are or will be overdue before the specified timestamp.
     */
    overdueBefore?: number;

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'touchedAt' | 'createdAt' | 'updatedAt'}`;

    /**
     * Pick results connected to any of the specified inspection template ids.
     */
    templateIdAny?: string[];

    /**
     * Pick results updated after the specified timestamp.
     */
    updatedAfter?: number;

    /**
     * Pick results updated before the specified timestamp.
     */
    updatedBefore?: number;
};

export const queryTypeDef: TypeDef<QueryType> = {
    optionalProperties: {
        assignedToAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to all specified user ids.'
            }
        },
        assignedToAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to any of the specified user ids.'
            }
        },
        createdAfter: {
            metadata: {
                description: 'Pick results created after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        createdBefore: {
            metadata: {
                description: 'Pick results created before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        createdByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results created by any of the specified user ids.'
            }
        },
        description: {
            metadata: {
                description: 'Pick results that (partially) match the specified description; case-insensitive.'
            },
            type: 'string'
        },
        expiresAfter: {
            metadata: {
                description: 'Pick results expiring after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        expiresBefore: {
            metadata: {
                description: 'Pick results expiring before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        hasComments: {
            metadata: {
                description: 'Pick results that have at least one comment.'
            },
            type: 'boolean'
        },
        hasDueDate: {
            metadata: {
                description: 'Pick results that have a due date set.'
            },
            type: 'boolean'
        },
        hasExpirationDate: {
            metadata: {
                description: 'Pick results that have an expiry date set.'
            },
            type: 'boolean'
        },
        hasUpdatedDate: {
            metadata: {
                description: 'Pick results that have been updated at least once.'
            },
            type: 'boolean'
        },
        inspectionIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to any of the inspection ids.'
            }
        },
        inspectionTemplateIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to any of the inspection template ids.'
            }
        },
        isAssigned: {
            metadata: {
                description: 'Pick results that have been assigned to at least one user.'
            },
            type: 'boolean'
        },
        isClosed: {
            metadata: {
                description: 'Pick results that have been closed.'
            },
            type: 'boolean'
        },
        isOverdue: {
            metadata: {
                description: 'Pick results that are overdue.'
            },
            type: 'boolean'
        },
        isRemoved: {
            metadata: {
                description: 'Pick results have been removed.'
            },
            type: 'boolean'
        },
        isReviewable: {
            metadata: {
                description: 'Pick results that are reviewable.'
            },
            type: 'boolean'
        },
        locationIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified location ids.'
            }
        },
        name: {
            metadata: {
                description: 'Pick results that (partially) match the specified name; case-insensitive.'
            },
            type: 'string'
        },
        overdueAfter: {
            metadata: {
                description: 'Pick results that are or will be overdue after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        overdueBefore: {
            metadata: {
                description: 'Pick results that are or will be overdue before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        sort: {
            enum: ['+createdAt', '-createdAt', '+updatedAt', '-updatedAt', '+touchedAt', '-touchedAt'],
            metadata: {
                default: '-touchedAt',
                description: 'Sorts results in either ascending (+) or descending order (-) based on the specified property name.'
            }
        },
        templateIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified inspection template ids.'
            }
        },
        updatedAfter: {
            metadata: {
                description: 'Pick results updated after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        updatedBefore: {
            metadata: {
                description: 'Pick results updated before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        }
    }
};

export const querySchema: JsonSchema = {
    additionalProperties: false,
    bsonType: 'object',
    properties: {
        assignedToAll: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        assignedToAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        createdAfter: {
            bsonType: 'number'
        },
        createdBefore: {
            bsonType: 'number'
        },
        createdByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        description: {
            bsonType: 'string'
        },
        expiresAfter: {
            bsonType: 'number'
        },
        expiresBefore: {
            bsonType: 'number'
        },
        hasComments: {
            bsonType: 'bool'
        },
        hasDueDate: {
            bsonType: 'bool'
        },
        hasExpirationDate: {
            bsonType: 'bool'
        },
        hasUpdatedDate: {
            bsonType: 'bool'
        },
        inspectionIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        inspectionTemplateIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        isAssigned: {
            bsonType: 'bool'
        },
        isClosed: {
            bsonType: 'bool'
        },
        isOverdue: {
            bsonType: 'bool'
        },
        isRemoved: {
            bsonType: 'bool'
        },
        isReviewable: {
            bsonType: 'bool'
        },
        locationIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        name: {
            bsonType: 'string'
        },
        overdueAfter: {
            bsonType: 'number'
        },
        overdueBefore: {
            bsonType: 'number'
        },
        sort: {
            bsonType: 'string',
            enum: ['+createdAt', '-createdAt', '+updatedAt', '-updatedAt', '+touchedAt', '-touchedAt']
        },
        templateIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        updatedAfter: {
            bsonType: 'number'
        },
        updatedBefore: {
            bsonType: 'number'
        }
    }
};

export type OutputType = TaskDocument[];

