import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useHashChange = (listener: EventListenerOrEventListenerObject, dependencies: unknown[]) => {
    const location = useLocation();

    useEffect(() => {
        dispatchEvent(new HashChangeEvent('hashchange'));
    }, [location.hash]);

    useEffect(() => {
        addEventListener('hashchange', listener);
        return () => removeEventListener('hashchange', listener);
    }, dependencies);
};
