import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getInspectionPlannings} from '../../api/endpoints/inspection-plannings/get/frontend';
import type {InspectionPlanDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {createOfflineObjectId} from '../../types/MongoDb';
import {emptyArray} from '../../util/arrays';
import {escapeRegex} from '../../util/strings';
import {useCurrentSession, useCurrentUser} from '../context';
import {sortAlphabetically, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useInspectionPlannings = (filter: {
    inspectionTemplateIds?: string[];
    userIds?: string[];
    entityIds?: string[];
    name?: string;
} = {}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspectionPlannings: JsonOf<InspectionPlanDocument>[];
    updateInspectionPlanning: (inspectionPlanningId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
    createInspectionPlanning: (params: Omit<JsonOf<InspectionPlanDocument>, '_id' | 'context' | 'active' | 'created' | 'creator' | 'notifications'>) => Promise<Omit<JsonOf<InspectionPlanDocument>, '_id'> | null>;
} => {
    const {currentSession} = useCurrentSession();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {user_id: uid, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {
        name,
        userIds = emptyArray,
        entityIds = authenticatedUser?.locations ?? emptyArray,
        inspectionTemplateIds = authenticatedUser?.templates ?? emptyArray
    } = filter;

    const searchName = filter.name && escapeRegex(filter.name);

    const {resources: inspectionPlannings, dexieActive} = useDexieResources<InspectionPlanDocument>({
        filter({_deleted, template, users, location, name}: JsonOf<InspectionPlanDocument>) {
            if (_deleted) {
                return false;
            }

            if (!entityIds.includes(location)) {
                return false;
            }

            if (!inspectionTemplateIds.includes(template)) {
                return false;
            }

            if (userIds.length) {
                if (!users?.length) {
                    return false;
                }

                if (!userIds.some((userId) => users.includes(userId))) {
                    return false;
                }
            }

            if (searchName) {
                return !!name && new RegExp(searchName, 'i').test(name);
            }

            return true;
        },
        sort: sortAlphabetically,
        table: dexie.inspectionPlannings,
        async query() {
            if (!entityId) {
                return false;
            }

            return dexie.inspectionPlannings
                .where('context.entityId').equals(entityId)
                .toArray();
        }
    }, [entityId, entityIds, userIds, inspectionTemplateIds, searchName]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!uid) {
            return;
        }

        setFetchActive(true);
        getInspectionPlannings({
            name,
            isRemoved: false,
            locationIdAny: entityIds?.length ? filter.entityIds : undefined,
            templateIdAny: inspectionTemplateIds?.length ? inspectionTemplateIds : undefined,
            assignedToAny: userIds.length ? userIds : undefined
        })
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [uid, visibilityState, name, entityIds, inspectionTemplateIds, userIds]);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspectionPlannings,
        updateInspectionPlanning: async (inspectionPlanningId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!uid || !entityId || !inspectionPlanningId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspectionPlannings',
                    resourceId: inspectionPlanningId,
                    userId: uid,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        createInspectionPlanning: async (params: Omit<JsonOf<InspectionPlanDocument>, '_id' | 'context' | 'active' | 'created' | 'creator' | 'notifications'>) => {
            if (!uid || !entityId) {
                return null;
            }
            const inspectionPlanning: Omit<JsonOf<InspectionPlanDocument>, '_id'> = {
                ...params,
                notifications: {
                    close: {
                        number: '',
                        onEvent: false,
                        period: ''
                    },
                    due: {
                        number: '',
                        onEvent: false,
                        period: ''
                    }
                },
                active: true,
                created: Date.now(),
                creator: uid,
                context: {
                    entityId
                },
                offline_id: createOfflineObjectId()
            };

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'inspectionPlannings',
                    resourceId: inspectionPlanning.offline_id!,
                    userId: uid,
                    entityId,
                    body: inspectionPlanning
                });

                return inspectionPlanning;
            } catch (error) {
                captureException(error);
                return null;
            }
        }
    };
};
