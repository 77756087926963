import type {FunctionComponent} from 'react';
import React, {useEffect, useRef} from 'react';
import Close from '../../../../frontend/icons/Close.svg';
import {__} from '../../../../translations';
import {classNames} from '../../../helpers/styling';
import {Icoontje} from '../../Icoontje';
import type {KnopjePropsCommon} from '../../Knopje';
import {Knopje} from '../../Knopje';
import {Tekst} from '../../Tekst';
import './Popup.scss';

declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        inert?: '' | false | null | undefined;
    }

    interface DialogHTMLAttributes<T> extends HTMLAttributes<T> {
        onCancel?: (event: Event & {
            target: HTMLDialogElement & {
                returnValue?: string;
            };
        }) => unknown;
        onClose?: (event: Event & {
            target: HTMLDialogElement & {
                returnValue?: string;
            };
        }) => unknown;
    }
}

export type PopupCancelAction = 'cancel' | 'escape';

export type PopupConfirmAction = 'confirm';

export type PopupCloseAction = PopupCancelAction | PopupConfirmAction;

export type PopupProps = {
    cancelButtonText?: string;
    name?: string;
    compact?: boolean;
    confirmButtonColor?: KnopjePropsCommon['color'];
    confirmButtonText?: string;
    onCancel?: (action: PopupCancelAction, value: null) => unknown;
    onClose?: <T extends PopupCloseAction>(action: T, value: T extends PopupConfirmAction ? FormData : null) => unknown;
    onConfirm?: (action: PopupConfirmAction, value: FormData) => unknown;
    showCancelButton?: boolean;
    showCloseButton?: boolean;
    showConfirmButton?: boolean;
    simple?: boolean;
    titleText?: string;
};

export const Popup: FunctionComponent<PopupProps> = ({
    compact = true,
    name = '',
    children,
    showCancelButton = true,
    showCloseButton = true,
    showConfirmButton = true,
    simple = compact,
    confirmButtonColor = 'green',
    confirmButtonText = __('Ok'),
    cancelButtonText = __('Cancel'),
    onCancel,
    onConfirm,
    onClose,
    titleText = ''
}) => {
    const formRef = useRef<HTMLFormElement>(null);
    const dialogRef = useRef<HTMLDialogElement>(null);

    useEffect(() => {
        const dialog = dialogRef.current as null | HTMLDialogElement & {
            open?: boolean;
            showModal?: () => void;
        };
        if (!dialog || dialog.open || !dialog.showModal) {
            return;
        }

        dialog.showModal();
    }, [dialogRef.current]);

    const handleClose = (event: {target: {returnValue?: string;};}) => {
        const action = (event.target.returnValue || 'escape') as 'confirm' | 'cancel' | 'escape';

        if (action === 'confirm') {
            const value = new FormData(formRef?.current ?? undefined);
            onConfirm?.(action, value);
            onClose?.(action, value);
        } else {
            onCancel?.(action, null);
            onClose?.(action, null);
        }
    };

    return (
        <dialog
            className={classNames('popup', {compact, simple, [name]: name})}
            ref={dialogRef}
            onCancel={!showCloseButton && !showCancelButton
                ? (event) => {
                    event.preventDefault();
                }
                : undefined}
            onClose={(event) => {
                event.preventDefault();
                handleClose(event);
            }}
            onSubmit={(event) => {
                event.preventDefault();

                const returnValue = (event.nativeEvent as any).submitter?.value || 'confirm';
                handleClose({target: {returnValue}});
            }}
        >
            <form
                method="dialog"
                className={classNames('popup__form', {
                    compact,
                    simple,
                    [name]: name
                })}
                ref={formRef}
            >
                <button type="button" className="sr-only" tabIndex={-1} aria-hidden inert=""/>

                {titleText || showCloseButton
                    ? (
                        <header className={classNames('popup__header', {
                            compact,
                            [name]: name
                        })}>
                            <Tekst size="huge" weight="bold">
                                {titleText}
                            </Tekst>
                            <Knopje
                                disabled={showCloseButton ? undefined : __('This dialog cannot be skipped.')} type="button" shape="square" size="large" tooltip={__('Close')} color={showCloseButton ? undefined : 'white'} tabIndex={-1} onClick={() => {
                                    showCloseButton && handleClose({target: {returnValue: 'close'}});
                                }}>
                                <Icoontje size="huge" Svg={Close}/>
                            </Knopje>
                        </header>
                    )
                    : null}

                <div className={classNames('popup__content', {
                    [name]: name
                })}>
                    {children}
                </div>

                {showConfirmButton || showCancelButton
                    ? (
                        <footer className={classNames('popup__footer', {
                            [name]: name
                        })}>
                            {showConfirmButton
                                ? (
                                    <Knopje
                                        type="submit"
                                        value="confirm"
                                        color={confirmButtonColor}
                                        paint="fill"
                                        size="huge"
                                    >
                                        <Tekst color="white" size="standard">
                                            {confirmButtonText}
                                        </Tekst>
                                    </Knopje>
                                )
                                : null}
                            {showCancelButton
                                ? (
                                    <Knopje type="button" size="huge" onClick={() => handleClose({target: {returnValue: 'cancel'}})}>
                                        <Tekst size="standard">
                                            {cancelButtonText}
                                        </Tekst>
                                    </Knopje>
                                )
                                : null}
                        </footer>
                    )
                    : null}
            </form>
        </dialog>
    );
};
