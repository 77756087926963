import type {ChartDocument} from '../../../../../database/collections/charts';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/charts/:chartId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    chartId: string;
};

export type QueryType = never;

export type OutputType = ChartDocument;
