/* eslint-disable react-hooks/exhaustive-deps */
import type {DependencyList} from 'react';
import {useEffect, useState} from 'react';

export const useAsyncMemo = <T>(factory: () => Promise<T | undefined>, dependencies: DependencyList, initial?: T): T | [] => {
    const [value, setValue] = useState<T | undefined>(initial);

    useEffect(() => {
        let unmounted = false;

        void factory().then((newValue) => {
            if (!unmounted) {
                setValue(newValue);
            }
        });

        return () => {
            unmounted = true;
        };
    }, [JSON.stringify(dependencies)]);

    return value ?? [];
};
