import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getInspectionTemplate} from '../../api/endpoints/inspection-templates/[inspectionTemplateId]/get/frontend';
import type {InspectionTemplateDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonOperation} from '../../types/JsonPatch';
import {createJsonPointer} from '../../types/JsonPointer';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {useCurrentSession} from '../context';
import {useDexieResource} from './useDexieResource';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useInspectionTemplate = (inspectionTemplateId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspectionTemplate: JsonOf<InspectionTemplateDocument> | null;
    updateInspectionTemplate: (patch: (JsonOperation | null)[], resourceId?: MongoDbObjectId | OfflineObjectId | undefined) => Promise<boolean>;
    createInspectionTemplate: (inspectionTemplate: Partial<JsonOf<InspectionTemplateDocument>>) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resource: inspectionTemplate, dexieActive} = useDexieResource<InspectionTemplateDocument>({
        table: dexie.inspectionTemplates,
        primaryKey: inspectionTemplateId
    });

    const {ready} = useReadyState({dexieActive, fetchActive});

    useEffect(() => {
        if (!userId || !inspectionTemplateId) {
            return;
        }

        setFetchActive(true);
        getInspectionTemplate({inspectionTemplateId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, inspectionTemplateId, visibilityState]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspectionTemplate,
        updateInspectionTemplate: async (patch: (JsonOperation | null)[], resourceId: MongoDbObjectId | OfflineObjectId | undefined = inspectionTemplateId) => {
            if (!userId || !entityId || !resourceId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspectionTemplates',
                    resourceId,
                    userId,
                    entityId,
                    patch: patch.filter((p): p is JsonOperation => p !== null)
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        createInspectionTemplate: async (inspectionTemplate: Partial<JsonOf<InspectionTemplateDocument>> = {}) => {
            if (!userId || !entityId) {
                return false;
            }
            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'inspectionTemplates',
                    resourceId: inspectionTemplate.offline_id!,
                    userId,
                    entityId,
                    body: {...inspectionTemplate, activated: true}
                });

                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'users',
                    resourceId: userId,
                    userId,
                    entityId,
                    patch: [{
                        op: 'add',
                        path: createJsonPointer('templates', '-'),
                        value: inspectionTemplate.offline_id
                    }]
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
