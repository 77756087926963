export const UserAgentHeader = {
    CH_UA: 'CH-UA',
    CH_UA_PLATFORM: 'CH-UA-Platform',
    SEC_CH_UA: 'Sec-CH-UA',
    SEC_CH_UA_PLATFORM: 'Sec-CH-UA-Platform',
    USER_AGENT: 'User-Agent'
} as const;

export type BrowserName = 'chrome' | 'edge' | 'firefox' | 'opera' | 'safari';

export type OperatingSystemName = 'android' | 'chromeos' | 'ios' | 'linux' | 'mac' | 'windows';

export const isBrowserName = (browserName: string): browserName is BrowserName => (/^(chrome|edge|firefox|opera|safari)$/).test(browserName);

export const isOperatingSystemName = (operatingSystemName: string): operatingSystemName is OperatingSystemName => (/^(android|ios|windows|mac|chromeos|linux)$/).test(operatingSystemName);

export const getBrowserName = (sources: {
    clientHintUserAgent: string;
    userAgent?: string;
} | {
    clientHintUserAgent?: string;
    userAgent: string;
}): BrowserName | null => {
    const {clientHintUserAgent, userAgent} = sources;

    if (clientHintUserAgent) {
        if ((/google chrome/i).test(clientHintUserAgent)) {
            return 'chrome';
        } else if ((/microsoft edge/i).test(clientHintUserAgent)) {
            return 'edge';
        } else if ((/opera/i).test(clientHintUserAgent)) {
            return 'opera';
        }
    }

    if (!userAgent) {
        return null;
    }

    if ((/(opr|opera)\/\d+/i).test(userAgent)) {
        return 'opera';
    } else if ((/firefox\/\d+/i).test(userAgent)) {
        return 'firefox';
    } else if ((/edge?\/\d+/i).test(userAgent)) {
        return 'edge';
    } else if ((/chrome\/\d+/i).test(userAgent)) {
        return 'chrome';
    } else if ((/safari\/\d+/i).test(userAgent)) {
        return 'safari';
    }

    return null;
};

export const getOperatingSystemName = (sources: {
    clientHintUserAgentPlatform: string;
    userAgent?: string;
} | {
    clientHintUserAgentPlatform?: string;
    userAgent: string;
}): OperatingSystemName | null => {
    const {clientHintUserAgentPlatform, userAgent} = sources;

    if (clientHintUserAgentPlatform) {
        if ((/windows/i).test(clientHintUserAgentPlatform)) {
            return 'windows';
        } else if ((/macos/i).test(clientHintUserAgentPlatform)) {
            return 'mac';
        }
    }
    if (!userAgent) {
        return null;
    }

    if ((/ipod|iphone|ipad|ios/i).test(userAgent)) {
        return 'ios';
    } else if ((/android/i).test(userAgent)) {
        return 'android';
    } else if ((/macintosh/i).test(userAgent)) {
        return 'mac';
    } else if ((/windows/i).test(userAgent)) {
        return 'windows';
    } else if ((/cros/i).test(userAgent)) {
        return 'chromeos';
    } else if ((/linux/i).test(userAgent)) {
        return 'linux';
    }

    return null;
};
