/* eslint-disable react/button-has-type */

import {captureException} from '@sentry/react';
import type {LocationState, Location} from 'history';
import type {AnchorHTMLAttributes, MouseEvent, ReactElement, VoidFunctionComponent} from 'react';
import React, {useMemo, useState} from 'react';
import type {NavLinkProps} from 'react-router-dom';
import {NavLink, useHistory} from 'react-router-dom';
import {ripple} from '../../../frontend/utilities/animations';
import {mergeUrlSearchParams} from '../../../util/strings';
import {useCurrentUser} from '../../context';
import {classNames} from '../../helpers/styling';
import type {IcoontjeComponent} from '../Icoontje';
import {ScreenReader} from '../ScreenReader';
import type {TekstComponent} from '../Tekst';
import './Knopje.scss';

declare const window: {
    dataLayer?: {
        push: (details: {
            [key: string]: string | number | boolean;
            event: `ce-${'btn' | 'lnk'}-${string}`;
        }) => boolean;
    };
};

declare module 'react' {
    interface ButtonHTMLAttributes<T> extends HTMLAttributes<T> {
        popovertarget?: string | undefined;
        popovertargetaction?: 'hide' | 'show' | 'toggle' | undefined;
    }

    interface AnchorHTMLAttributes<T> extends HTMLAttributes<T> {
        popovertarget?: string | undefined;
    }
}

export type KnopjePropsCommon = {

    /**
     * The content of the button.
     *
     * To limit variations and keep internal layout simple, only the following is allowed:
     * * A single Text component;
     * * A single Icon component;
     * * A single Icon component followed by a single Text Component.
     */
    children: TekstComponent | IcoontjeComponent | [IcoontjeComponent, TekstComponent] | [TekstComponent, IcoontjeComponent];

    /**
     * One or more additional classes to be appended to the button's own classes.
     *
     * * Providing a className should only be done for the purpose of positioning the button;
     * * Providing a className should not be done to visually alter the button.
     */
    className?: string;

    /**
     * Changes the color of the button.
     *
     * * Requires the `paint` property to determine how the chosen color is applied to the button.
     */
    color?: 'transparent' | 'gray' | 'white' | 'blue' | 'red' | 'green' | 'orange' | 'yellow';

    /**
     * Changes the four corners of the button.
     */
    corners?: 'sharp' | 'smooth' | 'round';

    /**
     * TODO.
     *
     */
    direction?: 'horizontal' | 'vertical';

    /**
     * Disables any interaction with the button if provided.
     *
     * * The message is shown on desktop as tooltip on hover;
     * * The message is read out loud if the user is using text-to-speech on any device.
     */
    disabled?: string;

    /**
     * Changes the way color is applied to the button.
     *
     * * fill: colors all borders and the background using the specified `color`;
     * * outline: colors all borders using the specified `color` and leaves the background transparent;
     * * underline: colors the bottom border using the specified `color`, leaving the other borders and background transparent.
     */
    paint?: 'none' | 'fill' | 'outline' | 'underline';

    /**
     * opens this popover when clicked and supported
     */
    popovertarget?: string;

    popovertargetaction?: 'hide' | 'show' | 'toggle';

    /**
     * Changes the style to a preset complex style.
     */
    preset?: 'fab';

    /**
     * Changes what corners should bend
     */
    quarter?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'none';

    /**
     * Changes the shape of the button.
     */
    shape?: 'rectangular' | 'square';

    /**
     * Changes the size of the button.
     *
     * * Only has effect on `children` without explicit sizes.
     */
    size?: 'medium' | 'large' | 'huge';

    /**
     * Changes default propagation.
     */
    stopPropagation?: boolean;

    /**
     * Adds a message that will be used if the user hovers over the button.
     *
     * * The disabled and busy messages are prioritized over the tooltip;
     * * The message is shown on desktop as tooltip on hover;
     * * The message is read out loud if the user is using text-to-speech on any device, but only after receiving focus.
     */
    tooltip?: string;

    /**
     * Submit clicks to Google Tag Manager if present.
     */
    trackingId?: Lowercase<string>;
};

export type KnopjePropsButton = KnopjePropsCommon & {

    /**
     * Adds a message that will be used if the user clicks on the button while the
     * button is busy waiting for an `onClick` Promise to fulfill.
     *
     * * The message is shown on desktop as tooltip on hover;
     * * The message is read out loud if the user is using text-to-speech on any device.
     */
    busyMessage?: string;

    /**
     * This property is not supported for non-anchors.
     */
    isActive?: never;

    /**
     * Perform an action when the button is clicked on, or when the ENTER key is used.
     *
     * * Any errors thrown from the function are sent to Sentry and then swallowed;
     * * Functions that return a Promise will cause the button to enter a busy state,
     * showing visual cues and preventing further clicks until the Promise has fulfilled.
     *
     * When using an asynchronous function, `event.currentTarget` may become stale;
     * using the `ref` argument will prevent this from happening.
     */
    onClick?: (event: MouseEvent<HTMLButtonElement>, ref: HTMLButtonElement) => unknown;

    rel?: never;

    tabIndex?: number;

    target?: never;

    /**
     * This property is not supported for non-anchors.
     */
    to?: never;

    /**
     * Change the default action of the button by changing its type.
     *
     * * button - does not do anything on click by default;
     * * submit - if part of a form, submits the form;
     * * reset - if part of a form, resets the form.
     */
    type?: 'button' | 'submit' | 'reset';

    value?: string;
};

export type KnopjePropsAnchor = KnopjePropsCommon & {

    /**
     * This property is not supported for anchors.
     */
    busyMessage?: never;

    /**
     * Change the default function used to check whether a link is currently active.
     */
    isActive?: (location: Location<LocationState>) => boolean;

    /**
     * Perform an action when the link is clicked on, or when the ENTER key is used.
     *
     * * The action must be fully synchronous.
     */
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;

    rel?: AnchorHTMLAttributes<HTMLAnchorElement>['rel'];

    /**
     * If `true`, do not add the url to the history when the link is clicked on.
     */
    replace?: boolean;

    target?: AnchorHTMLAttributes<HTMLAnchorElement>['target'];

    /**
     * Configures where the anchor navigates to.
     *
     * @link https://v5.reactrouter.com/web/api/Link/to-string
     * @link https://v5.reactrouter.com/web/api/Link/to-object
     * @link https://v5.reactrouter.com/web/api/Link/to-func
     */
    to: NavLinkProps['to'];

    /**
     * Change the default action of the button by changing its type.
     *
     * * anchor - triggers a navigation.
     */
    type?: 'anchor';
};

export type KnopjeProps = KnopjePropsButton | KnopjePropsAnchor;

export type KnopjeComponent = ReactElement<KnopjeProps>;

export const Knopje: VoidFunctionComponent<KnopjeProps> = ({
    rel = 'noopener noreferrer',
    popovertarget,
    popovertargetaction,
    target = '_blank',
    preset,
    className,
    children,
    color = 'transparent',
    corners = preset === 'fab' ? 'round' : 'smooth',
    quarter = '',
    size = preset === 'fab' ? 'huge' : 'medium',
    shape = preset === 'fab' ? 'square' : 'rectangular',
    paint = preset === 'fab' ? 'fill' : 'none',
    type = 'button',
    disabled = '',
    busyMessage = '',
    onClick,
    to,
    tooltip,
    direction = 'horizontal',
    trackingId,
    isActive,
    stopPropagation = true,
    ...rest
}): KnopjeComponent => {
    const history = useHistory();
    const {isCheckbuster} = useCurrentUser();
    const [busy, setBusy] = useState(false);
    const [showBusyMessage, setShowBusyMessage] = useState(false);
    const message = disabled || (showBusyMessage ? busyMessage : '');

    const alteredTo = useMemo<KnopjeProps['to']>(() => {
        if (typeof to !== 'string' || !to.startsWith('#')) {
            return to;
        }

        return mergeUrlSearchParams(to, history.location.hash);
    }, [to, history?.location.hash]);

    const buttonClassName = classNames('knopje', {
        busy,
        [color]: color,
        [corners]: corners,
        [direction]: direction,
        disabled,
        left: direction !== 'vertical' && Array.isArray(children) && children.length >= 2,
        [preset ?? '']: preset,
        [paint]: paint !== 'none',
        [quarter]: quarter,
        [shape]: shape,
        [size]: size
    }, className);

    const handleButtonClick = async (event: MouseEvent<HTMLButtonElement>) => {
        stopPropagation && event.stopPropagation();

        if (type === 'button' && !popovertarget) {
            event.preventDefault();
        }

        if (trackingId && window.dataLayer && !isCheckbuster) {
            window.dataLayer.push({
                busy,
                disabled,
                event: `ce-btn-${trackingId}`
            });
        }

        if (disabled || showBusyMessage) {
            alert(message);
            return;
        }

        if (busy) {
            setShowBusyMessage(true);
            return;
        }

        if (onClick || type !== 'button') {
            ripple(event);
        }

        if (!onClick) {
            return;
        }

        setBusy(true);

        try {
            await (onClick as (NonNullable<KnopjePropsButton['onClick']>))(event, event.currentTarget);
        } catch (error) {
            console.error(error);
            captureException(error);
        }

        setBusy(false);
        setShowBusyMessage(false);
    };

    const handleAnchorClick = async (event: MouseEvent<HTMLAnchorElement>) => {
        stopPropagation && event.stopPropagation();

        if (trackingId && window.dataLayer && !isCheckbuster) {
            window.dataLayer.push({
                busy,
                disabled,
                event: `ce-lnk-${trackingId}`
            });
        }

        if (disabled) {
            event.preventDefault();
            alert(message);
            return;
        }

        ripple(event);
        (onClick as KnopjePropsAnchor['onClick'])?.(event);
    };

    return type !== 'anchor'
        ? (
            <button
                {...rest}
                popovertarget={popovertarget}
                popovertargetaction={popovertargetaction}
                id={trackingId && `ce-btn-${trackingId}`}
                type={type}
                className={buttonClassName}
                onClick={handleButtonClick}
                aria-label={tooltip}
                aria-busy={busy}
                aria-disabled={!!disabled}
                title={message || tooltip}
            >
                {children}
                <ScreenReader message={message}/>
            </button>
        )
        : typeof alteredTo === 'string' && (/^https?:/).test(alteredTo)
            ? (
                <a
                    {...rest}
                    popovertarget={popovertarget}
                    id={trackingId && `ce-lnk-${trackingId}`}
                    href={alteredTo!}
                    className={buttonClassName}
                    onClick={handleAnchorClick}
                    aria-label={tooltip}
                    aria-disabled={!!disabled}
                    title={message || tooltip}
                    rel={rel}
                    target={target}
                >
                    {children}
                    <ScreenReader message={message}/>
                </a>
            )
            : (
                <NavLink
                    {...rest}
                    id={trackingId && `ce-lnk-${trackingId}`}
                    to={alteredTo!}
                    className={buttonClassName}
                    activeClassName="knopje--visiting"
                    isActive={(match, location) => {
                        if (isActive) {
                            return isActive(location);
                        }

                        if (!match) {
                            return false;
                        }

                        const {pathname, hash} = typeof to === 'string'
                            ? (/^(?<pathname>\/[a-z0-9\-\/]+)?(?<hash>#.+)?/i).exec(to)!.groups!
                            : typeof to === 'function'
                                ? {
                                    hash: undefined, pathname: undefined
                                }
                                : to!;

                        if (pathname && location.pathname !== pathname) {
                            return false;
                        }

                        if (hash) {
                            const wanted = new URLSearchParams(hash.replace('#', ''));
                            const actual = new URLSearchParams(location.hash.replace('#', ''));

                            for (const [key, wantedValue] of wanted.entries()) {
                                const actualValue = actual.get(key);
                                if (actualValue !== wantedValue) {
                                    return false;
                                }
                            }
                        }

                        return true;
                    }}
                    onClick={handleAnchorClick}
                    aria-label={tooltip}
                    aria-disabled={!!disabled}
                    title={message || tooltip}
                >
                    {children}
                    <ScreenReader message={message}/>
                </NavLink>
            );
};
