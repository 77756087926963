import type {ICreateChange, IDatabaseChange, IDeleteChange, IUpdateChange} from 'dexie-observable/api';
import {dexie} from './dexie';

const dispatchChange = (change:IDatabaseChange) => {
    if (change.type === 1) {
        eventTarget.dispatchEvent(new CustomEvent<ICreateChange>('create', {
            detail: change
        }));
    } else if (change.type === 2) {
        eventTarget.dispatchEvent(new CustomEvent<IUpdateChange>('update', {
            detail: change
        }));
    } else if (change.type === 3 && change.oldObj) {
        eventTarget.dispatchEvent(new CustomEvent<IDeleteChange>('delete', {
            detail: change
        }));
    }
};

dexie.on('changes', (changes) => {
    for (const change of changes) {
        if ('requestIdleCallback' in window) {
            requestIdleCallback(() => {
                dispatchChange(change);
            });
        } else {
            dispatchChange(change);
        }
    }
});

export const eventTarget = new EventTarget();

export const addEventListener: {
    (type: 'create', listener: (event: CustomEvent<ICreateChange>) => void, options?: AddEventListenerOptions): () => void;
    (type: 'update', listener: (event: CustomEvent<IUpdateChange>) => void, options?: AddEventListenerOptions): () => void;
    (type: 'delete', listener: (event: CustomEvent<IDeleteChange>) => void, options?: AddEventListenerOptions): () => void;
} = (type, listener, options) => {
    eventTarget.addEventListener(type, listener as EventListener, options);

    return () => removeEventListener(type, listener, options);
};

export const removeEventListener: {
    (type: 'create', listener: (event: CustomEvent<ICreateChange>) => void, options?: AddEventListenerOptions);
    (type: 'update', listener: (event: CustomEvent<IUpdateChange>) => void, options?: AddEventListenerOptions);
    (type: 'delete', listener: (event: CustomEvent<IDeleteChange>) => void, options?: AddEventListenerOptions);
} = (type, listener, options) => {
    eventTarget.removeEventListener(type, listener as EventListener, options);
};
