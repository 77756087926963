import type {MouseEvent, ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import './Backdrop.scss';

export type BackdropProps = {
    onClick: (event: MouseEvent<HTMLDivElement>) => unknown;
};

export type BackdropComponent = ReactElement<BackdropProps>;

export const Backdrop: VoidFunctionComponent<BackdropProps> = ({
    onClick
}): BackdropComponent => (
    <div
        className="backdrop"
        onClick={(event) => {
            event.stopPropagation();
            onClick(event);
        }}
    />
);
