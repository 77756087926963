import './changeListener';

export * from './dexie';
export * from './queue';
export * from './workbox-background-sync/WorkboxBackgroundSyncDexie';
export * from './metrics';

window.addEventListener('unhandledrejection', async (event) => {
    if (event.reason.message.includes('QuotaExceededError') || event.reason.name.includes('QuotaExceededError')) {
        const photoCache = await caches.open('workbox-photos');
        const photoKeys = await photoCache.keys();
        photoKeys.forEach((request) => {
            const resourceId = (/^\/([0-9a-f]{24})-/).exec(new URL(request.url).pathname)?.[1];

            if (resourceId && !window.location.pathname.includes(resourceId)) {
                photoCache.delete(request);
            }
        });
    }
});

