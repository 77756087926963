import type {ExclusiveLibraryTemplateDocument} from '../../../../../../../database';
import {HttpMethod} from '../../../../../../HttpMethod';

export const path = '/apiv2/exclusive-library-items/:exclusiveLibraryId/library-items/:exclusiveLibraryItemId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    exclusiveLibraryId: string;
    exclusiveLibraryItemId: string;
};

export type QueryType = never;

export type OutputType = ExclusiveLibraryTemplateDocument;
