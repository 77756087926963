import type {FunctionComponent, MouseEvent} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {createChartSegment} from '../../../../api/endpoints/charts/[chartId]/chart-segments/post/frontend';
import {updateChart} from '../../../../api/endpoints/charts/[chartId]/patch/frontend';
import {createChart} from '../../../../api/endpoints/charts/post/frontend';
import {createMagicLink} from '../../../../api/endpoints/magic-links/post/frontend';
import {replaceSession} from '../../../../api/endpoints/sessies/[sessionId]/put/frontend';
import {createSession} from '../../../../api/endpoints/sessies/post/frontend';
import type {
    InspectionTemplateDocument,
    ReportLayoutDocument,
    TaskDocument
} from '../../../../database';
import type {JsonOf} from '../../../../interfaces/helpers';
import {createJsonPointer} from '../../../../interfaces/models/json-pointer';
import {Icoontje} from '../../../../react/components/Icoontje';
import {Image} from '../../../../react/components/Image';
import {Knopje} from '../../../../react/components/Knopje';
import {Tekst} from '../../../../react/components/Tekst';
import {useCurrentUser} from '../../../../react/context';
import {
    useEntity,
    useInspections,
    useInspectionTemplates,
    useReportLayouts,
    useTasks
} from '../../../../react/hooks';
import {__} from '../../../../translations';
import {createInspection} from '../../../../types';
import {createOfflineObjectId} from '../../../../types/MongoDb';
import {hasRole, Role} from '../../../../util/permissions';
import {ContainedButton} from '../../../components/buttons/ContainedButton';
import {Form} from '../../../components/Form';
import {Input} from '../../../components/Input';
import {Content} from '../../../components/layout/Content';
import {MessageFooter} from '../../../components/layout/Footer';
import {Page} from '../../../components/layout/Page';
import Logo from '../../../icons/logo_checkbuster-black.png';
import SocialLogoBundle from '../../../icons/social-logo-bundle.svg';
import {
    demoChartData,
    demoImprovementReportData,
    demoInspectionTemplateData,
    demoReportTemplateData,
    demoTaskData
} from '../../../utilities/demoData';
import {setCookie} from '../../../utilities/storage';
import {formatRelativeTime} from '../../../utilities/timing';
import './LoginPage.scss';

export const LoginPage: FunctionComponent<RouteComponentProps<{
    magicKey?: string;
}>> = ({
    history,
    location,
    match
}) => {
    document.title = `Checkbuster | ${__('Login')}`;
    const {createInspection: addInspectionToQueue} = useInspections(0, 0, {tab: 'open'});
    const {createInspectionTemplate} = useInspectionTemplates();
    const {createTask} = useTasks();
    const {createReportLayout} = useReportLayouts();
    const verification = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get('verification');
    }, [location.search]);

    const magicLink = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);

        return {
            email: searchParams.get('email') ?? '',
            password: verification ?? match.params.magicKey ?? ''
        };
    }, [location.search, verification, match.params.magicKey]);

    const [email, setEmail] = useState(magicLink.email);
    const [password, setPassword] = useState(magicLink.password);
    const [message, setMessage] = useState('');
    const [busy, setBusy] = useState(false);
    const [highlight, setHighlight] = useState(false);
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {entity} = useEntity(authenticatedUser?.context.entityId);

    const onSubmit = async () => {
        setBusy(true);
        setHighlight(false);
        setMessage(__('Attempting to login...'));
        const [loginEmail, impersonateEmail] = email.split(/:asr?:/);

        try {
            const {json: session} = await createSession({
                email: loginEmail.includes('@') ? loginEmail : `${loginEmail}@checkbuster.com`,
                password
            });

            if (!session) {
                if (magicLink.password === password) {
                    setPassword('');
                    setMessage(__('The magic link is invalid or has expired. Please request a new password reset email.'));
                } else {
                    setMessage(__('Failed to login. Did you type your password correctly?'));
                }
                return;
            }

            if (impersonateEmail) {
                const {json: impersonateSession} = await replaceSession({
                    sessionId: session._id
                }, {
                    email: impersonateEmail,
                    password
                });

                if (!impersonateSession) {
                    setMessage(__('Failed to impersonate this user.'));
                    return;
                }

                setMessage(__('Impersonated successfully!'));
            }

            setMessage(__('Logged in successfully!'));
        } catch (error) {
            console.warn(error);
            setMessage(__('Failed to login. Are you connected to the internet?'));
        } finally {
            setBusy(false);
        }
    };

    const onClick = async (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setHighlight(false);

        const emailInput = document.getElementById('input-email') as HTMLInputElement | null;
        const emailValid = !!emailInput?.checkValidity();

        if (!emailValid) {
            setHighlight(true);
            setMessage(__('Please enter your email address and click again.'));
            return;
        }

        setMessage(__('We\'re sending you an email...'));

        try {
            const {response, json: magicLink} = await createMagicLink({email});

            if (!response.ok || !magicLink) {
                setMessage(__('We could not send you an email. Did you type your email address correctly?'));
                return;
            }

            if (magicLink.expiresAt) {
                const expireMessage = formatRelativeTime(+new Date(magicLink.expiresAt) - Date.now());
                setMessage(`${__('We\'ve sent you an email!')} ${__('The email will expire:')} ${expireMessage}`);
            } else {
                setMessage(__('We\'ve sent you an email!'));
            }
        } catch (error) {
            console.warn(error);
            setMessage(__('We could not send you an email. Are you connected to the internet?'));
        }
    };

    useEffect(() => setMessage(''), [email]);
    useEffect(() => setHighlight(false), [password]);

    useEffect(() => {
        if (!magicLink.email || !magicLink.password) {
            return;
        }

        onSubmit().catch(console.warn);
    }, [magicLink.email, magicLink.password]);

    useEffect(() => setCookie('authpage', 'email-login'), []);

    useEffect(() => {
        const initial = async () => {
            if (!authenticatedUser || !entity) {
                return;
            }

            if (verification && hasRole(authenticatedUser.roles, Role.GLOBAL_ADMINISTRATOR) && entity.owner === authenticatedUser._id) {
                const creationBase = {
                    context: authenticatedUser.context,
                    creator: authenticatedUser._id
                };

                const demoReportTemplateDataComplete: Partial<JsonOf<ReportLayoutDocument>> = {
                    ...creationBase,
                    ...demoReportTemplateData,
                    offline_id: createOfflineObjectId()
                };

                const demoImprovementReportDataComplete: Partial<JsonOf<ReportLayoutDocument>> = {
                    ...creationBase,
                    ...demoImprovementReportData,
                    offline_id: createOfflineObjectId()
                };

                const demoInspectionTemplateDataComplete: JsonOf<Omit<InspectionTemplateDocument, '_id' | 'offline_id'> & Required<Pick<InspectionTemplateDocument, 'offline_id'>>> = {
                    ...creationBase,
                    ...demoInspectionTemplateData,
                    reportTemplateIds: [demoReportTemplateDataComplete.offline_id!, demoImprovementReportDataComplete.offline_id!],
                    offline_id: createOfflineObjectId()
                };

                const demoInspection = createInspection(demoInspectionTemplateDataComplete, {
                    context: authenticatedUser.context,
                    creator: authenticatedUser._id,
                    inspectorEmail: authenticatedUser.email,
                    inspectorName: authenticatedUser.name ?? __('Me'),
                    location: entity._id,
                    locationName: entity.name ?? __('My Organisation'),
                    name: __('Checkbuster Demo Inspection')
                });

                const demoTaskDataComplete: Partial<JsonOf<TaskDocument>> = {
                    ...creationBase,
                    ...demoTaskData,
                    creatorName: authenticatedUser.name,
                    assignedToUser: [authenticatedUser._id],
                    meta: {
                        ...demoTaskData.meta,
                        inspectionId: demoInspection.offline_id,
                        locationId: demoInspection.location,
                        templateId: demoInspection.templateId
                    },
                    offline_id: createOfflineObjectId()
                };

                const demoReportTemplateInQueue = await createReportLayout(demoReportTemplateDataComplete);
                const demoImprovementReportInQueue = await createReportLayout(demoImprovementReportDataComplete);
                const demoInspectionTemplateInQueue = await createInspectionTemplate(demoInspectionTemplateDataComplete);
                const demoInspectionInQueue = await addInspectionToQueue(demoInspection);
                const demoTaskInQueue = await createTask(demoTaskDataComplete);

                for (const chartAndChartSegment of demoChartData) {
                    try {
                        const {chart, chartSegments} = chartAndChartSegment;
                        const {json: createdChart} = await createChart({
                            ...chart,
                            entity_id: authenticatedUser.context.entityId,
                            createdBy: authenticatedUser._id,
                            touchedBy: authenticatedUser._id
                        });

                        if (!createdChart) {
                            throw new Error('Failed to create initial chart data');
                        }

                        for (const chartSegment of chartSegments) {
                            const {json: createdChartSegment} = await createChartSegment({chartId: createdChart._id}, {
                                ...chartSegment,
                                chart_id: createdChart._id,
                                entity_id: authenticatedUser.context.entityId,
                                createdBy: authenticatedUser._id,
                                touchedBy: authenticatedUser._id
                            });
                            if (!createdChartSegment) {
                                throw new Error('Failed to create initial chart segment data');
                            }

                            await updateChart({chartId: createdChart._id}, [{
                                op: 'replace',
                                path: createJsonPointer('valueDataKey'),
                                value: createdChartSegment._id
                            }]);
                        }
                    } catch (e) {
                        return setMessage(__('Failed to create initial chart data. Please contact us at info@checkbuster.com'));
                    }
                }

                const success = demoReportTemplateInQueue && demoImprovementReportInQueue && demoInspectionTemplateInQueue && demoInspectionInQueue && demoTaskInQueue;
                if (!success) {
                    return setMessage(__('Failed to create initial data. Please contact us at info@checkbuster.com'));
                }
            }

            history.replace(authenticatedUser.needsPassword ? '/team-members/me' : '/inspections');
        };

        entity && authenticatedUser && initial();
    }, [entity?._id, authenticatedUser?.needsPassword]);

    return (
        <Page name="login" disableLogin>
            <Content>
                <Image src={Logo} className="newpage--login__logo" alt={__('Checkbuster logo')}/>
                <h1>
                    {__('Log in')}
                </h1>
                <div className={`form-animation ${busy ? 'form-animation--active' : ''}`}>
                    <Form onSubmit={onSubmit}>
                        <Input
                            value={email}
                            className={`newpage--login__email newpage--login__email--highlight-${highlight}`}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            id="input-email"
                            type="email"
                            name="email"
                            label={__('Email Address')}
                            autoComplete="email"
                            required
                        />
                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            id="input-current-password"
                            type="password"
                            name="password"
                            label={__('Password')}
                            autoComplete="current-password"
                            required
                        />
                        <Knopje
                            onClick={onClick}
                            className="newpage--login__forgot-password"
                            trackingId="forgot-password"
                        >
                            <Tekst decoration="underline">
                                {__('Forgot password?')}
                            </Tekst>
                        </Knopje>

                        <ContainedButton
                            id="button-sign-in" className="newpage--login__login" color="blue"
                            type="submit">
                            {__('Log in')}
                        </ContainedButton>
                    </Form>
                </div>

                <hr/>
                <Tekst>
                    {__('New to Checkbuster?')}
                </Tekst>

                <Knopje
                    type="anchor" to={`/register?email=${encodeURIComponent(email)}`} color="white" corners="round"
                    paint="fill" trackingId="register" className="register">
                    <Tekst color="blue" decoration="underline">
                        {__('Create an account')}
                    </Tekst>
                </Knopje>

                {window.location.hostname.endsWith('.com') || process.env.NODE_ENV !== 'production'
                    ? (
                        <Knopje
                            type="anchor" to="/single-sign-on" color="blue" paint="outline" corners="round"
                            size="huge" className="social" trackingId="login-use-sso">
                            <Icoontje size="standard" Svg={SocialLogoBundle}/>
                            <Tekst size="standard" weight="bold" color="black">
                                {__('Use Alternatives')}
                            </Tekst>
                        </Knopje>
                    )
                    : null}
            </Content>

            <MessageFooter message={message}/>
        </Page>
    );
};
