import type {FunctionComponent} from 'react';
import React, {useMemo, useState} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {createSession} from '../../../../api/endpoints/sessies/post/frontend';
import {createUser} from '../../../../api/endpoints/users/post/frontend';
import {currentLocale} from '../../../../helpers/locales';
import {Icoontje} from '../../../../react/components/Icoontje';
import {Image} from '../../../../react/components/Image';
import {Knopje} from '../../../../react/components/Knopje';
import {Tekst} from '../../../../react/components/Tekst';
import type {CheckboxCheckedState} from '../../../../react/components/toggles/Checkbox';
import {Checkbox} from '../../../../react/components/toggles/Checkbox';
import {__} from '../../../../translations';
import {ContainedButton} from '../../../components/buttons/ContainedButton';
import {Form} from '../../../components/Form';
import {Input} from '../../../components/Input';
import {Content} from '../../../components/layout/Content';
import {MessageFooter} from '../../../components/layout/Footer';
import {Page} from '../../../components/layout/Page';
import Logo from '../../../icons/logo_checkbuster-black.png';
import SocialLogoBundle from '../../../icons/social-logo-bundle.svg';
import {setCookie} from '../../../utilities/storage';
import './RegisterPage.scss';

export const RegisterPage: FunctionComponent<RouteComponentProps> = () => {
    document.title = `Checkbuster | ${__('Register')}`;
    const magicEmail = useMemo(() => new URLSearchParams(location.search).get('email') ?? '', [location.search]);

    const [email, setEmail] = useState(magicEmail);
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [busy, setBusy] = useState(false);
    const [termsChecked, setTermsChecked] = useState<CheckboxCheckedState>('unchecked');

    const onSubmit = async () => {
        setBusy(true);
        setMessage(__('Attempting to register...'));

        try {
            const {response: createUserResponse, json: user} = await createUser({
                email,
                password,
                method: 'register',
                language: currentLocale?.iso.split('-')[0]
            });

            if (!user || !createUserResponse.ok) {
                setMessage(__('This account could not be registered. Have you already registered this account?'));
            } else {
                setCookie('authpage', 'email-login');
                if (user.verified) {
                    setMessage(__('Skipping verification to log in immediately...'));
                    await createSession({email, password});
                } else {
                    setMessage(__('We\'ve sent you an email to confirm your registration!'));
                }
            }
        } catch (error) {
            console.warn(error);
            setMessage(__('This account could not be registered. Are you connected to the internet?'));
        }

        setBusy(false);
    };

    return (
        <Page name="register" disableLogin>
            <Content>
                <Image src={Logo} className="newpage--register__logo" alt={__('Checkbuster logo')}/>
                <h1>
                    {__('Create account')}
                </h1>
                <div className={`form-animation ${busy ? 'form-animation--active' : ''}`}>
                    <Form onSubmit={onSubmit}>
                        <Input
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            id="input-email"
                            type="email"
                            className="newpage--register__input"
                            name="email"
                            label={__('Your work email')}
                            autoComplete="email"
                            required
                        />

                        <Input
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            id="input-new-password"
                            type="password"
                            className="newpage--register__input"
                            name="password"
                            label={__('Password')}
                            autoComplete="new-password"
                            minLength={8}
                            required
                        />

                        <Checkbox
                            required
                            checked={termsChecked}
                            onChange={setTermsChecked}

                        >
                            <a
                                id="link-accept-tos"
                                className="newpage--register__link"
                                href="https://checkbuster.com/terms-of-service/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {__('I agree to the terms of service')}
                            </a>
                        </Checkbox>

                        <ContainedButton
                            id="button-register"
                            className="newpage--register__register"
                            color="blue"
                            type="submit">
                            {__('Create account')}
                        </ContainedButton>
                    </Form>
                </div>
                <hr/>
                <Tekst>
                    {__('Already have an account?')}
                </Tekst>

                <Knopje
                    trackingId="login"
                    type="anchor"
                    to={`/login?email=${encodeURIComponent(email)}`}
                >
                    <Tekst color="blue" decoration="underline">
                        {__('Log in with email')}
                    </Tekst>
                </Knopje>

                {window.location.hostname.endsWith('.com') || process.env.NODE_ENV !== 'production'
                    ? (
                        <Knopje type="anchor" to="/single-sign-on" color="blue" paint="outline" corners="round" size="huge" className="social" trackingId="register-use-sso">
                            <Icoontje size="standard" Svg={SocialLogoBundle}/>
                            <Tekst size="standard" weight="bold" color="black">
                                {__('Use Alternatives')}
                            </Tekst>
                        </Knopje>
                    )
                    : null}
            </Content>

            <MessageFooter message={message}/>
        </Page>
    );
};
