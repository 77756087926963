import React from 'react';
import './Form.scss';

type FormProps = {
    className?: string;
    onSubmit: (data: Record<string, FormDataEntryValue>, reset: () => void) => void | Promise<void>;
    children: React.ReactNode;
};

type FormState = {
    isBusy?: boolean;
};

export class Form extends React.PureComponent<FormProps, FormState> {
    unmounting = false;

    state: FormState = {
        isBusy: false
    };

    onSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        const {isBusy} = this.state;

        if (isBusy) {
            return;
        }

        this.setState({isBusy: true});

        const {currentTarget} = event;
        const data = Object.fromEntries(new FormData(currentTarget).entries());

        try {
            await this.props.onSubmit(data, () => currentTarget.reset());
        } catch (error) {
            this.setState({isBusy: false});
            throw error;
        }

        if (!this.unmounting) {
            this.setState({isBusy: false});
        }
    };

    componentWillUnmount(): void {
        this.unmounting = true;
    }

    render() {
        const {props, onSubmit} = this;
        const {className, children, ...rest} = props;

        const classNames = [
            'form',
            className
        ].join(' ').trim();

        return (
            <form
                className={classNames}
                {...rest}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                onSubmit={onSubmit}
            >
                {children}
            </form>
        );
    }
}
