import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import type {CheckboxProps} from '../../../toggles/Checkbox';
import {Checkbox} from '../../../toggles/Checkbox';

export type CheckboxVariantProps = Required<Pick<CheckboxProps, 'checked' | 'onChange' | 'onContextMenu' | 'disabled'>> & {
    locationId: string;
    locationName: string;
};

export type CheckboxVariantComponent = ReactElement<CheckboxVariantProps>;

export const CheckboxVariant: VoidFunctionComponent<CheckboxVariantProps> = ({
    locationId,
    locationName,
    ...rest
}): CheckboxVariantComponent => (
    <Checkbox {...rest} value={locationId}>
        {locationName}
    </Checkbox>
);
