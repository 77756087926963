import type {ReportLayoutDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/report-layouts';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results created by any of the specified user ids.
     */
    createdByAny?: string[];

    /**
     * Pick results expiring after the specified timestamp.
     */
    expiresAfter?: number;

    /**
     * Pick results expiring before the specified timestamp.
     */
    expiresBefore?: number;

    /**
     * Pick results that have an expiry date set.
     */
    hasExpirationDate?: boolean;

    /**
     * Pick results that have been updated at least once.
     */
    hasUpdatedDate?: boolean;

    /**
     * Pick results have been locked.
     */
    isLocked?: boolean;

    /**
     * Pick results have been removed.
     */
    isRemoved?: boolean;

    /**
     * Pick results locked after the specified timestamp.
     */
    lockedAfter?: number;

    /**
     * Pick results locked before the specified timestamp.
     */
    lockedBefore?: number;

    /**
     * Pick results locked by any of the specified user ids.
     */
    lockedByAny?: string[];

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Pick results removed after the specified timestamp.
     */
    removedAfter?: number;

    /**
     * Pick results removed before the specified timestamp.
     */
    removedBefore?: number;

    /**
     * Pick results removed by any of the specified user ids.
     */
    removedByAny?: string[];

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'touchedAt' | 'createdAt' | 'updatedAt'}`;

    /**
     * Pick results touched after the specified timestamp.
     */
    touchedAfter?: number;

    /**
     * Pick results touched before the specified timestamp.
     */
    touchedBefore?: number;

    /**
     * Pick results touched by any of the specified user ids.
     */
    touchedByAny?: string[];

    /**
     * Pick results updated after the specified timestamp.
     */
    updatedAfter?: number;

    /**
     * Pick results updated before the specified timestamp.
     */
    updatedBefore?: number;

    /**
     * Pick results updated by any of the specified user ids.
     */
    updatedByAny?: string[];
};

export type OutputType = ReportLayoutDocument[];
