import {captureException} from '@sentry/react';
import {useEffect, useMemo, useState} from 'react';
import {getReportLayouts} from '../../api/endpoints/report-templates/get/frontend';
import type {ReportLayoutDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import type {SerializableObject} from '../../types/Serializable';
import {escapeRegex} from '../../util/strings';
import {useCurrentSession} from '../context';
import type {SimplifiedReportLayout} from '../helpers/SimplifiedReportLayout';
import {simplifyReportLayout} from '../helpers/SimplifiedReportLayout';
import {sortAlphabetically, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useReportLayouts = (filter: {
    name?: string;
    reportLayoutIds?: string[];
} = {}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    reportLayouts: JsonOf<ReportLayoutDocument>[];
    simplifiedReportLayouts: SimplifiedReportLayout[];
    createReportLayout: (reportLayout: Partial<JsonOf<ReportLayoutDocument>>) => Promise<boolean>;
    updateReportLayout: (reportLayoutId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};
    const searchName = filter.name && escapeRegex(filter.name);

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resources: reportLayouts, dexieActive} = useDexieResources<ReportLayoutDocument>({
        filter({_id, offline_id, name, _deleted}: JsonOf<ReportLayoutDocument>) {
            if (_deleted) {
                return false;
            }

            if (filter.reportLayoutIds && !filter.reportLayoutIds.includes(_id ?? offline_id)) {
                return false;
            }

            if (searchName && (!name || !new RegExp(searchName, 'i').test(name))) {
                return false;
            }

            return true;
        },
        sort: sortAlphabetically,
        table: dexie.reportTemplates,
        query: async () => entityId && dexie.reportTemplates
            .where('context.entityId').equals(entityId)
            .toArray()
    }, [entityId, searchName, filter.reportLayoutIds]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId) {
            return;
        }

        setFetchActive(true);
        getReportLayouts({})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState]);

    const simplifiedReportLayouts = useMemo(() => reportLayouts.map(simplifyReportLayout), [reportLayouts]);

    return {
        ready,
        dexieActive,
        fetchActive,
        reportLayouts,
        simplifiedReportLayouts,
        createReportLayout: async (body) => {
            if (!userId || !entityId) {
                return false;
            }
            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'reportTemplates',
                    resourceId: body.offline_id!,
                    userId,
                    entityId,
                    body: body as unknown as SerializableObject
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        updateReportLayout: async (reportLayoutId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !reportLayoutId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'reportTemplates',
                    resourceId: reportLayoutId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
