import {useEffect, useState} from 'react';
import {HttpStatusCode} from '../../api/HttpStatusCode';
import {postRequest} from '../../frontend/utilities/network';
import {hasProperty} from '../../types';
import {useVisibilityChange} from './useVisibilityChange';

export const useFetch = (endpoint: string, body: Record<string, unknown>, dependencies: unknown[]) => {
    const [fetchActive, setFetchActive] = useState(false);

    const getNewInformation = () => {
        if (fetchActive || dependencies.some((dependency) => !dependency)) {
            return;
        }

        (async function retrieve(rangeStart = 0) {
            const start = performance.now();
            setFetchActive(true);

            try {
                const response = await postRequest(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Range: `docs=${rangeStart}-${rangeStart + 99}`
                    },
                    body
                });

                if (response.status === HttpStatusCode.PARTIAL_CONTENT) {
                    console.log(`[useFetch] endpoint=${endpoint} duration=${Math.ceil(performance.now() - start)} status=${response.status} ${response.headers.get('ms-content-range')?.replace('docs ', 'docs=')}`);
                    return retrieve(rangeStart + 100);
                }
                console.log(`[useFetch] endpoint=${endpoint} duration=${Math.ceil(performance.now() - start)} status=${response.status}`);
            } catch (error) {
                console.log(`[useFetch] endpoint=${endpoint} duration=${Math.ceil(performance.now() - start)} error=${hasProperty(error, 'message') ? error.message : error}`);
            }

            setFetchActive(false);
        }());
    };

    useVisibilityChange(getNewInformation, dependencies);

    useEffect(getNewInformation, dependencies);

    return {fetchActive};
};
