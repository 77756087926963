import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getChart} from '../../api/endpoints/charts/[chartId]/get/frontend';
import type {ChartDocument} from '../../database/collections/charts';
import type {JsonOf} from '../../interfaces/helpers';
import {noop} from '../../util/functions';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useChart = (chartId: string): {
    ready: boolean;
    fetchActive: boolean;
    chart: JsonOf<ChartDocument> | null;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId} = currentSession ?? {};
    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive: true, fetchActive});
    const [visibilityState, setVisibilityState] = useState(0);
    const [chart, setChart] = useState<JsonOf<ChartDocument> | null>(null);
    useEffect(() => {
        if (!userId || !chartId) {
            return;
        }

        let aborted = false;

        setFetchActive(true);
        getChart({
            chartId
        })
            .then(({json, response}) => {
                !aborted && setChart(response.ok && json ? json as JsonOf<ChartDocument> : null);
            })
            .catch((error) => {
                captureException(error);
                !aborted && setChart(null);
            })
            .then(() => setFetchActive(false), noop);

        return () => {
            aborted = true;
        };
    }, [userId, visibilityState]);
    useEffect(() => {
        if (!chartId) {
            return;
        }

        setFetchActive(true);
        getChart({chartId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [chartId, visibilityState]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    return {
        ready,
        fetchActive,
        chart
    };
};
