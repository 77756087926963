import React, {forwardRef} from 'react';
import type {ToggleProps, ToggleCheckedState} from '../Toggle';
import {Toggle} from '../Toggle';
import './Checkbox.scss';

export type CheckboxCheckedState = ToggleCheckedState;

export type CheckboxProps = Omit<ToggleProps<CheckboxCheckedState>, 'variant'>;

/**
 * @see {RadioButton}
 * @see {Switch}
 * @see {Toggle}
 * @see {ToggleDescription}
 * @see {CheckboxProps}
 */
export const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>((props, labelRef) => (
    <Toggle {...props} variant="checkbox" ref={labelRef}/>
));
