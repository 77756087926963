export type AppEnvironment = 'web' | 'pwa' | 'twa' | 'app';

export const APP_ENVIRONMENTS = Object.freeze(['web', 'pwa', 'twa', 'app'] satisfies AppEnvironment[]);

export const isValidEnvironment = (env: string | undefined, ...acceptedEnvironments: AppEnvironment[]): env is AppEnvironment => !!env && (acceptedEnvironments.length
    ? acceptedEnvironments
    : APP_ENVIRONMENTS
).includes(env as AppEnvironment);

export const environmentFrom = (env: string | undefined, fallback: AppEnvironment = 'web'): AppEnvironment => isValidEnvironment(env) ? env : fallback;

export const environmentAllowsPayment = (env: string | undefined): boolean => isValidEnvironment(environmentFrom(env), 'web', 'pwa');
