import type {MetricKey} from '../dexie';
import {__} from '../translations';

export const units: {
    text: string;
    value: string;
}[] = [
    {text: __('candela'), value: 'cd'},
    {text: __('celcius'), value: '°C'},
    {text: __('centimeter'), value: 'CM'},
    {text: __('decibel'), value: 'dB'},
    {text: __('fahrenheit'), value: '°F'},
    {text: __('feet'), value: 'ft'},
    {text: __('gram'), value: 'g'},
    {text: __('hours'), value: __('Hours')},
    {text: __('inch'), value: '"'},
    {text: __('kelvin'), value: 'K'},
    {text: __('kilogram'), value: 'kg'},
    {text: __('kilopascal'), value: 'kPa'},
    {text: __('kilopounds per square inch'), value: 'KPSI'},
    {text: __('meter'), value: 'm'},
    {text: __('micrometer'), value: 'µm'},
    {text: __('milliampere'), value: 'mA'},
    {text: __('millimeter'), value: 'mm'},
    {text: __('minutes'), value: 'min'},
    {text: __('mol'), value: 'mol'},
    {text: __('newton'), value: 'N'},
    {text: __('ohm'), value: 'Ω'},
    {text: __('percentage'), value: '%'},
    {text: __('pieces'), value: __('Pieces')},
    {text: __('pounds per square inch'), value: 'PSI'},
    {text: __('relative light units'), value: 'RLU'},
    {text: __('seconds'), value: 'sec'},
    {text: __('volt'), value: 'V'}
];

export const operators: {
    text: string;
    value: string;
}[] = [
    {text: __('Equals'), value: '='},
    {text: __('Greater than'), value: '>'},
    {text: __('Greater than or equal to'), value: '>='},
    {text: __('Lower than'), value: '<'},
    {text: __('Lower than or equal to'), value: '<='}
];

export const metricWeights: Record<MetricKey, number> = {
    chartSegmentsCreated: 0,
    chartSegmentsDeleted: 0,
    chartSegmentsUpdated: 0,
    chartsCreated: 0,
    chartsDeleted: 0,
    chartsUpdated: 0,
    crashes: -100,
    entitiesCreated: 15,
    entitiesDeleted: 5,
    entitiesUpdated: 0,
    errors: -25,
    inspectionPlanningsCreated: 3,
    inspectionPlanningsDeleted: 3,
    inspectionPlanningsUpdated: 1,
    inspectionTemplatesActivated: 10,
    inspectionTemplatesCreated: 3,
    inspectionTemplatesDeleted: 3,
    inspectionTemplatesUpdated: 1,
    inspectionsCreated: 5,
    inspectionsDeleted: 3,
    inspectionsDone: 10,
    inspectionsLocked: 3,
    inspectionsUpdated: 1,
    membershipsCreated: 0,
    membershipsDeleted: 0,
    membershipsUpdated: 0,
    photosCreated: 5,
    photosDeleted: 0,
    photosUpdated: 5,
    pricesCreated: 0,
    pricesDeleted: 0,
    pricesUpdated: 0,
    productsCreated: 0,
    productsDeleted: 0,
    productsUpdated: 0,
    profilesCreated: 0,
    profilesDeleted: 0,
    profilesUpdated: 0,
    reportTemplatesCreated: 5,
    reportTemplatesDeleted: 5,
    reportTemplatesUpdated: 1,
    resourceTranslationsCreated: 0,
    resourceTranslationsDeleted: 0,
    resourceTranslationsUpdated: 0,
    tasksAssigned: 5,
    tasksCommented: 5,
    tasksCreated: 5,
    tasksDeleted: 5,
    tasksUpdated: 1,
    tempCreated: 0,
    tempDeleted: 0,
    tempUpdated: 0,
    usersCreated: 15,
    usersDeleted: 5,
    usersUpdated: 1
};

export const reviewConfig: Record<string, number[]> = {
    minimumUsageScores: [100, 500, 1500],
    minimumDaysBetweenPopups: [0, 7, 90]
};

export const features: Record<string, string[]> = {
    free: [__('Max. 5 team members.'), __('Max. 10 inspections p.m.'), __('Templates in public library'), __('Up to 3 locations'), __('Create tasks'), __('Download report in PDF'), __('Standard reports')],
    premium: [__('Unlimited team members'), __('Unlimited inspections'), __('Private templates'), __('Create & manage tasks'), __('Plan your inspections'), __('Download reports in Word'), __('Custom reports')]
};

export const featuresTableData: {
    description: string;
    free: string;
    premium: string;
}[] = [
    {description: __('Team members'), free: __('Max. 5'), premium: __('Unlimited')},
    {description: __('Inspections p.m.'), free: __('Max. 10'), premium: __('Unlimited')},
    {description: __('Add tasks'), free: '✓', premium: '✓'},
    {description: __('Assign tasks'), free: 'x', premium: '✓'},
    {description: __('Roles & Permissions'), free: 'x', premium: '✓'},
    {description: __('Manage locations'), free: __('Up to 3 loc.'), premium: __('Unlimited')},
    {description: __('Private templates'), free: 'x', premium: '✓'},
    {description: __('Download templates'), free: __('Max. 5'), premium: __('Unlimited')},
    {description: __('Follow-up questions'), free: __('Unlimited'), premium: __('Unlimited')},
    {description: __('Download PDF reports'), free: '✓', premium: '✓'},
    {description: __('Download Word reports'), free: 'x', premium: '✓'},
    {description: __('Custom reports'), free: 'x', premium: '✓'}
];
