import {captureException, captureMessage} from '@sentry/react';
import {updateInspectionPlanning} from '../api/endpoints/inspection-plannings/[inspectionPlanningId]/patch/frontend';
import {createInspectionPlanning} from '../api/endpoints/inspection-plannings/post/frontend';
import {updateInspectionTemplate} from '../api/endpoints/inspection-templates/[inspectionTemplateId]/patch/frontend';
import {createInspectionTemplate} from '../api/endpoints/inspection-templates/post/frontend';
import {updateInspection} from '../api/endpoints/inspections/[inspectionId]/patch/frontend';
import {createInspection} from '../api/endpoints/inspections/post/frontend';
import {updateLocation} from '../api/endpoints/locations/[locationId]/patch/frontend';
import {createLocation} from '../api/endpoints/locations/post/frontend';
import {updatePhoto} from '../api/endpoints/photos/[photoId]/patch/frontend';
import {createPhoto} from '../api/endpoints/photos/post/frontend';
import {updateReportLayout} from '../api/endpoints/report-templates/[reportLayoutId]/patch/frontend';
import {createReportLayout} from '../api/endpoints/report-templates/post/frontend';
import {updateResourceTranslation} from '../api/endpoints/resource-translations/[resourceTranslationId]/patch/frontend';
import {createResourceTranslation} from '../api/endpoints/resource-translations/post/frontend';
import {updateTask} from '../api/endpoints/tasks/[taskId]/patch/frontend';
import {createTask} from '../api/endpoints/tasks/post/frontend';
import {updateUser} from '../api/endpoints/users/[userId]/patch/frontend';
import {HttpStatusCode} from '../api/HttpStatusCode';
import {__} from '../translations';
import {hasProperty} from '../types';
import type {JsonPatch} from '../types/JsonPatch';
import type {MongoDbObjectId} from '../types/MongoDb';
import {isOfflineObjectId} from '../types/MongoDb';
import type {QueueEntry} from './dexie';
import {dexie, maxKey, minKey} from './dexie';

let timeout: number | undefined;
let processQueuePromise: Promise<number> | undefined;

const apiv2Map = {
    users: {
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateUser({userId: resourceId}, patch)
        }
    },
    inspections: {
        create: {
            endpoint: createInspection
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateInspection({inspectionId: resourceId}, patch)
        }
    },
    inspectionTemplates: {
        create: {
            endpoint: createInspectionTemplate
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateInspectionTemplate({inspectionTemplateId: resourceId}, patch)
        }
    },
    tasks: {
        create: {
            endpoint: createTask
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateTask({taskId: resourceId}, patch)
        }
    },
    resourceTranslations: {
        create: {
            endpoint: createResourceTranslation
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateResourceTranslation({resourceTranslationId: resourceId}, patch)
        }
    },
    entities: {
        create: {
            endpoint: createLocation
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateLocation({locationId: resourceId}, patch)
        }
    },
    inspectionPlannings: {
        create: {
            endpoint: createInspectionPlanning
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateInspectionPlanning({inspectionPlanningId: resourceId}, patch)
        }
    },
    photos: {
        create: {
            endpoint: createPhoto
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updatePhoto({photoId: resourceId}, patch)
        }
    },
    reportTemplates: {
        create: {
            endpoint: createReportLayout
        },
        update: {
            endpoint: (resourceId: string, patch: JsonPatch) => updateReportLayout({reportLayoutId: resourceId}, patch)
        }
    }
};

const getResourceId = async (response: Response, offline_id: string): Promise<string | null> => {
    const json = await response.json() as unknown;
    const object = (Array.isArray(json) ? json[0] : json) as unknown;

    if (!object) {
        return null;
    }

    if (hasProperty(object, '_id') && hasProperty(object, 'offline_id')
        && typeof object._id === 'string' && object.offline_id === offline_id) {
        return object._id;
    }

    if (hasProperty(object, 'data') && hasProperty(object.data, '_id') && hasProperty(object.data, 'offline_id')
        && typeof object.data._id === 'string' && object.data.offline_id === offline_id) {
        return object.data._id;
    }

    return null;
};

export const processQueue = async (userId: MongoDbObjectId): Promise<HttpStatusCode | 0 | -1 | -2> => {
    if (processQueuePromise) {
        return processQueuePromise;
    }

    timeout &&= void clearTimeout(timeout);

    return processQueuePromise = Promise.resolve().then(async () => {
        let status = 0;
        let entry: QueueEntry | undefined;

        while ((entry = await dexie.queue
            .where(['userId', 'timestamp'])
            .between([userId, minKey], [userId, maxKey], true, true)
            .first()) !== undefined
        ) {
            if (entry === null) {
                captureException(new Error('Encountered null entry in queue during upload'));
                await dexie.queue
                    .where(['userId', 'timestamp'])
                    .between([userId, minKey], [userId, maxKey], true, true)
                    .limit(1)
                    .modify((value, ref) => {
                        if (value === null) { // Just to be absolutely sure that we're never deleting customer data
                            delete (ref as Partial<{value: QueueEntry;}>).value;
                        }
                    });
                continue;
            }

            try {
                const {resourceId, resourceTableName, action, body, patch} = entry;

                if (!body && !patch?.length) {
                    await dexie.queue.delete(entry.id!);
                }

                let response: Response;

                if (action === 'create' && apiv2Map[resourceTableName][action] && body) {
                    const {endpoint} = apiv2Map[resourceTableName][action];

                    try {
                        const result = await endpoint(body);
                        response = result.response;
                    } catch (error: unknown) {
                        if (!(error instanceof Error) || error.name !== 'ValidationError') {
                            throw error;
                        }

                        return -2;
                    }
                } else if (action === 'update' && apiv2Map[resourceTableName][action] && patch) {
                    const {endpoint} = apiv2Map[resourceTableName][action];

                    try {
                        const filteredPatch = patch.filter((operation) => {
                            if (operation.op === 'test' && typeof operation.value !== 'string' && typeof operation.value !== 'number' && typeof operation.value !== 'boolean' && operation.value !== null) {
                                captureMessage(`Bad test case for "${operation.path}"`, {
                                    extra: operation
                                });
                                return false;
                            }

                            return true;
                        });

                        const result = await endpoint(resourceId, filteredPatch);
                        response = result.response;
                    } catch (error: unknown) {
                        if (!(error instanceof Error) || error.name !== 'ValidationError') {
                            throw error;
                        }

                        return -2;
                    }
                } else {
                    return -2;
                }

                status = response.status;

                if (response.ok
                    || response.status === HttpStatusCode.FORBIDDEN
                    // TODO: Add conflict resolution
                    || response.status === HttpStatusCode.CONFLICT
                ) {
                    await dexie.queue.delete(entry.id!);
                } else if (response.status === HttpStatusCode.BAD_REQUEST) {
                    if (patch?.[0] && hasProperty(patch[0], 'value') && (typeof patch[0].value === 'string' || typeof patch[0].value === 'number')) {
                        const {resourceId, resourceTableName} = entry;
                        const {op, path, value} = patch[0];

                        if (confirm(__('There is a problem with your sync. Would you like to discard the bad update in your queue and continue syncing?\n\n\nInfo for Checkbuster support:\nCannot apply update "{{value}}" to path "{{path}}" on document "{{resourceId}}" in "{{tableName}}".')
                            .replace('{{resourceId}}', resourceId)
                            .replace('{{tableName}}', resourceTableName)
                            .replace('{{path}}', path)
                            .replace('{{value}}', value.toString()))
                        ) {
                            await dexie.queue.delete(entry.id!);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                } else if (response.status === HttpStatusCode.NOT_FOUND) {
                    const entryResourceIdToDelete = entry.resourceId;
                    const amountToDelete = await dexie.queue.where('resourceId').equals(entryResourceIdToDelete).count();
                    if (confirm(__('Cannot update document "{{resourceId}}" in "{{tableName}}". Do you want to discard the {{amount}} changes you\'ve made to this document?')
                        .replace('{{resourceId}}', entry.resourceId)
                        .replace('{{tableName}}', resourceTableName)
                        .replace('{{amount}}', amountToDelete.toString()))) {
                        await dexie.queue.where('resourceId').equals(entryResourceIdToDelete).delete();
                    } else {
                        break;
                    }
                } else {
                    break;
                }

                const offline_id = isOfflineObjectId(entry.resourceId) ? entry.resourceId : null;
                const _id = offline_id ? await getResourceId(response, offline_id) : null;

                if (_id && offline_id) {
                    await dexie.queue.where('resourceId').equals(offline_id).modify({
                        resourceId: _id
                    });

                    await dexie.queue.toCollection().modify((entry, ref) => {
                        let needsModification = false;
                        const source = entry.patch || entry.body!;
                        const replacer = (key: string | null, value: any): any => {
                            if (value === offline_id) {
                                needsModification = true;
                                return _id;
                            }

                            if (Array.isArray(value)) {
                                return value.map((v) => replacer(null, v));
                            }

                            return value;
                        };

                        const stringified = JSON.stringify(source, replacer);
                        if (needsModification) {
                            const result = JSON.parse(stringified);
                            ref.value = entry.patch
                                ? {
                                    ...entry,
                                    patch: result
                                }
                                : {
                                    ...entry,
                                    body: result
                                };
                        }
                    });
                }
            } catch (error) {
                console.warn(error);
                captureException(error);
                status = -1;
                break;
            }
        }

        return status;
    }).finally(async () => {
        processQueuePromise = undefined;
    });
};

export const addToQueue = async (item: QueueEntry): Promise<number | void> => dexie.queue.put(item);

