import 'intersection-observer';
import {captureException} from '@sentry/react';
import React from 'react';
import {createRoot} from 'react-dom/client';
import {isValidEnvironment} from '../helpers/AppEnvironment';
import {currentLocale} from '../helpers/locales';
import {CurrentSessionProvider} from '../react/context/CurrentSession';
import {CurrentUserProvider} from '../react/context/CurrentUser';
import {ReviewPopup} from '../react/integrations/ReviewPopup';
import {UsageMetrics} from '../react/integrations/UsageMetrics';
import {registerServiceWorker} from '../service-worker/workbox';
import {Router, createHistory, matchPath} from './components/system/Router';
import {routes} from './components/system/Router/routes/mobile';
import {Sentry, startSentry} from './components/system/Sentry';
import {getCookie, setCookie} from './utilities/storage';
import './App.scss';

const history = createHistory(currentLocale!.iso);

setCookie('iso', currentLocale!.iso);
console.log('It\'s a good day for using Checkbuster');

if (!isValidEnvironment(getCookie('env'))) {
    setCookie('env', 'web', 365, 'none');
}

const fontSizeValue = getCookie('size');
fontSizeValue && document.body.style.setProperty('--font-size', fontSizeValue);

startSentry({
    routing: [history, routes.map((route) => ({
        ...route,
        path: `/${currentLocale!.iso}${route.path}`
    })), matchPath]
});

registerServiceWorker().catch(captureException);

// Workaround for Firefox https://caniuse.com/?search=rel%3Dpreload
if (!document.createElement('link').relList?.supports?.('preload')) {
    const links: NodeListOf<HTMLLinkElement> = document.head.querySelectorAll('link[rel="preload"][as="style"]');

    links.forEach((link) => {
        link.onload = null;
        link.onerror = null;
        link.rel = 'stylesheet';
    });
}

createRoot(document.getElementById('app-root')!).render(
    <Sentry>
        <CurrentSessionProvider>
            <CurrentUserProvider>
                <Router history={history} routes={routes}/>
                <UsageMetrics/>
                <ReviewPopup/>
            </CurrentUserProvider>
        </CurrentSessionProvider>
    </Sentry>
);
