import {postRequest} from './network';

export const readAsDataURL = (blob: Blob): Promise<string> => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error ?? new Error('Could not read file: error'));
    reader.onabort = () => reject(reader.error ?? new Error('Could not read file: abort'));
    reader.readAsDataURL(blob);
});

export const downloadImage = async (url: string, mode?: 'cors' | 'no-cors'): Promise<Blob> => {
    const response = await postRequest(url, {method: 'GET', mode: 'cors'});

    if (!response.ok) {
        throw new Error(`Failed downloading image (code ${response.status})`);
    }

    return response.blob();
};

export const loadImage = (dataUrl: string): Promise<HTMLImageElement> => new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve(image);
    image.onerror = reject;
    image.onabort = reject;
    image.src = dataUrl;
});

export const resizeImage = (image: HTMLImageElement, options: {
    contentType: string;
    minSize?: number;
    minWidth?: number;
    minHeight?: number;
    maxSize?: number;
    maxWidth?: number;
    maxHeight?: number;
    quality?: number;
    grow?: boolean;
}): {
    dataUrl: string;
    width: number;
    height: number;
} => {
    const {contentType, minSize, maxSize, quality = 1} = options;
    const minWidth = options.minWidth ?? minSize ?? 0;
    const minHeight = options.minHeight ?? minSize ?? 0;
    const maxWidth = options.maxWidth ?? maxSize ?? Infinity;
    const maxHeight = options.maxHeight ?? maxSize ?? Infinity;
    const canvas = document.createElement('canvas');

    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;

    if (canvas.width < minWidth) {
        canvas.height *= minWidth / canvas.width;
        canvas.width = minWidth;
    }

    if (canvas.height < minHeight) {
        canvas.width *= minHeight / canvas.height;
        canvas.height = minHeight;
    }

    if (canvas.width > maxWidth) {
        canvas.height *= maxWidth / canvas.width;
        canvas.width = maxWidth;
    }

    if (canvas.height > maxHeight) {
        canvas.width *= maxHeight / canvas.height;
        canvas.height = maxHeight;
    }

    canvas
        .getContext('2d')
        ?.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, canvas.width, canvas.height);

    return {
        dataUrl: canvas.toDataURL(contentType, quality),
        width: canvas.width,
        height: canvas.height
    };
};

export const loadAndResizeImageFile = async (file: File, maxSize = 1080): Promise<{
    dataUrl: string;
    width: number;
    height: number;
}> => {
    const dataUrl = await readAsDataURL(file);
    const image = await loadImage(dataUrl);

    return resizeImage(image, {
        contentType: file.type,
        maxSize
    });
};

export const parseDataUrl = (dataUrl: string): {
    content: string;
    contentEncoding: string;
    contentType: string;
} | null => {
    const match = (/^data:(?<contentType>image\/\w+);(?<contentEncoding>\w+),(?<content>.+$)/).exec(dataUrl);

    if (!match) {
        return null;
    }

    const {
        content,
        contentEncoding,
        contentType
    } = match.groups!;

    return {
        content,
        contentEncoding,
        contentType
    };
};
