export enum HttpMethod {
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH',
    POST = 'POST',
    PUT = 'PUT'
}

export const formatHttpMethod = (httpMethod: HttpMethod): string => ({
    DELETE: 'Delete',
    GET: 'Get',
    PATCH: 'Update',
    POST: 'Create',
    PUT: 'Replace'
})[httpMethod];

