import {queryTypeDef as inspectionQueryTypeDef} from '../../../api/endpoints/inspections/get/shared';
import {queryTypeDef as taskQueryTypeDef} from '../../../api/endpoints/tasks/get/shared';
import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {inspectionTypeDef} from '../inspections';
import {taskTypeDef} from '../tasks';
import type {ChartSegmentDocument,
    InspectionChartSegmentDocument,
    TaskChartSegmentDocument} from './ChartSegmentDocument';

export const inspectionChartSegmentTypeDef: TypeDef<Omit<InspectionChartSegmentDocument, 'collection'>> = {
    additionalProperties: false,
    metadata: {
        name: 'InspectionChartSegmentDocument'
    },
    optionalProperties: {
        expiresAt: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        chart_id: {
            type: 'string'
        },
        colors: {
            elements: {
                type: 'string'
            }
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        entity_id: {
            type: 'string'
        },
        field: {
            enum: [
                'score',
                'total',
                'incorrect-item-count',
                ...Object.keys(inspectionTypeDef.properties),
                ...Object.keys(inspectionTypeDef.optionalProperties)
            ] as never
        },
        filter: inspectionQueryTypeDef,
        method: {
            enum: ['add', 'average']
        },
        name: {
            type: 'string'
        },
        removed: {
            type: 'int8'
        } as never,
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        type: {
            enum: ['line', 'bar', 'area']
        }
    }
};

export const taskChartSegmentTypeDef: TypeDef<Omit<TaskChartSegmentDocument, 'collection'>> = {
    additionalProperties: false,
    metadata: {
        name: 'TaskChartSegmentDocument'
    },
    optionalProperties: {
        expiresAt: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        chart_id: {
            type: 'string'
        },
        colors: {
            elements: {
                type: 'string'
            }
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        entity_id: {
            type: 'string'
        },
        field: {
            enum: [
                ...Object.keys(taskTypeDef.properties),
                ...Object.keys(taskTypeDef.optionalProperties)
            ] as never
        },
        filter: taskQueryTypeDef,
        method: {
            enum: ['add', 'average']
        },
        name: {
            type: 'string'
        },
        removed: {
            type: 'int8'
        } as never,
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        type: {
            enum: ['line', 'bar', 'area']
        }
    }
};

export const chartSegmentTypeDef: TypeDef<ChartSegmentDocument> = {
    discriminator: 'collection',
    mapping: {
        inspections: inspectionChartSegmentTypeDef,
        tasks: taskChartSegmentTypeDef
    },
    metadata: {
        name: 'ChartSegmentDocument'
    }
};
