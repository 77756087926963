import type {FunctionComponent, ReactNode} from 'react';
import React from 'react';
import {classNames} from '../../helpers/styling';
import './List.scss';

export const List = (({
    children,
    className
}) => (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex,jsx-a11y/no-redundant-roles
    <ul role="list" className={classNames('list', {}, className)} tabIndex={0}>
        {children}
    </ul>
)) satisfies FunctionComponent<{
    children: ReactNode;
    className?: string;
}>;
