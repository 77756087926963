import type {JsonSchemaObject} from '../../../interfaces/models/json-schema-draft-04';

export const textItemSchema: JsonSchemaObject = {
    additionalProperties: false,
    bsonType: 'object',
    properties: {
        addedAt: {
            bsonType: 'number'
        },
        addedBy: {
            bsonType: 'string'
        },
        id: {
            bsonType: 'number'
        },
        input: {
            additionalProperties: false,
            bsonType: 'object',
            properties: {
                comment: {
                    bsonType: 'string'
                },
                photos: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                state: {
                    bsonType: 'string',
                    enum: ['ok', 'empty', 'bad', 'none']
                },
                taskIds: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                value: {
                    bsonType: 'string'
                }
            },
            required: [
                'comment',
                'photos',
                'state',
                'taskIds',
                'value'
            ]
        },
        isFavourite: {
            bsonType: 'bool'
        },
        locked: {
            bsonType: 'bool'
        },
        parentConditionId: {
            bsonType: 'number'
        },
        parentId: {
            bsonType: 'number'
        },
        spec: {
            additionalProperties: false,
            bsonType: 'object',
            properties: {
                autoTask: {
                    bsonType: 'bool'
                },
                conditions: {
                    bsonType: 'array',
                    items: {
                        oneOf: [{
                            additionalProperties: false,
                            bsonType: 'object',
                            properties: {
                                equals: {
                                    bsonType: 'string',
                                    enum: ['===', '!==']
                                },
                                id: {
                                    bsonType: 'number'
                                },
                                itemState: {
                                    bsonType: 'string',
                                    enum: ['ok', 'bad', 'empty', 'none']
                                }
                            },
                            required: [
                                'equals',
                                'id',
                                'itemState'
                            ]
                        }, {
                            additionalProperties: false,
                            bsonType: 'object',
                            properties: {
                                equals: {
                                    bsonType: 'string',
                                    enum: ['===', '!==']
                                },
                                id: {
                                    bsonType: 'number'
                                },
                                itemValue: {
                                    bsonType: 'string'
                                }
                            },
                            required: [
                                'equals',
                                'id',
                                'itemValue'
                            ]
                        }]
                    }
                },
                dynamic: {
                    bsonType: 'bool'
                },
                extraInfoPhotos: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                extraInfoText: {
                    bsonType: 'string'
                },
                extraInfoToggle: {
                    bsonType: 'bool'
                },
                isText: {
                    bsonType: 'bool'
                },
                label: {
                    bsonType: 'string'
                },
                predefinedComments: {
                    bsonType: 'bool'
                },
                predefinedCommentsArray: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                weight: {
                    bsonType: 'number'
                }
            },
            required: [
                'autoTask',
                'conditions',
                'dynamic',
                'extraInfoPhotos',
                'extraInfoText',
                'extraInfoToggle',
                'isText',
                'label',
                'predefinedComments',
                'predefinedCommentsArray',
                'weight'
            ]
        },
        type: {
            bsonType: 'string',
            enum: ['text']
        },
        updated: {
            bsonType: 'number'
        },
        updatedAt: {
            bsonType: 'number'
        },
        updatedBy: {
            bsonType: 'objectId'
        }
    },
    required: [
        'id',
        'input',
        'isFavourite',
        'locked',
        'spec',
        'type'
    ]
} as const;
