import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {ripple} from '../../utilities/animations';
import Eye from './cb-ico-visibility-black.svg';
import EyeSlash from './cb-ico-visibility-off-black.svg';
import './Input.scss';

type InputProps = React.HTMLAttributes<HTMLTextAreaElement | HTMLInputElement> & {
    id?: string;
    value?: string;
    name: string;
    minLength?: number;
    required?: boolean;
    label: string | React.ReactElement;
    placeholder?: string;
    pattern?: string;
    inputMode?: string;
    autoFocus?: boolean;
    min?: number;
    type: 'text' | 'textarea' | 'email' | 'password' | 'number' | 'color';
    autoComplete: 'off' | 'email' | 'current-password' | 'new-password';
    disabled?: boolean;
    externalRef?: React.RefObject<HTMLInputElement>;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(({onFocus, onBlur, onChange, className, type, label, name, value, id = `input-${name}`, ...rest}, externalRef) => {
    const [showPassword, setShowPassword] = useState<boolean>();
    const [inputActive, setInputActive] = useState<boolean>();
    const [containsColon, setContainsColon] = useState<boolean>();
    const ref = useRef<HTMLInputElement>(null);
    const realType = showPassword || type === 'email' && containsColon ? 'text' : type;
    const PasswordToggle = showPassword ? Eye : EyeSlash;
    const classNames = [
        'input',
        className
    ].join(' ').trim();

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        if (containsColon && value) {
            ref.current.selectionStart = value.indexOf(':') + 1;
        }
    }, [!!containsColon]);

    return (
        <label htmlFor={id} className={classNames} data-testid={`label-${name}`}>
            <span className={`input__placeholder input__placeholder--active-${inputActive || value ? 'yes' : 'no'}`}>
                {label || name}
            </span>

            <input
                {...rest}
                ref={externalRef ?? ref}
                value={value}
                name={name}
                type={realType}
                onClick={ripple}
                id={id}
                onFocus={(event) => {
                    setInputActive(true);
                    onFocus?.(event);
                }}
                onBlur={(event) => {
                    setInputActive(event.currentTarget.value !== '');
                    onBlur?.(event);
                }}
                className="input__field"
                data-testid={`input-${name}`}
                onChange={(event) => {
                    setContainsColon(type === 'email' && event.currentTarget.value.includes(':'));
                    onChange?.(event);
                }}
            />

            {type === 'password'
                ? (
                    <PasswordToggle
                        className="input__icon" onClick={() => setShowPassword(!showPassword)}
                        data-testid={`input-toggle-${name}`}
                    />
                )
                : null}
        </label>
    );
});
