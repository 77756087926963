import type {Table} from 'dexie';
import Dexie from 'dexie';
import 'dexie-observable';
import type {
    UserWithContextDocument,
    EntityDocument,
    InspectionDocument, InspectionPlanDocument,
    InspectionTemplateDocument,
    PhotoDocument,
    PriceDocument,
    ProductDocument,
    PublicSessionDocument,
    ReportLayoutDocument, SimplifiedMembershipDocument,
    ResourceTranslationDocument,
    TaskDocument,
    VerifiedDomainDocument
} from '../database';
import type {ChartDocument} from '../database/collections/charts';
import type {ChartSegmentDocument} from '../database/collections/chartSegments';
import type {JsonOf} from '../interfaces/helpers';
import type {JsonPatch} from '../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../types/MongoDb';
import type {SerializableObject} from '../types/Serializable';
import {indexedDB, IDBKeyRange} from './indexeddb';
import type {MetricKey} from './metrics';

export const minKey = Dexie.minKey;
export const maxKey = Dexie.maxKey;

export type ResourceTableName =
    'charts'
    | 'chartSegments'
    | 'entities'
    | 'inspections'
    | 'memberships'
    | 'resourceTranslations'
    | 'inspectionPlannings'
    | 'inspectionTemplates'
    | 'profiles'
    | 'photos'
    | 'prices'
    | 'products'
    | 'reportTemplates'
    | 'tasks'
    | 'users'
    | 'temp';

export type QueueBaseEntry = {
    id?: number;
    timestamp: number;
    action: 'create' | 'update';
    resourceTableName: ResourceTableName;
    resourceId: MongoDbObjectId | OfflineObjectId;
    userId: MongoDbObjectId;
    entityId: MongoDbObjectId;
    patch?: JsonPatch;
    body?: SerializableObject;
};

export type QueueCreateEntry = QueueBaseEntry & {
    action: 'create';
    body: SerializableObject & {
        _id?: string | undefined;
        offline_id?: string | undefined;
    };
};

export type QueueUpdateEntry = QueueBaseEntry & {
    action: 'update';
    patch: JsonPatch;
};

export type QueueEntry = QueueCreateEntry | QueueUpdateEntry;

export type MetricsRecord = Partial<Record<MetricKey, number>> & {
    userId: string;
};

export type ContentDetailsRecord<T extends Record<string, number>> = {
    resourceTableName: ResourceTableName;
    timestamp: number;
    userId: string;
    query: string;
    details: T;
};

export type ReviewsRecord = {
    userId: string;
    attemptedCount: number;
    lastAttemptedAt: number;
};
export type ReportAutomationsRecord = {
    userId: string;
    favouriteOnPicture: boolean;
    favouriteOnComment: boolean;
    favouriteOnTask: boolean;
};

class CheckbusterDexie extends Dexie {
    public readonly reportAutomations!: Table<ReportAutomationsRecord, string>;

    public readonly contentDetails!: Table<ContentDetailsRecord<Record<string, number>>, string>;

    public readonly charts!: Table<JsonOf<ChartDocument>, string>;

    public readonly chartSegments!: Table<JsonOf<ChartSegmentDocument>, string>;

    public readonly entities!: Table<JsonOf<EntityDocument>, string>;

    public readonly incidents!: never;

    public readonly incidentTemplates!: never;

    public readonly inspections!: Table<JsonOf<InspectionDocument>, string>;

    public readonly memberships!: Table<JsonOf<SimplifiedMembershipDocument>, string>;

    public readonly resourceTranslations!: Table<JsonOf<ResourceTranslationDocument>, string>;

    public readonly inspectionPlannings!: Table<JsonOf<InspectionPlanDocument>, string>;

    public readonly inspectionTemplates!: Table<JsonOf<InspectionTemplateDocument>, string>;

    public readonly metrics!: Table<MetricsRecord, string>;

    public readonly prices!: Table<JsonOf<Omit<PriceDocument, 'stripeId'>>, string>;

    public readonly products!: Table<JsonOf<Omit<ProductDocument, 'stripeId'>>, string>;

    public readonly profiles!: never;

    public readonly photos!: Table<JsonOf<PhotoDocument>, string>;

    public readonly queue!: Table<QueueEntry, number>;

    public readonly reportTemplates!: Table<JsonOf<ReportLayoutDocument>, string>;

    public readonly reviews!: Table<ReviewsRecord, string>;

    public readonly sessions!: Table<JsonOf<PublicSessionDocument>, string>;

    public readonly tasks!: Table<JsonOf<TaskDocument>, string>;

    public readonly users!: Table<JsonOf<UserWithContextDocument>, string>;

    public readonly verifiedDomains!: Table<JsonOf<VerifiedDomainDocument>, string>;

    public readonly temp!: Table<{key: string; value: any;}, string>;

    public constructor(databaseName) {
        super(databaseName, {indexedDB, IDBKeyRange});

        /*
            table name: `primary key
                single index
                multi index
                compound index`
         */
        this.version(33).stores({
            charts: `_id,
                entity_id`,
            chartSegments: `_id,
                chart_id,
                entity_id`,
            contentDetails: `[userId+resourceTableName+query],
                timestamp`,
            dirHandles: null,
            entities: `_id,
                context.entityId,
                [_id+name]`,
            incidentTemplates: null,
            incidents: null,
            inspectionPlannings: `_id,
                offline_id,
                context.entityId,
                [_id+name]`,
            inspectionTemplates: `_id,
                offline_id,
                context.entityId,
                [_id+name],
                [context.entityId+createdAt]`,
            inspections: `_id,
                offline_id,
                context.entityId,
                conductedAt,
                [location+templateId],
                [_id+name],
                [context.entityId+templateId]`,
            memberships: `_id,
                context.entityId`,
            resourceTranslations: `_id,
                entity_id,
                [resource_type+resource_id+languageCode]`,
            prices: `_id,
                product_id`,
            products: '_id',
            profiles: null,
            pushNotificationPreferences: null,
            photos: `_id,
                entity_id`,
            queue: `++id,
                resourceId,
                resourceTableName,
                [resourceId+action],
                [resourceTableName+action],
                [userId+timestamp],
                [userId+resourceTableName+action+timestamp]`,
            reportTemplates: `_id,
                context.entityId,
                [_id+name],
                [context.entityId+createdAt],
                [context.entityId+updatedAt],
                [context.entityId+touchedAt],
                [context.entityId+name]`,
            sessions: `_id,
                token,
                user_id,
                impersonator_id`,
            tasks: `_id,
                context.entityId,
                creator,
                *assignedToUser,
                *assignedToExternalUser,
                [_id+title]`,
            users: `_id,
                context.entityId,
                email,
                name,
                *templates,
                [_id+name],
                [context.entityId+createdAt],
                [context.entityId+updatedAt],
                [context.entityId+touchedAt],
                [context.entityId+name],
                [context.entityId+email]`,
            verifiedDomains: `_id,
                entity_id`,
            metrics: 'userId',
            reviews: 'userId',
            reportAutomations: 'userId',
            temp: 'key'
        });
    }
}

export const dexie = new CheckbusterDexie('checkbuster-dexie');

(window as any)._dexie = dexie;
