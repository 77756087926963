import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {markdownToHtml} from '../../../reports/docx/markdown/markdown';
import {classNames} from '../../helpers/styling';
import './Tekst.scss';

export type TekstProps = {
    children: string | number | (string | number)[];
    color?: 'inherit' | 'black' | 'gray' | 'white' | 'red' | 'green' | 'blue' | 'orange' | 'yellow';
    size?: 'inherit' | 'mini' | 'small' | 'standard' | 'large' | 'huge' | 'humongous';
    weight?: 'inherit' | 'bold' | 'thin';
    style?: 'inherit' | 'italic';
    decoration?: 'inherit' | 'underline' | 'line-through';
    className?: string;
};

export type TekstComponent = ReactElement<TekstProps>;

export const Tekst: VoidFunctionComponent<TekstProps> = ({
    children,
    className,
    color = 'inherit',
    size = 'inherit',
    weight = 'inherit',
    style = 'inherit',
    decoration = 'inherit'
}): TekstComponent => {
    const tekstClassName = classNames('tekst', {
        [color]: color !== 'inherit',
        [size]: size !== 'inherit',
        [weight]: weight !== 'inherit',
        [style]: style !== 'inherit',
        [decoration]: decoration !== 'inherit'
    }, className);
    if (Array.isArray(children)) {
        return (
            <div className={tekstClassName}>
                {children}
            </div>
        );
    }
    const markdownHTML = markdownToHtml((children ?? '').toString());

    // eslint-disable-next-line react/no-danger
    return <div className={tekstClassName} dangerouslySetInnerHTML={{__html: markdownHTML}}/>;
};
