import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect} from 'react';
import type {ReportAutomationsRecord} from '../../dexie';
import {dexie} from '../../dexie';
import type {JsonOperation} from '../../types/JsonPatch';
import {useCurrentUser} from '../context';

export const useReportAutomations = (): {
    reportAutomations: Partial<Omit<ReportAutomationsRecord, 'userId'>>;
    updateReportAutomations: (path: string, value: boolean) => void;
    handleAutomation: (entry: JsonOperation) => JsonOperation | undefined;
} => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const uid = authenticatedUser?._id;

    useEffect(() => {
        uid && dexie.reportAutomations.add({
            userId: uid,
            favouriteOnPicture: false,
            favouriteOnComment: false,
            favouriteOnTask: false
        } as ReportAutomationsRecord).catch(() => undefined);
    }, [uid]);

    const reportAutomations = useLiveQuery(async () => ((uid && await dexie.reportAutomations.get(uid)) || null), [uid], null);
    const {favouriteOnPicture, favouriteOnComment, favouriteOnTask} = reportAutomations ?? {};

    return {
        reportAutomations: {
            favouriteOnPicture,
            favouriteOnComment,
            favouriteOnTask
        },
        updateReportAutomations: (path, value) => {
            uid && dexie.reportAutomations
                .where('userId').equals(uid)
                .modify((record: ReportAutomationsRecord): void => {
                    record[path] = value;
                })
                .catch(() => undefined);
        },
        handleAutomation: (entry: JsonOperation): JsonOperation | undefined => {
            if (favouriteOnPicture && entry.path.endsWith('/input/photos/-')) {
                return {
                    op: 'replace',
                    path: entry.path.replace('/input/photos/-', '/isFavourite'),
                    value: true
                };
            } else if (favouriteOnComment && entry.path.endsWith('/input/comment') && entry.op === 'replace' && entry.value !== '') {
                return {
                    op: 'replace',
                    path: entry.path.replace('/input/comment', '/isFavourite'),
                    value: true
                };
            } else if (favouriteOnTask && entry.path.endsWith('/input/taskIds')) {
                return {
                    op: 'replace',
                    path: entry.path.replace('/input/taskIds', '/isFavourite'),
                    value: true
                };
            }
        }
    };
};
