import type {VoidFunctionComponent, ReactElement} from 'react';
import React, {useState} from 'react';
import ChevronDown from '../../../frontend/icons/chevron-down.svg';
import ChevronUp from '../../../frontend/icons/chevron-up.svg';
import {Icoontje} from '../Icoontje';
import {Knopje} from '../Knopje';
import type {TreeGroupProps} from '../TreeGroup';

export type TreeItemProps = {
    children: ReactElement | [ReactElement, ReactElement<TreeGroupProps>] | [ReactElement, null];
};

export type TreeItemComponent = ReactElement<TreeGroupProps>;

export const TreeItem: VoidFunctionComponent<TreeItemProps> = ({children}): TreeItemComponent => {
    const [expanded, setExpanded] = useState(true);

    return (
        <li role="treeitem" className="treeview__item" aria-expanded={expanded}>
            <Knopje
                color="white"
                paint="fill"
                shape="square"
                corners="round"
                size="large"
                className="treeview__toggle"
                onClick={() => setExpanded(!expanded)}
            >
                <Icoontje color="black" Svg={expanded ? ChevronUp : ChevronDown}/>
            </Knopje>
            {children}
        </li>
    );
};
