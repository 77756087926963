import type {InspectionDocument} from '../../../../database';
import type {JsonSchema} from '../../../../database/collections/JsonSchema04';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/inspections';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results assigned by any of the specified user ids.
     */
    assignedByAny?: string[];

    /**
     * Pick results assigned to all specified user ids.
     */
    assignedToAll?: string[];

    /**
     * Pick results assigned to any of the specified user ids.
     */
    assignedToAny?: string[];

    /**
     * @deprecated Not Implemented.
     *
     * Pick results assigned to one of the specified user ids.
     */
    assignedToOne?: string[];

    /**
     * Pick results closed after the specified timestamp.
     */
    closedAfter?: number;

    /**
     * Pick results closed before the specified timestamp.
     */
    closedBefore?: number;

    /**
     * Pick results closed by any of the specified user ids.
     */
    closedByAny?: string[];

    /**
     * Pick results that will close (or have closed) after the specified timestamp.
     */
    closesAfter?: number;

    /**
     * Pick results that will close (or have closed) before the specified timestamp.
     */
    closesBefore?: number;

    /**
     * Pick results conducted after the specified timestamp.
     */
    conductedAfter?: number;

    /**
     * Pick results conducted before the specified timestamp.
     */
    conductedBefore?: number;

    /**
     * Pick results conducted by all specified user ids.
     */
    conductedByAll?: string[];

    /**
     * Pick results conducted by any of the specified user ids.
     */
    conductedByAny?: string[];

    /**
     * @deprecated Not Implemented.
     *
     * Pick results conducted by one of the specified user ids.
     */
    conductedByOne?: string[];

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results created by any of the specified user ids.
     */
    createdByAny?: string[];

    /**
     * Pick results expiring after the specified timestamp.
     */
    expiresAfter?: number;

    /**
     * Pick results expiring before the specified timestamp.
     */
    expiresBefore?: number;

    /**
     * Pick results that have a close date set.
     */
    hasCloseDate?: boolean;

    /**
     * Pick results that have a due date set.
     */
    hasDueDate?: boolean;

    /**
     * Pick results that have an expiry date set.
     */
    hasExpirationDate?: boolean;

    /**
     * Pick results that have an open date set.
     */
    hasOpensDate?: boolean;

    /**
     * Pick results that have at least one connected task.
     */
    hasTasks?: boolean;

    /**
     * Pick results that have been updated at least once.
     */
    hasUpdatedDate?: boolean;

    /**
     * Pick results that have been assigned to at least one user.
     */
    isAssigned?: boolean;

    /**
     * Pick results have been closed.
     */
    isClosed?: boolean;

    /**
     * Pick results have been locked.
     */
    isLocked?: boolean;

    /**
     * Pick results that are overdue.
     */
    isOverdue?: boolean;

    /**
     * Pick results have been removed.
     */
    isRemoved?: boolean;

    /**
     * Pick results that match any of the specified item states.
     */
    itemStateAny?: ('ok' | 'bad' | 'none' | 'empty')[];

    /**
     * Pick results connected to any of the specified location ids.
     */
    locationIdAny?: string[];

    /**
     * Pick results locked after the specified timestamp.
     */
    lockedAfter?: number;

    /**
     * Pick results locked before the specified timestamp.
     */
    lockedBefore?: number;

    /**
     * Pick results locked by any of the specified user ids.
     */
    lockedByAny?: string[];

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Pick results that have been or will be opened after the specified timestamp.
     */
    opensAfter?: number;

    /**
     * Pick results that have been or will be opened before the specified timestamp.
     */
    opensBefore?: number;

    /**
     * Pick results that are or will be overdue after the specified timestamp.
     */
    overdueAfter?: number;

    /**
     * Pick results that are or will be overdue before the specified timestamp.
     */
    overdueBefore?: number;

    /**
     * Pick results removed after the specified timestamp.
     */
    removedAfter?: number;

    /**
     * Pick results removed before the specified timestamp.
     */
    removedBefore?: number;

    /**
     * Pick results removed by any of the specified user ids.
     */
    removedByAny?: string[];

    /**
     * Pick results connected to all specified report layout ids.
     */
    reportLayoutIdAll?: string[];

    /**
     * Pick results connected to any of the specified report layout ids.
     */
    reportLayoutIdAny?: string[];

    /**
     * Pick results connected to one of the specified report layout ids.
     */
    reportLayoutIdOne?: string[];

    /**
     * @deprecated
     */
    reportTemplateIdAll?: string[];

    /**
     * @deprecated
     */
    reportTemplateIdAny?: string[];

    /**
     * @deprecated
     */
    reportTemplateIdOne?: string[];

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'conductedAt' | 'touchedAt' | 'createdAt' | 'updatedAt'}`;

    /**
     * Pick results connected to all specified task ids.
     */
    taskIdAll?: string[];

    /**
     * Pick results connected to any of the specified task ids.
     */
    taskIdAny?: string[];

    /**
     * Pick results connected to one of the specified task ids.
     */
    taskIdOne?: string[];

    /**
     * Pick results connected to any of the specified inspection template ids.
     */
    templateIdAny?: string[];

    /**
     * Pick results touched after the specified timestamp.
     */
    touchedAfter?: number;

    /**
     * Pick results touched before the specified timestamp.
     */
    touchedBefore?: number;

    /**
     * Pick results touched by any of the specified user ids.
     */
    touchedByAny?: string[];

    /**
     * Pick results updated after the specified timestamp.
     */
    updatedAfter?: number;

    /**
     * Pick results updated before the specified timestamp.
     */
    updatedBefore?: number;

    /**
     * Pick results updated by any of the specified user ids.
     */
    updatedByAny?: string[];
};

export const queryTypeDef: TypeDef<QueryType> = {
    optionalProperties: {
        assignedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned by any of the specified user ids.'
            }
        },
        assignedToAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to all specified user ids.'
            }
        },
        assignedToAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results assigned to any of the specified user ids.'
            }
        },
        assignedToOne: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results assigned to one of the specified user ids.
                    
                    Not Implemented.`
            }
        },
        closedAfter: {
            metadata: {
                description: 'Pick results closed after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        closedBefore: {
            metadata: {
                description: 'Pick results closed before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        closedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results closed by any of the specified user ids.'
            }
        },
        closesAfter: {
            metadata: {
                description: 'Pick results that will close (or have closed) after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        closesBefore: {
            metadata: {
                description: 'Pick results that will close (or have closed) before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        conductedAfter: {
            metadata: {
                description: 'Pick results conducted after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        conductedBefore: {
            metadata: {
                description: 'Pick results conducted before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        conductedByAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results conducted by all specified user ids.'
            }
        },
        conductedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results conducted by any of the specified user ids.'
            }
        },
        conductedByOne: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results conducted by one of the specified user ids.
                    
                    Not Implemented.`
            }
        },
        createdAfter: {
            metadata: {
                description: 'Pick results created after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        createdBefore: {
            metadata: {
                description: 'Pick results created before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        createdByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results created by any of the specified user ids.'
            }
        },
        expiresAfter: {
            metadata: {
                description: 'Pick results expiring after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        expiresBefore: {
            metadata: {
                description: 'Pick results expiring before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        hasCloseDate: {
            metadata: {
                description: 'Pick results that have a close date set.'
            },
            type: 'boolean'
        },
        hasDueDate: {
            metadata: {
                description: 'Pick results that have a due date set.'
            },
            type: 'boolean'
        },
        hasExpirationDate: {
            metadata: {
                description: 'Pick results that have an expiry date set.'
            },
            type: 'boolean'
        },
        hasOpensDate: {
            metadata: {
                description: 'Pick results that have an open date set.'
            },
            type: 'boolean'
        },
        hasTasks: {
            metadata: {
                description: 'Pick results that have at least one connected task.'
            },
            type: 'boolean'
        },
        hasUpdatedDate: {
            metadata: {
                description: 'Pick results that have been updated at least once.'
            },
            type: 'boolean'
        },
        isAssigned: {
            metadata: {
                description: 'Pick results that have been assigned to at least one user.'
            },
            type: 'boolean'
        },
        isClosed: {
            metadata: {
                description: 'Pick results have been closed.'
            },
            type: 'boolean'
        },
        isLocked: {
            metadata: {
                description: 'Pick results have been locked.'
            },
            type: 'boolean'
        },
        isOverdue: {
            metadata: {
                description: 'Pick results that are overdue.'
            },
            type: 'boolean'
        },
        isRemoved: {
            metadata: {
                description: 'Pick results have been removed.'
            },
            type: 'boolean'
        },
        itemStateAny: {
            elements: {
                enum: ['ok', 'bad', 'none', 'empty'],
                metadata: {
                    default: 'bad',
                    description: 'Pick results that match any of the specified item states.'
                }
            }
        },
        locationIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified location ids.'
            }
        },
        lockedAfter: {
            metadata: {
                description: 'Pick results locked after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        lockedBefore: {
            metadata: {
                description: 'Pick results locked before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        lockedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results locked by any of the specified user ids.'
            }
        },
        name: {
            metadata: {
                description: 'Pick results that (partially) match the specified name; case-insensitive.'
            },
            type: 'string'
        },
        opensAfter: {
            metadata: {
                description: 'Pick results that have been or will be opened after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        opensBefore: {
            metadata: {
                description: 'Pick results that have been or will be opened before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        overdueAfter: {
            metadata: {
                description: 'Pick results that are or will be overdue after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        overdueBefore: {
            metadata: {
                description: 'Pick results that are or will be overdue before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        removedAfter: {
            metadata: {
                description: 'Pick results removed after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        removedBefore: {
            metadata: {
                description: 'Pick results removed before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        removedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results removed by any of the specified user ids.'
            }
        },
        reportLayoutIdAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to all specified report layout ids.'
            }
        },
        reportLayoutIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified report layout ids.'
            }
        },
        reportLayoutIdOne: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results connected to one of the specified report layout ids.
                    
                    Not Implemented.`
            }
        },
        reportTemplateIdAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results connected to all specified report layout ids.
                                    
                Deprecated: renamed to reportLayoutIdAll`
            }
        },
        reportTemplateIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results connected to any of the specified report layout ids.
                    
                Deprecated: renamed to reportLayoutIdAny`
            }
        },
        reportTemplateIdOne: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results connected to one of the specified report layout ids.
                
                Deprecated: renamed to reportLayoutIdOne`
            }
        },
        sort: {
            enum: ['+touchedAt', '-touchedAt', '+conductedAt', '-conductedAt', '+createdAt', '-createdAt', '+updatedAt', '-updatedAt'],
            metadata: {
                default: '-conductedAt',
                description: 'Sorts results in either ascending (+) or descending order (-) based on the specified property name.'
            }
        },
        taskIdAll: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to all specified task ids.'
            }
        },
        taskIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified task ids.'
            }
        },
        taskIdOne: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: `Pick results connected to one of the specified task ids.
                    
                    Not Implemented.`
            }
        },
        templateIdAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results connected to any of the specified inspection template ids.'
            }
        },
        touchedAfter: {
            metadata: {
                description: 'Pick results touched after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        touchedBefore: {
            metadata: {
                description: 'Pick results touched before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        touchedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results touched by any of the specified user ids.'
            }
        },
        updatedAfter: {
            metadata: {
                description: 'Pick results updated after the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        updatedBefore: {
            metadata: {
                description: 'Pick results updated before the specified timestamp.',
                format: 'int64'
            },
            type: 'float64'
        },
        updatedByAny: {
            elements: {
                type: 'string'
            },
            metadata: {
                description: 'Pick results updated by any of the specified user ids.'
            }
        }
    }
};

export const querySchema: JsonSchema = {
    additionalProperties: false,
    bsonType: 'object',
    properties: {
        assignedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        assignedToAll: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        assignedToAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        assignedToOne: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        closedAfter: {
            bsonType: 'number'
        },
        closedBefore: {
            bsonType: 'number'
        },
        closedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        closesAfter: {
            bsonType: 'number'
        },
        closesBefore: {
            bsonType: 'number'
        },
        conductedAfter: {
            bsonType: 'number'
        },
        conductedBefore: {
            bsonType: 'number'
        },
        conductedByAll: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        conductedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        conductedByOne: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        createdAfter: {
            bsonType: 'number'
        },
        createdBefore: {
            bsonType: 'number'
        },
        createdByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        creatorId: {
            bsonType: 'string'
        },
        creatorIds: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        expiresAfter: {
            bsonType: 'number'
        },
        expiresBefore: {
            bsonType: 'number'
        },
        hasCloseDate: {
            bsonType: 'bool'
        },
        hasDueDate: {
            bsonType: 'bool'
        },
        hasExpirationDate: {
            bsonType: 'bool'
        },
        hasOpensDate: {
            bsonType: 'bool'
        },
        hasTasks: {
            bsonType: 'bool'
        },
        hasUpdatedDate: {
            bsonType: 'bool'
        },
        isAssigned: {
            bsonType: 'bool'
        },
        isClosed: {
            bsonType: 'bool'
        },
        isLocked: {
            bsonType: 'bool'
        },
        isOverdue: {
            bsonType: 'bool'
        },
        isRemoved: {
            bsonType: 'bool'
        },
        itemStateAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string',
                enum: ['ok', 'bad', 'none', 'empty']
            }
        },
        locationId: {
            bsonType: 'string'
        },
        locationIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        locationIds: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        lockedAfter: {
            bsonType: 'number'
        },
        lockedBefore: {
            bsonType: 'number'
        },
        lockedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        name: {
            bsonType: 'string'
        },
        opensAfter: {
            bsonType: 'number'
        },
        opensBefore: {
            bsonType: 'number'
        },
        overdueAfter: {
            bsonType: 'number'
        },
        overdueBefore: {
            bsonType: 'number'
        },
        removedAfter: {
            bsonType: 'number'
        },
        removedBefore: {
            bsonType: 'number'
        },
        removedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        reportTemplateIdAll: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        reportTemplateIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        reportTemplateIdOne: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        sort: {
            bsonType: 'string',
            enum: ['+touchedAt', '-touchedAt', '+conductedAt', '-conductedAt', '+createdAt', '-createdAt', '+updatedAt', '-updatedAt']
        },
        taskIdAll: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        taskIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        taskIdOne: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        templateId: {
            bsonType: 'string'
        },
        templateIdAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        templateIds: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        touchedAfter: {
            bsonType: 'number'
        },
        touchedBefore: {
            bsonType: 'number'
        },
        touchedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        },
        updatedAfter: {
            bsonType: 'number'
        },
        updatedBefore: {
            bsonType: 'number'
        },
        updatedByAny: {
            bsonType: 'array',
            items: {
                bsonType: 'string'
            }
        }
    }
};

export type OutputType = InspectionDocument[];
