import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {__} from '../../../translations';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';
import {RadioButton} from '../toggles/RadioButton';

type SlideoutComponent = ReactElement;

export type taskStateSlideoutProps = {
    taskState: string;
    setTaskState: (taskState) => void;
};

export const TaskStateSlideout: VoidFunctionComponent<taskStateSlideoutProps> = ({
    taskState,
    setTaskState
}): SlideoutComponent => (
    <Slideout>
        <Tekst weight="bold" className="slideout__title">
            {__('Filter task state')}
        </Tekst>

        <div className="slideout__list">
            <RadioButton onChange={() => setTaskState('all')} checked={taskState === 'all' ? 'checked' : 'unchecked'}>
                <Tekst>{__('All tasks')}</Tekst>
            </RadioButton>
            <RadioButton onChange={(value) => setTaskState(value === 'checked' ? 'open' : 'all')} checked={taskState === 'open' ? 'checked' : 'unchecked'}>
                <Tekst>{__('Open tasks')}</Tekst>
            </RadioButton>
            <RadioButton onChange={(value) => setTaskState(value === 'checked' ? 'submitted' : 'all')} checked={taskState === 'submitted' ? 'checked' : 'unchecked'}>
                <Tekst>{__('Tasks pending approval')}</Tekst>
            </RadioButton>
            <RadioButton onChange={(value) => setTaskState(value === 'checked' ? 'done' : 'all')} checked={taskState === 'done' ? 'checked' : 'unchecked'}>
                <Tekst>{__('Finished tasks')}</Tekst>
            </RadioButton>
        </div>
    </Slideout>
);

