import type {InspectionPlanDocument} from '../../../../../database';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/inspection-plannings/:inspectionPlanningId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    inspectionPlanningId: string;
};

export type QueryType = never;

export type OutputType = InspectionPlanDocument;
