import type {ReactElement, VoidFunctionComponent} from 'react';
import React, {useState} from 'react';
import {List} from '../../../frontend/components/List';
import {ListItem} from '../../../frontend/components/ListItem';
import {User} from '../../../frontend/components/User';
import PlusIcon from '../../../frontend/icons/plus.svg';
import {__} from '../../../translations';
import {usePermissions, useUrlStateArray, useUsers} from '../../hooks';
import {Icoontje} from '../Icoontje';
import {Knopje} from '../Knopje';
import {SearchBar} from '../SearchBar';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';
import {Checkbox} from '../toggles/Checkbox';

export type TeammemberSlideoutProps = {
    show: 'all' | 'assigned';
    headerText?: string;
    subText?: string;
    userIds?: string[];
    setUserIds?: (userIds) => void;
    onCheckboxChange?: (userId: string, value: boolean) => void;
    urlName?: string;
};

export type SlideoutComponent = ReactElement<TeammemberSlideoutProps>;

export const TeamMemberSlideout: VoidFunctionComponent<TeammemberSlideoutProps> = ({
    subText,
    headerText,
    show,
    userIds,
    setUserIds,
    onCheckboxChange = (_id, value) => {
        if (value) {
                setUserIds!([...userIds!, _id]);
        } else {
            const filtered = userIds!.filter((userId) => userId !== _id);
                setUserIds!(filtered);
        }
    }, urlName = 'userIds'
}): SlideoutComponent => {
    const {canCreateTemplates} = usePermissions();
    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchChange = (event) => {
        setSearchValue(event.currentTarget.value);
    };
    if (!userIds || !setUserIds) {
        [userIds, setUserIds] = useUrlStateArray(urlName, []);
    }
    const {users} = useUsers({
        search: searchValue || undefined
    });
    return (
        <Slideout onReset={() => setUserIds!([])}>
            <div className="slideout__search">
                <SearchBar
                    placeholder={__('Search team members')}
                    description={__('Search for team members')}
                    className="slideout__search"
                    value={searchValue}
                    onChange={onSearchChange}
                />
                {canCreateTemplates
                    ? (
                        <><Knopje className="invite-team-member" type="anchor" to="/team-members/create">
                            <Tekst color="blue" className="invite-team-member__text">{__('Invite team members')}</Tekst>
                            <Icoontje color="blue" size="mini" Svg={PlusIcon}/>
                        </Knopje>
                        <hr className="horizontal-rule"/></>
                    )
                    : null}
            </div>
            <Tekst weight="bold" className="slideout__title">
                {headerText ?? __('Filter team members')}
            </Tekst>
            {subText
                ? (
                    <Tekst className="slideout__subtitle" color="gray" style="italic" size="small">
                        {subText}
                    </Tekst>
                )
                : null}
            {!headerText
                ? (
                    <Knopje color="white" paint="fill" type="reset" className="slideout__clear">
                        <Tekst color="blue">
                            {__('Clear all')}
                        </Tekst>
                    </Knopje>
                )
                : <div/>}
            <div className="slideout__list">
                <List>
                    {users.map(({_id, email, name}) => {
                        const selected = !!userIds!.find((userId) => userId === _id);
                        return (
                            // eslint-disable-next-line react/jsx-no-useless-fragment
                            <>
                                {show === 'all'
                                    ? (
                                        <ListItem key={_id}>
                                            <Checkbox
                                                checked={selected ? 'checked' : 'unchecked'}
                                                onChange={(value) => onCheckboxChange(_id, value === 'checked')}
                                            >
                                                <User
                                                    id={_id}
                                                    email={email}
                                                    name={name}
                                                />
                                            </Checkbox>
                                        </ListItem>
                                    )
                                    : selected
                                        ? (
                                            <ListItem key={_id}>
                                                <User id={_id} email={email} name={name}/>
                                            </ListItem>
                                        )
                                        : null}
                            </>
                        );
                    })}
                </List>
            </div>
        </Slideout>
    );
};
