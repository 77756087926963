import type {LocationDocument} from '../../../../database';
import {entityTypeDef} from '../../../../database/collections/entities/entityTypeDef';
import type {TypeDef} from '../../../../interfaces/models/json-type-definition';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/locations';

export const method = HttpMethod.POST;

export type BodyType =
    Omit<LocationDocument, 'name' | 'parentId' | '_id' | '_deleted' | 'expiresAt' | 'apps' | 'branch' | 'classicLocationId' | 'classicParentId' | 'code' | 'createdAt' | 'createdBy' | 'creator' | 'device' | 'exclusiveLibrary' | 'modified' | 'owner' | 'photoSettings' | 'removed' | 'removedAt' | 'removedBy' | 'resellerId' | 'token' | 'touchedAt' | 'touchedBy' | 'type' | 'uid' | 'updated' | 'updatedAt' | 'updatedBy' | 'version'>
    & {
    name: string;
    parentId: string;
};

export const bodyTypeDef: TypeDef<BodyType> = ((): TypeDef<BodyType> => {
    const {
        _deleted,
        expiresAt,
        parentId,
        apps,
        branch,
        classicLocationId,
        classicParentId,
        code,
        createdAt,
        createdBy,
        creator,
        device,
        exclusiveLibrary,
        modified,
        owner,
        photoSettings,
        removed,
        removedAt,
        removedBy,
        resellerId,
        token,
        touchedAt,
        touchedBy,
        type,
        uid,
        updated,
        updatedAt,
        updatedBy,
        version,
        ...optionalProperties
    } = entityTypeDef.optionalProperties;

    return {
        additionalProperties: true,
        optionalProperties,
        properties: {
            name: {
                type: 'string'
            },
            parentId: {
                type: 'string'
            }
        }
    };
})();

export type ParamType = never;

export type QueryType = never;

export type OutputType = LocationDocument;
