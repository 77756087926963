import {querySchema as inspectionQuerySchema} from '../../../api/endpoints/inspections/get/shared';
import {querySchema as taskQuerySchema} from '../../../api/endpoints/tasks/get/shared';
import {inspectionsSchema} from '../inspections';
import type {JsonSchemaQuery} from '../JsonSchema04';
import {tasksSchema} from '../tasks';

export const chartSegmentsSchema: JsonSchemaQuery = {
    $jsonSchema: {
        allOf: [{
            additionalProperties: false,
            bsonType: 'object',
            properties: {
                _id: {
                    bsonType: 'objectId'
                },
                chart_id: {
                    bsonType: 'objectId'
                },
                collection: {},
                colors: {
                    bsonType: 'array',
                    items: {
                        bsonType: 'string'
                    }
                },
                createdAt: {
                    bsonType: 'number'
                },
                createdBy: {
                    bsonType: 'objectId'
                },
                entity_id: {
                    bsonType: 'objectId'
                },
                field: {},
                filter: {},
                method: {
                    bsonType: 'string',
                    enum: ['add', 'average']
                },
                name: {
                    bsonType: 'string'
                },
                offline_id: {
                    bsonType: 'string'
                },
                removed: {
                    bsonType: 'number'
                },
                removedAt: {
                    bsonType: 'number'
                },
                removedBy: {
                    bsonType: 'objectId'
                },
                touchedAt: {
                    bsonType: 'number'
                },
                touchedBy: {
                    bsonType: 'objectId'
                },
                type: {
                    bsonType: 'string',
                    enum: ['line', 'bar', 'area']
                },
                updatedAt: {
                    bsonType: 'number'
                },
                updatedBy: {
                    bsonType: 'objectId'
                }
            },
            required: [
                '_id',
                'chart_id',
                'collection',
                'colors',
                'createdAt',
                'createdBy',
                'entity_id',
                'field',
                'filter',
                'method',
                'name',
                'removed',
                'touchedAt',
                'touchedBy',
                'type'
            ]
        }, {
            anyOf: [{
                additionalProperties: true,
                bsonType: 'object',
                properties: {
                    collection: {
                        bsonType: 'string',
                        enum: ['inspections']
                    },
                    field: {
                        bsonType: 'string',
                        enum: [
                            'score',
                            'total',
                            'incorrect-item-count',
                            ...('properties' in inspectionsSchema.$jsonSchema && inspectionsSchema.$jsonSchema.properties
                                ? Object.keys(inspectionsSchema.$jsonSchema.properties)
                                : []
                            )
                        ]
                    },
                    filter: inspectionQuerySchema
                }
            }, {
                additionalProperties: true,
                bsonType: 'object',
                properties: {
                    collection: {
                        bsonType: 'string',
                        enum: ['tasks']
                    },
                    field: {
                        bsonType: 'string',
                        enum: [
                            ...('properties' in tasksSchema.$jsonSchema && tasksSchema.$jsonSchema.properties
                                ? Object.keys(tasksSchema.$jsonSchema.properties)
                                : []
                            )
                        ]
                    },
                    filter: taskQuerySchema
                }
            }]
        }]
    }
};
