import {useLiveQuery} from 'dexie-react-hooks';
import type {MetricKey, MetricsRecord, ReviewsRecord} from '../../dexie';
import {dexie, getTrackedUser, trackMetric} from '../../dexie';
import {useCurrentSession} from '../context';

export const useMetrics = (): {
    review: ReviewsRecord | null;
    metrics: MetricsRecord | null;
    trackMetric: (metric: MetricKey) => void;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id} = currentSession ?? {};
    const uid = user_id ?? getTrackedUser();

    const {review, metrics} = useLiveQuery(async () => ({
        review: (uid && await dexie.reviews.get(uid)) || null,
        metrics: (uid && await dexie.metrics.get(uid)) || null
    }), [uid], {
        review: null,
        metrics: null
    });

    return {
        review,
        metrics,
        trackMetric: (metric: MetricKey) => {
            uid && trackMetric(uid, metric);
        }
    };
};
