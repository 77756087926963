import type {ReportLayoutDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/report-layouts/:reportLayoutId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    reportLayoutId: string;
};

export type QueryType = never;

export type OutputType = ReportLayoutDocument;
