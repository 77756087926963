import type {FunctionComponent} from 'react';
import {useEffect} from 'react';
import type {MetricKey} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import {useCurrentUser} from '../context';

declare const window: {
    dataLayer?: {
        push: (details: {
            event: `ce-${MetricKey}`;
            [key: string]: string | number | boolean;
        }) => boolean;
    };
};

export const GoogleTagManager: FunctionComponent = () => {
    const {isCheckbuster} = useCurrentUser();
    useEffect(() => isCheckbuster
        ? undefined
        : addEventListener('update', (event) => {
            const {table: tableName, mods} = event.detail;

            if (tableName !== 'metrics' || !window.dataLayer || document.hidden) {
                return;
            }

            for (const [key, value] of Object.entries(mods)) {
                if (key === 'userId' || typeof value !== 'number') {
                    return;
                }

                window.dataLayer.push({event: `ce-${key as MetricKey}`, count: value});
            }
        }), [isCheckbuster]);

    return null;
};
