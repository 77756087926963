declare const window: {
    webkit?: {
        messageHandlers?: {
            saveFile?: {
                postMessage: (message: {
                    name: string;
                    dataArray: number[];
                }) => void;
            };
        };
    };
    android?: {
        saveFile: (message: string) => void;
    };
};

const saveFileBrowser = (name: string, blob: Blob): void => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.style.display = 'none';
    link.download = name;
    document.body.append(link);
    link.click();
    URL.revokeObjectURL(url);
    link.remove();
};

const saveFileIos = (name: string, dataArray: number[]): void => {
    window.webkit?.messageHandlers?.saveFile?.postMessage({
        name,
        dataArray
    });
};

const saveFileAndroid = (name: string, dataArray: number[]): void => {
    window?.android?.saveFile(JSON.stringify({
        name,
        dataArray
    }));
};

export const saveFile = (name: string, buffer: ArrayBuffer | Uint8Array, fallback?: (name: string, buffer: ArrayBuffer | Uint8Array) => void): void => {
    if (window.webkit?.messageHandlers?.saveFile?.postMessage) {
        return saveFileIos(name, Array.from(new Uint8Array(buffer)));
    } else if (window.android?.saveFile) {
        return saveFileAndroid(name, Array.from(new Uint8Array(buffer)));
    }

    saveFileBrowser(name, new Blob([buffer]));
};
