import {fetchApi} from '../../../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export * from './shared';

export const getLibraryItem = async (urlParams: ParamType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        urlParams
    });

    return result;
};
