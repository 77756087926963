import {dexie} from '../../../../../dexie';
import {fetchApi} from '../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export const updateLocation = (urlParams: ParamType, body: BodyType) => fetchApi<BodyType, OutputType, ParamType, QueryType>({
    body,
    method,
    path,
    urlParams
}).then(async (result) => {
    result.response.ok && result.json && await dexie.entities.put(result.json);
    return result;
});
