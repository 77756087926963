import {useLiveQuery} from 'dexie-react-hooks';
import type {FunctionComponent, ReactNode} from 'react';
import React, {useEffect, useMemo, useState} from 'react';
import {getLocations} from '../../../api/endpoints/locations/get/frontend';
import {getMemberships} from '../../../api/endpoints/memberships/get/frontend';
import {getReportLayouts} from '../../../api/endpoints/report-templates/get/frontend';
import {getSessions} from '../../../api/endpoints/sessies/get/frontend';
import {getUsers} from '../../../api/endpoints/users/get/frontend';
import {dexie} from '../../../dexie';
import {noop} from '../../../util/functions';
import {useVisibilityState} from '../../hooks';
import {useReadyState} from '../../hooks/useReadyState';
import type {CurrentSessionContext} from './CurrentSessionContext';
import {currentSessionContext} from './CurrentSessionContext';

export const CurrentSessionProvider: FunctionComponent<{
    children: ReactNode;
}> = ({children}) => {
    const visibilityState = useVisibilityState();
    const [dexieActive, setDexieActive] = useState(true);
    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive, fetchActive});

    const currentSession = useLiveQuery(async () => {
        setDexieActive(true);

        const result = await dexie.sessions
            .where('token').above('0')
            .first();

        setDexieActive(false);

        return result;
    }, []) ?? null;

    useEffect(() => {
        setFetchActive(true);
        getSessions({current: true})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [visibilityState]);

    useEffect(() => {
        if (!currentSession) {
            return;
        }

        getUsers({}).catch(noop);
        getLocations({}).catch(noop);
        getMemberships({}).catch(noop);
        getReportLayouts({}).catch(noop);
    }, [currentSession?._id, visibilityState]);

    const value = useMemo<CurrentSessionContext>(() => ({
        ready,
        currentSession
    }), [ready, currentSession?.user_id, currentSession?.token]);

    return (
        <currentSessionContext.Provider value={value}>
            {children}
        </currentSessionContext.Provider>
    );
};
