import type {JsonSchemaObject, JsonSchemaSplit} from '../../collections/JsonSchema04';
import {notPresent} from '../../collections/JsonSchema04';

export const removableSchemaProperties: JsonSchemaObject['properties'] = {
    removed: {
        bsonType: 'number',
        description: 'A single bit used in place of a boolean to allow indexing by IndexedDB; `0` if not removed and `1` if removed',
        maximum: 1,
        minimum: 0
    },
    removedAt: {
        bsonType: 'number',
        description: 'When `removed`, stores the time in milliseconds since epoch of when this document was removed',
        minimum: 0
    },
    removedBy: {
        bsonType: 'objectId',
        description: 'When `removed`, stores the `_id` of the team member who has removed this document'
    }
};

export const removableSchemaVariations: JsonSchemaSplit = {
    oneOf: [{
        bsonType: 'object',
        properties: {
            removed: {
                ...removableSchemaProperties.removed,
                maximum: 0
            },
            removedAt: notPresent,
            removedBy: notPresent
        },
        required: [
            'removed'
        ]
    }, {
        bsonType: 'object',
        properties: {
            removed: {
                ...removableSchemaProperties.removed,
                minimum: 1
            },
            removedAt: removableSchemaProperties.removedAt,
            removedBy: removableSchemaProperties.removedBy
        },
        required: [
            'removed',
            'removedAt',
            'removedBy'
        ]
    }]
};
