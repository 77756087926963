import {dexie} from '../../../../dexie';
import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path} from './shared';

export const createSession = async (body: BodyType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body,
        method,
        path
    });

    const {response, json} = result;
    if (response.ok && json) {
        await dexie.transaction('readwrite', dexie.sessions, async () => {
            await dexie.sessions.where('token').above('0').modify((session) => {
                delete session.token;
            });

            await dexie.sessions.put(json);
        });
    }

    return result;
};
