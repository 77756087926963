import type {ChangeEvent, FunctionComponent} from 'react';
import React from 'react';

import './Select.scss';

export const Select: FunctionComponent<{
    value: string[];
    multi?: boolean;
    name: string;
    className?: string;
    placeholder?: string;
    disabled?: boolean;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => unknown | Promise<unknown>;
}> = ({
    name,
    value,
    multi,
    placeholder,
    disabled,
    onChange,
    children,
    ...rest
}) => (
    <label htmlFor={name} className="select" {...rest}>
        <select
            disabled={disabled}
            value={multi ? value : value[0] ? value[0] : ''}
            multiple={!!multi}
            aria-label="select"
            className="select__input"
            name={name}
            onChange={onChange}
            data-testid="select_input"
        >
            {placeholder ? <option className="select__placeholder" value="">{placeholder}</option> : null}
            {children}
        </select>
    </label>
);
export const SelectOption: FunctionComponent<{
    value: string;
    text?: string;
    disabled?: boolean;
    selected?: boolean;
}> = ({
    value,
    text,
    disabled,
    children,
    ...rest
}) => (
    <option value={value} disabled={disabled} {...rest}>
        {text ?? value}
    </option>
);

