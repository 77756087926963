import type {MagicLinkDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/magic-links';

export const method = HttpMethod.POST;

export type BodyType = {
    email: string;
    language?: string;
};

export type ParamType = never;

export type QueryType = never;

export type OutputType = Omit<MagicLinkDocument, 'magicKey' | 'magicPassword'>;
