import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {
    getExclusiveLibraryItem
} from '../../api/endpoints/exclusive-libraries/[exclusiveLibraryId]/library-items/[exclusiveLibraryItemId]/get/frontend';
import type {ExclusiveLibraryTemplateDocument} from '../../database';
import type {JsonOf} from '../../interfaces/helpers';
import {noop} from '../../util/functions';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useExclusiveLibraryItem = (libraryId: string, libraryItemId: string): {
    ready: boolean;
    fetchActive: boolean;
    libraryItem: JsonOf<ExclusiveLibraryTemplateDocument> | null;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId} = currentSession ?? {};
    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive: true, fetchActive});
    const [visibilityState, setVisibilityState] = useState(0);
    const [libraryItem, setLibraryItem] = useState<JsonOf<ExclusiveLibraryTemplateDocument> | null>(null);
    useEffect(() => {
        if (!userId || !libraryId || !libraryItemId) {
            return;
        }

        let aborted = false;

        setFetchActive(true);
        getExclusiveLibraryItem({
            exclusiveLibraryId: libraryId,
            exclusiveLibraryItemId: libraryItemId
        })
            .then(({json, response}) => {
                !aborted && setLibraryItem(response.ok && json ? json as JsonOf<ExclusiveLibraryTemplateDocument> : null);
            })
            .catch((error) => {
                captureException(error);
                !aborted && setLibraryItem(null);
            })
            .then(() => setFetchActive(false), noop);

        return () => {
            aborted = true;
        };
    }, [userId, visibilityState]);
    useEffect(() => {
        if (!libraryItemId) {
            return;
        }

        setFetchActive(true);
        getExclusiveLibraryItem({exclusiveLibraryId: libraryId, exclusiveLibraryItemId: libraryItemId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [libraryItemId, visibilityState]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    return {
        ready,
        fetchActive,
        libraryItem
    };
};
