import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect} from 'react';
import type {ReviewsRecord} from '../../dexie';
import {calculateUsageScore, dexie} from '../../dexie';
import {canRequestReview, requestReview} from '../../frontend/native-hooks/requestReview';
import {reviewConfig} from '../../helpers/variables';
import {useCurrentUser} from '../context';
import {useMetrics} from './useMetrics';

export const useReviewPopup = () => {
    const {currentUser: authenticatedUser, isCheckbuster} = useCurrentUser();
    const {metrics} = useMetrics();

    const review = useLiveQuery<ReviewsRecord | undefined>(async () => {
        if (!authenticatedUser?._id) {
            return;
        }

        const fallbackReviewDocument = {
            userId: authenticatedUser._id,
            attemptedCount: 0,
            lastAttemptedAt: Date.now()
        };

        const reviewDocument = await dexie.reviews.get(authenticatedUser._id);

        if (reviewDocument) {
            return reviewDocument;
        }

        await dexie.reviews.put(fallbackReviewDocument);

        return fallbackReviewDocument;
    }, [authenticatedUser?._id]);

    useEffect(() => {
        if (!metrics || !review) {
            return;
        }

        const {userId, attemptedCount, lastAttemptedAt} = review;

        const minimumDaysBetweenPopup = reviewConfig.minimumDaysBetweenPopups[attemptedCount] ?? reviewConfig.minimumDaysBetweenPopups[reviewConfig.minimumUsageScores.length - 1];
        if (lastAttemptedAt + minimumDaysBetweenPopup * 24 * 60 * 60 * 1000 >= Date.now()) {
            return;
        }

        const score = calculateUsageScore(metrics);

        const minimumUsageScore = reviewConfig.minimumUsageScores[attemptedCount] ?? reviewConfig.minimumUsageScores[reviewConfig.minimumUsageScores.length - 1];
        if (score < minimumUsageScore) {
            return;
        }

        canRequestReview() && requestReview(isCheckbuster)
            .then(() => dexie.reviews.put({
                userId,
                attemptedCount: attemptedCount + 1,
                lastAttemptedAt: Date.now()
            }))
            .catch(console.warn);
    }, [metrics, review]);
};
