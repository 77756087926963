import {captureException} from '@sentry/react';
import type {InspectionDocument} from '../../../../database';
import {dexie} from '../../../../dexie';
import type {JsonOf} from '../../../../interfaces/helpers';
import {normalizeInspectionSection} from '../../../../types';
import {emptyArray} from '../../../../util/arrays';
import {ajv} from '../../../helpers/ajv/frontend';
import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path, bodyTypeDef} from './shared';

const bodyTypeDefClean: typeof bodyTypeDef = {
    ...bodyTypeDef,
    additionalProperties: false
};

const bodyValidator = ajv.compile(bodyTypeDefClean);

export const createInspection = (input) => {
    // TODO remove this later
    const cleanInput = {
        ...input,
        closed: input.closed ?? input.isDone ? 1 : 0,
        conclusion: input.conclusion ?? '',
        createdAt: input.created ?? input.updated ?? input.touchedAt ?? Date.now(),
        createdBy: input.creator ?? '0'.repeat(24),
        locked: input.locked ?? 0,
        removed: input.removed ?? 0,
        reportTemplateIds: input.reportTemplateIds ?? emptyArray,
        sections: input.sections.map((section) => normalizeInspectionSection(section)),
        tags: input.tags ?? emptyArray,
        taskIds: input.taskIds ?? emptyArray,
        touchedAt: input.touchedAt ?? input.updated ?? Date.now(),
        touchedBy: input.touchedBy ?? input.creator ?? '0'.repeat(24),
        users: input.users ?? input.creator ? [input.creator] : emptyArray
    };
    const inputCopy = JSON.parse(JSON.stringify(cleanInput));
    const validBody = bodyValidator(inputCopy);

    if (!validBody) {
        console.error(bodyValidator.errors);
        const error = new Error(`${method} ${path} failed output integrity check`);
        error.name = 'ValidationError';
        captureException(error, {
            extra: {
                errors: bodyValidator.errors
            }
        });
        throw error;
    }

    return fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body: inputCopy,
        method,
        path
    }).then(async (result) => {
        result.response.ok && result.json && await dexie.inspections.put(result.json as unknown as JsonOf<InspectionDocument>);
        return result;
    });
};
