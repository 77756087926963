import type {JsonSchemaObject} from '../../../interfaces/models/json-schema-draft-04';
import {inspectionItemSchema} from '../inspection-items/inspectionItemSchema';

export const inspectionSectionSchema: JsonSchemaObject = {
    additionalProperties: false,
    bsonType: 'object',
    properties: {
        addedAt: {
            bsonType: 'number'
        },
        addedBy: {
            bsonType: 'string'
        },
        copy: {
            bsonType: 'bool'
        },
        data: {
            bsonType: 'array',
            items: {
                oneOf: [inspectionItemSchema, {
                    bsonType: 'null'
                }]
            }
        },
        id: {
            bsonType: 'number'
        },
        text: {
            bsonType: 'string'
        }
    },
    required: [
        'copy',
        'data',
        'id',
        'text'
    ]
} as const;
