import type {ChangeEvent, FunctionComponent, ReactNode} from 'react';
import React, {useRef, useState, useEffect} from 'react';
import {ripple} from '../../utilities/animations';
import './RadioButton.scss';

export type RadioButtonValue = string;

type Awaitable<T> = T | Promise<T>;

export type RadioButtonProps = {
    value: RadioButtonValue;
    label: string | ReactNode;
    labelPosition?: 'left' | 'right';
    onChange: (value: RadioButtonValue, event: ChangeEvent<HTMLInputElement>) => Awaitable<void>;
    className?: string;
    checked: boolean;
    required?: boolean;
    disabled?: boolean;
    name?: string;
};

export const RadioButton: FunctionComponent<RadioButtonProps> = ({
    value,
    label,
    onChange,
    checked,
    className = '',
    labelPosition = 'right',
    ...props
}) => {
    const ref = useRef<HTMLInputElement>(null);
    const [busy, setBusy] = useState(false);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => console.warn('RadioButton has been deprecated; please use /src/react/components/toggles/RadioButton instead!'), []);
    }

    async function handleChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (busy) {
            event.preventDefault();
            return;
        }

        try {
            setBusy(true);
            await onChange(event.target.value, event);
        } catch (error) {
            console.error(error);
        } finally {
            setBusy(false);
        }
    }

    return (
        <label className={`radio radio--label-${labelPosition} ${className}`.trim()} onClick={busy ? undefined : ripple}>
            {labelPosition === 'left' ? label : null}
            <input
                {...props}
                role="radio"
                onChange={handleChange}
                type="radio"
                value={value}
                checked={checked}
                aria-checked={checked}
                ref={ref}
            />
            {labelPosition === 'right' ? label : null}
        </label>
    );
};
