import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import './ScreenReader.scss';

export type ScreenReaderProps = {
    message: string;
};

export type ScreenReaderComponent = ReactElement<ScreenReaderProps>;

export const ScreenReader: VoidFunctionComponent<ScreenReaderProps> = ({
    message
}): ScreenReaderComponent => (
    <span className="sr-only" aria-live="assertive">
        {message || null}
    </span>
);
