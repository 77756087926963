import {createContext} from 'react';
import type {PublicSessionDocument} from '../../../database';
import type {JsonOf} from '../../../interfaces/helpers';

export type CurrentSessionContext = {
    currentSession: JsonOf<PublicSessionDocument> | null;
    ready: boolean;
};

export const currentSessionContext = createContext<CurrentSessionContext>({
    currentSession: null,
    ready: false
});
