import type {Request} from 'express';
import {getCookie} from '../frontend/utilities/storage';

export type Locale = {
    name: string;
    iso: string;
};

export const locales: Locale[] = [
    {name: 'English', iso: 'en'},
    {name: 'Nederlands', iso: 'nl'},
    {name: 'Deutsch', iso: 'de'},
    {name: 'Français', iso: 'fr'},
    {name: 'Español', iso: 'es'},
    {name: 'Italiano', iso: 'it'},
    {name: 'Український', iso: 'uk-UA'},
    {name: '中国人 (简体字)', iso: 'zh-CN'},
    {name: '日本', iso: 'ja'},
    {name: 'Tiếng Việt', iso: 'vi-VN'},
    {name: 'Português', iso: 'pt'},
    {name: 'עִברִית', iso: 'he-IL'},
    {name: 'हिन्दी', iso: 'hi-IN'}
];

export type LocaleIso =
    'de'
    | 'en'
    | 'es'
    | 'fr'
    | 'he-IL'
    | 'hi-IN'
    | 'it'
    | 'ja'
    | 'nl'
    | 'pt'
    | 'uk-UA'
    | 'vi-VN'
    | 'zh-CN';

export const defaultLocale = locales[0];

export const localeNames: string[] = locales.map(({name}) => name);

export const localeIsos: string[] = locales.map(({iso}) => iso);

export const getLocale = (iso: string): Locale | undefined => {
    const locale = locales.find((locale) => locale.iso === iso);

    if (!locale && iso.includes('-')) {
        const singleLocale = getLocale(iso.split('-')[0]);

        return singleLocale && {
            name: singleLocale.name,
            iso: `${singleLocale.iso}-${singleLocale.iso.toLocaleUpperCase()}`
        };
    }

    return locale;
};

export const currentLocale: Locale | undefined = typeof location !== 'undefined'
    ? (/^\/(?:__|pf)\//).test(location.pathname) ? getLocale(getCookie('iso') ?? 'en') : getLocale((/^\/?([a-z]{2}(?:-[A-Z]{2})?)(?:\/|$)/).exec(location.pathname)?.[1] ?? 'en') ?? defaultLocale
    : undefined;

export const getPreferredLocale = (req: Request): Locale => {
    const {iso} = req.cookies as {
        iso?: string;
    };

    if (iso) {
        const locale = getLocale(iso);

        if (locale) {
            return locale;
        }
    }

    const preferredIso = req.acceptsLanguages(localeIsos);

    if (preferredIso) {
        const locale = getLocale(preferredIso);

        if (locale) {
            return locale;
        }
    }

    return locales[0];
};
