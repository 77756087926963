import {captureException} from '@sentry/react';
import type {JsonOf} from '../../../../interfaces/helpers';
import {__} from '../../../../translations';
import {ajv} from '../../../helpers/ajv/frontend';
import {fetchApi} from '../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path, bodyTypeDef} from './shared';

const bodyValidator = ajv.compile(bodyTypeDef);

export const createUser = (input: JsonOf<BodyType>) => {
    const inputCopy = JSON.parse(JSON.stringify(input)) as JsonOf<BodyType>;
    const validBody = bodyValidator(inputCopy);

    if (!validBody) {
        console.error(bodyValidator.errors);
        const error = new Error(`${method} ${path} failed output integrity check`);
        error.name = 'ValidationError';
        captureException(error, {
            extra: {
                errors: bodyValidator.errors
            }
        });
        throw error;
    }

    return fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body: {
            language: 'en',
            organisationName: __('My Organisation'),
            userName: (/^(.+?)@/).exec(inputCopy.email)?.[1] || __('Me'),
            ...inputCopy
        },
        method,
        path
    });
};
