import {captureException} from '@sentry/react';
import {useLiveQuery} from 'dexie-react-hooks';
import {useEffect, useMemo, useState} from 'react';
import {getInspectionPlanning} from '../../api/endpoints/inspection-plannings/[inspectionPlanningId]/get/frontend';
import type {InspectionPlanDocument} from '../../database';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonOperation, JsonPatch} from '../../types/JsonPatch';
import {applyJsonPatch} from '../../types/JsonPatch';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useInspectionPlanning = (inspectionPlanningId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    inspectionPlanning: JsonOf<InspectionPlanDocument> | null;
    updateInspectionPlanning: (patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [dexieActive, setDexieActive] = useState(true);
    const {ready} = useReadyState({dexieActive, fetchActive});
    const [visibilityState, setVisibilityState] = useState(0);

    const offline = useLiveQuery<JsonOf<InspectionPlanDocument> | undefined>(async () => {
        if (!inspectionPlanningId) {
            return;
        }

        setDexieActive(true);
        const result = await dexie.inspectionPlannings
            .where('_id').equals(inspectionPlanningId)
            .or('offline_id').equals(inspectionPlanningId)
            .first();
        setDexieActive(false);

        return result;
    }, [inspectionPlanningId]) ?? null;

    const creation = useLiveQuery<QueueCreateEntry | undefined>(() => {
        if (!inspectionPlanningId) {
            return;
        }

        return dexie.queue
            .where(['resourceId', 'action']).equals([inspectionPlanningId, 'create'])
            .first() as Promise<QueueCreateEntry | undefined>;
    }, [inspectionPlanningId])?.body as JsonOf<InspectionPlanDocument> | null ?? null;

    const patches = useLiveQuery<JsonOperation[]>(async () => {
        if (!inspectionPlanningId) {
            return [];
        }

        const bloated = await dexie.queue
            .where(['resourceId', 'action']).equals([inspectionPlanningId, 'update'])
            .toArray() as QueueUpdateEntry[];

        return bloated
            .map(({patch}) => patch)
            .flat(2)
            .filter(({op}) => op !== 'test');
    }, [inspectionPlanningId]) ?? null;

    const inspectionPlanning = useMemo<JsonOf<InspectionPlanDocument> | null>(() => {
        const base = offline ?? creation;

        if (!base || !patches?.length) {
            return base;
        }

        return applyJsonPatch<JsonOf<InspectionPlanDocument>>(base, patches);
    }, [offline, creation, patches]);

    useEffect(() => {
        if (!userId || !inspectionPlanningId) {
            return;
        }

        setFetchActive(true);
        getInspectionPlanning({inspectionPlanningId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState, inspectionPlanningId]);

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    return {
        ready,
        dexieActive,
        fetchActive,
        inspectionPlanning,
        updateInspectionPlanning: async (patch: JsonPatch) => {
            if (!userId || !entityId || !inspectionPlanningId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'inspectionPlannings',
                    resourceId: inspectionPlanningId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
