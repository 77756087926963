import type {FunctionComponent, ImgHTMLAttributes} from 'react';
import React, {useEffect, useState} from 'react';
import Logo from '../../../frontend/icons/Logo.png';
import {ripple} from '../../../frontend/utilities/animations';
import {useCurrentSession, useCurrentUser} from '../../context';

const transPixel = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC';

export type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'loading'> & {
    src: string;
    loading?: boolean;
};

export const Image: FunctionComponent<ImageProps> = ({src, onClick, loading, ...props}) => {
    const [url, setUrl] = useState<string>();
    const {currentSession: session} = useCurrentSession();
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {token} = session ?? {};
    const {_id: uid, context} = authenticatedUser ?? {};
    const {entityId} = context ?? {};
    const validSrc = src.startsWith('data:') || src.startsWith('https:') || (src.startsWith('/') && !src.startsWith('/workbox')) || src.startsWith('static/');

    useEffect(() => {
        if (validSrc) {
            return setUrl(src);
        }

        if (!src || !uid || !token || !entityId || src.includes('undefined')) {
            return;
        }

        const [resourceId, imageHash] = (/\/workbox-checkbuster\/photos\/(.+)/).exec(src)?.[1]?.split('-') ?? [];

        if (!resourceId || !imageHash) {
            return;
        }

        setUrl(Logo);
    }, [src, uid, token, entityId]);

    return (
        <img
            {...props} src={url ?? (validSrc ? src : loading ? transPixel : Logo)}
            onClick={(event) => {
                ripple(event);
                onClick?.(event);
            }}
        />
    );
};
