export const createRange = (rangeStartIndex: number, rangeEndIndex?: number) => `docs=${rangeStartIndex}-${rangeEndIndex ?? ''}`;

export const createContentRange = (rangeStartIndex: number, returnedDocs: number, availableDocs: number) => `docs=${rangeStartIndex}-${rangeStartIndex + returnedDocs - 1}/${availableDocs}`;

export const parseRange = (range: string | undefined): {
    rangeEndIndex: number | undefined;
    rangeStartIndex: number | undefined;
} => {
    if (!range) {
        return {
            rangeEndIndex: undefined,
            rangeStartIndex: undefined
        };
    }

    const {start, end} = (/^docs=(?<start>[0-9]+)-(?<end>[0-9]*)/).exec(range)?.groups ?? {};

    return {
        rangeEndIndex: end ? Number(end) : undefined,
        rangeStartIndex: start ? Number(start) : undefined
    };
};

export const isRangeSatisfiable = (rangeStartIndex: number, rangeEndIndex: number, limit: number): boolean => {
    const range = rangeEndIndex - rangeStartIndex + 1;
    return rangeStartIndex >= 0 && rangeEndIndex >= 0 && range > 0 && range <= limit;
};

export const createContentDetails = <T extends string | Record<string, number>>(input: Record<T extends string ? T : keyof T, number>): URLSearchParams => {
    const params = new URLSearchParams();

    for (const [key, value] of Object.entries<number>(input)) {
        params.set(key, value.toString());
    }

    params.sort();

    return params;
};

export const parseContentDetails = <T extends string | Record<string, number>>(input: string): Partial<Record<T extends string ? T : keyof T, number>> => {
    const params = new URLSearchParams(input);
    const result: Partial<Record<T extends string ? T : keyof T, number>> = {};

    for (const [key, value] of params.entries()) {
        result[key] = Number(value);
    }

    return result;
};
