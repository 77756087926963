import {useEffect} from 'react';
import {getLocale} from '../../helpers/locales';
import {applyUpdate, downloadUpdate, getUpdateStatus, UpdateStatus} from '../../service-worker/updates';
import {noop} from '../../util/functions';

const downloadAndApplyWhenPossible = () => {
    if (document.visibilityState !== 'visible') {
        return;
    }

    const status = getUpdateStatus();
    if (status === UpdateStatus.UP_TO_DATE) {
        return downloadUpdate();
    }

    if (status !== UpdateStatus.WAITING) {
        return;
    }

    const [empty, locale, target, forbidden] = location.pathname.split('/');
    if (forbidden || !target || empty !== '' || !getLocale(locale)) {
        return;
    }

    return applyUpdate(true);
};

export const useAutomaticUpdater = process.env.NODE_ENV === 'development'
    ? noop
    : () => {
        useEffect(() => {
            document.addEventListener('visibilitychange', downloadAndApplyWhenPossible);

            return () => document.removeEventListener('visibilitychange', downloadAndApplyWhenPossible);
        }, []);
    };
