import type {InspectionTemplateDocument} from '../database';
import type {InspectionDocument} from '../database/collections/inspections/InspectionDocument';
import type {JsonOf, OneOf} from '../interfaces/helpers';
import {createAtpItem, createDropdownItem, createLocationItem, createNumberItem, createRangeItem, createSignatureItem, createSmileyItem, createStarItem, createTextItem, createYesNoItem} from '../util/inspectionItems';
import {normalizeInspectionSection} from './InspectionSection';
import {createOfflineObjectId} from './MongoDb';

const CreateMap = {
    yesNo: createYesNoItem,
    number: createNumberItem,
    range: createRangeItem,
    atp: createAtpItem,
    text: createTextItem,
    location: createLocationItem,
    signature: createSignatureItem,
    dropdown: createDropdownItem,
    star: createStarItem,
    smiley: createSmileyItem
} as const;

// eslint-disable-next-line arrow-body-style
export const createInspectionTemplateItem = <T extends keyof typeof CreateMap>(itemType: T, fill: Parameters<typeof CreateMap[T]>[0] = {}): ReturnType<typeof CreateMap[T]> => {
    // @ts-expect-error i don't know why this fails
    return CreateMap[itemType](fill);
};

export const createInspection = (template: JsonOf<Omit<InspectionTemplateDocument, '_id' | 'offline_id'> & OneOf<Required<Pick<InspectionTemplateDocument, '_id' | 'offline_id'>>>>, {
    context,
    creator,
    name,
    location,
    locationName,
    inspectorName,
    inspectorEmail
}: Required<NonNullable<Pick<JsonOf<InspectionDocument>, 'context' | 'creator' | 'name' | 'location' | 'locationName' | 'inspectorName' | 'inspectorEmail'>>>): Omit<JsonOf<InspectionDocument>, '_id'> => ({
    _deleted: false,
    closed: 0,
    conclusion: '',
    conductedAt: Date.now(),
    context,
    created: Date.now(),
    createdAt: Date.now(),
    createdBy: creator,
    creator,
    incident: false,
    inspectorName,
    inspectorEmail,
    isDone: false,
    location,
    locationName,
    locked: 0,
    name,
    offline_id: createOfflineObjectId(),
    predefinedComments: template.predefinedComments?.filter((c) => !!c) ?? [],
    removed: 0,
    reportTemplateIds: template.reportTemplateIds?.filter((i) => !!i) ?? [],
    sections: (template.sections ?? [])
        .map(normalizeInspectionSection)
        .filter((section) => !!section),
    tags: template.tags ?? [],
    taskIds: [],
    templateId: template._id ?? template.offline_id!,
    templateName: template.name,
    touchedAt: Date.now(),
    touchedBy: creator,
    users: [creator]
});
