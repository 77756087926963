import {LoginPage} from '../../../../pages/mobile/login';
import {LogoutPage} from '../../../../pages/mobile/logout';
import {RegisterPage} from '../../../../pages/mobile/register';
import {SingleSignOnPage} from '../../../../pages/mobile/single-sign-on';
import {SingleSignOnProviderIdPage} from '../../../../pages/mobile/single-sign-on_providerId';
import {StatisticsCreatePage} from '../../../../pages/mobile/statistics_create';
import {getCookie} from '../../../../utilities/storage';
import type {RouteDetails} from '../Router';
import {asyncRoute, syncRoute} from './helpers';

const authpage = getCookie('authpage') ?? 'sso';
const authpopup = !!parseInt(getCookie('auth.popup') ?? '0');

const authenticationRoutes: RouteDetails[] = [
    syncRoute(
        '/single-sign-on/:providerId',
        SingleSignOnProviderIdPage
    ),

    // TODO: Remove after NXT is gone
    ...['/magic/:magicKey', '/verify/:magicKey', '/login/reset/:magicKey', '/register/confirm/:magicKey'].map((path) => syncRoute(
        path,
        LoginPage
    )),

    // Prefer the authentication page last used by this device
    ...[{
        key: 'sso',
        route: syncRoute(
            '/single-sign-on',
            SingleSignOnPage
        ),
        enabled: location.hostname.endsWith('.com') || process.env.NODE_ENV !== 'production'
    }, {
        key: 'email-register',
        route: syncRoute(
            '/register',
            RegisterPage
        ),
        enabled: true
    }, {
        key: 'email-login',
        route: syncRoute(
            '/login',
            LoginPage
        ),
        enabled: true
    }]
        .filter(({enabled}) => enabled)
        .sort((a, b) => a.key === authpage ? -1 : 1)
        .map(({route}) => route),

    syncRoute(
        '/logout',
        LogoutPage
    )
];

const otherRoutes: RouteDetails[] = [
    asyncRoute(
        '/inspections',
        import('../../../../pages/mobile/inspections')
    ),

    asyncRoute(
        '/inspections/create',
        import('../../../../pages/mobile/inspections_create_templates')
    ),

    asyncRoute(
        '/inspections/create/:templateId/:locationId',
        import('../../../../pages/mobile/inspections_create_templateId_locationId_name')
    ),

    asyncRoute(
        '/inspections/create/:templateId',
        import('../../../../pages/mobile/inspections_create_templateId_locations')
    ),

    asyncRoute(
        '/inspections/:inspectionId',
        import('../../../../pages/mobile/inspections_inspectionId')
    ),

    asyncRoute(
        '/inspections/:inspectionId/tasks',
        import('../../../../pages/mobile/inspections_inspectionId_tasks')
    ),

    asyncRoute(
        '/inspections/:inspectionId/sections/:sectionIndex',
        import('../../../../pages/mobile/inspections_inspectionId_sections_sectionIndex')
    ),

    asyncRoute(
        '/inspections/:inspectionId/sections/:sectionIndex/items',
        import('../../../../pages/mobile/inspections_inspectionId_sections_sectionIndex_items')
    ),

    asyncRoute(
        '/inspections/:inspectionId/sections/:sectionIndex/items/:itemIndex',
        import('../../../../pages/mobile/inspections_inspectionId_sections_sectionIndex_items_itemIndex')
    ),

    asyncRoute(
        '/inspections/:inspectionId/sections/:sectionIndex/items/:itemIndex/tasks/create',
        import('../../../../pages/mobile/inspections_inspectionId_sections_sectionIndex_items_itemIndex_tasks_create')
    ),

    asyncRoute(
        '/inspections/:inspectionId/sections/:sectionIndex/items/:itemIndex/tasks/create/users',
        import('../../../../pages/mobile/inspections_inspectionId_sections_sectionIndex_items_itemIndex_tasks_create_users')
    ),

    asyncRoute(
        '/inspections/:inspectionId/preview',
        import('../../../../pages/mobile/inspections_inspectionId_preview')
    ),

    asyncRoute(
        '/inspections/:inspectionId/favourites',
        import('../../../../pages/mobile/inspections_inspectionId_favourites')
    ),

    asyncRoute(
        '/organisations/:organisationId/create',
        import('../../../../pages/mobile/organisations_create_organisationId_name')
    ),

    asyncRoute(
        '/inspections/:inspectionId/photos',
        import('../../../../pages/mobile/inspections_inspectionId_photos')
    ),

    asyncRoute(
        '/tasks',
        import('../../../../pages/mobile/tasks')
    ),

    asyncRoute(
        '/tasks/create',
        import('../../../../pages/mobile/tasks_create')
    ),

    asyncRoute(
        '/tasks/create/users',
        import('../../../../pages/mobile/tasks_create_users')
    ),

    asyncRoute(
        '/tasks/:taskId',
        import('../../../../pages/mobile/tasks_taskId')
    ),

    asyncRoute(
        '/tasks/:taskId/users',
        import('../../../../pages/mobile/tasks_taskId_users')
    ),

    asyncRoute(
        '/team-members/create',
        import('../../../../pages/mobile/team-members_create/TeamMembersCreatePage')
    ),
    asyncRoute(
        '/team-members/create/:userIds/templates',
        import('../../../../pages/mobile/team-members_create_userids_templates/TeamMembersCreateUserIdsTemplatesPage')
    ),
    asyncRoute(
        '/team-members/create/:userIds/locations',
        import('../../../../pages/mobile/team-members_create_userids_locations/TeamMembersCreateUserIdsLocationsPage')
    ),

    asyncRoute(
        '/team-members/:teamMemberId',
        import('../../../../pages/mobile/team-members_teamMemberId/TeamMembersTeamMemberIdPage')
    ),

    asyncRoute(
        '/team-members',
        import('../../../../pages/mobile/team-members/TeamMembersPage')
    ),

    asyncRoute(
        '/inspection-templates',
        import('../../../../pages/mobile/inspection-templates')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/edit',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_edit')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/team-members',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_team-members/InspectionTemplatesInspectionTemplateIdTeamMembersPage')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/report-templates',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_report-templates/InspectionTemplatesInspectionTemplateIdReportTemplatesPage')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/done',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_done/InspectionTemplatesInspectionTemplateIdDonePage')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/sections/:sectionIndex/items',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_sections_sectionIndex_items')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/sections/:sectionIndex/items/:itemIndex',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_sections_sectionIndex_items_itemIndex')
    ),

    asyncRoute(
        '/libraries',
        import('../../../../pages/mobile/libraries')
    ),

    asyncRoute(
        '/libraries/:libraryId',
        import('../../../../pages/mobile/libraries_libraryId')
    ),

    asyncRoute(
        '/libraries/:libraryId/:libraryItemId',
        import('../../../../pages/mobile/libraries_libraryId_libraryItemId')
    ),

    asyncRoute(
        '/calendar',
        import('../../../../pages/mobile/calendar')
    ),

    asyncRoute(
        '/inspection-plannings',
        import('../../../../pages/mobile/inspection-plannings')
    ),

    asyncRoute(
        '/inspection-plannings/create/:templateId/:locationId/:userIds/:schedule',
        import('../../../../pages/mobile/inspection-plannings_create_templateId_locationId_userIds_schedule')
    ),

    asyncRoute(
        '/inspection-plannings/create/:templateId/:locationId/:userIds',
        import('../../../../pages/mobile/inspection-plannings_create_templateId_locationId_userIds')
    ),

    asyncRoute(
        '/inspection-plannings/create/:templateId/:locationId',
        import('../../../../pages/mobile/inspection-plannings_create_templateId_locationId')
    ),

    asyncRoute(
        '/inspection-plannings/create/:templateId',
        import('../../../../pages/mobile/inspection-plannings_create_templateId')
    ),

    asyncRoute(
        '/inspection-plannings/create',
        import('../../../../pages/mobile/inspection-plannings_create')
    ),

    asyncRoute(
        '/inspection-plannings/:inspectionPlanningId',
        import('../../../../pages/mobile/inspection-plannings_inspectionPlanningId')
    ),

    asyncRoute(
        '/statistics',
        import('../../../../pages/mobile/statistics')
    ),

    ...['/statistics/create', '/statistics/create/:chartId'].map((path) => syncRoute(
        path,
        StatisticsCreatePage
    )),

    asyncRoute(
        '/statistics/:chartId',
        import('../../../../pages/mobile/statistics_chartId')
    ),

    asyncRoute(
        '/organisations',
        import('../../../../pages/mobile/organisations')
    ),

    asyncRoute(
        '/organisations/:organisationId',
        import('../../../../pages/mobile/organisations')
    ),

    asyncRoute(
        '/organisations/:organisationId/edit',
        import('../../../../pages/mobile/organisations_organisationId_edit')
    ),

    asyncRoute(
        '/organisations/:organisationId/users',
        import('../../../../pages/mobile/organisations_organisationId_users')
    ),

    asyncRoute(
        '/report-templates/create',
        import('../../../../pages/mobile/report-templates_create')
    ),

    asyncRoute(
        '/report-templates/:reportTemplateId',
        import('../../../../pages/mobile/report-templates_reportTemplateId')
    ),

    asyncRoute(
        '/report-templates',
        import('../../../../pages/mobile/report-templates')
    ),

    asyncRoute(
        '/upgrade',
        import('../../../../pages/mobile/upgrade')
    ),

    asyncRoute(
        '/upgrade/success',
        import('../../../../pages/mobile/upgrade_success')
    ),

    asyncRoute(
        '/upgrade/cancel',
        import('../../../../pages/mobile/upgrade_cancel')
    ),

    asyncRoute(
        '/settings/sessions',
        import('../../../../pages/mobile/settings_sessions')
    ),

    asyncRoute(
        '/settings/subscription',
        import('../../../../pages/mobile/settings_subscription')
    ),

    asyncRoute(
        '/settings/gdpr',
        import('../../../../pages/mobile/settings_gdpr')
    ),

    asyncRoute(
        '/settings/language-a11y',
        import('../../../../pages/mobile/settings_language-a11y')
    ),

    asyncRoute(
        '/settings/offline-actions',
        import('../../../../pages/mobile/settings_offline-actions')
    ),

    asyncRoute(
        '/settings/storage',
        import('../../../../pages/mobile/settings_storage')
    ),

    asyncRoute(
        '/settings/version',
        import('../../../../pages/mobile/settings_version')
    ),

    asyncRoute(
        '/settings/report-automations',
        import('../../../../pages/mobile/settings_report-automations')
    ),

    asyncRoute(
        '/settings/verified-domains',
        import('../../../../pages/mobile/settings_verified-domains/SettingsVerifiedDomainsPage')
    ),

    asyncRoute(
        '/settings',
        import('../../../../pages/mobile/settings')
    ),

    asyncRoute(
        '/contact',
        import('../../../../pages/mobile/contact')
    ),

    asyncRoute(
        '/inspection-templates/:inspectionTemplateId/resource-translations/:resourceTranslationId',
        import('../../../../pages/mobile/inspection-templates_inspectionTemplateId_resource-translations__resourceTranslationId')
    )
];

export const routes = authpopup
    ? [...otherRoutes, ...authenticationRoutes]
    : [...authenticationRoutes, ...otherRoutes];
