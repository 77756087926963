import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {atpItemTypeDef} from './atpItemTypeDef';
import {dropdownItemTypeDef} from './dropdownItemTypeDef';
import type {InspectionItemDocument} from './InspectionItemDocument';
import {locationItemTypeDef} from './locationItemTypeDef';
import {numberItemTypeDef} from './numberItemTypeDef';
import {rangeItemTypeDef} from './rangeItemTypeDef';
import {signatureItemTypeDef} from './signatureItemTypeDef';
import {smileyItemTypeDef} from './smileyItemTypeDef';
import {starItemTypeDef} from './starItemTypeDef';
import {textItemTypeDef} from './textItemTypeDef';
import {yesNoItemTypeDef} from './yesNoItemTypeDef';

export const inspectionItemTypeDef: TypeDef<InspectionItemDocument> = {
    discriminator: 'type',
    mapping: {
        atp: atpItemTypeDef,
        dropdown: dropdownItemTypeDef,
        location: locationItemTypeDef,
        number: numberItemTypeDef,
        range: rangeItemTypeDef,
        signature: signatureItemTypeDef,
        smiley: smileyItemTypeDef,
        star: starItemTypeDef,
        text: textItemTypeDef,
        yesNo: yesNoItemTypeDef
    },
    nullable: true
};
