import type {ITableCellOptions} from 'docx';
import {convertMillimetersToTwip} from 'docx';
import type {Padding} from '../config/ReportData';

export const hexColor = (color: string): string => color.replace('#', '');

export const cssPadding = ([top, right, bottom, left]: Padding): NonNullable<ITableCellOptions['margins']> => ({
    bottom: convertMillimetersToTwip(bottom),
    left: convertMillimetersToTwip(left),
    right: convertMillimetersToTwip(right),
    top: convertMillimetersToTwip(top)
});

export const columnWidths = (widthDxa: number, percentages: number[]): number[] => percentages.map((percentage) => widthDxa * percentage / 100);

export const pxToMm = (px: number): number => px * 0.13125;

export const mmToPx = (mm: number): number => mm / 0.13125;
