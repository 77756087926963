import {useLiveQuery} from 'dexie-react-hooks';
import type {FunctionComponent, ImgHTMLAttributes} from 'react';
import React, {useState, useMemo} from 'react';
import Logo from '../../../frontend/icons/Logo.png';
import {ripple} from '../../../frontend/utilities/animations';
import {isMongoDbObjectId, isOfflineObjectId} from '../../../types/MongoDb';
import {classNames} from '../../helpers/styling';
import {getOfflinePhotoUrl, getOnlinePhotoUrl} from '../../hooks';
import './Photo.scss';

export const isPhotoId = (src: string): boolean => isOfflineObjectId(src) || isMongoDbObjectId(src);

export type PhotoProps = Pick<ImgHTMLAttributes<HTMLImageElement>, 'className' | 'alt' | 'draggable'> & {
    cache?: boolean;
    photo_id: string;
    fallbackUrl?: string;
};

export const Photo: FunctionComponent<PhotoProps> = ({cache = false, fallbackUrl = Logo, photo_id, className, alt, draggable}) => {
    const [error, setError] = useState('');
    const offlinePhotoSource = useLiveQuery(() => isOfflineObjectId(photo_id) ? getOfflinePhotoUrl(photo_id) : '', [photo_id], '');
    const onlinePhotoSource = useMemo(() => isMongoDbObjectId(photo_id) ? `${getOnlinePhotoUrl(photo_id)}?cache=${cache ? 'cache-first' : 'network-only'}` : '', [photo_id, cache]);
    const src = error || offlinePhotoSource || onlinePhotoSource || fallbackUrl;

    function onError() {
        setError(fallbackUrl);
    }

    return (
        <picture onClick={ripple} className={classNames('photo', {}, className)} onError={onError} draggable={draggable}>
            <img src={src} alt={alt} className={classNames('photo__content')}/>
        </picture>
    );
};
