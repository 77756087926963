import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {DropdownItemDocument} from './DropdownItemDocument';

export const dropdownItemTypeDef: TypeDef<Omit<DropdownItemDocument, 'type'>> = {
    additionalProperties: false,
    metadata: {
        name: 'DropdownItemDocument'
    },
    optionalProperties: {
        addedAt: {
            type: 'float64'
        },
        addedBy: {
            type: 'string'
        },
        parentConditionId: {
            type: 'float64'
        },
        parentId: {
            type: 'float64'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        id: {
            type: 'float64'
        },
        input: {
            additionalProperties: false,
            properties: {
                comment: {
                    type: 'string'
                },
                options: {
                    elements: {
                        additionalProperties: false,
                        properties: {
                            selected: {
                                type: 'boolean'
                            },
                            text: {
                                type: 'string'
                            },
                            value: {
                                type: 'float64'
                            }
                        }
                    }
                },
                photos: {
                    elements: {
                        type: 'string'
                    }
                },
                state: {
                    enum: ['ok', 'empty', 'bad', 'none']
                },
                taskIds: {
                    elements: {
                        type: 'string'
                    }
                }
            }
        },
        isFavourite: {
            type: 'boolean'
        },
        locked: {
            type: 'boolean'
        },
        spec: {
            additionalProperties: false,
            properties: {
                autoTask: {
                    type: 'boolean'
                },
                conditions: {
                    elements: {
                        additionalProperties: false,
                        optionalProperties: {
                            itemState: {
                                enum: ['ok', 'empty', 'bad', 'none']
                            },
                            itemValue: {
                                type: 'string'
                            }
                        },
                        properties: {
                            equals: {
                                enum: ['===', '!==']
                            },
                            id: {
                                type: 'float64'
                            }
                        }
                    } as never
                },
                dynamic: {
                    type: 'boolean'
                },
                extraInfoPhotos: {
                    elements: {
                        type: 'string'
                    }
                },
                extraInfoText: {
                    type: 'string'
                },
                extraInfoToggle: {
                    type: 'boolean'
                },
                isDropdown: {
                    type: 'boolean'
                },
                label: {
                    type: 'string'
                },
                multi: {
                    type: 'boolean'
                },
                options: {
                    elements: {
                        additionalProperties: false,
                        properties: {
                            text: {
                                type: 'string'
                            },
                            value: {
                                type: 'float64'
                            }
                        }
                    }
                },
                predefinedComments: {
                    type: 'boolean'
                },
                predefinedCommentsArray: {
                    elements: {
                        type: 'string'
                    }
                }
            }
        }
    }
};
