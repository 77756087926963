import type {History} from 'history';
import {createBrowserHistory} from 'history';
import type {FunctionComponent} from 'react';
import React from 'react';
import type {RouterProps as BrowserRouterProps, MemoryRouterProps} from 'react-router';
import {Redirect} from 'react-router';
import {Route, Router as ReactRouter, Switch} from 'react-router-dom';
import {Image} from '../../../../react/components/Image';
import {PopupStoreUpdate} from '../../../../react/components/popups/PopupStoreUpdate';
import {GoogleTagManager, UserDotCom} from '../../../../react/integrations';
import {__} from '../../../../translations';
import {MenuProvider} from '../../../context/MenuContext';
import {useAutomaticUpdater} from '../../../hooks';
import loadingImage from '../../../icons/loading-icon.gif';
import './Router.scss';

export {matchPath} from 'react-router-dom';

export const createHistory = (locale: string): History => createBrowserHistory({
    basename: `/${locale}`
});

export type RouteDetails = {
    path: string;
    Component: React.JSXElementConstructor<Record<string, unknown>>;
};

export type RouterProps = Partial<MemoryRouterProps> & BrowserRouterProps & {
    routes: RouteDetails[];
};

export const LoadingScreen: FunctionComponent = () => (
    <div className="loading-screen">
        <Image className="loading-screen__logo" src={loadingImage} alt={__('Loading indicator')}/>
    </div>
);
export const Router: FunctionComponent<RouterProps> = (props) => {
    const {routes, children, ...rest} = props;

    useAutomaticUpdater();

    const fallback = routes.find(({path}) => !path.includes(':'))?.path ?? '/404';
    const renderedRoutes = routes.map(({path, Component}) => <Route key={path} path={path} component={Component} exact/>);

    const search = new URLSearchParams(location.search);
    if (location.hash !== '#(%E2%95%AF%C2%B0%E2%96%A1%C2%B0%EF%BC%89%E2%95%AF' && search.has('shortcut')) {
        renderedRoutes.unshift((
            <Redirect to={search.get('shortcut')!}/>
        ));
    }

    return (
        <ReactRouter {...rest}>
            <MenuProvider>
                <Switch>
                    {renderedRoutes}
                    <Redirect from="*" to={fallback}/>
                </Switch>
            </MenuProvider>
            <UserDotCom/>
            <GoogleTagManager/>
            <PopupStoreUpdate/>
        </ReactRouter>
    );
};
