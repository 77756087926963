import {HttpMethod} from '../../api/HttpMethod';
import {HttpStatusCode} from '../../api/HttpStatusCode';
import type {Identifiable} from '../../types/Identifiable';
import type {RequestEvent, ResponseEvent} from './networkEvents';
import {eventTarget} from './networkEvents';
import {getCookie} from './storage';

export type Update = {
    path: string;
    value: unknown;
    fromValue?: unknown;
    toValue?: unknown;
    operation?: 'append' | 'delete';
} & Identifiable;

/**
 * @deprecated
 */
export const postRequest = async (input: string, init: Omit<RequestInit, 'body'> & {
  body?: Record<string, unknown>;
} = {}): Promise<Response> => {
    const method = (init.method as HttpMethod | undefined) ?? HttpMethod.POST;

    eventTarget.dispatchEvent(new CustomEvent<RequestEvent>('request', {
        detail: {
            url: input,
            method
        }
    }));

    const environment = getCookie('env') ?? 'web';

    const response = await fetch(input, {
        ...init,
        method,
        body: init.body
            ? JSON.stringify({
                ...init.body,
                device: init.body?.device ? environment : undefined
            })
            : undefined
    }).catch((error) => {
        error.isFetchError = true;
        throw error;
    });

    if (response.headers.get('Content-Type')?.includes('application/json')) {
        eventTarget.dispatchEvent(new CustomEvent<ResponseEvent>('response', {
            detail: {
                url: input,
                status: response.status,
                ok: response.ok,
                json: response.ok && response.status !== HttpStatusCode.NO_CONTENT ? await response.clone().json() : null,
                method
            }
        }));
    }

    return response;
};
