import type {FunctionComponent, MouseEvent} from 'react';
import React, {useState} from 'react';
import {ripple} from '../../../utilities/animations';
import {debounce} from '../../../utilities/timing';
import './Button.scss';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    id?: string;
    Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
    badge?: string;
    spin?: boolean;
    tooltip?: string;
    onDisabledClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void | Promise<void>;
};

export const Button: FunctionComponent<ButtonProps> = ({
    className,
    tooltip,
    Icon,
    badge,
    spin,
    disabled,
    onDisabledClick,
    onClick,
    children = null,
    ...rest
}) => {
    const [busy, setBusy] = useState(false);

    const onClickWrapper = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
        event.stopPropagation();
        const onClickProp = disabled ? onDisabledClick : onClick;

        if (!onClickProp || busy) {
            return;
        }

        ripple(event);
        setBusy(true);

        try {
            await debounce(() => onClickProp(event));
        } finally {
            setBusy(false);
        }
    };

    const classNames = [
        'button',
        `button--busy-${busy ? 'yes' : 'no'}`,
        `button--icon-${Icon ? 'yes' : 'no'}`,
        `button--badge-${badge ? 'yes' : 'no'}`,
        `button--disabled-${disabled ? 'yes' : 'no'}`,
        className
    ].join(' ').trim();

    return (
        <button
            type="button"
            title={tooltip}
            className={classNames}
            {...rest}
            onClick={onClickWrapper}
            data-badge={badge}
            disabled={!!disabled && !onDisabledClick}
        >
            {Icon ? <Icon className={`button__icon button__icon--spin-${spin ? 'yes' : 'no'}`}/> : null}
            {children
            && (
                <div className="button__content">
                    {children}
                </div>
            )}
        </button>
    );
};
