import sanitize from 'sanitize-filename';

export const escapeRegex = (string: string): string => string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');

export const capitalize = (word: string): string => word.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase());

type Placeholder =
    'day'
    | 'weekNr'
    | 'date'
    | 'year'
    | 'inspection-start-date'
    | 'inspection-end-date'
    | 'inspector-name'
    | 'inspection-name'
    | 'location-name'
    | 'template-name';

export const replacePlaceholders = (template: string, replacements: Partial<Record<Placeholder, string | number>>): string => {
    let result = template;

    Object.entries(replacements).forEach(([key, value]) => {
        result = result.replace(new RegExp(`\\\\?\\[${key}\\]`, 'gi'), value.toString());
    });

    return result;
};

export const mergeUrlSearchParams = (target: string, current: string): string => {
    const wantedParams = new URLSearchParams(target.replace('#', ''));
    const newParams = new URLSearchParams(current.replace('#', ''));

    for (const wantedKey of wantedParams.keys()) {
        newParams.delete(wantedKey);

        for (const wantedValue of wantedParams.getAll(wantedKey)) {
            newParams.append(wantedKey, wantedValue);
        }
    }

    newParams.sort();
    return `#${newParams.toString()}`;
};

export const sanitizeFilename = (name: string): string => sanitize(name.trim());

export const encodeFilename = (name: string) => encodeURIComponent(sanitizeFilename(name));

