import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getTask} from '../../api/endpoints/tasks/[taskId]/get/frontend';
import type {TaskDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import {useCurrentSession} from '../context';
import {useDexieResource} from './useDexieResource';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useTask = (taskId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    task: JsonOf<TaskDocument> | null;
    updateTask: (patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resource: task, dexieActive} = useDexieResource<TaskDocument>({
        table: dexie.tasks,
        primaryKey: taskId
    });

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId || !taskId) {
            return;
        }

        setFetchActive(true);
        getTask({taskId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, taskId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        task,
        updateTask: async (patch: JsonPatch) => {
            if (!userId || !entityId || !taskId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'tasks',
                    resourceId: taskId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
