import type {FunctionComponent} from 'react';
import React, {useState, useEffect} from 'react';
import type {RouteComponentProps} from 'react-router-dom';
import {createChartSegment} from '../../../../api/endpoints/charts/[chartId]/chart-segments/post/frontend';
import {updateChart} from '../../../../api/endpoints/charts/[chartId]/patch/frontend';
import {createChart} from '../../../../api/endpoints/charts/post/frontend';
import {getSessions} from '../../../../api/endpoints/sessies/get/frontend';
import {getUser} from '../../../../api/endpoints/users/[userId]/get/frontend';
import type {InspectionTemplateDocument, ReportLayoutDocument, TaskDocument} from '../../../../database';
import type {JsonOf} from '../../../../interfaces/helpers';
import {createJsonPointer} from '../../../../interfaces/models/json-pointer';
import {Image} from '../../../../react/components/Image';
import {useCurrentUser} from '../../../../react/context';
import {useEntity, useInspections, useInspectionTemplates, useReportLayouts, useTasks} from '../../../../react/hooks';
import {__} from '../../../../translations';
import {createInspection} from '../../../../types';
import {createOfflineObjectId} from '../../../../types/MongoDb';
import {Content} from '../../../components/layout/Content';
import {Page} from '../../../components/layout/Page';
import loadingImage from '../../../icons/loading-icon.gif';
import {
    demoChartData,
    demoImprovementReportData,
    demoInspectionTemplateData,
    demoReportTemplateData, demoTaskData
} from '../../../utilities/demoData';
import {setCookie} from '../../../utilities/storage';

export const SingleSignOnProviderIdPage: FunctionComponent<RouteComponentProps<{
    providerId: string;
}>> = (props) => {
    const {history, match} = props;
    document.title = `Checkbuster | ${__('Single Sign-On')}`;
    const {currentUser: authenticatedUser} = useCurrentUser();
    const {entity} = useEntity(authenticatedUser?.context.entityId);
    const {createInspection: addInspectionToQueue} = useInspections(0, 0, {tab: 'open'});
    const {createInspectionTemplate} = useInspectionTemplates();
    const {createTask} = useTasks();
    const {createReportLayout} = useReportLayouts();
    const [needsDemoData, setNeedsDemoData] = useState<boolean>(true);

    useEffect(() => setCookie('authpage', 'sso'), []);

    useEffect(() => {
        if (authenticatedUser && !needsDemoData) {
            history.replace(authenticatedUser.needsPassword ? `/team-members/${authenticatedUser._id}` : '/inspections');
        }
    }, [authenticatedUser, needsDemoData]);

    useEffect(() => {
        (async () => {
            if (!authenticatedUser || !entity || !needsDemoData) {
                return;
            }

            if (entity.owner !== authenticatedUser._id.toString() || !authenticatedUser.touchedAt || !authenticatedUser.createdAt || authenticatedUser.touchedAt - authenticatedUser.createdAt > 60000 || authenticatedUser.templates.length > 0) {
                return setNeedsDemoData(false);
            }

            const creationBase = {
                context: authenticatedUser.context,
                creator: authenticatedUser._id
            };

            const demoReportTemplateDataComplete: Partial<JsonOf<ReportLayoutDocument>> = {
                ...creationBase,
                ...demoReportTemplateData,
                offline_id: createOfflineObjectId()
            };

            const demoImprovementReportDataComplete: Partial<JsonOf<ReportLayoutDocument>> = {
                ...creationBase,
                ...demoImprovementReportData,
                offline_id: createOfflineObjectId()
            };

            const demoInspectionTemplateDataComplete: JsonOf<Omit<InspectionTemplateDocument, '_id' | 'offline_id'> & Required<Pick<InspectionTemplateDocument, 'offline_id'>>> = {
                ...creationBase,
                ...demoInspectionTemplateData,
                reportTemplateIds: [demoReportTemplateDataComplete.offline_id!, demoImprovementReportDataComplete.offline_id!],
                offline_id: createOfflineObjectId()
            };

            const demoInspection = createInspection(demoInspectionTemplateDataComplete, {
                context: authenticatedUser.context,
                creator: authenticatedUser._id,
                inspectorEmail: authenticatedUser.email,
                inspectorName: authenticatedUser.name ?? __('Me'),
                location: authenticatedUser.context.entityId,
                locationName: __('My Organisation'),
                name: __('Checkbuster Demo Inspection')
            });

            const demoTaskDataComplete: Partial<JsonOf<TaskDocument>> = {
                ...creationBase,
                ...demoTaskData,
                creatorName: authenticatedUser.name,
                assignedToUser: [authenticatedUser._id],
                meta: {
                    ...demoTaskData.meta,
                    inspectionId: demoInspection.offline_id,
                    locationId: demoInspection.location,
                    templateId: demoInspection.templateId
                },
                offline_id: createOfflineObjectId()
            };

            const demoReportTemplateInQueue = await createReportLayout(demoReportTemplateDataComplete);
            const demoImprovementReportInQueue = await createReportLayout(demoImprovementReportDataComplete);
            const demoInspectionTemplateInQueue = await createInspectionTemplate(demoInspectionTemplateDataComplete);
            const demoInspectionInQueue = await addInspectionToQueue(demoInspection);
            const demoTaskInQueue = await createTask(demoTaskDataComplete);

            for (const chartAndChartSegment of demoChartData) {
                try {
                    const {chart, chartSegments} = chartAndChartSegment;
                    const {json: createdChart} = await createChart({
                        ...chart,
                        entity_id: authenticatedUser.context.entityId,
                        createdBy: authenticatedUser._id,
                        touchedBy: authenticatedUser._id
                    });

                    if (!createdChart) {
                        throw new Error('Failed to create initial chart data');
                    }

                    for (const chartSegment of chartSegments) {
                        const {json: createdChartSegment} = await createChartSegment({chartId: createdChart._id}, {
                            ...chartSegment,
                            chart_id: createdChart._id,
                            entity_id: authenticatedUser.context.entityId,
                            createdBy: authenticatedUser._id,
                            touchedBy: authenticatedUser._id
                        });
                        if (!createdChartSegment) {
                            throw new Error('Failed to create initial chart segment data');
                        }

                        await updateChart({chartId: createdChart._id}, [{
                            op: 'replace',
                            path: createJsonPointer('valueDataKey'),
                            value: createdChartSegment._id
                        }]);
                    }
                } catch (e) {
                    setNeedsDemoData(false);
                    return alert(__('Failed to create initial chart data. Please contact us at info@checkbuster.com'));
                }
            }

            const success = demoReportTemplateInQueue && demoImprovementReportInQueue && demoInspectionTemplateInQueue && demoInspectionInQueue && demoTaskInQueue;
            if (!success) {
                setNeedsDemoData(false);
                return alert(__('Failed to create initial data. Please contact us at info@checkbuster.com'));
            }

            setNeedsDemoData(false);
        })();
    }, [!!authenticatedUser, !!entity]);

    useEffect(() => {
        getSessions({current: true})
            .then(async ({json: sessions}) => {
                const userId = sessions?.[0]?.user_id;

                if (!userId) {
                    return history.replace('/single-sign-on');
                }

                const {response, json: user} = await getUser({userId});

                !response.ok && history.replace('/single-sign-on');

                if (match.params.providerId === 'microsoft' && !user?.microsoftId) {
                    history.replace('/single-sign-on#link-microsoft');
                }
            })
            .catch(() => history.replace('/single-sign-on'));
    }, []);

    return (
        <Page name="sso-providerid" disableLogin>
            <Content>
                <div className="loading-screen">
                    <Image className="loading-screen__logo" src={loadingImage} alt={__('Loading indicator')}/>
                </div>
            </Content>
        </Page>
    );
};
