import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import {removableTypeDef} from '../../interfaces';
import type {PhotoDocument} from './PhotoDocument';

export const photoTypeDef: TypeDef<PhotoDocument> = {
    additionalProperties: false,
    optionalProperties: {
        ...removableTypeDef.optionalProperties,
        createdBy: {
            type: 'string'
        },
        description: {
            type: 'string'
        },
        expiresAt: {
            type: 'timestamp'
        },
        name: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        s3_bucket: {
            enum: ['cb3', 'cb3-acc', 'cb3-tst', 'cb3-dev']
        },
        s3_key: {
            type: 'string'
        },
        s3_region: {
            enum: ['eu-central-1']
        },
        s3_url: {
            type: 'string'
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        ...removableTypeDef.properties,
        _id: {
            type: 'string'
        },
        createdAt: {
            type: 'float64'
        },
        entity_id: {
            type: 'string'
        },
        file_bytes: {
            type: 'float64'
        },
        file_hash: {
            type: 'string'
        },
        file_type: {
            metadata: {
                description: 'An IANA image media type in the form of `image/*`.',
                regex: (/^image\/\w+$/).toString()
            },
            type: 'string'
        } as never
    }
};
