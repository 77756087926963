import {useEffect, useState} from 'react';
import type {UpdateStatus} from '../../service-worker/updates';
import {addUpdateListener, getUpdateStatus, removeUpdateListener} from '../../service-worker/updates';

export const useUpdateStatus = (): UpdateStatus => {
    const [updateStatus, setUpdateStatus] = useState(getUpdateStatus());

    useEffect(() => {
        addUpdateListener(setUpdateStatus);

        return () => removeUpdateListener(setUpdateStatus);
    }, []);

    return updateStatus;
};
