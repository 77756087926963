import type {UserWithContextDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/users/:userId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    userId: string;
};

export type QueryType = never;

export type OutputType = Omit<UserWithContextDocument, '_ip' | 'activeCampaignId' | 'blocked' | 'classicUserId' | 'isReseller' | 'isSuperAdmin' | 'mail' | 'password' | 'profile' | 'salesGotInfo' | 'verificationKey' | 'version' | 'token'>;
