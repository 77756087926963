import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getTasks} from '../../api/endpoints/tasks/get/frontend';
import type {TaskDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {hasRole, Role} from '../../util/permissions';
import {escapeRegex} from '../../util/strings';
import {useCurrentUser} from '../context';
import {sortChronologically, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useTasks = (filter: {
    name?: string;
    createdBefore?: number;
    createdAfter?: number;
    userIds?: string[];
    entityIds?: string[];
    taskIds?: string[];
    inspectionTemplateIds?: string[];
    inspectionId?: string;
} = {}, condition: {
    fetch?: boolean;
    dexie?: boolean;
} = {
    fetch: true,
    dexie: true
}): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    tasks: JsonOf<TaskDocument>[];
    createTask: (task: Partial<JsonOf<TaskDocument>>) => Promise<boolean>;
    updateTask: (taskId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const userId = authenticatedUser?._id;
    const email = authenticatedUser?.email;
    const roles = authenticatedUser?.roles;
    const entityId = authenticatedUser?.context?.entityId;
    const searchName = filter.name && escapeRegex(filter.name);
    const {
        taskIds,
        userIds,
        createdBefore,
        createdAfter,
        inspectionId,
        entityIds,
        inspectionTemplateIds
    } = filter;
    const templateIds = inspectionTemplateIds;

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resources: tasks, dexieActive} = useDexieResources<TaskDocument>({
        filter({_id, offline_id, title: name, meta, creator, assignedToUser, created, _deleted}: JsonOf<TaskDocument>) {
            if (_deleted) {
                return false;
            }

            if (inspectionId && meta.inspectionId !== inspectionId) {
                return false;
            }

            if (createdBefore && created > createdBefore || createdAfter && created < createdAfter) {
                return false;
            }

            if (searchName && !!name && !new RegExp(searchName, 'i').test(name)) {
                return false;
            }

            if (taskIds && !taskIds.includes(_id ?? offline_id)) {
                return false;
            }

            if (templateIds && (!meta.templateId || !templateIds.includes(meta.templateId))) {
                return false;
            }

            if (entityIds && (!meta.locationId || !entityIds.includes(meta.locationId))) {
                return false;
            }

            const taskUsers = [creator, ...assignedToUser ?? []];
            if (userIds && !userIds.some((user) => taskUsers.includes(user))) {
                return false;
            }

            return true;
        },
        sort: sortChronologically,
        table: dexie.tasks,
        query: async () => {
            if (!entityId || !userId || !email || !roles || condition.dexie === false) {
                return false;
            }

            const myTasks = await dexie.tasks
                .where('creator').equals(userId)
                .or('assignedToUser').equals(userId)
                .or('assignedToExternalUser').equals(email)
                .toArray();

            const entityTasks = hasRole(roles, Role.GLOBAL_ADMINISTRATOR) || hasRole(roles, Role.TASK_ADMINISTRATOR)
                ? await dexie.tasks
                    .where('context.entityId').equals(entityId)
                    .toArray()
                : [];

            return [
                ...myTasks,
                ...entityTasks.filter(({_id}) => !myTasks.find((task) => task._id === _id))
            ];
        }
    }, [condition.dexie, searchName, createdAfter, createdBefore, inspectionId, entityId, userId, email, templateIds, entityIds, userIds, taskIds, roles?.join('')]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId || condition.fetch === false) {
            return;
        }

        setFetchActive(true);
        getTasks({})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState, inspectionId]);

    return {
        ready,
        dexieActive,
        fetchActive,
        tasks,
        createTask: async (body) => {
            if (!userId || !entityId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'tasks',
                    resourceId: body.offline_id!,
                    userId,
                    entityId,
                    body
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        updateTask: async (taskId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !taskId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'tasks',
                    resourceId: taskId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
