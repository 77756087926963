import type {ChangeEventHandler, FunctionComponent} from 'react';
import React, {useEffect, useState} from 'react';
import {ripple} from '../../utilities/animations';
import {formatLocalDate} from '../../utilities/timing';
import './DateInput.scss';

export const DateInput: FunctionComponent<{
  value: Date | undefined;
  name: string;
  className?: string;
  disabled?: boolean;
  onChange: (date: string) => void;
  min?: boolean;
}> = ({value, name, onChange, className, disabled, min}) => {
    const classNames = [
        className,
        'date-input'
    ].join(' ').trim();

    const [tempValue, setTempValue] = useState<string>();

    useEffect(() => {
        setTempValue(value ? formatLocalDate(value, '-') : '');
    }, [value]);

    const onDateChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        onChange(event.target.value);
    };

    return (
        <input
            disabled={disabled}
            type="date"
            value={tempValue}
            name={name}
            min={min ? formatLocalDate(Date.now(), '-') : undefined}
            onClick={ripple}
            id={`date-input-${name}`}
            className={classNames}
            data-testid={`date-input-${name}`}
            onBlur={onDateChange}
            onChange={(event) => setTempValue(event.target.value)}
        />
    );
};
