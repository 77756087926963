import {workbox} from './workbox';

export enum UpdateStatus {
    CHECKING = 0,
    DOWNLOADING = 1,
    WAITING = 2,
    UP_TO_DATE = 3
}

const updateListeners: ((status: UpdateStatus) => void)[] = [(status) => {
    console.log('[SW]', 'UPDATE_STATUS', UpdateStatus[status]);
}];

const update = {
    isRedundant: false,
    lastChecked: Date.now(),

    _status: UpdateStatus.UP_TO_DATE,

    get status() {
        return this._status;
    },
    set status(updateStatus: UpdateStatus) {
        const previous = this._status;

        this._status = updateStatus;

        if (previous !== updateStatus) {
            updateListeners.forEach((listener) => listener(updateStatus));
        }
    }
};

export const getUpdateStatus = (): UpdateStatus => update.status;

export const getUpdateLastChecked = (): number => update.lastChecked;

export const downloadUpdate = async (): Promise<void> => {
    try {
        if (document.hidden || update.status !== UpdateStatus.UP_TO_DATE) {
            return;
        }

        update.lastChecked = Date.now();
        update.status = UpdateStatus.CHECKING;
        const registration = await navigator.serviceWorker.getRegistration();
        const newRegistration = await registration?.update() as unknown as (ServiceWorkerRegistration | undefined);

        update.status = newRegistration?.installing ? UpdateStatus.DOWNLOADING : UpdateStatus.UP_TO_DATE;
    } catch (error) {
        update.status = UpdateStatus.UP_TO_DATE;
    }
};

export const applyUpdate = (immediate: boolean = false) => {
    if (update.status === UpdateStatus.WAITING) {
        const callback = () => {
            if (update.status !== UpdateStatus.WAITING && timeout) {
                return clearInterval(timeout);
            }

            const focussedElement = document.querySelector(':focus');

            if (!focussedElement || !(/input|textarea|canvas/i).test(focussedElement.tagName)) {
                location.reload();
            }
        };

        const timeout = immediate ? callback() : setInterval(callback, 1000);
    }
};

export const addUpdateListener = (callback: (updateStatus: UpdateStatus) => void) => {
    updateListeners.push(callback);
    callback(update.status);
};

export const removeUpdateListener = (callback: (updateStatus: UpdateStatus) => void) => {
    const index = updateListeners.indexOf(callback);

    index >= 0 && updateListeners.splice(index, 1);
};

workbox.addEventListener('redundant', () => {
    update.isRedundant = true;
    update.status = UpdateStatus.DOWNLOADING;
});

workbox.addEventListener('activated', () => {
    update.status = update.isRedundant ? UpdateStatus.WAITING : UpdateStatus.UP_TO_DATE;
});
