import type {ChartDocument} from '../../../../../database/collections/charts';
import type {JsonPatch} from '../../../../../types/JsonPatch';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/charts/:chartId';

export const method = HttpMethod.PATCH;

export type BodyType = JsonPatch;

export type ParamType = {
    chartId: string;
};

export type QueryType = never;

export type OutputType = ChartDocument;
