export type RoleBit = 0 | 1;
export type RoleBits = RoleBit[];

export enum Role {
    GLOBAL_ADMINISTRATOR = 0,
    USER_ADMINISTRATOR = 1,
    ORGANISATION_ADMINISTRATOR = 2,
    TEMPLATE_ADMINISTRATOR = 3,
    TEAM_MEMBER = 4,
    EXCLUSIVE_LIBRARY_ADMINISTRATOR = 5,
    TASK_ADMINISTRATOR = 6
}

export const amountOfRoles = Object.keys(Role).length;

export const normalizeRoles = (roles: number[] | undefined = []): RoleBits => {
    if (roles.length === amountOfRoles && roles.every((role): role is RoleBit => role === 0 || role === 1)) {
        return roles;
    }

    return Array.from({length: amountOfRoles}, (_, i) => getRole(roles, i));
};

export const hasRole = (roles: number[], role: Role): boolean => !!roles[role];

export const getRole = (roles: number[], role: Role): RoleBit => roles[role] === 1 ? 1 : 0;

export const setRole = (roles: number[], role: Role, bit: RoleBit): RoleBits => setRoles(roles, [role], bit);

export const setRoles = (roles: number[], changeRoles: Role[], bit: RoleBit): RoleBits => {
    const clone = Array.from(roles);
    for (const role of changeRoles) {
        clone[role] = bit;
    }
    return normalizeRoles(clone);
};

export const canAssignNames = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR];

export const canAssignPasswords = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR];

export const canAssignRoles = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canCreateTemplates = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.TEMPLATE_ADMINISTRATOR];

export const canUpdateTemplates = canCreateTemplates;

export const canRemoveTemplates = canCreateTemplates;

export const canAssignTemplates = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR] || !!roles[Role.TEMPLATE_ADMINISTRATOR];

export const canCreateLocations = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR] || !!roles[Role.ORGANISATION_ADMINISTRATOR];

export const canUpdateLocations = canCreateLocations;

export const canRemoveLocations = canCreateLocations;

export const canCreateTasks = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.TASK_ADMINISTRATOR];

export const canUpdateTasks = canCreateTasks;

export const canRemoveTasks = canCreateTasks;

export const canAssignTasks = canCreateTasks;

export const canAssignLocations = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR] || !!roles[Role.ORGANISATION_ADMINISTRATOR];

export const canCreateUsers = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR];

export const canRemoveUsers = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR] || !!roles[Role.USER_ADMINISTRATOR];

export const canVerifyDomains = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canUpgradeSubscription = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canUpdateMemberships = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canCancelSubscription = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canCreateCharts = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export const canRemoveCharts = (roles: number[]): boolean => !!roles[Role.GLOBAL_ADMINISTRATOR];

export function isAssigned(singleId: string | undefined, assignedIds: string[]): boolean;
export function isAssigned(multipleIds: string[] | undefined, assignedIds: string[]): boolean;
export function isAssigned(singleOrMultipleIds: string | string[] | undefined, assignedIds: string[]): boolean {
    if (!singleOrMultipleIds) {
        return true;
    }

    if (typeof singleOrMultipleIds === 'string') {
        return assignedIds.includes(singleOrMultipleIds);
    }

    return singleOrMultipleIds.every((singleId) => assignedIds.includes(singleId));
}
