import type {ChartSegmentDocument} from '../../../../../../database/collections/chartSegments';
import {HttpMethod} from '../../../../../HttpMethod';

export const path = '/apiv2/charts/:chartId/chart-segments';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {

    /**
     * Pick results that match the specified chart _id;
     */
    chartId: string;
};

export type QueryType = {

    /**
     * Pick results have been removed.
     */
    isRemoved?: boolean;
};

export type OutputType = ChartSegmentDocument[];
