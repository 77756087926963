import type {Table} from 'dexie';
import {useLiveQuery} from 'dexie-react-hooks';
import type {ObjectId} from 'mongodb';
import {useEffect, useMemo, useState} from 'react';
import {dexie} from '../../dexie';
import {addEventListener} from '../../dexie/changeListener';
import type {QueueCreateEntry, QueueUpdateEntry} from '../../dexie/dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {hasProperty} from '../../types';
import {applyJsonPatch} from '../../types/JsonPatch';
import {isOfflineObjectId} from '../../types/MongoDb';
import {emptyArray} from '../../util/arrays';

export const useDexieResource = <Document extends {
    _id?: ObjectId | undefined;
    offline_id?: string | undefined;
}, PrimaryKey extends string | number = string>({table, primaryKey}: {
    table: Table<JsonOf<Document>, PrimaryKey>;
    primaryKey: PrimaryKey | undefined;
}): {
    dexieActive: boolean;
    resource: JsonOf<Document> | null;
} => {
    const [dexieActive, setDexieActive] = useState(true);
    const [resource, setResource] = useState<JsonOf<Document> | null>(null);

    const create = useLiveQuery<QueueCreateEntry | null, null>(async () => {
        if (primaryKey === undefined) {
            return null;
        }

        const result = await dexie.queue
            .where(['resourceId', 'action'])
            .equals([primaryKey, 'create'])
            .first();

        return result ? result as QueueCreateEntry : null;
    }, [primaryKey], null);

    const updates = useLiveQuery<QueueUpdateEntry[], QueueUpdateEntry[]>(async () => {
        if (primaryKey === undefined) {
            return emptyArray;
        }

        const results = await dexie.queue
            .where(['resourceId', 'action'])
            .equals([primaryKey, 'update'])
            .toArray();

        return results.length
            ? results as QueueUpdateEntry[]
            : emptyArray;
    }, [table.name], emptyArray);

    const patchedResource = useMemo<JsonOf<Document> | null>(() => {
        console.log(`[useDexieResource] ${table.name} resource=${resource ? 1 : 0} create=${create ? 1 : 0} updates=${updates.length}`, {
            resource,
            create,
            updates
        });

        const patched = resource ?? create?.body;

        if (!patched) {
            return null;
        }

        const patches = updates
            .filter(({resourceId}) => resourceId === patched._id || resourceId === patched.offline_id)
            .map(({patch}) => patch)
            .flat(2)
            .filter(({op}) => op !== 'test');

        return applyJsonPatch<JsonOf<Document>>(patched as JsonOf<Document>, patches);
    }, [resource, create, updates]);

    useEffect(() => {
        if (!primaryKey) {
            setResource(null);
            return;
        }

        if (typeof primaryKey === 'string' && isOfflineObjectId(primaryKey) && table.schema.indexes.find(({name}) => name === 'offline_id')) {
            setDexieActive(true);
            table.where('offline_id').equals(primaryKey).first().then((resource) => {
                setDexieActive(false);
                if (!hasProperty(resource, '_id')) {
                    return;
                }

                setResource(resource);
            });
        } else {
            setDexieActive(true);
            table.get(primaryKey).then((resource) => {
                setDexieActive(false);
                if (!hasProperty(resource, '_id')) {
                    return;
                }

                setResource(resource);
            });
        }
    }, [primaryKey]);

    useEffect(() => primaryKey && addEventListener('create', (event) => {
        const {table: tableName, obj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id') || record._id !== primaryKey && record.offline_id !== primaryKey) {
            return;
        }

        setResource(record);
    }), [primaryKey]);

    useEffect(() => primaryKey && addEventListener('update', (event) => {
        const {table: tableName, obj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id') || record._id !== primaryKey && record.offline_id !== primaryKey) {
            return;
        }

        setResource(record);
    }), [primaryKey]);

    useEffect(() => primaryKey && addEventListener('delete', (event) => {
        const {table: tableName, oldObj: record} = event.detail;

        if (tableName !== table.name || !hasProperty(record, '_id') || record._id !== primaryKey && record.offline_id !== primaryKey) {
            return;
        }

        setResource(null);
    }), [primaryKey]);

    return {resource: patchedResource, dexieActive};
};

