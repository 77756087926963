import type {JsonPointer} from './JsonPointer';
import type {JsonPointerPart} from './JsonPointerPart';

export const escapeJsonPointerPart = (part: JsonPointerPart): string => typeof part === 'number'
    ? `${part}`
    : part
        .replace(/~/g, '~0')
        .replace(/\//g, '~1');

export const unescapeJsonPointerPart = (part: string): JsonPointerPart => {
    const stringValue = part.replace(/~1/g, '/').replace(/~0/g, '~');
    const numberValue = parseInt(stringValue);

    return stringValue === numberValue.toString() ? numberValue : stringValue;
};

export const createJsonPointer = (...parts: JsonPointerPart[]): JsonPointer => `/${parts.map(escapeJsonPointerPart).join('/')}`;

export const destructJsonPointer = (pointer: JsonPointer): JsonPointerPart[] => pointer
    .replace(/^\//, '')
    .replace(/\/-$/, '')
    .split('/').map(unescapeJsonPointerPart);
