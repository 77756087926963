import {dexie} from '../../../../../dexie';
import {fetchApi} from '../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path} from './shared';

export const replaceSession = async (urlParams: ParamType, body: BodyType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body,
        method,
        path,
        urlParams
    });

    const {response, json} = result;
    if (response.ok && json) {
        await dexie.sessions.put(json);
    }

    return result;
};
