import type {ExclusiveLibraryDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/exclusive-libraries';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results that are owned by the domain of the user;
     */
    isOwner?: boolean;

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'created' | 'name'}`;
};

export type OutputType = ExclusiveLibraryDocument[];
