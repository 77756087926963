import type {ChangeEvent, ChangeEventHandler, ReactElement, VoidFunctionComponent} from 'react';
import React, {useRef, useState} from 'react';
import Search from '../../../frontend/icons/Search.svg';
import {classNames} from '../../helpers/styling';
import {useUrlState} from '../../hooks';
import {Icoontje} from '../Icoontje';
import {Knopje} from '../Knopje';
import './SearchBar.scss';

export type SearchBarProps = {
    placeholder: string;
    description: string;
    className?: string;
    autoFocus?: boolean;
    searchButton?: boolean;
    defaultValue?: string;
    name?: string;
    value?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
};

export type SearchBarComponent = ReactElement<SearchBarProps>;

export const SearchBar: VoidFunctionComponent<SearchBarProps> = ({
    name,
    placeholder,
    defaultValue,
    description,
    className,
    autoFocus = false,
    value,
    onChange,
    searchButton = false
}): SearchBarComponent => {
    const inputElementRef = useRef<HTMLInputElement>(null);
    const [localValue, setLocalValue] = name ? useUrlState<string>(name, '') : useState<string>(value!);
    const divClassName = classNames('search-bar', {}, className);

    return (
        <form
            className={divClassName}
            onSubmit={(event) => {
                event.preventDefault();
                inputElementRef.current?.blur();

                searchButton && onChange?.({
                    target: {
                        value: localValue
                    },
                    currentTarget: {
                        value: localValue
                    }
                } as ChangeEvent<HTMLInputElement>);
            }}
        >
            <Icoontje
                className="search-bar__icon"
                size="standard"
                color={value ? 'orange' : 'gray'}
                Svg={Search}
            />
            <input
                ref={inputElementRef}
                defaultValue={defaultValue}
                className="search-bar__input"
                type="search"
                name={name}
                value={localValue}
                onChange={(event) => {
                    setLocalValue(event.currentTarget.value);
                    !searchButton && onChange?.(event);
                }}
                placeholder={placeholder}
                autoFocus={autoFocus && !localValue}
                aria-label={description}
                onBlur={(event) => searchButton && onChange?.(event)}
            />
            {searchButton
                ? (
                    <Knopje type="submit" paint="fill" shape="square" color="blue" corners="smooth" className="search-bar__button">
                        <Icoontje color="white" Svg={Search}/>
                    </Knopje>
                )
                : null}
        </form>
    );
};
