import {Workbox} from 'workbox-window';

let _isControlling = false;

export const workbox = new Workbox('/service-worker.js');

export const registerServiceWorker = () => workbox.register({immediate: true});

export const isServiceWorkerControlling = (): boolean => _isControlling;

export const serviceWorkerControlling = workbox.controlling.then(() => _isControlling = true);

export const getNotificationPermission = async (): Promise<NotificationPermission> => {
    if (!window.Notification) {
        return 'denied';
    }

    if (Notification.permission !== 'default') {
        return Notification.permission;
    }

    return new Promise<NotificationPermission>((resolve) => {
        Notification.requestPermission(resolve)?.then(resolve).catch(() => resolve('denied'));
    });
};

export const getNotificationSubscription = async (registration: ServiceWorkerRegistration): Promise<PushSubscription | null> => {
    // TODO: https://checkbuster.atlassian.net/browse/CB-829
    const publicKey = 'BBz2e5RHM3K1pvjSHSFiBKWQ_uZ2yrJQSJIB1wYcsNjPCZK8ioCzYEjSNpmn86g74UZ3UOS5jwUx_Ve0fv4XrFE';

    const convertPublicKey = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    };

    return await registration.pushManager.getSubscription() ?? registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: convertPublicKey(publicKey)
    });
};
