import type {EntityDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/locations/:locationId';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = {
    locationId: string;
};

export type QueryType = never;

export type OutputType = Omit<EntityDocument, 'apps' | 'branch' | 'classicLocationId' | 'classicParentId' | 'code' | 'device' | 'exclusiveLibrary' | 'photoSettings' | 'resellerId' | 'token' | 'type' | 'uid' | 'version'>;
