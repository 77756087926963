import type {FunctionComponent} from 'react';
import React from 'react';
import type {ButtonProps} from '../Button';
import {Button} from '../Button';
import './ContainedButton.scss';

export const ContainedButton: FunctionComponent<ButtonProps & {
    color?: 'red' | 'green' | 'blue' | 'white' | 'white-green' | 'black';
}> = ({className, color = 'blue', ...rest}) => {
    const classNames = [
        'button--style-contained',
        `button--color-${color}`,
        className
    ].join(' ').trim();

    return (
        <Button className={classNames} {...rest}/>
    );
};
