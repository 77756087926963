import type {ReactElement, VoidFunctionComponent} from 'react';
import React, {useState} from 'react';
import {List} from '../../../frontend/components/List';
import {ListItem} from '../../../frontend/components/ListItem';
import {RadioButton} from '../../../frontend/components/RadioButton';
import {__} from '../../../translations';
import {useCurrentUser} from '../../context';
import {useInspectionTemplates, useUrlStateArray} from '../../hooks';
import {Knopje} from '../Knopje';
import {SearchBar} from '../SearchBar';
import {Slideout} from '../Slideout';
import {Tekst} from '../Tekst';
import {Checkbox} from '../toggles/Checkbox';

export type InspectionTemplateSlideoutProps = {
    show: 'all' | 'assigned';
    radio?: boolean;
    headerText?: string;
    inspectionTemplateIds?: string[];
    setInspectionTemplateIds?: (userIds) => void;
    onCheckboxChange?: (templateId: string, value: boolean, name?: string) => void;
};

export type SlideoutComponent = ReactElement<InspectionTemplateSlideoutProps>;

export const InspectionTemplateSlideout: VoidFunctionComponent<InspectionTemplateSlideoutProps> = ({
    show,
    radio,
    headerText,
    inspectionTemplateIds,
    setInspectionTemplateIds,
    onCheckboxChange = (_id, value, name) => {
        if (value) {
                                                                                                               setInspectionTemplateIds!([...inspectionTemplateIds!, _id]);
        } else {
            const filtered = inspectionTemplateIds!.filter((userId) => userId !== _id);
                                                                                                               setInspectionTemplateIds!(filtered);
        }
    }
}): SlideoutComponent => {
    if (!inspectionTemplateIds || !setInspectionTemplateIds) {
        [inspectionTemplateIds, setInspectionTemplateIds] = useUrlStateArray('inspectionTemplateIds', []);
    }
    const {currentUser: authenticatedUser} = useCurrentUser();

    const [searchValue, setSearchValue] = useState<string>('');
    const onSearchChange = (event) => {
        setSearchValue(event.currentTarget.value);
    };

    const {inspectionTemplates} = useInspectionTemplates({
        name: searchValue || undefined,
        inspectionTemplateIds: show === 'all' ? undefined : authenticatedUser?.templates
    });

    return (
        <Slideout onReset={() => setInspectionTemplateIds!([])}>
            <SearchBar
                placeholder={__('Search inspection templates')}
                description={__('Search for inspection templates based on their name')}
                className="slideout__search"
                value={searchValue}
                onChange={onSearchChange}
            />
            <Tekst weight="bold" className="slideout__title">
                {headerText ?? __('Filter inspection templates')}
            </Tekst>
            {!headerText
                ? (
                    <Knopje color="white" paint="fill" type="reset" className="slideout__clear">
                        <Tekst color="blue">
                            {__('Clear all')}
                        </Tekst>
                    </Knopje>
                )
                : <div/>}
            <div className="slideout__list">
                <List>
                    {inspectionTemplates?.map(({_id, name}) => {
                        const selected = !!inspectionTemplateIds!.find((inspectionTemplateId) => inspectionTemplateId === _id);
                        return (
                            <ListItem key={_id}>
                                {radio
                                    ? (
                                        <RadioButton
                                            label={name} checked={selected}
                                            value={selected ? 'checked' : 'unchecked'}
                                            onChange={(value) => onCheckboxChange(_id, value === 'checked', name)}/>
                                    )
                                    : (
                                        <Checkbox
                                            checked={selected ? 'checked' : 'unchecked'}
                                            onChange={(value) => onCheckboxChange(_id, value === 'checked', name)}>
                                            {name}
                                        </Checkbox>
                                    )}
                            </ListItem>
                        );
                    })}
                </List>
            </div>
        </Slideout>
    );
};
