import {captureException} from '@sentry/react';
import {useEffect, useMemo, useState} from 'react';
import type {QueryType} from '../../api/endpoints/exclusive-libraries/get/frontend';
import {getExclusiveLibraries} from '../../api/endpoints/exclusive-libraries/get/frontend';
import type {ExclusiveLibraryDocument} from '../../database';
import type {JsonOf} from '../../interfaces/helpers';
import {emptyArray} from '../../util/arrays';
import {noop} from '../../util/functions';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useVisibilityState} from './useVisibilityState';

export const useLibraries = (filter?: QueryType): {
    ready: boolean;
    fetchActive: boolean;
    libraries: JsonOf<ExclusiveLibraryDocument>[];
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive: true, fetchActive});
    const [libraries, setLibraries] = useState<JsonOf<ExclusiveLibraryDocument>[]>(emptyArray);
    const visibilityState = useVisibilityState();
    const refresh = useMemo(() => visibilityState, [visibilityState]);

    useEffect(() => {
        if (!userId) {
            return;
        }

        let aborted = false;

        setFetchActive(true);
        getExclusiveLibraries(filter)
            .then(({json, response}) => {
                !aborted && setLibraries(response.ok && json ? json as JsonOf<ExclusiveLibraryDocument>[] : emptyArray);
            })
            .catch((error) => {
                captureException(error);
                !aborted && setLibraries(emptyArray);
            })
            .then(() => setFetchActive(false), noop);

        return () => {
            aborted = true;
        };
    }, [userId, refresh, filter?.isOwner, filter?.name, filter?.sort]);

    return {
        ready,
        fetchActive,
        libraries
    };
};
