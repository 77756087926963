import type {PublicSessionDocument} from '../../../../../database';
import {HttpMethod} from '../../../../HttpMethod';

export const path = '/apiv2/sessies/:sessionId';

export const method = HttpMethod.PUT;

export type BodyType = {
    email: string;
    password: string;
};

export type ParamType = {
    sessionId: string;
};

export type QueryType = never;

export type OutputType = PublicSessionDocument;
