import type {ReactElement, VoidFunctionComponent} from 'react';
import React from 'react';
import {Tekst} from '../../../Tekst';
import type {RadioButtonProps} from '../../../toggles/RadioButton';
import './TextVariant.scss';

export type TextVariantProps = Required<Pick<RadioButtonProps, 'checked'>> & {
    locationId: string;
    locationName: string;
};

export type TextVariantComponent = ReactElement<TextVariantProps>;

export const TextVariant: VoidFunctionComponent<TextVariantProps> = ({
    checked,
    locationId,
    locationName,
    ...rest
}): TextVariantComponent => (
    <Tekst {...rest} weight="bold" color={checked === 'checked' ? 'blue' : 'gray'}>
        {locationName}
    </Tekst>
);
