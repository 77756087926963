import {useLiveQuery} from 'dexie-react-hooks';
import {useMemo} from 'react';
import type {TaskDocument} from '../../database';
import {dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import {hasRole, Role} from '../../util/permissions';
import {useCurrentUser} from '../context';

export const useTaskCount = (): {
    taskCount: number;
} => {
    const {currentUser: authenticatedUser} = useCurrentUser();
    const userId = authenticatedUser?._id;
    const email = authenticatedUser?.email;
    const roles = authenticatedUser?.roles;
    const entityId = authenticatedUser?.context?.entityId;

    const filterFunction = useMemo(() => ({
        _deleted,
        creator,
        assignedToUser,
        assignedToExternalUser,
        state,
        title,
        description,
        context
    }: JsonOf<TaskDocument>) => {
        if (_deleted || !userId || !email || !roles || !entityId || state === 'done') {
            return false;
        }

        if ((hasRole(roles, Role.GLOBAL_ADMINISTRATOR) || hasRole(roles, Role.TASK_ADMINISTRATOR)) && context.entityId === entityId) {
            return true;
        }

        if (creator !== userId && !assignedToUser.includes(userId) && !assignedToExternalUser.includes(email)) {
            return false;
        }

        return true;
    }, [userId, email, roles]);

    const tasksStored = useLiveQuery<number | undefined>(async () => {
        if (!entityId) {
            return;
        }

        return dexie.tasks
            .filter(filterFunction)
            .count();
    }, [entityId, filterFunction]) ?? 0;

    const tasksCreated = useLiveQuery<number | undefined>(async () => dexie.queue
        .where(['resourceTableName', 'action']).equals([dexie.tasks.name, 'create'])
        .and(({body}) => {
            const task = body as JsonOf<TaskDocument>;
            return filterFunction(task);
        })
        .count(), [filterFunction]) ?? 0;

    return {
        taskCount: tasksStored + tasksCreated
    };
};
