import type {BodyType, OutputType} from '../../api/endpoints/photos/post/shared';
import type {PublicSessionDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import {loadAndResizeImageFile, parseDataUrl} from '../../frontend/utilities/image';
import type {JsonOf} from '../../interfaces/helpers';
import {createOfflineObjectId, isOfflineObjectId} from '../../types/MongoDb';
import {sanitizeFilename} from '../../util/strings';

type PhotoDocumentOffline = JsonOf<Required<Omit<OutputType, '_id' | 'updatedAt' | 'updatedBy' | 'expiresAt'>>>;
type PhotoDocumentQueueCreate = PhotoDocumentOffline & JsonOf<Required<BodyType>>;

export const getOnlinePhotoUrl = (photo_id: string): string => `/apiv2/resources/photos/${photo_id}`;

export const getOfflinePhotoUrl = (photo_id: string): Promise<string> => dexie.queue.where('[resourceId+action]')
    .equals([photo_id, 'create'])
    .first()
    .then((queueEntry) => {
        if (!queueEntry) {
            return '';
        }

        const {
            content,
            contentEncoding,
            contentType
        } = queueEntry.body as PhotoDocumentQueueCreate;

        return `data:${contentType};${contentEncoding},${content}`;
    });

export const getPhotoUrl = async (photo_id: string): Promise<string> => isOfflineObjectId(photo_id)
    ? getOfflinePhotoUrl(photo_id)
    : getOnlinePhotoUrl(photo_id);

export const createPhoto = async ({file, maxSize, body, session}: {
    file: File;
    maxSize: number;
    body: JsonOf<Omit<BodyType, 'content' | 'contentEncoding' | 'contentType'>>;
    session: JsonOf<PublicSessionDocument>;
}): Promise<PhotoDocumentOffline> => {
    const {dataUrl: photoSource} = await loadAndResizeImageFile(file, maxSize);
    const {content, contentEncoding, contentType} = parseDataUrl(photoSource) ?? {};

    if (!content || !contentEncoding || !contentType) {
        throw new Error('Cannot create new photo without a photo file');
    }

    const offline_id = createOfflineObjectId();
    const entity_id = session.entity_id!;
    const user_id = session.user_id;

    const photo: PhotoDocumentOffline = {
        createdAt: Date.now(),
        createdBy: user_id,
        description: body.description || '',
        entity_id,
        file_bytes: file.size,
        file_hash: '',
        file_type: contentType as `image/${string}`,
        name: sanitizeFilename(body.name || file.name),
        offline_id,
        removed: 0,
        touchedAt: Date.now(),
        touchedBy: user_id
    };

    const create: PhotoDocumentQueueCreate = {
        ...photo,
        content,
        contentEncoding: contentEncoding as 'base64',
        contentType: contentType as `image/${string}`
    };

    await addToQueue({
        timestamp: Date.now(),
        action: 'create',
        resourceTableName: 'photos',
        resourceId: offline_id,
        userId: user_id,
        entityId: entity_id,
        body: create
    });

    return photo;
};

export const usePhotos = ({session}: {
    session: JsonOf<PublicSessionDocument> | null;
}) => ({
    createPhoto: (file: File, maxSize: number, body: JsonOf<Omit<BodyType, 'content' | 'contentEncoding' | 'contentType'>>): Promise<PhotoDocumentOffline> => {
        if (!session) {
            throw new Error('Cannot create new photo without session');
        }

        return createPhoto({file, maxSize, body, session});
    }
});
