import type {EntityDocument} from '../../database';
import type {JsonOf} from '../../interfaces/helpers';

export type EntityRelationshipNode = {
    current: JsonOf<EntityDocument>;
    children: EntityRelationshipNode[];
};

export const createEntityRelationshipMap = (entities: JsonOf<EntityDocument>[]): Record<string, JsonOf<EntityDocument>[]> => {
    const result: Record<string, JsonOf<EntityDocument>[]> = {};

    for (const entity of entities) {
        result[entity._id] ??= [];

        if (entity.parentId) {
            result[entity.parentId] ??= [];
            result[entity.parentId].push(entity);
        }
    }

    return result;
};

export const createEntityTree = (rootEntity: JsonOf<EntityDocument>, childEntityMap: Record<string, JsonOf<EntityDocument>[]>): EntityRelationshipNode => ({
    current: rootEntity,
    children: childEntityMap[rootEntity._id]?.map((childEntity) => createEntityTree(childEntity, childEntityMap)) ?? []
});

export const toEntityArray = (tree: EntityRelationshipNode): JsonOf<EntityDocument>[] => {
    const recurse = (node: EntityRelationshipNode, result: JsonOf<EntityDocument>[]) => {
        result.push(node.current);
        node.children.forEach((n) => recurse(n, result));
        return result;
    };

    return recurse(tree, []);
};
