import type {TypeDef} from '../../../interfaces/models/json-type-definition';
import type {InspectionPlanDocument} from './InspectionPlanDocument';

export const inspectionPlanTypeDef: TypeDef<InspectionPlanDocument> = {
    additionalProperties: false,
    optionalProperties: {
        _deleted: {
            type: 'boolean'
        },
        1: {
            type: 'string'
        },
        createdAt: {
            type: 'float64'
        },
        createdBy: {
            type: 'string'
        },
        device: {
            type: 'string'
        },
        executedOnServer: {
            type: 'float64'
        },
        expiresAt: {
            type: 'timestamp'
        },
        language: {
            type: 'string'
        },
        offline_id: {
            type: 'string'
        },
        removed: {
            type: 'float64'
        },
        removedAt: {
            type: 'float64'
        },
        removedBy: {
            type: 'string'
        },
        tags: {
            elements: {
                type: 'string'
            }
        },
        touchedAt: {
            type: 'float64'
        },
        touchedBy: {
            type: 'string'
        },
        uid: {
            type: 'string'
        },
        updated: {
            type: 'float64'
        },
        updatedAt: {
            type: 'float64'
        },
        updatedBy: {
            type: 'string'
        }
    },
    properties: {
        _id: {
            type: 'string'
        },
        active: {
            type: 'boolean'
        },
        context: {
            additionalProperties: false,
            properties: {
                entityId: {
                    type: 'string'
                }
            }
        },
        created: {
            type: 'float64'
        },
        creator: {
            type: 'string'
        },
        dueDate: {} as never,
        dueTime: {} as never,
        endDate: {} as never,
        endTime: {} as never,
        location: {
            type: 'string'
        },
        locationName: {
            type: 'string'
        },
        name: {
            type: 'string'
        },
        notifications: {
            additionalProperties: false,
            properties: {
                close: {
                    additionalProperties: false,
                    properties: {
                        number: {
                            type: 'string'
                        },
                        onEvent: {
                            type: 'boolean'
                        },
                        period: {
                            type: 'string'
                        }
                    }
                },
                due: {
                    additionalProperties: false,
                    properties: {
                        number: {
                            type: 'string'
                        },
                        onEvent: {
                            type: 'boolean'
                        },
                        period: {
                            type: 'string'
                        }
                    }
                }
            }
        },
        repeat: {
            additionalProperties: false,
            properties: {
                days: {
                    additionalProperties: false,
                    properties: {
                        friday: {
                            type: 'boolean'
                        },
                        monday: {
                            type: 'boolean'
                        },
                        saturday: {
                            type: 'boolean'
                        },
                        sunday: {
                            type: 'boolean'
                        },
                        thursday: {
                            type: 'boolean'
                        },
                        tuesday: {
                            type: 'boolean'
                        },
                        wednesday: {
                            type: 'boolean'
                        }
                    }
                },
                endDate: {} as never,
                endTime: {} as never,
                every: {
                    additionalProperties: false,
                    properties: {
                        number: {
                            type: 'string'
                        },
                        period: {
                            type: 'string'
                        }
                    }
                },
                times: {
                    type: 'string'
                }
            }
        },
        startDate: {} as never,
        startTime: {} as never,
        template: {
            type: 'string'
        },
        templateName: {
            type: 'string'
        },
        users: {
            elements: {
                type: 'string'
            }
        }
    }
};
