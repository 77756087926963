import type {InspectionDocument} from '../../../../../database';
import {dexie} from '../../../../../dexie';
import type {JsonOf} from '../../../../../interfaces/helpers';
import {fetchApi} from '../../../../helpers/frontend';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {path, method} from './shared';

export const updateInspection = (urlParams: ParamType, body: BodyType) => {
    const migratedBody = body.map((jsonOperation) => {
        if (jsonOperation.op === 'replace' && jsonOperation.path.endsWith('/input/options') && Array.isArray(jsonOperation.value)) {
            return {
                op: jsonOperation.op,
                path: jsonOperation.path,
                value: (jsonOperation.value as Partial<{
                    selected: boolean;
                    text: string;
                    value: number;
                }>[]).map((option) => ({
                    selected: !!option.selected,
                    text: option.text ?? '',
                    value: option.value ?? 0
                }))
            };
        }
        return jsonOperation;
    });

    return fetchApi<BodyType, OutputType, ParamType, QueryType>({
        body: migratedBody,
        method,
        path,
        urlParams
    }).then(async (result) => {
        result.response.ok && result.json && await dexie.inspections.put(result.json as unknown as JsonOf<InspectionDocument>);
        return result;
    });
};
