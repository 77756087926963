import type {HttpMethod} from '../../api/HttpMethod';
import type {Serializable} from '../../types/Serializable';

export const eventTarget = new EventTarget();

export type RequestEvent = {
    url: string;
    method: HttpMethod;
};

export type ResponseEvent = {
    url: string;
    status: number;
    ok: boolean;
    json: Serializable;
    method: HttpMethod;
};

export const addEventListener: {
    (type: 'request', listener: (event: CustomEvent<RequestEvent>) => void, options?: AddEventListenerOptions): () => void;
    (type: 'response', listener: (event: CustomEvent<ResponseEvent>) => void, options?: AddEventListenerOptions): () => void;
} = (type, listener, options) => {
    eventTarget.addEventListener(type, listener as EventListener, options);

    return () => removeEventListener(type, listener, options);
};

export const removeEventListener: {
    (type: 'request', listener: (event: CustomEvent<RequestEvent>) => void, options?: AddEventListenerOptions);
    (type: 'response', listener: (event: CustomEvent<ResponseEvent>) => void, options?: AddEventListenerOptions);
} = (type, listener, options) => {
    eventTarget.removeEventListener(type, listener as EventListener, options);
};
