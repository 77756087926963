import {captureException} from '@sentry/react';
import type {ChangeEvent, MouseEvent, ReactNode} from 'react';
import React, {useEffect, useRef, useState, forwardRef} from 'react';
import {classNames} from '../../../helpers/styling';
import {ScreenReader} from '../../ScreenReader';
import type {ToggleDescription} from '../ToggleDescription';
import './Toggle.scss';

export type ToggleCheckedState = 'checked' | 'unchecked' | 'indeterminate';

export type ToggleProps<T = ToggleCheckedState> = {

    /**
     * If provided, used when the Toggle is disabled while processing `onChange`.
     *
     * Message is shown as tooltip where possible and as alert when clicked.
     * Triggers screen readers to read the message when the message is changed.
     */
    busyMessage?: string;

    /**
     * If provided, provides full control over the state of the Toggle.
     * Requires `onChange` to be provided to handle user input.
     */
    checked?: T;

    /**
     * Preferably a `ToggleDescription` component.
     *
     * Custom children are possible but will not be styled by the Toggle.
     */
    children?: typeof ToggleDescription | ReactNode;

    /**
     * If provided, sets the default state of the Toggle.
     * Cannot be changed once set.
     */
    defaultChecked?: T;

    /**
     * If provided, disables the Toggle.
     *
     * Message is shown as tooltip where possible and as alert when clicked.
     * Triggers screen readers to read the message when the message is changed.
     */
    disabled?: string;

    /**
     * The name of the Toggle when part of a `form`.
     */
    name?: string;

    /**
     * Called immediately when the user attempts to change the state of the Toggle.
     * Requires `checked` to be provided to handle user input.
     */
    onChange?: (checked: T, event: ChangeEvent<HTMLInputElement>) => unknown;

    /**
     * Called when the user attempts to right-click or long-press the Toggle.
     */
    onContextMenu?: (checked: T, input: HTMLInputElement) => unknown;

    /**
     * Only provide if the Toggle is part of a `form`.
     */
    required?: boolean;

    /**
     * If provided, disables the Toggle.
     *
     * Message is shown as tooltip where possible and as alert when clicked.
     * Triggers screen readers to read the message when the checkbox is focussed.
     */
    tooltip?: string;

    /**
     * The value of the Toggle when part of a `form`.
     */
    value?: string;

    variant?: 'checkbox' | 'radio' | 'switch';
};

/**
 * @see {Checkbox}
 * @see {RadioButton}
 * @see {Switch}
 * @see {ToggleDescription}
 * @see {ToggleProps}
 */
export const Toggle = forwardRef<HTMLLabelElement, ToggleProps>(({
    busyMessage,
    checked,
    children,
    defaultChecked,
    disabled,
    name,
    onChange,
    onContextMenu,
    required,
    tooltip,
    value = '',
    variant = 'checkbox',
    ...rest
}, labelRef) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [busy, setBusy] = useState(false);
    const [showBusyMessage, setShowBusyMessage] = useState(false);
    const currentChecked = checked ?? defaultChecked ?? 'indeterminate';

    async function handleChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (busy || disabled) {
            event.preventDefault();
        }

        if (disabled) {
            return alert(disabled);
        }

        if (busy) {
            return setShowBusyMessage(true);
        }

        if (!onChange) {
            return;
        }

        setBusy(true);

        try {
            await onChange(event.target.checked ? 'checked' : 'unchecked', event);
        } catch (error) {
            console.error(error);
            captureException(error);
        } finally {
            setBusy(false);
            setShowBusyMessage(false);
        }
    }

    async function handleContextMenu(event: MouseEvent<HTMLLabelElement>): Promise<void> {
        if (busy || disabled || onContextMenu) {
            event.preventDefault();
        }

        if (disabled) {
            return alert(disabled);
        }

        if (busy) {
            return setShowBusyMessage(true);
        }

        if (!onContextMenu || !inputRef.current) {
            return;
        }

        setBusy(true);

        try {
            await onContextMenu(inputRef.current.checked ? 'checked' : 'unchecked', inputRef.current);
        } catch (error) {
            console.error(error);
            captureException(error);
        } finally {
            setBusy(false);
            setShowBusyMessage(false);
        }
    }

    function handleClick(event: MouseEvent<HTMLInputElement>): void {
        if (busy || disabled) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        inputRef.current!.indeterminate = currentChecked === 'indeterminate';
    }, [currentChecked, inputRef.current]);

    return (
        <label
            className={classNames('toggle', {
                [currentChecked]: currentChecked,
                [variant]: variant,
                busy,
                disabled
            })}
            onContextMenu={handleContextMenu}
            ref={labelRef}
            title={disabled || busy && busyMessage || tooltip}
        >
            <input
                {...rest}
                aria-busy={busy}
                aria-checked={checked === 'indeterminate' ? 'mixed' : checked === 'checked'}
                aria-disabled={!!disabled}
                checked={checked && checked === 'checked'}
                className="toggle__input"
                defaultChecked={defaultChecked && defaultChecked === 'checked'}
                name={name}
                onChange={handleChange}
                onClick={handleClick}
                ref={inputRef}
                required={required}
                role={variant}
                type={variant === 'radio' ? 'radio' : 'checkbox'}
                value={value}
            />
            {
                children
                    ? (
                        <div className="toggle__content">
                            {children}
                        </div>
                    )
                    : null
            }
            <ScreenReader message={disabled || showBusyMessage && busyMessage || ''}/>
        </label>
    );
});
