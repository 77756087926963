import type {UserWithContextDocument} from '../../../../database';
import {HttpMethod} from '../../../HttpMethod';

export const path = '/apiv2/users';

export const method = HttpMethod.GET;

export type BodyType = never;

export type ParamType = never;

export type QueryType = {

    /**
     * Pick results created after the specified timestamp.
     */
    createdAfter?: number;

    /**
     * Pick results created before the specified timestamp.
     */
    createdBefore?: number;

    /**
     * Pick results created by any of the specified user ids.
     */
    createdByAny?: string[];

    /**
     * Pick results partially matching email.
     */
    email?: string;

    /**
     * Pick results expiring after the specified timestamp.
     */
    expiresAfter?: number;

    /**
     * Pick results expiring before the specified timestamp.
     */
    expiresBefore?: number;

    /**
     * Pick results that have an expiry date set.
     */
    hasExpirationDate?: boolean;

    /**
     * Pick results that have been updated at least once.
     */
    hasUpdatedDate?: boolean;

    /**
     * Pick results that are global administrator.
     */
    isGlobalAdministrator?: boolean;

    /**
     * Pick results that are library administrator.
     */
    isLibraryAdministrator?: boolean;

    /**
     * Pick results that are location administrator.
     */
    isLocationAdministrator?: boolean;

    /**
     * Pick results that have been removed.
     */
    isRemoved?: boolean;

    /**
     * Pick results that are task administrator.
     */
    isTaskAdministrator?: boolean;

    /**
     * Pick results that are team member.
     */
    isTeamMember?: boolean;

    /**
     * Pick results that are template administrator.
     */
    isTemplateAdministrator?: boolean;

    /**
     * Pick results that are user administrator.
     */
    isUserAdministrator?: boolean;

    /**
     * Pick results that are verified.
     */
    isVerified?: boolean;

    /**
     * Pick results that fit all of given location ids.
     */
    locationIdAll?: string[];

    /**
     * Pick results that fit any of given location ids.
     */
    locationIdAny?: string[];

    /**
     * Pick results that logged in after the specified timestamp.
     */
    loggedInAfter?: number;

    /**
     * Pick results that logged in before the specified timestamp.
     */
    loggedInBefore?: number;

    /**
     * Pick results that (partially) match the specified name;
     * case-insensitive.
     */
    name?: string;

    /**
     * Pick results that need a password.
     */
    needsPassword?: boolean;

    /**
     * Pick results removed after the specified timestamp.
     */
    removedAfter?: number;

    /**
     * Pick results removed before the specified timestamp.
     */
    removedBefore?: number;

    /**
     * Pick results removed by any of the specified user ids.
     */
    removedByAny?: string[];

    /**
     * Sorts results in either ascending (+) or descending order (-) based
     * on the specified property name.
     */
    sort?: `${'+' | '-'}${'touchedAt' | 'createdAt' | 'updatedAt'}`;

    /**
     * Pick results that fit all of given template ids.
     */
    templateIdAll?: string[];

    /**
     * Pick results that fit any of given template ids.
     */
    templateIdAny?: string[];

    /**
     * Pick results touched after the specified timestamp.
     */
    touchedAfter?: number;

    /**
     * Pick results touched before the specified timestamp.
     */
    touchedBefore?: number;

    /**
     * Pick results touched by any of the specified user ids.
     */
    touchedByAny?: string[];

    /**
     * Pick results updated after the specified timestamp.
     */
    updatedAfter?: number;

    /**
     * Pick results updated before the specified timestamp.
     */
    updatedBefore?: number;

    /**
     * Pick results updated by any of the specified user ids.
     */
    updatedByAny?: string[];
};

export type OutputType = Omit<UserWithContextDocument, '_ip' | 'activeCampaignId' | 'blocked' | 'classicUserId' | 'isReseller' | 'isSuperAdmin' | 'mail' | 'password' | 'profile' | 'salesGotInfo' | 'verificationKey' | 'version' | 'token'>[];
