import {captureException} from '@sentry/react';
import {useEffect, useMemo, useState} from 'react';
import {getExclusiveLibraryItems} from '../../api/endpoints/exclusive-libraries/[exclusiveLibraryId]/library-items/get/frontend';
import type {ExclusiveLibraryDocument, LibraryDocument} from '../../database';
import type {JsonOf} from '../../interfaces/helpers';
import {emptyArray} from '../../util/arrays';
import {noop} from '../../util/functions';
import {useCurrentSession} from '../context';
import {useReadyState} from './useReadyState';
import {useVisibilityState} from './useVisibilityState';

export const useExclusiveLibraryItems = (libraryId: string, page: number, pageSize: number, filter?: {name?: string;}): {
    ready: boolean;
    fetchActive: boolean;
    libraryItems: JsonOf<LibraryDocument>[];
    libraryItemsCount: number;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const {ready} = useReadyState({dexieActive: true, fetchActive});
    const [libraryItems, setLibraryItems] = useState<JsonOf<LibraryDocument>[]>(emptyArray);
    const [libraryItemsCount, setLibraryItemsCount] = useState<number>(0);
    const visibilityState = useVisibilityState();
    const refresh = useMemo(() => visibilityState, [visibilityState]);

    useEffect(() => {
        if (!userId) {
            return;
        }

        let aborted = false;

        setFetchActive(true);
        getExclusiveLibraryItems({exclusiveLibraryId: libraryId}, {
            name: filter?.name || undefined
        }, (page - 1) * pageSize, page * pageSize - 1)
            .then(({json, response}) => {
                !aborted && setLibraryItems(response.ok && json ? json as JsonOf<ExclusiveLibraryDocument>[] : emptyArray);
                !aborted && setLibraryItemsCount(parseInt(response.headers.get('content-range')?.split('/')[1] ?? '0'));
            })
            .catch((error) => {
                captureException(error);
                !aborted && setLibraryItems(emptyArray);
            })
            .then(() => setFetchActive(false), noop);

        return () => {
            aborted = true;
        };
    }, [userId, page, pageSize, refresh, filter?.name]);

    return {
        ready,
        fetchActive,
        libraryItems,
        libraryItemsCount
    };
};
