import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getLocations} from '../../api/endpoints/locations/get/frontend';
import type {EntityDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {useCurrentSession} from '../context';
import {sortAlphabetically, useDexieResources} from './useDexieResources';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useEntities = (): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    entities: JsonOf<EntityDocument>[];
    createEntity: (entity: Partial<JsonOf<EntityDocument>>) => Promise<boolean>;
    updateEntity: (resourceId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: entityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resources: entities, dexieActive} = useDexieResources<EntityDocument>({
        sort: sortAlphabetically,
        table: dexie.entities,
        query: async () => entityId && dexie.entities
            .where('_id').equals(entityId)
            .or('context.entityId').equals(entityId)
            .distinct().toArray()
    }, [entityId]);

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId) {
            return;
        }

        setFetchActive(true);
        getLocations({})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        entities,
        createEntity: async (body) => {
            if (!userId || !entityId) {
                return false;
            }
            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'create',
                    resourceTableName: 'entities',
                    resourceId: body.offline_id!,
                    userId,
                    entityId,
                    body
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        },
        updateEntity: async (resourceId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !resourceId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'entities',
                    resourceId,
                    userId,
                    entityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
