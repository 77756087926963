import type {JsonSchemaQuery} from '../../../interfaces/models/json-schema-draft-04';
import {inspectionSectionSchema} from '../inspection-sections/inspectionSectionSchema';

export const inspectionsSchema: JsonSchemaQuery = {
    $jsonSchema: {
        additionalProperties: false,
        bsonType: 'object',
        properties: {
            _deleted: {
                bsonType: 'bool'
            },
            _id: {
                bsonType: 'objectId'
            },
            archived: {},
            automatedExport: {
                additionalProperties: false,
                bsonType: 'object',
                properties: {
                    pdf: {
                        additionalProperties: false,
                        bsonType: 'object',
                        properties: {
                            Bucket: {
                                bsonType: [
                                    'null',
                                    'string'
                                ]
                            },
                            Key: {
                                bsonType: [
                                    'null',
                                    'string'
                                ]
                            },
                            createdAt: {
                                bsonType: [
                                    'null',
                                    'number'
                                ]
                            },
                            email: {
                                bsonType: 'string'
                            },
                            errorCount: {
                                bsonType: 'number'
                            },
                            filePath: {
                                bsonType: 'string'
                            },
                            fileSize: {
                                bsonType: 'number'
                            },
                            signedUrl: {
                                bsonType: 'string'
                            }
                        },
                        required: [
                            'email',
                            'errorCount'
                        ]
                    }
                }
            },
            closed: {
                bsonType: 'number'
            },
            closedAt: {
                bsonType: 'number'
            },
            closedBy: {
                bsonType: 'objectId'
            },
            closesAt: {
                bsonType: 'number'
            },
            codeID: {
                bsonType: 'string'
            },
            conclusion: {
                bsonType: 'string'
            },
            conductedAt: {
                bsonType: 'number'
            },
            conductedBy: {
                bsonType: 'objectId'
            },
            context: {
                additionalProperties: false,
                bsonType: 'object',
                properties: {
                    entityId: {
                        bsonType: 'string'
                    }
                },
                required: [
                    'entityId'
                ]
            },
            created: {
                bsonType: 'number'
            },
            createdAt: {
                bsonType: 'number'
            },
            createdBy: {
                bsonType: 'objectId'
            },
            creator: {
                bsonType: 'string'
            },
            dueAt: {
                bsonType: 'number'
            },
            dueDate: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            dueTime: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            endDate: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            endTime: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            entity_id: {
                bsonType: 'objectId'
            },
            expiresAt: {
                bsonType: 'date'
            },
            incident: {
                bsonType: 'bool'
            },
            inspectorEmail: {
                bsonType: 'string'
            },
            inspectorName: {
                bsonType: 'string'
            },
            isDone: {
                bsonType: 'bool'
            },
            location: {
                bsonType: 'string'
            },
            locationName: {
                bsonType: 'string'
            },
            locked: {
                bsonType: 'number'
            },
            lockedAt: {
                bsonType: 'number'
            },
            lockedBy: {
                bsonType: 'objectId'
            },
            modified: {
                bsonType: 'number'
            },
            name: {
                bsonType: 'string'
            },
            offline_id: {
                bsonType: 'string'
            },
            opensAt: {
                bsonType: 'number'
            },
            planningsId: {
                bsonType: 'string'
            },
            predefinedComments: {
                bsonType: 'array',
                items: {
                    additionalProperties: false,
                    bsonType: 'object',
                    properties: {
                        value: {
                            bsonType: 'string'
                        }
                    }
                }
            },
            removed: {
                bsonType: 'number'
            },
            removedAt: {
                bsonType: 'number'
            },
            removedBy: {
                bsonType: 'objectId'
            },
            reportTemplateIds: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            },
            sections: {
                bsonType: 'array',
                items: {
                    oneOf: [inspectionSectionSchema, {
                        bsonType: 'null'
                    }]
                }
            },
            startDate: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            startTime: {
                bsonType: [
                    'number',
                    'string'
                ]
            },
            tags: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            },
            taskIds: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            },
            templateId: {
                bsonType: 'string'
            },
            templateName: {
                bsonType: 'string'
            },
            touchedAt: {
                bsonType: 'number'
            },
            touchedBy: {
                bsonType: 'objectId'
            },
            updated: {
                bsonType: 'number'
            },
            updatedAt: {
                bsonType: 'number'
            },
            updatedBy: {
                bsonType: 'objectId'
            },
            users: {
                bsonType: 'array',
                items: {
                    bsonType: 'string'
                }
            }
        },
        required: [
            '_id',
            'closed',
            'conclusion',
            'conductedAt',
            'context',
            'createdAt',
            'createdBy',
            'isDone',
            'location',
            'locked',
            'name',
            'removed',
            'reportTemplateIds',
            'sections',
            'tags',
            'taskIds',
            'templateId',
            'touchedAt',
            'touchedBy',
            'users'
        ]
    }
} as const;
