import {captureException} from '@sentry/react';
import {useEffect, useState} from 'react';
import {getLocation} from '../../api/endpoints/locations/[locationId]/get/frontend';
import type {EntityDocument} from '../../database';
import {addToQueue, dexie} from '../../dexie';
import type {JsonOf} from '../../interfaces/helpers';
import type {JsonPatch} from '../../types/JsonPatch';
import type {MongoDbObjectId, OfflineObjectId} from '../../types/MongoDb';
import {useCurrentSession} from '../context';
import {useDexieResource} from './useDexieResource';
import {useReadyState} from './useReadyState';
import {useVisibilityChange} from './useVisibilityChange';

export const useEntity = (entityId: string | undefined): {
    ready: boolean;
    dexieActive: boolean;
    fetchActive: boolean;
    entity: JsonOf<EntityDocument> | null;
    updateEntity: (entityId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => Promise<boolean>;
} => {
    const {currentSession} = useCurrentSession();
    const {user_id: userId, entity_id: contextEntityId} = currentSession ?? {};

    const [fetchActive, setFetchActive] = useState(true);
    const [visibilityState, setVisibilityState] = useState(0);

    const {resource: entity, dexieActive} = useDexieResource<EntityDocument>({
        table: dexie.entities,
        primaryKey: entityId
    });

    const {ready} = useReadyState({dexieActive, fetchActive});

    useVisibilityChange((visible) => {
        visible && setVisibilityState(Date.now());
    }, []);

    useEffect(() => {
        if (!userId || !entityId) {
            return;
        }

        setFetchActive(true);
        getLocation({locationId: entityId})
            .then(() => setFetchActive(false))
            .catch(() => setFetchActive(false));
    }, [userId, entityId, visibilityState]);

    return {
        ready,
        dexieActive,
        fetchActive,
        entity,
        updateEntity: async (entityId: MongoDbObjectId | OfflineObjectId, patch: JsonPatch) => {
            if (!userId || !entityId || !contextEntityId) {
                return false;
            }

            try {
                await addToQueue({
                    timestamp: Date.now(),
                    action: 'update',
                    resourceTableName: 'entities',
                    resourceId: entityId,
                    userId,
                    entityId: contextEntityId,
                    patch
                });

                return true;
            } catch (error) {
                captureException(error);
                return false;
            }
        }
    };
};
