import type {MouseEvent, ChangeEvent, FunctionComponent, ReactNode} from 'react';
import React, {useEffect, useRef, useState} from 'react';
import {ripple} from '../../../frontend/utilities/animations';
import './Checkbox.scss';

export type CheckboxValue = 'checked' | 'unchecked' | 'indeterminate';

type Awaitable<T> = T | Promise<T>;

export type CheckboxProps = {
    value: CheckboxValue;
    label: string | ReactNode;
    labelPosition?: 'left' | 'right';
    onChange: (value: CheckboxValue, event: ChangeEvent<HTMLInputElement>) => Awaitable<void>;
    role?: 'checkbox' | 'switch';
    className?: string;
    required?: boolean;
    disabled?: boolean;
    name?: string;
    onContextMenu?: (value: CheckboxValue, event: MouseEvent<HTMLLabelElement>) => Awaitable<void>;
};

/**
 * @deprecated
 */
export const Checkbox: FunctionComponent<CheckboxProps> = ({
    value,
    label,
    onChange,
    onContextMenu,
    className = '',
    role = 'checkbox',
    labelPosition = 'right',
    ...props
}) => {
    const ref = useRef<HTMLInputElement>(null);
    const [busy, setBusy] = useState(false);

    if (process.env.NODE_ENV === 'development') {
        useEffect(() => console.warn('Checkbox has been deprecated; please use /src/react/components/toggles/Checkbox instead!'), []);
    }

    async function handleChange(event: ChangeEvent<HTMLInputElement>): Promise<void> {
        if (busy) {
            event.preventDefault();
            return;
        }

        try {
            setBusy(true);
            await onChange(event.target.checked ? 'checked' : 'unchecked', event);
        } catch (error) {
            console.error(error);
        } finally {
            setBusy(false);
        }
    }

    function handleContextMenu(event: MouseEvent<HTMLLabelElement>): boolean {
        const {current} = ref;
        if (!onContextMenu || !current) {
            return true;
        }

        event.preventDefault();

        Promise
            .resolve(setBusy(true))
            .then(() => onContextMenu(current.checked ? 'unchecked' : 'checked', event))
            .catch(console.error)
            .then(() => setBusy(false)) || setBusy(false);

        return false;
    }

    useEffect(() => {
        ref.current!.indeterminate = value === 'indeterminate';
    }, [value, ref.current]);

    return (
        <label className={`checkbox checkbox--${role} checkbox--label-${labelPosition} ${className}`.trim()} onClick={busy ? undefined : ripple} onContextMenu={handleContextMenu}>
            {labelPosition === 'left' ? label : null}
            <input
                {...props}
                role={role}
                onChange={handleChange}
                type="checkbox"
                checked={value === 'checked'}
                aria-checked={value === 'indeterminate' ? 'mixed' : value === 'checked'}
                ref={ref}
            />
            {labelPosition === 'right' ? label : null}
        </label>
    );
};
