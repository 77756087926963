import {dexie} from '../../../../../dexie';
import {fetchApi} from '../../../../helpers/frontend';
import {HttpStatusCode} from '../../../../HttpStatusCode';
import type {BodyType, OutputType, ParamType, QueryType} from './shared';
import {method, path} from './shared';

export const removeUser = async (urlParams: ParamType) => {
    const result = await fetchApi<BodyType, OutputType, ParamType, QueryType>({
        method,
        path,
        urlParams
    });

    const {response} = result;
    if (response.ok || response.status === HttpStatusCode.NOT_FOUND) {
        await dexie.users.delete(urlParams.userId);
    }

    return result;
};

