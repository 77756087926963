import {_ as compile} from 'ajv/dist/compile/codegen/code';
import type {KeywordCxt} from 'ajv/dist/jtd';
import type Ajv from 'ajv/dist/jtd';
import format from 'ajv/dist/vocabularies/format/format';
import addBson from 'ajv-bsontype';
import addFormats from 'ajv-formats';

export const defineAjv = (ajv: Ajv): Ajv => {
    ajv.addKeyword('name');
    ajv.addKeyword('description');
    ajv.addKeyword('default');
    ajv.addKeyword('example');
    ajv.addKeyword({
        code(cxt: KeywordCxt) {
            cxt.pass(compile`${cxt.data}.length === ${cxt.schema}`);
        },
        keyword: 'length',
        schemaType: 'number',
        type: 'string'
    });
    ajv.addKeyword({
        code(cxt: KeywordCxt) {
            cxt.pass(compile`eval(${cxt.schema}).test(${cxt.data})`);
        },
        keyword: 'regex',
        schemaType: 'string',
        type: 'string'
    });
    ajv.addKeyword(format);
    addFormats(ajv);
    addBson(ajv);
    return ajv;
};
